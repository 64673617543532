import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from 'src/app/shared/cd-popup';
import { EXCEL_DEMO, FILE_TEMPLATE_NAME } from '../batch-upload-adjustment.model';
import { BatchUploadAdjustmentService } from '../batch-upload-adjustment.service';

@Component({
  selector: 'app-upload-file-format',
  templateUrl: './upload-file-format.component.html',
  styleUrls: ['./upload-file-format.component.scss'],
})
export class UploadFileFormatComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<UploadFileFormatComponent>, private batchUploadAdjustmentService: BatchUploadAdjustmentService) {}

  ngOnInit() {}

  createTemplate() {
    this.batchUploadAdjustmentService.downLoadExcel(EXCEL_DEMO, FILE_TEMPLATE_NAME);
  }

  cancel() {
    this.dialogRef.close();
  }
}
