import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { LayoutHeaderService } from 'src/app/core/services/layout-header.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { AdminHeaderConfig } from '../../layout/admin-layout/admin-header/admin-header-config';
import { EmployerProfileDetail, RelationshipTotalCount } from '../employer';
import { EmployerProfileInformationComponent } from '../employer-profile-information/employer-profile-information.component';
import { EmployerService } from '../employer.service';

@Component({
  selector: 'app-employer-profile-detail',
  templateUrl: './employer-profile-detail.component.html',
  styleUrls: ['./employer-profile-detail.component.scss'],
})
export class EmployerProfileDetailComponent implements OnInit {
  @ViewChild('erProfileInfo')
  erProfileInfoComponent!: EmployerProfileInformationComponent;
  erInfoDetailShow = false;

  PermissionAccess = PermissionAccess;

  PermissionItem = PermissionItem;

  relationshipTotalCount?: RelationshipTotalCount = {};

  id = '';

  detailLoading = false;

  scoreLoading = false;

  isHeaderSticky = false;

  get isLoading() {
    return this.detailLoading || this.scoreLoading;
  }

  isStaff = false;

  haveSqPermission = false;

  haveSubsidiaryPermission = false;

  _typeShow = false;

  constructor(
    private route: ActivatedRoute,
    private service: EmployerService,
    private layoutHeaderService: LayoutHeaderService,
    private router: Router,
    private permissionService: PermissionService,
    private toastAlert: ToastAlertService
  ) {}

  headerButton: AdminHeaderConfig = {
    title: 'er.profile.title',
    backButton: {
      text: 'Employer Enquiry Information Page',
      url: '#',
      method: this.back.bind(this),
    },
  };

  back() {
    this.router.navigate(['/employer/list']);
  }
  employerProfileDetail?: EmployerProfileDetail;

  ngOnInit(): void {
    document.documentElement.scrollTop = 0;
    this.layoutHeaderService.setHeaderButton(this.headerButton);

    this.getPermissions();

    this.id = this.route.snapshot.queryParams['id'];
    if (this.id) {
      this.initType(this.id);
      this.getProfileDetail(this.id);
      this.getTotalScore(this.id);
    }
  }

  getPermissions() {
    this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.CUSTOMER_EMPLOYER).then(havePermission => {
      if (havePermission) {
        this.isStaff = true;
      }
    });
    this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.CUSTOMER_EMPLOYER_SQ).then(havePermission => {
      if (havePermission) {
        this.haveSqPermission = true;
      }
    });
    this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.CUSTOMER_EMPLOYER_SUBSIDIARY).then(havePermission => {
      if (havePermission) {
        this.haveSubsidiaryPermission = true;
      }
    });
  }

  getProfileDetail(id: string) {
    this.detailLoading = true;
    this.service
      .getProfileDetail(id)
      .pipe(
        finalize(() => {
          this.detailLoading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0) {
          this.employerProfileDetail = data.data;
        } else {
          this.toastError(data.message);
        }
      });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  getTotalScore(id: string) {
    this.scoreLoading = true;
    this.service
      .getTotalScore(id)
      .pipe(
        finalize(() => {
          this.scoreLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0) {
          this.relationshipTotalCount = res.data;
        }
      });
  }

  erProfileInfoDetailShow() {
    this.erProfileInfoComponent.detailShow = true;
  }

  showPlaceholderHeight(isSticky: boolean) {
    this.isHeaderSticky = isSticky;
  }

  initType(id: string) {
    if (id.startsWith('ORSO') || id.startsWith('MACAUCPF') || id.startsWith('MACAUPPS')) {
      this._typeShow = true;
    }
  }
}
