import { Component, Inject, OnInit } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CDS_POPUP_DATA, MatDialogRef } from '@cds/ng-web-components/popup';

@Component({
  selector: 'app-has-been-sent-customer-popup',
  templateUrl: './has-been-sent-customer-popup.component.html',
  styleUrls: ['./has-been-sent-customer-popup.component.scss'],
})
export class HasBeenSentCustomerPopupComponent implements OnInit {
  buttonConfig: CdsButtonConfig = {
    size: 'md',
    style: 'primary',
    disabled: false,
  };
  constructor(
    private dialogRef: MatDialogRef<HasBeenSentCustomerPopupComponent>,
    @Inject(CDS_POPUP_DATA)
    public data: {
      ids: [];
    }
  ) {}

  ngOnInit(): void {}

  gotIt() {
    this.dialogRef.close({ agree: true });
  }
}
