/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit } from '@angular/core';
import { CdsPopupService, MatDialogRef, CDS_POPUP_DATA } from '@cds/ng-web-components/popup';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';
import { UserAgreementPopupComponent } from 'src/app/views/commission/user-agreement-popup.component';
import { I18N_KEY } from '../calendar.config';
import { CalendarService } from '../calendar.service';

@Component({
  selector: 'app-report-no-event',
  templateUrl: './report-no-event.component.html',
  styleUrls: ['./report-no-event.component.scss'],
})
export class ReportNoEventComponent implements OnInit {
  permissionAccess = PermissionAccess;
  permissionItem = PermissionItem;

  event: any;
  isReview = true;
  dataDisplayed: any[] = [];
  pageConfig: PageConfig = {
    pageSize: 10,
  };
  loading = false;
  columnsConfig: Array<ColumnConfig> = [
    { key: 'eventDate', title: 'Process Date' },
    { key: 'reportNo', title: 'Report No.' },
  ];
  constructor(
    public calendarService: CalendarService,
    @Inject(CDS_POPUP_DATA) public data: { isReview: boolean; event: any },
    private dialogRef: MatDialogRef<ReportNoEventComponent>,
    private cdsPopup: CdsPopupService
  ) {}

  ngOnInit(): void {
    this.isReview = this.data.isReview;
    this.event = this.data.event;
    this.dataDisplayed = this.data.event.items || [];
    this.dataDisplayed.sort((a, b) => a.reportNo - b.reportNo);
  }

  review() {
    this.calendarService.reviewReportNoEvents(this.data.event.flowId).subscribe(res => {
      if (res.result === ResponseResult.SUCCESS) {
        this.requestPop();
        this.calendarService.reviewEvents = null;
      } else {
        this.requestPop(res.message);
      }
    });
  }

  approve() {
    this.calendarService.approveReportNoEvents(this.data.event.flowId).subscribe(res => {
      this.requestPop(res.result === ResponseResult.SUCCESS ? '' : res.message);
    });
  }

  cancle() {
    this.dialogRef.close();
  }

  requestPop(message?: string) {
    const popConfig = {
      message: message || I18N_KEY.COMMON_ACTION_SUCCESS,
      continue: I18N_KEY.COMMON_GOT,
      type: 'alert',
    };

    const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, { data: popConfig });
    popupRef.afterClosed().subscribe(() => {
      if (!message) {
        this.dialogRef.close({
          isChange: true,
          event: this.data.event,
          isReviewMode: this.isReview,
        });
      }
    });
  }
}
