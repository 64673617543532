/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { finalize } from 'rxjs';
import moment from 'moment';

import { CdsLangService } from '@cds/ng-core/lang';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CDS_SIZE } from '@cds/ng-core/configuration';
import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';

import { BTN_CONFIG_SECONDARY } from 'src/app/config/btn.config';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';

import { CommissionAppealCaseService } from 'src/app/core/services/commission/commission-appeal-case.service';
import { CommissionDefaultScaleService } from 'src/app/views/system/commission-default-scale/commission-default-scale.service';
import { UserAgreementPopupComponent } from 'src/app/views/commission/user-agreement-popup.component';
import { I18N_KEY, PENDING_TRANSACTION_COLUMNS, COMM_FLAGS, COMM_FLAGS_OTHER, BLANK, PAGE_SIZE } from './appeal-case.config';
import { AgentCodeAsyncValidator } from './appeal-case.validators';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { AccountTypes } from 'src/app/core/models/enum/account-type.enum';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { PermissionService } from 'src/app/core/services/permission.service';
import { requiredValidator, agentCodeValidator, ScaleCodeAsyncValidator } from 'src/app/core/validators';
import { DefaultScaleType } from 'src/app/core/models/enum/default-scale-type.enum';

@Component({
  selector: 'app-appeal-case',
  templateUrl: './appeal-case.component.html',
  styleUrls: ['./appeal-case.component.scss'],
})
export class appealCaseComponent implements OnInit {
  @Input() commissionPoolId = '';
  permissionAccess = PermissionAccess;
  permissionItem = PermissionItem;
  searchRecord: any;
  btnCfg: CdsButtonConfig = BTN_CONFIG_SECONDARY;

  errors: any;
  isSearchloading = false;
  previousSearchParams: any;
  pageConfig: PageConfig = {};
  columnsConfig: Array<ColumnConfig> = PENDING_TRANSACTION_COLUMNS;
  dataDisplayed: Array<any> = [];

  defaultScales: any;
  editRow: any;
  dataMakeAppealSource: any;
  dataMakeAppeal: Array<any> = [];
  isEdit = false;
  isSaveLoading = false;
  commFlagConfig: CdsDropdownConfig = {
    options: [],
  };

  formGroup: FormGroup = new FormGroup({
    details: new FormArray([]),
    commFlag: new FormControl('', [Validators.required]),
    trfsppcCode: new FormControl('', {
      validators: [requiredValidator({ error: I18N_KEY.SCALE_CODE_NULL })],
      asyncValidators: [this.scaleCodeAsyncValidator.validate.bind(this.scaleCodeAsyncValidator)],
    }),
    trfspCode: new FormControl('', {
      validators: [requiredValidator({ error: I18N_KEY.SCALE_CODE_NULL })],
      asyncValidators: [this.scaleCodeAsyncValidator.validate.bind(this.scaleCodeAsyncValidator)],
    }),
    fshkpcCode: new FormControl('', {
      asyncValidators: [this.scaleCodeAsyncValidator.validate.bind(this.scaleCodeAsyncValidator)],
    }),
  });

  get makeAppeal() {
    return this.formGroup?.get('details') as FormArray;
  }
  get commFlag() {
    return this.formGroup?.get('commFlag') as FormControl;
  }
  get trfsppcCode() {
    return this.formGroup?.get('trfsppcCode') as FormControl;
  }
  get trfspCode() {
    return this.formGroup?.get('trfspCode') as FormControl;
  }
  get fshkpcCode() {
    return this.formGroup.get('fshkpcCode') as FormControl;
  }

  get isResetDisable() {
    if (this.dataMakeAppealSource.length !== this.dataMakeAppeal.length) {
      return false;
    }
    const rawRalue = this.formGroup.getRawValue();
    let tag = true;
    for (const key in rawRalue) {
      if (key === 'details') {
        rawRalue[key].forEach((item: any) => {
          const tempAgentInfo = this.dataMakeAppeal.find(agent => agent.agentCode == item.agentCode);
          if (!tempAgentInfo) {
            tag = false;
          } else {
            const keys = Object.keys(item);
            keys.forEach(key => {
              if (tempAgentInfo[key] != item[key]) {
                tag = false;
              }
            });
          }
        });
      } else {
        const rawRalueTemp = rawRalue[key] || '';
        const originalValueTemp = this.editRow[key] || '';
        if (rawRalueTemp !== originalValueTemp) {
          tag = false;
        }
      }
    }
    return tag;
  }

  get isSaveDisable() {
    let tag = false;
    const rawRalue = this.formGroup.getRawValue()['details'];
    rawRalue.forEach((item: any) => {
      const keys = Object.keys(item);
      let sumSharing = 0;
      keys.forEach(key => {
        if (key !== 'agentCode') {
          sumSharing += item[key] * 1;
        }
        if (!item[key]) {
          tag = true;
        }
      });
      if (sumSharing === 0) {
        tag = true;
      }
    });
    return tag || this.isResetDisable || this.formGroup.invalid || !this.formGroup.dirty || this.formGroup.pending || this.isSaveLoading;
  }

  constructor(
    private commissionAppealCaseService: CommissionAppealCaseService,
    private scaleCodeAsyncValidator: ScaleCodeAsyncValidator,
    private agentCodeAsyncValidator: AgentCodeAsyncValidator,
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService,
    private route: ActivatedRoute,
    private router: Router,
    private permissionService: PermissionService,
    private commissionDefaultScaleService: CommissionDefaultScaleService
  ) {
    this.permissionService.hasPermission(PermissionAccess.W, PermissionItem.COMM_INFO_INDIVIDUAL_TRF).then(hasPermission => {
      if (!hasPermission) {
        this.columnsConfig = PENDING_TRANSACTION_COLUMNS.slice(1);
      }
    });
    this.commFlag.valueChanges.subscribe(value => {
      const selector = this.commFlagConfig.options.find(item => item.value === value) as any;
      this.trfsppcCode.reset({
        value: selector?.detail?.IND_TRF_SP_PC?.trim() || '',
        disabled: value !== COMM_FLAGS_OTHER.value,
      });
      this.trfspCode.reset({
        value: selector?.detail?.IND_TRF_SP?.trim() || '',
        disabled: value !== COMM_FLAGS_OTHER.value,
      });
      this.fshkpcCode.reset({
        value: selector?.detail?.IND_FS_PC?.trim() || '',
        disabled: value !== COMM_FLAGS_OTHER.value,
      });
      this.validateAgentCode();
    });
    this.fshkpcCode.valueChanges.subscribe(() => {
      this.validateAgentCode();
    });
  }

  ngOnInit(): void {
    this.initDefaultScale();
  }

  initDefaultScale() {
    this.commissionDefaultScaleService
      .getList({
        page: 0,
        size: 10000,
        defaultScaleType: DefaultScaleType.MPF_IND,
      })
      .subscribe(res => {
        if (res.result === ResponseResult.SUCCESS) {
          const data = res.data.content || [];
          this.defaultScales = data.map((item: any) => {
            item.value = `${item.agentChannel}_${item.compensationRate}`;
            item.label = COMM_FLAGS[item.value as unknown as keyof typeof COMM_FLAGS];
            return item;
          });
          this.initData();
        } else {
          this.openAlertPop(res.message);
        }
      });
  }

  initData() {
    this.commissionPoolId = this.route.snapshot.paramMap.get('commissionPoolId') || '';
    const referenceNo = this.route.snapshot.paramMap.get('referenceNo') || '';
    if (this.commissionPoolId && referenceNo) {
      this.getMakeAppealData({
        referenceNo: referenceNo,
        page: 0,
        size: PAGE_SIZE,
        commissionPoolId: this.commissionPoolId,
      });
    } else {
      this.search({});
    }
  }

  search(params: any) {
    if (this.isSearchloading) {
      return;
    }
    this.previousSearchParams = null;
    this.pageConfig = {
      pageSize: PAGE_SIZE,
      current: 1,
    };
    const apiParams = { ...params, page: 0, size: PAGE_SIZE };
    if (this.commissionPoolId) {
      apiParams.commissionPoolId = this.commissionPoolId;
    }
    if (apiParams.receivedDateFrom) {
      apiParams.receivedDateFrom = moment(apiParams.receivedDateFrom, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    if (apiParams.receivedDateTo) {
      apiParams.receivedDateTo = moment(apiParams.receivedDateTo, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    this.getPendingData(apiParams);
  }

  getPendingData(params: any) {
    this.isSearchloading = true;
    this.commissionAppealCaseService
      .getPendingTransationData(params)
      .pipe(
        finalize(() => {
          this.isSearchloading = false;
        })
      )
      .subscribe(res => {
        if (res.result === ResponseResult.SUCCESS) {
          this.previousSearchParams = params;
          this.initTableData(res.data);
          this.errors = null;
        } else {
          this.initTableData({
            totalElements: 0,
            content: [],
          });
          this.errors = JSON.parse(res.message);
        }
      });
  }

  initTableData(data: any) {
    this.pageConfig = {
      ...this.pageConfig,
      totalCount: data.totalElements || 0,
    };
    const tempData = data.content || [];
    const dataDisplayed: Array<any> = [];
    tempData.forEach((item: any) => {
      item.commFlagLabel = item.commFlag && item.commFlag !== BLANK ? COMM_FLAGS[item.commFlag as unknown as keyof typeof COMM_FLAGS] : BLANK;
      if (item.receivedDate) {
        item.receivedDate = item.receivedDate ? moment(new Date(item.receivedDate)).format('DD/MM/YYYY') : '/';
      }
      if (item.appealDate) {
        item.appealDate = item.appealDate ? moment(new Date(item.appealDate)).format('DD/MM/YYYY') : '/';
      }
      if (item.cancelledOrRejectedDate) {
        item.cancelledOrRejectedDate = item.cancelledOrRejectedDate ? moment(new Date(item.cancelledOrRejectedDate)).format('DD/MM/YYYY') : '/';
      }
      item.isEditable = !item.cancelledOrRejectedDate;
      item.accountType = AccountTypes.find(list => list.value === item.accountType)?.label || item.accountType;
      item.details.forEach((detail: any) => {
        dataDisplayed.push({
          ...item,
          ...detail,
        });
      });
    });
    this.dataDisplayed = dataDisplayed;
  }

  rebackTo(item: any) {
    if (this.isEdit) {
      return;
    }
    this.router.navigate(['/commission/pool-info/'], {
      queryParams: {
        commissionPoolId: item.commissionPoolId,
        tabType: 'individualTransfer',
      },
    });
  }

  pageChange(ev: any) {
    if (this.pageConfig.current !== ev.current) {
      this.pageConfig.current = ev.current;
      let params = { ...this.pageConfig, size: PAGE_SIZE, page: (this.pageConfig.current as number) - 1 };
      if (this.previousSearchParams) {
        params = { ...this.previousSearchParams, ...params };
      }
      this.getPendingData(params);
    }
  }

  alertPendingTransaction() {
    const msg = this.langService.translate('commission.individualTransfer.pendingTransactionAlert');
    this.openPop(msg);
  }

  referenceClick(row: any) {
    if (this.isEdit) {
      return;
    }
    this.pageConfig = {
      pageSize: PAGE_SIZE,
      current: 1,
    };
    this.previousSearchParams = null;
    this.getMakeAppealData({
      referenceNo: row.referenceNo,
      commissionPoolId: row.commissionPoolId,
      size: PAGE_SIZE,
      page: 0,
    });
  }

  getMakeAppealData(params: any) {
    this.isSaveLoading = true;
    this.commissionAppealCaseService
      .getPendingTransationData(params)
      .pipe(
        finalize(() => {
          this.isSaveLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === ResponseResult.SUCCESS) {
          this.previousSearchParams = params;
          this.initTableData(res.data);
          this.initMakeAppeal(res.data);
        } else {
          this.openAlertPop(res.message);
        }
      });
  }

  initMakeAppeal(data: any) {
    const tempData = data.content || [];
    if (tempData.length) {
      this.dataMakeAppealSource = tempData[0].details.map((item: any) => {
        return { ...item };
      });
      this.editRow = tempData[0];
      this.initFromGroup();
      this.initCommFlag();
      this.isEdit = true;
    }
  }

  initFromGroup() {
    this.dataMakeAppeal = this.dataMakeAppealSource.map((item: any) => {
      return { ...item };
    });
    const tempArr: Array<FormGroup> = [];
    this.dataMakeAppeal.forEach((item: any) => {
      item.sharingPC = (item.sharingPC * 1).toFixed(3);
      item.sharingComm = (item.sharingComm * 1).toFixed(3);
      tempArr.push(this.createChildFormGroup(item));
    });
    this.commFlag.reset(this.editRow.commFlag);
    this.commFlag.markAsDirty();
    this.trfsppcCode.reset({
      value: this.editRow.trfsppcCode || '',
      disabled: this.editRow.commFlag !== COMM_FLAGS_OTHER.value,
    });
    this.trfspCode.reset({
      value: this.editRow.trfspCode || '',
      disabled: this.editRow.commFlag !== COMM_FLAGS_OTHER.value,
    });
    this.fshkpcCode.reset({
      value: this.editRow.fshkpcCode || '',
      disabled: this.editRow.commFlag !== COMM_FLAGS_OTHER.value,
    });
    this.formGroup.setControl('details', new FormArray(tempArr));
  }

  createChildFormGroup(item?: any) {
    return new FormGroup({
      agentCode: new FormControl(item ? item.agentCode : '', {
        initialValueIsDefault: true,
        validators: [requiredValidator({ error: I18N_KEY.AGENT_CODE_NULL }), agentCodeValidator({ error: I18N_KEY.AGENT_CODE_INVALID })],
        asyncValidators: [this.agentCodeAsyncValidator.myValidate(this.editRow, this.formGroup, this.dataMakeAppeal).bind(this.agentCodeAsyncValidator)],
      }),
      sharingPC: new FormControl(item ? item.sharingPC : ''),
      sharingComm: new FormControl(item ? item.sharingComm : ''),
    });
  }

  initCommFlag() {
    const options = this.defaultScales.filter((item: any) => {
      return item.scenario === this.editRow.scenario;
    });
    this.commFlagConfig.options = [...options, COMM_FLAGS_OTHER];
  }

  alertAccountCodeInfo() {
    const msg = this.langService.translate('commission.accountCode.futherInfo.tableArea');
    this.openPop(msg);
  }

  cancleEdit() {
    const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
      data: {
        message: I18N_KEY.COMMON_ACTION_CANCLE,
        cancel: I18N_KEY.COMMON_CANCLE,
        continue: I18N_KEY.COMMON_CONTINUE,
        type: 'confirm',
      },
    });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm && confirm.agree) {
        this.isEdit = false;
        this.editRow = null;
        this.dataMakeAppealSource = [];
        this.dataMakeAppeal = [];
      }
    });
  }

  addAgent() {
    this.dataMakeAppeal.push({
      agentCode: '',
      sharingPC: '',
      sharingComm: '',
      agentChannel: '',
    });
    this.makeAppeal.push(this.createChildFormGroup());
  }

  deleteAgent(index: number) {
    this.dataMakeAppeal.splice(index, 1);
    this.makeAppeal.removeAt(index);
  }

  reset() {
    if (this.isResetDisable) {
      return;
    }
    this.initFromGroup();
  }

  save() {
    if (this.isSaveDisable) {
      return;
    }
    const value = this.makeAppeal.getRawValue();
    let totalSharingPC = 0;
    let totalSharingComm = 0;
    let duplicateAgent = false;
    const agents: Array<any> = [];
    value.forEach((item: any) => {
      totalSharingPC += item.sharingPC * 1;
      totalSharingComm += item.sharingComm * 1;
      if (agents.includes(item.agentCode)) {
        duplicateAgent = true;
      } else {
        agents.push(item.agentCode);
      }
    });
    if (duplicateAgent) {
      this.openAlertPop(I18N_KEY.AGENT_CODE_DUPLICATE);
      return;
    }
    if (totalSharingPC != 100 || totalSharingComm != 100) {
      this.openAlertPop(I18N_KEY.SHARING_ERROR);
      return;
    }
    this.commissionAppealCaseService.saveMakeAppeal(this.createParams()).subscribe(res => {
      if (res.result === ResponseResult.SUCCESS) {
        this.getMakeAppealData({
          referenceNo: this.editRow.referenceNo,
          commissionPoolId: this.editRow.commissionPoolId,
          size: PAGE_SIZE,
          page: (this.pageConfig.current as number) - 1,
        });
        this.openAlertPop(I18N_KEY.COMMON_ACTION_SUCCESS);
      } else {
        this.openAlertPop(res.message);
      }
    });
  }

  createParams() {
    if (!this.editRow) {
      return null;
    }
    const getRawValue = this.formGroup.getRawValue();
    getRawValue.trfsppcCode = getRawValue.trfsppcCode.trim();
    getRawValue.trfspCode = getRawValue.trfspCode.trim();
    getRawValue.fshkpcCode = getRawValue.fshkpcCode.trim();
    const obj: any = {
      referenceNo: this.editRow.referenceNo,
      commissionPoolId: this.editRow.commissionPoolId,
      ...getRawValue,
    };
    return obj;
  }

  openAlertPop(key: string) {
    const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
      data: {
        continue: I18N_KEY.COMMON_GOT,
        type: 'alert',
        message: key,
      },
      size: CDS_SIZE['MEDIUM'],
    });
    popupRef.afterClosed().subscribe(() => {});
  }

  openPop(msg: string) {
    const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
      size: CDS_SIZE['SMALL'],
      data: {
        message: msg,
        continue: I18N_KEY.COMMON_GOT,
        type: 'alert',
        actionRight: true,
      },
    });
    popupRef.afterClosed().subscribe(() => {});
  }

  validateAgentCode() {
    const isMPFPAP = this.editRow.accountType === 'MPF-PAP';
    const isPP = this.editRow.formType === 'PP';
    const isOther = this.commFlag.value === COMM_FLAGS_OTHER.value;
    const fshkpcCode = this.fshkpcCode.value;
    if (!this.makeAppeal.controls.length) {
      return;
    }
    this.dataMakeAppeal.forEach((item, index) => {
      const control = (this.makeAppeal.controls[index] as FormGroup).controls['agentCode'];
      let error = control.errors ? control.errors['error'] : null;
      if (error && error === I18N_KEY.SCALE_CODE_LIMIT) {
        control.setErrors(null);
        error = null;
      }
      const isAgency = item.agentChannel === 'AGENCY' || item.agentChannel === 'Agency';
      if (control.value && isOther && fshkpcCode && !error && (!isAgency || !isMPFPAP || !isPP)) {
        control.setErrors({
          error: I18N_KEY.SCALE_CODE_LIMIT,
        });
      }
    });
  }

  scaleCodeInput(event: any, key: string) {
    const control = this.formGroup.get(key);
    let value = event.target.value;
    value = value.toUpperCase().replace(/[^\a-\z\A-\Z0-9\ ]/g, '');
    value = value.substring(0, 6);
    const pos = event.target.selectionEnd;
    control?.setValue(value);
    event.target.setSelectionRange(pos, pos);
  }

  sharingInput(event: any, index: number, key: string) {
    const control = this.makeAppeal.get(index.toString())?.get(key);
    let value = event.target.value;
    value = value.match(/^(([1-9]\d?)|100|0|(\d{1,2})\.(\d){0,3})$/g); // 只允許數字3位小數點3位
    const pos = event.target.selectionEnd;
    if (value === control?.value) {
      return;
    }
    control?.setValue(value);
    event.target.setSelectionRange(pos, pos);
  }

  sharingChange(event: any, index: number, key: string) {
    if (event.target) {
      const control = this.makeAppeal.get(index.toString())?.get(key);
      let value = event.target.value;
      value = value.match(/^(([1-9]\d?)|100|0|(\d{1,2})\.(\d){0,3})$/g); // 只允許數字3位小數點3位
      value = parseFloat(value ? value : 0).toFixed(3);
      if (value === control?.value) {
        return;
      }
      control?.setValue(value);
    }
  }
}
