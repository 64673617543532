/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl, ValidationErrors } from '@angular/forms';

export const numberValidator = (errors?: ValidationErrors) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value?.trim();
    if (!value) {
      return null;
    }
    const reg = /^[0-9]+$/; // eslint-disable-line
    if (!reg.test(value)) {
      return errors || { numberValidator: true };
    }
    return null;
  };
};

export const demicalValidator = (errors?: ValidationErrors) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value?.trim();
    if (!value) {
      return null;
    }
    const reg = /^-?[1-9]*(\.\d*)?$|^-?0(\.\d*)?$/; // eslint-disable-line
    if (!reg.test(value)) {
      return errors || { demicalValidator: true };
    }
    return null;
  };
};
