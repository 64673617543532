<app-cd-popup>
  <cds-popup-title *ngIf="data.title">{{ data.title | lang }}</cds-popup-title>
  <cds-popup-content>
    <div *ngIf="data.message" innerHtml="{{ data.message | lang : data.params }}"></div>
    <div *ngIf="!data.message" innerHtml="{{ 'common.action.cancel' | lang : data.params }}"></div>
    <div *ngIf="data.subMessage" innerHtml="{{ data.subMessage | lang }}"></div>
  </cds-popup-content>
  <cds-popup-actions>
    <cds-button size="sm" [style]="'secondary'" (click)="cancle()">{{ 'common.cancel' | lang }} </cds-button>
    <cds-button size="sm" (click)="continue()">{{ data.continue || 'common.continue' | lang }} </cds-button>
  </cds-popup-actions>
</app-cd-popup>
