import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsFormFieldModule } from '@cds/ng-web-components/form-field';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsPopupModule } from '@cds/ng-web-components/popup';
import { CdsProgressModule } from '@cds/ng-web-components/progress';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';
import { CdsDatepickerModule } from '@cds/ng-web-components/datepicker';
import { CdsFileUploadModule } from '@cds/ng-experimental/file-upload';

import { RequestFormatComponent } from './manual-adjustment/request-format/request-format.component';
import { ApeUploadFileComponent } from './manual-adjustment/ape-upload-file/ape-upload-file.component';

import { ApeManualComponent } from './ape-manual.component';
import { NbvRatioComponent } from './nbv-ratio/nbv-ratio.component';
import { ManualAdjustmentComponent } from './manual-adjustment/manual-adjustment.component';
import { CdsToastModule } from '@cds/ng-web-components/toast';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CdsDropdownModule,
    SharedModule,
    CdsDatepickerModule,
    CdsFileUploadModule,
    CdsIconModule,

    CdsLanguageModule,
    CdsFormFieldModule,
    CdsTextfieldModule,
    CdsAssistiveTextModule,
    CdsButtonModule,
    CdsPopupModule,
    CdsProgressModule,
    CdsToastModule,
  ],
  declarations: [ApeManualComponent, NbvRatioComponent, ManualAdjustmentComponent, RequestFormatComponent, ApeUploadFileComponent],
})
export class ApeManualModule {}
