/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BasicResponse, IPageResponse } from '../../../core/models/response/response';
import { environment } from 'src/environments/environment';
import { NbvRatio } from 'src/app/core/models/enum/nbv-ratio.enum';
import { aoaToSheet, FileType } from 'src/app/utils/xlsx';
import { EXCEL_COLUMNS } from './ape-manual.model';

@Injectable({
  providedIn: 'root',
})
export class CommissionApeManualService {
  constructor(private http: HttpClient) {}

  getList(params: any): Observable<BasicResponse<IPageResponse<ApeManaulAdjustItem>>> {
    return this.http.get<BasicResponse<IPageResponse<ApeManaulAdjustItem>>>(
      `${environment.apiPrefix}${environment.commissionServiceExt}${'/ape-manual/list'}`,
      {
        params: params,
      }
    );
  }

  uploadFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}${'/ape-manual/upload'}`, formData);
  }

  delete(id: string): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/ape-manual/delete/${id}`, null);
  }

  validate(id: string): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/ape-manual/validate/${id}`, null);
  }

  getNbvRatios(): Observable<BasicResponse<NbvRatios>> {
    return this.http.get<BasicResponse<NbvRatios>>(`${environment.apiPrefix}${environment.commissionServiceExt}${'/setting/nbv-ratio/query'}`);
  }

  saveNbvRatios(params: any): Observable<BasicResponse<NbvRatios>> {
    return this.http.post<BasicResponse<NbvRatios>>(`${environment.apiPrefix}${environment.commissionServiceExt}${'/setting/nbv-ratio/save'}`, params);
  }

  downLoadExcel(data: Array<any>, fileName: string, isDetail?: boolean) {
    const excelData: any[] = [];
    let arr = [...EXCEL_COLUMNS];
    if (!isDetail) {
      arr = arr.slice(0, -1);
    }
    excelData.push(arr);
    data.forEach(item => {
      const temp = [];
      temp.push(item.accountType);
      temp.push(item.accountCode);
      temp.push(item.memberCode);
      temp.push(item.agentCode);
      temp.push(item.adjustType);
      temp.push(item.negativeFlag);
      temp.push(item.apeAum);
      temp.push(item.vsfTrailerFee);
      if (isDetail) {
        temp.push(item.resultMsg);
      }
      excelData.push(temp);
    });
    aoaToSheet(
      {
        fileName,
        fileType: FileType.XLSX,
      },
      excelData
    );
  }
}

export interface ApeManaulAdjustItem {
  uploadDate: string;
  conversedFileName: string;
  createBy: CreaterBy;
  Status: string;
  validatedSuccess: string;
  id: string;
  status: string;
}
export interface CreaterBy {
  id?: string;
  name: string;
}

export interface NbvRatios {
  lastUpdateTime: string;
  lastUpdater: string;
  nbvRatios?: Array<NbvRatioItem>;
}
export interface NbvRatioItem {
  apeType: NbvRatio;
  value: number;
}
