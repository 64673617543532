/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CDS_POPUP_DATA, CdsPopupService } from '@cds/ng-web-components/popup';
import { NotifyService } from 'src/app/core/services/notify.service';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { EEEmpfDetailSimple, EREmpfDetailSimple } from './add-md-reference-number-popup.model';
import { CdsOption } from '@cds/ng-core/configuration';

@Component({
  selector: 'app-add-md-reference-number-popup',
  templateUrl: './add-md-reference-number-popup.component.html',
  styleUrls: ['./add-md-reference-number-popup.component.scss'],
})
export class AddMdReferenceNumberPopupComponent implements OnInit {
  step = 1;
  mdIffControl: FormControl = new FormControl('', {
    initialValueIsDefault: true,
  });
  eeEmpfDetailSimple?: EEEmpfDetailSimple;
  erEmpfDetailSimple?: EREmpfDetailSimple;

  get isNoChange() {
    return this.mdIffControl.value === '';
  }

  options: CdsOption[] = [];
  search(option: CdsOption) {
    console.log(option);
  }

  constructor(
    private dialogRef: MatDialogRef<AddMdReferenceNumberPopupComponent>,
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService,
    @Inject(CDS_POPUP_DATA)
    public data: {
      empfNo: string;
      prodType: 'EE' | 'ER';
    },
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    private notifyService: NotifyService
  ) {}

  ngOnInit(): void {
    this.cdHttpServeService.get<any>('/ext/eb-ssms-sales-journey-service/empf/detailSimpleByEmpfNo', { empfNo: this.data.empfNo }).subscribe({
      next: res => {
        if (res.result !== 0) {
          this.alert.warning('Warning!', `${res.message}`);
        } else {
          if (this.data.prodType === 'EE') {
            this.eeEmpfDetailSimple = res.data;
          } else if (this.data.prodType === 'ER') {
            this.erEmpfDetailSimple = res.data;
          }
        }
      },
      error: err => {
        this.alert.error('Error!', err);
      },
    });
  }

  closeCurrentPopup() {
    this.dialogRef.close();
  }

  onReset() {
    this.step = 1;
    this.mdIffControl.reset();
  }

  onSubmit() {
    this.notifyService.screenLoadingSubject.next(true);
    this.cdHttpServeService
      .post('/ext/eb-ssms-sales-journey-service/empf/addMdNo', {
        mdNo: this.mdIffControl.value,
        empfNo: this.data.empfNo,
      })
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            this.dialogRef.close();
            this.cdsPopup.open(AlertPopupComponent, {
              size: 'sm',
              data: {
                message: this.langService.translate('common.action.change.success.italic'),
                buttonName: this.langService.translate('common.gotItWithExclamation'),
              },
            });
          }
          this.notifyService.screenLoadingSubject.next(false);
        },
        error: err => {
          this.alert.error('Error!', err);
          this.notifyService.screenLoadingSubject.next(false);
        },
      });
  }

  get isDisabledAdd() {
    return this.mdIffControl.value === '';
  }

  onBackToEdit() {
    this.step = 1;
  }

  onAdd() {
    if (this.isDisabledAdd) {
      return;
    }

    this.step = 2;
  }
}
