/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnConfig } from 'src/app/shared/data-table';

export const APE_MANUAL_ADJUSTMENT_UPLOAD_COLUMNS: Array<ColumnConfig> = [
  { key: 'createTime', sticky: true, title: 'Upload Date' },
  { key: 'fileName', sticky: true, title: 'File Name' },
  { key: 'processor', sticky: true, title: 'Processor' },
  { key: 'batchStatusLabel', title: 'Status' },
  { key: 'validatedCountStr', title: 'Pass Validate' },
  { key: 'updatedCountStr', title: 'Success Updated' },
  { key: 'lastUpdateTime', title: 'Last Updated Date' },
  { key: 'action', title: 'Action' },
];

export const EXCEL_COLUMNS = [
  'Account Type',
  'Employer /Member Account Code',
  'Member Account Code [PAC Flag]',
  'Agent Code',
  'Adjust type',
  'Negative Flag',
  'APE/AUM',
  'VSF/Trailer Fee',
  'Result',
];

export const EXCEL_DEMO = [
  {
    accountType: 'MPF-ER',
    accountCode: '20000001',
    memberCode: '',
    agentCode: '300001',
    adjustType: '112',
    negativeFlag: '',
    apeAum: '100.00',
    vsfTrailerFee: '00',
    result: '',
  },
];
export const FILE_TEMPLATE_NAME = 'APE_VSF_AUM_TRAILERFEE_template';
export const BYTE_OF_10M = 10485760;
export const FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const PAGE_SIZE = 10;
export const I18N_KEY = {
  COMIS_VALIDATE_CONFIRM: 'commission.ape.validateWarning',
  FILE_ERROR: 'Please use standard template to upload / Over 100 records in the file.',
};
