import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '../cd-popup';

@Component({
  selector: 'app-will-be-lose-input',
  templateUrl: './will-be-lose-input.component.html',
  styleUrls: ['./will-be-lose-input.component.scss'],
})
export class WillBeLoseInputComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<WillBeLoseInputComponent>) {}

  ngOnInit(): void {}

  onCancel() {
    this.dialogRef.close({ agree: false });
  }

  onContinue() {
    this.dialogRef.close({ agree: true });
  }
}
