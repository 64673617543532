import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';

@Injectable({
  providedIn: 'root',
})
export class ValidateService {
  constructor(private cdHttpServeService: CdHttpServeService) {}

  agentValidate = (control: AbstractControl) => {
    return new Promise<ValidationErrors | null>(resolve => {
      if (control.value === '' || control.value == undefined) {
        resolve(null);
      }

      if (control.value.length >= 4) {
        this.cdHttpServeService.post(`/ext/eb-ssms/customer-service/add-delegation/validate`, { agentCode: control.value }).subscribe({
          next: res => {
            if (res.result === 0) {
              resolve(null);
            }
            resolve({ invalid: true });
          },
          error: () => {
            resolve({ invalid: true });
          },
        });
      } else {
        resolve({ invalid: true });
      }
    });
  };
}
