import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from 'src/app/shared/cd-popup';
import { CommissionApeManualService } from 'src/app/views/commission/ape-manual/commission-ape-manual.service';
import { EXCEL_DEMO, FILE_TEMPLATE_NAME } from '../../ape-manual.model';

@Component({
  selector: 'app-request-format',
  templateUrl: './request-format.component.html',
  styleUrls: ['./request-format.component.scss'],
})
export class RequestFormatComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<RequestFormatComponent>, private commissionApeManualAdjustService: CommissionApeManualService) {}

  ngOnInit(): void {}

  createTemplate() {
    this.commissionApeManualAdjustService.downLoadExcel(EXCEL_DEMO, FILE_TEMPLATE_NAME);
  }

  cancel() {
    this.dialogRef.close();
  }
}
