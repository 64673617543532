import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ChatUser } from '../json/canned';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ViewChatComponent } from './view-chat/view-chat.component';
import { ChatService } from 'src/app/views/chatbot-admin/_services/chat.service';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { Alerts } from '../../_common/alerts';
import { PageConfig } from 'src/app/shared/data-table';

@Component({
  selector: 'app-chat-history',
  templateUrl: './chat-history.component.html',
  styleUrls: ['./chat-history.component.scss'],
})
export class ChatHistoryComponent implements OnInit {
  public pageCount: IPaginationCount = { current: 1, count: 1 };
  @Input() frontPagination = true;

  loading: boolean = false;
  count = 0;
  rows: any = [];
  cso: any = '';
  agent: any = '';
  roomId: any = '';
  type: any = '';
  keyword: any = '';
  filterRows = [];
  headers = [
    { key: 'cso', title: 'CSO' },
    { key: 'agent', title: 'Agent' },
    { key: 'enquiryType', title: 'Type' },
    { key: 'roomId', title: 'Session Id' },
    { key: 'date', title: 'Date' },
    { key: 'liveChatDuration', title: 'Chat Duration(Minutes)' },
    { key: 'schemeCd', title: 'Action' },
  ];
  modalOptions: NgbModalOptions = {};
  modalRef: any;
  pageConfig: PageConfig = {
    current: 1,
    pageSize: 10,
    totalCount: 0,
  };

  constructor(public modalService: NgbModal, public chatServices: ChatService, public cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.getChatHistory();
  }

  view(item: any) {
    this.openFormModal(item);
  }

  download(item: any) {
    this.chatServices.downloadChatHistor(item.roomId).subscribe(resp => {
      this.loading = false;
      if (resp) {
        if (resp.size > 0) {
          const blob = new Blob([resp], { type: resp.type });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          const contentDisposition = resp.headers?.get('content-disposition');
          let fileName = 'report';
          if (contentDisposition) {
            const match = contentDisposition.match(/filename="(.+)"/);
            if (match) {
              fileName = match[1];
            }
          }
          link.download = fileName;
          link.click();
          window.URL.revokeObjectURL(url);
        } else {
          Alerts.showInfoMessage('Report is not available');
        }
      } else {
        Alerts.showErrorMessage('No File Found');
      }
    });
  }

  updateFilteredRows() {
    const currentPage = this.pageConfig.current ?? 1; // Default to page 1 if undefined
    const pageSize = this.pageConfig.pageSize ?? 5; // Default to 5 rows if undefined
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    this.filterRows = this.rows.slice(startIndex, endIndex);
    this.pageConfig.totalCount = this.rows.length;
  }

  handlePageChange(event: { current: number; pageSize: number }) {
    debugger;
    if (this.pageConfig.current !== event.current) {
      this.pageConfig.current = event.current;
      this.pageConfig.pageSize = event.pageSize;
      this.getChatHistory();
    }
  }
  getChatHistory() {
    this.loading = true;
    this.filterRows = [];
    debugger;
    this.chatServices.getCloseChatList(this.cso, this.agent, this.type, this.keyword, this.pageConfig).subscribe(resp => {
      this.loading = false;
      if (resp) {
        this.rows = resp?.data
          ?.sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime())
          .map((item: any) => {
            let obj = {
              id: item.roomId,
              roomId: item.roomId,
              liveChatDuration: item.liveChatDuration,
              enquiryType: item.enquiryType,
              cso: item.agent ? item.agent : '-',
              agent: item.userc ? item.userc : '-',
              date: item.users[0].msgDate,
            };
            return obj;
          })
          .filter((item: any) => item !== null)
          .sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime());
      }
      this.pageConfig = {
        ...this.pageConfig,
        totalCount: resp?.total_count,
      };
      this.filterRows = this.rows;
    });
  }

  openFormModal(item: any) {
    this.modalOptions.backdrop = 'static';
    this.modalOptions.keyboard = false;
    this.modalOptions.size = 'lg';
    this.modalOptions.centered = true;
    this.modalRef = this.modalService.open(ViewChatComponent, this.modalOptions);
    this.modalRef.componentInstance.data = item;
    this.modalRef.result.then((result: any) => {}).catch((error: any) => {});
  }

  Delete(val: any) {}
  Edit(val: any) {}
}
