/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { filter, finalize } from 'rxjs';

import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';

import { ResponseResult } from 'src/app/core/models/response/response-result';

import { CommissionJournalService } from 'src/app/core/services/commission/commission-journal.service';

import { FileType, aoaToSheet } from 'src/app/utils/xlsx';

import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';
import { UserAgreementPopupComponent } from '../user-agreement-popup.component';
import {
  DATE_TYPE_OPTS,
  ACCOUNT_TYPE_OPTIONS,
  FILE_NAME,
  I18N_KEY,
  APE_JOURNAL_COLUMNS,
  PAGE_SIZE,
  DOWN_LOAD_MAX,
  EXCEL_COLUMNS,
  FILE_HEADER,
  TRANSACTION_TYPE_OPTIONS,
  CHANNEL_OPTIONS,
} from './ape-journal.config';

import { CDS_SIZE } from '@cds/ng-core/configuration';
import {
  dateRangeInCompleteValidator,
  dateRangeInvalidValidator,
  dateValidator,
  agentCodeValidator,
  numberValidator,
  requiredValidator,
} from 'src/app/core/validators';
import { CommissionCode } from 'src/app/core/models/enum/commission-code.enum';

@Component({
  selector: 'app-ape-journal',
  templateUrl: './ape-journal.component.html',
  styleUrls: ['./ape-journal.component.scss'],
})
export class ApeJournalComponent implements OnInit {
  isDownLoadingPdf = false;

  dateTypeConfig = { options: DATE_TYPE_OPTS };
  accountTypeOptions = ACCOUNT_TYPE_OPTIONS;
  transactionTypesOptions = TRANSACTION_TYPE_OPTIONS;
  channelOptions = CHANNEL_OPTIONS;

  isDownLoading = false;
  isOverMax = false;
  isShowSearchDatePicker = true; // Fix DatePicker Bug

  formGroup = new FormGroup(
    {
      dateType: new FormControl(DATE_TYPE_OPTS[0].value, {
        initialValueIsDefault: true,
      }),
      runDateFrom: new FormControl('', {
        initialValueIsDefault: true,
        validators: [requiredValidator({ error: I18N_KEY.DATE_From_NULL }), dateValidator({ error: I18N_KEY.INVALID_RUN_DATE })],
      }),
      runDateTo: new FormControl('', {
        initialValueIsDefault: true,
        validators: [requiredValidator({ error: I18N_KEY.DATE_TO_NULL }), dateValidator({ error: I18N_KEY.INVALID_RUN_DATE })],
      }),
      accountType: new FormControl('', {
        initialValueIsDefault: true,
      }),
      transactionType: new FormControl('', {
        initialValueIsDefault: true,
      }),
      agentChannel: new FormControl('', {
        initialValueIsDefault: true,
      }),
      employerAccountCode: new FormControl('', {
        validators: [
          numberValidator({
            error: I18N_KEY.ACCOUNT_NO_INVALID,
          }),
        ],
        initialValueIsDefault: true,
      }),
      memberAccountCode: new FormControl('', {
        validators: [
          numberValidator({
            error: I18N_KEY.MEMBER_NO_INVALID,
          }),
        ],
        initialValueIsDefault: true,
      }),
      agentCode: new FormControl('', {
        validators: [
          agentCodeValidator({
            error: I18N_KEY.AGENT_CODE_INVALID,
          }),
        ],
        initialValueIsDefault: true,
      }),
    },
    [
      dateRangeInCompleteValidator('runDateFrom', 'runDateTo', { error: I18N_KEY.DATE_RANGE_INCOMPLETE }),
      dateRangeInvalidValidator('runDateFrom', 'runDateTo', { error: I18N_KEY.INVALID_DATE_RANGE }),
    ]
  );

  previousSearchParams: any;
  pageConfig: PageConfig = {};
  loading = false;
  dataDisplayed: any[] = [];
  columnsConfig: Array<ColumnConfig> = APE_JOURNAL_COLUMNS;

  private _pageSize = PAGE_SIZE;

  get dateType() {
    return this.formGroup.get('dateType') as FormControl;
  }

  get runDateFrom() {
    return this.formGroup.get('runDateFrom') as FormControl;
  }

  get runDateTo() {
    return this.formGroup.get('runDateTo') as FormControl;
  }

  get accountType() {
    return this.formGroup.get('accountType') as FormControl;
  }

  get transactionType() {
    return this.formGroup.get('transactionType') as FormControl;
  }

  get agentChannel() {
    return this.formGroup.get('agentChannel') as FormControl;
  }

  get employerAccountCode() {
    return this.formGroup.get('employerAccountCode') as FormControl;
  }

  get memberAccountCode() {
    return this.formGroup.get('memberAccountCode') as FormControl;
  }

  get agentCode() {
    return this.formGroup.get('agentCode') as FormControl;
  }

  get totalElements() {
    return this.pageConfig.totalCount || 0;
  }

  get searchDisabled() {
    const fromDate = this.runDateFrom.value;
    const toDate = this.runDateTo.value;
    const accountType = this.accountType.value;
    const transactionType = this.transactionType.value;
    const channel = this.agentChannel.value;
    const accountNo = this.employerAccountCode.value?.trim();
    const memberNo = this.memberAccountCode.value?.trim();
    const agentCode = this.agentCode.value?.trim();
    const hasValue = accountType || transactionType || channel || accountNo || memberNo || agentCode;
    return !fromDate || !toDate || !this.formGroup.dirty || this.formGroup.invalid || !hasValue;
  }

  get downloadDisabled() {
    return !this.previousSearchParams || this.isDownLoading || !this.totalElements;
  }

  constructor(private commissionJournalService: CommissionJournalService, private cdsPopup: CdsPopupService) {}

  ngOnInit() {}

  search() {
    this.previousSearchParams = null;
    this.pageConfig = {
      pageSize: PAGE_SIZE,
      current: 1,
    };
    this.getdata();
  }

  createParams() {
    const params: any = {
      page: (this.pageConfig.current || 1) - 1,
      size: this._pageSize,
    };
    if (this.previousSearchParams) {
      return { ...this.previousSearchParams, ...params };
    }
    const value = { ...this.formGroup.value };
    const keys: string[] = Object.keys(value);
    keys.forEach(key => {
      if (value[key]) {
        params[key] = value[key];
      }
    });
    if (params.runDateFrom) {
      params.runDateFrom = moment(params.runDateFrom, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    if (params.runDateTo) {
      params.runDateTo = moment(params.runDateTo, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    return params;
  }

  reset() {
    this.previousSearchParams = null;
    this.formGroup.reset();
    this.dataDisplayed = [];
    this.isShowSearchDatePicker = false;
    setTimeout(() => {
      this.isShowSearchDatePicker = true; // Fix DatePicker Bug
    });
  }

  download() {
    if (this.isOverMax) {
      this.openAlertPop(I18N_KEY.DOWN_LOAD_MAX_FAIL);
      return;
    }

    if (this.downloadDisabled) {
      return;
    }

    this.isDownLoading = true;
    const params = {
      ...this.previousSearchParams,
      current: 1,
      size: this.totalElements,
    };
    this.commissionJournalService
      .getApeData(params)
      .pipe(
        finalize(() => {
          this.isDownLoading = false;
        }),
        filter(res => res.result === ResponseResult.SUCCESS)
      )
      .subscribe((res: any) => {
        const data = this.createData(res.data.content || []);
        const excelData = this.createExcelData(data);
        aoaToSheet(
          {
            fileName: `${FILE_NAME} ${moment().format('YYYYMMDD_HHmmss')}`,
            fileType: FileType.CSV,
          },
          excelData
        );
      });
  }

  createExcelData(data: any[]) {
    const excelData: any[] = [];
    excelData.push([`${FILE_HEADER}${moment().format('YYYY-MM-DD HH:MM:SS')}`]);
    excelData.push(EXCEL_COLUMNS);
    data.forEach(item => {
      const temp = [];
      temp.push(item.runDate);
      temp.push(item.txnEffectiveDate);
      temp.push(item.employerAccountCode);
      temp.push(item.memberAccountCode);
      temp.push(item.accountType);
      temp.push(item.transactionType);
      temp.push(item.paymentSubTransRefNo);
      temp.push(item.groupTransRefNo);
      temp.push(item.pacFlag);
      temp.push(item.processor);
      temp.push(item.specialIndicator);
      temp.push(item.agentChannel);
      temp.push(item.branch);
      temp.push(item.agentCode);
      // temp.push(item.agentName);
      temp.push(item.share);
      temp.push(item.commissionCode);
      temp.push(item.apeType);
      temp.push(item.apeAmount);
      temp.push(item.apeSystemAdjust);
      temp.push(item.vsfAmount);
      temp.push(item.averageAumAmount);
      // temp.push(item.openingAumAmount);
      // temp.push(item.closingAumAmount);
      temp.push(item.trailerFee);
      excelData.push(temp);
    });
    return excelData;
  }

  pageChange(ev: any) {
    if (this.pageConfig.current !== ev.current) {
      this.pageConfig.current = ev.current;
      this.getdata();
    }
  }

  getdata() {
    this.loading = true;
    const params = this.createParams();
    this.commissionJournalService
      .getApeData(params)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
        filter(res => res.result === ResponseResult.SUCCESS)
      )
      .subscribe((res: any) => {
        this.previousSearchParams = params;
        const data = res.data;
        this.isOverMax = data.totalElements > DOWN_LOAD_MAX;
        if (this.isOverMax) {
          this.openAlertPop(I18N_KEY.DOWN_LOAD_MAX_LIMMIT);
          return;
        }
        this.pageConfig = {
          ...this.pageConfig,
          totalCount: data.totalElements,
        };
        this.dataDisplayed = this.createData(data.content || []);
      });
  }

  createData(data: any[]) {
    return data.map(item => {
      item.pacFlag = item.pacFlag ? item.pacFlag : '';

      const accountType = ACCOUNT_TYPE_OPTIONS.find(accountType => accountType.value === item.accountType);
      item.accountType = accountType ? accountType.label : '';

      item.txnEffectiveDate = item.txnEffectiveDate ? moment(new Date(item.txnEffectiveDate)).format('DD/MM/YYYY') : '';
      item.runDate = item.runDate ? moment(new Date(item.runDate)).format('DD/MM/YYYY') : '';

      item.commissionCode = item.commissionCode ? CommissionCode[item.commissionCode] : '';

      item.share = item.share ? item.share.toFixed(3) : '';
      item.apeAmount = item.apeAmount ? item.apeAmount.toFixed(2) : '';
      item.apeSystemAdjust = item.apeSystemAdjust ? item.apeSystemAdjust.toFixed(2) : '';
      item.vsfAmount = item.vsfAmount ? item.vsfAmount.toFixed(2) : '';
      item.averageAumAmount = item.averageAumAmount ? item.averageAumAmount.toFixed(2) : '';

      return item;
    });
  }

  openAlertPop(key: string) {
    const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
      data: {
        continue: I18N_KEY.COMMON_GOT,
        type: 'alert',
        message: key,
      },
      size: CDS_SIZE['MEDIUM'],
    });
    popupRef.afterClosed().subscribe(() => {});
  }
}
