import { ColumnConfig } from 'src/app/shared/data-table';

export const PAGE_SIZE = 10;

export const I18N_KEY = {
  ACCOUNT_NO_INVALID: 'commission.common.accountNo.invalid',
  MEMBER_NO_INVALID: 'commission.common.memberNo.invalid',
  REPORT_NO_INVALID: 'common.business.reportNo.invalid',
  RECEIVED_AMOUNT_INVALID: 'common.business.receivedAmount.invalid',
  INVALID_CLEARED_DATE: 'Invalid Cleard Date',
  INPUT_NUMBER: 'common.number.input',
};

export enum SchemeCode {
  ORSO = 'ORSO',
  MACAUPPS = 'MACAUPPS',
  MACAUCPF = 'MACAUCPF',
  GS = 'GS',
  HT = 'HT',
  SK = 'SK',
}
export const SchemeCodeOpts = [
  { label: 'ORSO', value: SchemeCode.ORSO },
  { label: 'MACAUPPS', value: SchemeCode.MACAUPPS },
  { label: 'MACAUCPF', value: SchemeCode.MACAUCPF },
  { label: 'MPF', value: SchemeCode.GS },
  // { label: 'HT', value: SchemeCode.HT },
  // { label: 'SK', value: SchemeCode.SK },
];

export const SchemeCodeConfig = {
  label: 'common.business.schemeCode',
  placeholder: 'common.business.schemeCodePlaceholderSelect',
  options: SchemeCodeOpts,
};

export enum StatusCode {
  CLEARED = 'CLEARED',
  REJECT = 'REJECT',
  RECEIVE = 'RECEIVE',
  PENDING = 'PENDING',
  UNIDENTIFIED = 'UNIDENTIFIED',
  TAGGED = 'TAGGED',
  CANCEL = 'CANCEL',
  REJECT_DISHONOR = 'REJECT_DISHONOR',
  REJECT_REFUND = 'REJECT_REFUND',
}

export const StatusCodeOpts = [
  { label: 'Cleared', value: StatusCode.CLEARED },
  { label: 'Reject', value: StatusCode.REJECT },
  { label: 'Receive', value: StatusCode.RECEIVE },
  { label: 'Pending', value: StatusCode.PENDING },
  { label: 'Unidentified', value: StatusCode.UNIDENTIFIED },
  { label: 'Tagged', value: StatusCode.TAGGED },
  { label: 'Cancel', value: StatusCode.CANCEL },
  { label: 'Reject Dishonor', value: StatusCode.REJECT_DISHONOR },
  { label: 'Reject Refund', value: StatusCode.REJECT_REFUND },
];

export const TrBnkAcctNoOpts = [
  { label: '006-391-08842671', value: '006-391-08842671' },
  { label: '004-500-539291001', value: '004-500-539291001' },
  { label: '004-500-539291002', value: '004-500-539291002' },
  { label: '004-500-539291006', value: '004-500-539291006' },
  { label: '003-447-00918828', value: '003-447-00918828' },
  { label: '003-447-08153405', value: '003-447-08153405' },
];
export const StatusCodeConfig = {
  label: 'common.business.statusCode',
  placeholder: 'common.business.statusCode.placeholder',
  options: StatusCodeOpts,
};
export const TrBnkAcctNoConfig = {
  label: 'common.business.trusteeBankAccountNumber',
  placeholder: 'common.business.trusteeBankAccountNumber.placeholder',
  options: TrBnkAcctNoOpts,
};

export enum PayMthd {
  APP_TO_APP = 'APP_TO_APP',
  BANK_TRANSFER = 'BANK_TRANSFER',
  BILL_PYM = 'BILL_PYM',
  CASH = 'CASH',
  CASHIER_ORDER = 'CASHIER_ORDER',
  CMU = 'CMU',
  CREDIT_TRANSFER = 'CREDIT_TRANSFER',
  DDA = 'DDA',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  E_CHEQUE = 'E_CHEQUE',
  FORFEIT_ACCT_OFFSET = 'FORFEIT_ACCT_OFFSET',
  FPS = 'FPS',
  MPFA_TRANSFER = 'MPFA_TRANSFER',
  OTHERS = 'OTHERS',
  PHYSICAL_CHEQUE = 'PHYSICAL_CHEQUE',
  PPS = 'PPS',
  QR_CODE = 'QR_CODE',
  RESERVE_ACCT_OFFSET = 'RESERVE_ACCT_OFFSET',
}

export const PayMthdOpts = [
  { label: 'App to App', value: PayMthd.APP_TO_APP },
  { label: 'Bank Transfer', value: PayMthd.BANK_TRANSFER },
  { label: 'Bill Payment', value: PayMthd.BILL_PYM },
  { label: 'Cash Deposit', value: PayMthd.CASH },
  { label: 'Cashier Order', value: PayMthd.CASHIER_ORDER },
  { label: 'CMU', value: PayMthd.CMU },
  { label: 'Credit Transfer', value: PayMthd.CREDIT_TRANSFER },
  { label: 'DDA Direct Debit', value: PayMthd.DDA },
  { label: 'Direct Debit', value: PayMthd.DIRECT_DEBIT },
  { label: 'e-Cheque', value: PayMthd.E_CHEQUE },
  { label: 'Offset From Forfeiture Account', value: PayMthd.FORFEIT_ACCT_OFFSET },
  { label: 'FPS', value: PayMthd.FPS },
  { label: 'MPFA Transfer', value: PayMthd.MPFA_TRANSFER },
  { label: 'Others', value: PayMthd.OTHERS },
  { label: 'Physical Cheque', value: PayMthd.PHYSICAL_CHEQUE },
  { label: 'PPS', value: PayMthd.PPS },
  { label: 'FPS QR Code', value: PayMthd.QR_CODE },
  { label: 'Offset From Reserve Account', value: PayMthd.RESERVE_ACCT_OFFSET },
];

export const PayMthdConfig = {
  label: 'common.business.paymentMethod',
  placeholder: 'common.business.paymentMethod.placeholder',
  options: PayMthdOpts,
};

export const PAYMENT_DETAILS_COLUMNS: Array<ColumnConfig> = [
  { key: 'schemeCode', sticky: true, title: 'common.business.schemeCode' },
  { key: 'erAccountCode', stickyEnd: true, title: 'common.business.employerAcconutCode' },
  { key: 'payrollGroupId', title: 'common.business.payrollGroupID' },
  { key: 'memberAccountCode', title: 'common.business.memberAcconutCode' },
  { key: 'receiptReferenceNo', title: 'common.business.receiptReferenceNumber' },
  { key: 'statusCode', title: 'common.business.statusCode' },
  { key: 'paymentSubmissionReferenceNo', title: 'common.business.paymentSubmissionReferenceNumber' },
  { key: 'paymentMethod', title: 'common.business.paymentMethod' },
  { key: 'receivedAmount', title: 'common.business.receivedAmount' },
  { key: 'bankCode', title: 'common.business.bankCode' },
  { key: 'branchCode', title: 'common.business.branchCode' },
  { key: 'bankAccountNo', title: 'common.business.bankAccountNumber' },
  { key: 'payerName', title: 'common.business.payerName' },
  { key: 'chequeDate', title: 'common.business.chequeDate' },
  { key: 'chequeBankInDate', title: 'common.business.chequeBankInDate' },
  { key: 'otherTransactionReferenceNo', title: 'common.business.otherTransactionReferenceNumber' },
  { key: 'clearedDate', title: 'common.business.clearedDate' },
  { key: 'receivedDate', title: 'common.business.receivedDate' },
  { key: 'chequeDirectMailToEmpfFlag', title: 'paymentJournal.clkChqFlg' },
  { key: 'oriRefNo', title: 'paymentJournal.oriRefNo' },
  { key: 'invalidFlg', title: 'paymentJournal.invalidFlg' },
  { key: 'splitFundingId', title: 'paymentJournal.splitfundingID' },
  { key: 'splitFundingFlag', title: 'paymentJournal.splitfundingFlag' },
  { key: 'trusteeBankCode', title: 'common.business.trusteeBankCode' },
  { key: 'trusteeBankBranchCode', title: 'common.business.trusteeBranchBankCode' },
  { key: 'trusteeBankAccountNo', title: 'common.business.trusteeBankAccountNumber' },
  { key: 'workflowReferenceNo', title: 'common.business.workflowReferenceNumber' },
  { key: 'reportNo', title: 'common.business.reportNo' },
  { key: 'currency', title: 'common.business.currency' },
  { key: 'auditFee', title: 'common.business.auditFee' },
];

export const EXCEL_COLUMNS = [
  'SCHEME CODE',
  'EMPLOYER ACCOUNT CODE',
  'PAYROLL GRPOUP ID',
  'MEMBER ACCOUNT CODE',
  'RECEIPT REFERENCE NO',
  'STATUS CODE',
  'PAYMENT SUBMISSSION REFERENCE NUMBER',
  'PAYMENT METHOD',
  'RECEIVED AMOUT',
  'BANK CODE',
  'BRANCH CODE',
  'BANK ACCOUNT NUMBER',
  'PAYER NAME',
  'CHEQUE DATE',
  'CHEQUE BANK-IN DATE',
  'OTHER TRANSACTION REFERENCE NUMBER',
  'CLEARED DATE',
  'RECEIVED DATE',
  'CHEQUE DIRECT MAILING TO EMPF FLAG',
  'ORIGINAL INPUT REFERENCE NUMBER',
  'INVALID FLAG',
  'SPLIT FUNDING ID',
  'SPLIT FUNDING (FLAG)',
  'TRUSTEE BANK CODE',
  'TRUSTEE BANK BRANCH CODE',
  'TRUSTEE BANK ACCOUNT NUMBER',
  'WORKFLOW REFERENCE NUMBER',
  'REPORT NO',
  'CURRENCY',
  'AUDIT FEE',
];
