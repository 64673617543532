export enum TransactionType {
  INTERNAL_TRANSFER = 'INTERNAL_TRANSFER',
  TRANSFER = 'TRANSFER',
  ENROLMENT = 'ENROLMENT',
  APE_ANN_PREM = 'APE_ANN_PREM',
  APE_CONTR_REGULAR = 'APE_CONTR_REGULAR',
  APE_CONTR_LUMPSUM = 'APE_CONTR_LUMPSUM',
  APE_TRF_IN_PE = 'APE_TRF_IN_PE',
  APE_TRF_IN_PM = 'APE_TRF_IN_PM',
  APE_TRF_IN_PC = 'APE_TRF_IN_PC',
  APE_TRF_IN_PP = 'APE_TRF_IN_PP',
  APE_TRF_IN_PT = 'APE_TRF_IN_PT',
  APE_COMM_ADJUST = 'APE_COMM_ADJUST',
  SYSTEM_ADJUST_APE = 'SYSTEM_ADJUST_APE',
  MANUAL_ADJUST_112 = 'MANUAL_ADJUST_112',
  MANUAL_ADJUST_141 = 'MANUAL_ADJUST_141',
  SYSTEM_TRAILER_FEE = 'SYSTEM_TRAILER_FEE',
  MANUAL_ADJUST_136 = 'MANUAL_ADJUST_136',

  APE_CONTR_115 = 'APE_CONTR_115',
  APE_CONTR_133 = 'APE_CONTR_133',
  APE_CONTR_112 = 'APE_CONTR_112',
  APE_PLAN_TRF = 'APE_PLAN_TRF',
  APE_IND_TRF = 'APE_IND_TRF',
  APE_COMM_ADJ_112 = 'APE_COMM_ADJ_112',
  APE_COMM_ADJ_112P = 'APE_COMM_ADJ_112P',
  APE_COMM_ADJ_112I = 'APE_COMM_ADJ_112I',
  APE_COMM_ADJ_115 = 'APE_COMM_ADJ_115',
  APE_COMM_ADJ_133 = 'APE_COMM_ADJ_133',
  SYS_ADJ_APE_RECON = 'SYS_ADJ_APE_RECON',
  SYS_ADJ_APE_REJOIN = 'SYS_ADJ_APE_REJOIN',
  SYS_ADJ_APE_TERM = 'SYS_ADJ_APE_TERM',
  MAN_ADJ_112 = 'MAN_ADJ_112',
  MAN_ADJ_112P = 'MAN_ADJ_112P',
  MAN_ADJ_112I = 'MAN_ADJ_112I',
  MAN_ADJ_115 = 'MAN_ADJ_115',
  MAN_ADJ_133 = 'MAN_ADJ_133',
  MAN_ADJ_141ANN = 'MAN_ADJ_141ANN',
  MAN_ADJ_935ANN = 'MAN_ADJ_935ANN',
  SYS_TRAILER_FEE = 'SYS_TRAILER_FEE',
  MAN_ADJ_136FEE = 'MAN_ADJ_136FEE ',
}
