/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { filter, finalize } from 'rxjs';
import moment from 'moment';
import { CdsToastService } from '@cds/ng-web-components/toast';

import { PageConfig } from 'src/app/shared/data-table';
import { CommissionApeManualService } from 'src/app/views/commission/ape-manual/commission-ape-manual.service';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { UploadFileStatus, UploadFileStatusText } from 'src/app/core/models/enum/upload-file-status.enum';
import { CdPopupService, CdPopupSize, CdPopupType, MatDialogRef } from 'src/app/shared/cd-popup';

import { RequestFormatComponent } from './request-format/request-format.component';
import { ApeUploadFileComponent } from './ape-upload-file/ape-upload-file.component';
import { APE_MANUAL_ADJUSTMENT_UPLOAD_COLUMNS, I18N_KEY, PAGE_SIZE } from '../ape-manual.model';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum';
import { PermissionService } from 'src/app/core/services/permission.service';

@Component({
  selector: 'app-manual-adjustment',
  templateUrl: './manual-adjustment.component.html',
  styleUrls: ['./manual-adjustment.component.scss'],
})
export class ManualAdjustmentComponent implements OnInit {
  permissionAccess = PermissionAccess;
  permissionItem = PermissionItem;
  uploadFileStatus = UploadFileStatus;
  columnsConfig = APE_MANUAL_ADJUSTMENT_UPLOAD_COLUMNS;
  pageConfig: PageConfig = {
    pageSize: PAGE_SIZE,
    current: 1,
  };
  loading = false;
  dataDisplayed: any[] = [];

  get totalElements() {
    return this.pageConfig.totalCount || 0;
  }

  constructor(
    private permissionService: PermissionService,
    private toast: CdsToastService,
    private cdPopup: CdPopupService,
    private commissionApeManualAdjustService: CommissionApeManualService
  ) {}

  ngOnInit(): void {
    this.permissionService.hasPermission(PermissionAccess.W, PermissionItem.COMM_APE_MANUAL_ADJ_NBV_RATIO).then(hasPermission => {
      if (!hasPermission) {
        this.columnsConfig = APE_MANUAL_ADJUSTMENT_UPLOAD_COLUMNS.slice(0, -1);
      }
      this.getData();
    });
  }

  getData() {
    this.loading = true;
    const params = this.createParams();
    this.commissionApeManualAdjustService
      .getList(params)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((res: any) => {
        if (res.result === ResponseResult.SUCCESS) {
          this.pageConfig = {
            ...this.pageConfig,
            totalCount: res.data.totalElements,
          };
          const tempData = res.data.content || [];
          this.dataDisplayed = tempData.map((item: any) => {
            item.createTime = item.createTime ? moment(new Date(item.createTime)).format('DD/MM/YYYY') : '';
            item.lastUpdateTime = item.lastUpdateTime ? moment(new Date(item.lastUpdateTime)).format('DD/MM/YYYY') : '';
            item.processor = item.createBy.name ? item.createBy.name : '';
            item.batchStatusLabel = UploadFileStatusText.get(item.batchStatus);
            return item;
          });
        } else {
          this.toast.error(res.message);
        }
      });
  }

  fileDetail(el: any) {
    console.log(el);
    // this.CommissionApeManualService.detail(el.id).subscribe(res => {
    //   if (res.result === ResponseResult.SUCCESS) {
    //     this.CommissionApeManualService.downLoadExcel(res.data, el.fileName, true);
    //   } else {
    //     this.toast.error(res.message);
    //   }
    // });
  }

  requestedFormat() {
    const popupRef: MatDialogRef<RequestFormatComponent> = this.cdPopup.open(RequestFormatComponent, {
      size: CdPopupSize.LARGE,
      isShowClose: false,
    });
    popupRef.afterClosed().subscribe(() => {});
  }

  delete(id: string) {
    const deleteConfirm: MatDialogRef<any> = this.cdPopup.openCommon({ type: CdPopupType.DELETE });
    deleteConfirm
      .afterClosed()
      .pipe(
        filter(confirm => {
          return confirm && confirm.delete;
        })
      )
      .subscribe(() => {
        this.commissionApeManualAdjustService.delete(id).subscribe(res => {
          if (res.result === ResponseResult.SUCCESS) {
            this.getData();
          } else {
            this.toast.error(res.message);
          }
        });
      });
  }

  validate(id: string, fileName: string) {
    const popupRef: MatDialogRef<any> = this.cdPopup.openCommon({
      type: CdPopupType.CONTINUE,
      data: {
        message: I18N_KEY.COMIS_VALIDATE_CONFIRM,
        params: { p: fileName },
      },
    });
    popupRef
      .afterClosed()
      .pipe(filter(confirm => confirm && confirm.continue))
      .subscribe(() => {
        this.commissionApeManualAdjustService.validate(id).subscribe(res => {
          if (res.result === ResponseResult.SUCCESS) {
            this.getData();
          } else {
            this.toast.error(res.message);
          }
        });
      });
  }

  upload() {
    const popupRef: MatDialogRef<ApeUploadFileComponent> = this.cdPopup.open(ApeUploadFileComponent, {
      size: CdPopupSize.LARGE,
    });
    popupRef.afterClosed().subscribe(isChange => {
      if (isChange) {
        this.getData();
      }
    });
  }

  pageChange(ev: any) {
    if (this.pageConfig.current !== ev.current) {
      this.pageConfig.current = ev.current;
      this.getData();
    }
  }

  createParams() {
    const params: any = {
      page: (this.pageConfig.current || 1) - 1,
      size: 10,
    };
    return params;
  }
}
