<div class="col-xs-12 col-md-12 l-plr-0">
  <h2 class="cds-h2-light l-pt-4 l-mb-7">Appeal Case Handling</h2>
</div>
<div class="col-xs-12 col-md-12 l-plr-0">
  <div class="l-d-f l-ai-cen">
    <span class="cds-body-hero cds-demibold l-mb-0 cds-green-text">Searching the Pending Transaction</span>
    <cds-icon class="l-pl-2 cursor-pointer" size="sm" (click)="alertPendingTransaction()" icon="action:info_1"></cds-icon>
  </div>
</div>
<div class="l-mt-6">
  <app-search-record-transaction (search)="search($event)" [errors]="errors"></app-search-record-transaction>
  <div>
    <app-data-table
      [frontPagination]="false"
      [fixed]="false"
      [pageConfig]="pageConfig"
      (reloadTable)="pageChange($event)"
      [isLoading]="isSearchloading"
      [dataSource]="dataDisplayed"
      [columnsConfig]="columnsConfig">
      <ng-template appDtHeaderCellDef="accountNo" let-element>
        <div>{{ element.title | lang }}</div>
        <div><cds-icon (click)="alertAccountCodeInfo()" class="cursor-pointer" icon="action:info_1"></cds-icon></div>
      </ng-template>
      <ng-template appDtBodyCellDef="edit" let-element>
        <cds-icon
          *ngIf="element.isEditable"
          [disabled]="isEdit"
          class="cursor-pointer"
          (click)="referenceClick(element)"
          size="sm"
          icon="action:edit"></cds-icon>
      </ng-template>
      <ng-template appDtBodyCellDef="referenceNo" let-element>
        <span class="cds-demibold" [ngClass]="{ 'text-underline': !isEdit }" (click)="rebackTo(element)">
          {{ element['referenceNo'] }}
        </span>
      </ng-template>
      <ng-template appDtBodyCellDef="accountType" let-element>
        <span class="line-no-wrap">
          {{ element['accountType'] }}
        </span>
      </ng-template>
      <ng-template appDtBodyCellDef="commFlagLabel" let-element>
        <span class="line-no-wrap">
          {{ element['commFlagLabel'] }}
        </span>
      </ng-template>
      <ng-template appDtBodyCellDef="sharingPC" let-element>
        <span> {{ element['sharingPC'] | mynumber : '3' }}% </span>
      </ng-template>
      <ng-template appDtBodyCellDef="sharingComm" let-element>
        <span> {{ element['sharingComm'] | mynumber : '3' }}% </span>
      </ng-template>
    </app-data-table>
  </div>
</div>

<div class="col-xs-12 col-md-12 l-pt-56 l-plr-0" *ngIf="isEdit && !isSaveLoading" id="make-appeal-form">
  <div>
    <div class="l-d-f l-ai-cen">
      <span class="cds-body-hero cds-demibold l-mb-0 cds-green-text">Making Appeal</span>
      <div class="l-ml-7 l-d-f l-ai-cen cursor-pointer" (click)="cancleEdit()">
        <cds-icon icon="action:edit"></cds-icon>
        <span class="cds-h6 cds-demibold l-ml-2 l-mb-0">{{ 'commission.pool.common.cancelEdit' | lang }} </span>
      </div>
    </div>
  </div>

  <div class="l-mt-7 l-pa-5 cds-white l-d-f">
    <div class="l-pt-1">
      <cds-icon size="md" color="var(--cds-color-m-green)" icon="environment:office_building"> </cds-icon>
    </div>
    <div class="l-fg-1 l-ml-5">
      <h4 class="cds-h4 cds-demibold l-ma-0 l-pb-3 div-border-bottom">{{ editRow?.accountName }}</h4>
      <div class="l-pt-4 l-pb-5 row l-plr-0 div-border-bottom">
        <div class="col-xs-6 col-md-6 row l-plr-0 l-pb-1">
          <span class="col-xs-6 col-md-5 cds-body2 cds-dark-navy-text light-3 l-mb-0">Account Type</span>
          <span class="col-xs-6 col-md-7 cds-body1 l-mb-0"> {{ editRow?.accountType }}</span>
        </div>
        <div class="col-xs-6 col-md-6 row l-plr-0 l-pb-1">
          <span class="col-xs-6 col-md-5 cds-body2 cds-dark-navy-text light-3 l-mb-0">Transaction Reference No.</span>
          <span class="col-xs-6 col-md-7 cds-body1 l-mb-0">{{ editRow?.referenceNo }}</span>
        </div>
        <div class="col-xs-6 col-md-6 row l-plr-0 l-pb-1">
          <div class="col-xs-6 col-md-5 cds-body2 cds-dark-navy-text light-3 l-mb-0 l-d-f">
            <span class="l-mr-2">{{ 'common.business.acconutCode' | lang }}</span>
            <cds-icon (click)="alertAccountCodeInfo()" class="cursor-pointer" icon="action:info_1"></cds-icon>
          </div>
          <span class="col-xs-6 col-md-7 cds-body1 l-mb-0">{{ editRow?.accountNo ? editRow.accountNo : '-' }}</span>
        </div>
        <div class="col-xs-6 col-md-6 row l-plr-0 l-pb-1">
          <span class="col-xs-6 col-md-5 cds-body2 cds-dark-navy-text light-3 l-mb-0">Commission Pool No.</span>
          <span class="col-xs-6 col-md-7 cds-body1 l-mb-0">{{ editRow?.commissionPoolId }}</span>
        </div>
        <div class="col-xs-6 col-md-6 row l-plr-0 l-pb-1"></div>
        <div class="col-xs-6 col-md-6 row l-plr-0 l-pb-1">
          <span class="col-xs-6 col-md-5 cds-body2 cds-dark-navy-text light-3 l-mb-0">Form Type</span>
          <span class="col-xs-6 col-md-7 cds-body1 l-mb-0">{{ editRow?.formType }}</span>
        </div>
      </div>
      <form class="l-pt-7 row l-plr-0">
        <div class="col-md-12 l-plr-0 l-mb-3 cds-body1 cds-demibold">Commission Details</div>
        <div class="col-xs-12 col-md-10 l-plr-0">
          <table class="cds-table full text-left cds-body1 l-mb-6">
            <thead>
              <tr class="div-bg-light-grey">
                <th style="width: 25%" class="div-border l-pa-4 cds-demibold">Comm Flag</th>
                <th style="width: 25%" class="div-border l-pa-4 cds-demibold">TRF SP HKPC Scale Code</th>
                <th style="width: 25%" class="div-border l-pa-4 cds-demibold">TRF SP COMM Scale Code</th>
                <th style="width: 25%" class="div-border l-pa-4 cds-demibold">FS HKPC Scale Code</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="div-border l-pa-4">
                  <div class="l-d-f">
                    <cds-form-field>
                      <cds-dropdown [config]="commFlagConfig" [formControl]="commFlag"> </cds-dropdown>
                      <cds-assistive-text *ngIf="commFlag.dirty && commFlag.errors">{{
                        'commission.appeal.pendingTransaction.commFlag.empty' | lang
                      }}</cds-assistive-text>
                    </cds-form-field>
                  </div>
                </td>
                <td class="div-border l-pa-4">
                  <div class="l-d-f">
                    <cds-form-field>
                      <cds-textfield placeholder="" [formControl]="trfsppcCode" (input)="scaleCodeInput($event, 'trfsppcCode')"> </cds-textfield>
                      <cds-assistive-text *ngIf="trfsppcCode?.dirty && trfsppcCode?.errors">{{trfsppcCode.errors?.['error'] | lang}}</cds-assistive-text>
                    </cds-form-field>
                  </div>
                </td>
                <td class="div-border l-pa-4">
                  <div class="l-d-f">
                    <cds-form-field>
                      <cds-textfield placeholder="" [formControl]="trfspCode" (input)="scaleCodeInput($event, 'trfspCode')"> </cds-textfield>
                      <cds-assistive-text *ngIf="trfspCode?.dirty && trfspCode?.errors">{{trfspCode.errors?.['error'] | lang}}</cds-assistive-text>
                    </cds-form-field>
                  </div>
                </td>
                <td class="div-border l-pa-4">
                  <div class="l-d-f">
                    <cds-form-field>
                      <cds-textfield placeholder="" [formControl]="fshkpcCode" (input)="scaleCodeInput($event, 'fshkpcCode')"></cds-textfield>
                      <cds-assistive-text *ngIf="fshkpcCode?.dirty && fshkpcCode?.errors">{{fshkpcCode.errors?.['error'] | lang}}</cds-assistive-text>
                    </cds-form-field>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-12 l-plr-0 l-mb-3 cds-body1 cds-demibold">Agent Details</div>
        <div [formGroup]="formGroup" class="col-xs-12 col-md-8 l-plr-0">
          <table formArrayName="details" class="cds-table full text-left cds-body1 l-mb-6">
            <thead>
              <tr class="div-bg-light-grey">
                <th style="width: 25%" class="div-border l-pa-4 cds-demibold">Agent code</th>
                <th style="width: 25%" class="div-border l-pa-4 cds-demibold">Sharing PC</th>
                <th style="width: 25%" class="div-border l-pa-4 cds-demibold">Sharing Comm</th>
                <th style="width: 15%" class="div-border l-pa-4 cds-demibold">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of dataMakeAppeal; let index = index" [formGroupName]="index.toString()">
                <td class="div-border l-pa-4">
                  <div class="l-d-f">
                    <cds-form-field>
                      <cds-textfield placeholder="" formControlName="agentCode"> </cds-textfield>
                      <cds-assistive-text *ngIf="makeAppeal.get(index.toString())?.get('agentCode')?.errors">
                        {{makeAppeal.get(index.toString())?.get('agentCode')?.errors?.['error'] | lang}}
                      </cds-assistive-text>
                    </cds-form-field>
                  </div>
                </td>
                <td class="div-border l-pa-4">
                  <div class="l-d-f">
                    <cds-form-field>
                      <cds-textfield
                        placeholder=""
                        formControlName="sharingPC"
                        (change)="sharingChange($event, index, 'sharingPC')"
                        (input)="sharingInput($event, index, 'sharingPC')">
                      </cds-textfield>
                    </cds-form-field>
                    <span class="l-pt-2 l-ml-1">%</span>
                  </div>
                </td>
                <td class="div-border l-pa-4">
                  <div class="l-d-f">
                    <cds-form-field>
                      <cds-textfield
                        placeholder=""
                        formControlName="sharingComm"
                        (change)="sharingChange($event, index, 'sharingComm')"
                        (input)="sharingInput($event, index, 'sharingComm')">
                      </cds-textfield>
                    </cds-form-field>
                    <span class="l-pt-2 l-ml-1">%</span>
                  </div>
                </td>
                <td class="div-border l-pa-4">
                  <span
                    (click)="deleteAgent(index)"
                    class="cds-body2 cds-demibold cds-color-coral cds-text-coral cursor-pointer"
                    *ngIf="dataMakeAppeal.length > 1"
                    >Delete</span
                  >
                </td>
              </tr>
              <tr>
                <td class="div-border l-pl-9 l-pt-4 l-pb-4" colspan="8">
                  <div class="cursor-pointer l-d-f l-ai-fe l-d-if" (click)="addAgent()">
                    <cds-icon class="icon-add l-mr-2" size="sm" color="white" icon="action:button_plus"></cds-icon>
                    <span class="cds-body2 cds-demibold l-ma-0">Add agent</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  </div>

  <div class="l-mt-7">
    <cds-button size="md" [disabled]="isResetDisable" [config]="btnCfg" (click)="reset()">Reset</cds-button>
    <cds-button size="md" [disabled]="isSaveDisable" class="l-ml-4" (click)="save()">Save</cds-button>
  </div>
</div>

<div *ngIf="isSaveLoading" class="l-mt-8 text-center">
  <app-loading></app-loading>
</div>
