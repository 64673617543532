export const texts = {
  datePattern: /^(([0-3]{1})?[0-9]{1}\/([0-1]{1})?[0-9]{1}\/[0-9]{4})$/,
  amountPattern: /^(([0-9]|([1-9][0-9]{1,23}))(.[0-9]{1,3})?)$/,
  cancelMsg: 'commission.pool.common.cancelMsg',
  cancel: 'commission.pool.common.cancel',
  continue: 'commission.pool.common.continue',
  yes: 'commission.pool.common.yes',
  no: 'commission.pool.common.no',
  alertSuccessMsg: 'commission.pool.common.alertSuccessMsg',
  alertSuccessContinue: 'commission.pool.common.alertSuccessContinue',
  alertFailedMsg: 'commission.pool.common.alertFailedMsg',
  alertFailedContinue: 'commission.pool.common.alertFailedContinue',
  inputDateFormat: 'commission.pool.common.inputDateFormat',
  monthYearFormat: 'commission.pool.common.monthYearFormat',
  inputAmountFormat: 'commission.pool.common.inputAmountFormat',

  effectiveDateError: 'commission.pool.summary.effectiveDateError',
  notEditReleased: 'commission.pool.summary.notEditReleased',
  notEditBackDay: 'commission.pool.summary.notEditBackDay',
  todayLaterSix: 'commission.pool.summary.todayLaterSix',
  fcMonthLaterSix: 'commission.pool.summary.fcMonthLaterSix',
  fcMonthInvalid: 'commission.pool.summary.fcMonthInvalid',
  fcMonthAccountTypeError: 'commission.pool.summary.fcMonthAcctTypeErr',
  fcMonthWaring: 'commission.pool.summary.fcMonthWarning',
  nextCommYearBackDay: 'commission.pool.summary.nextCommYearBackDay',
  nextCommYearLater: 'commission.pool.summary.nextCommYearLater',

  relatedNotFound: 'commission.pool.related.add.relatedNotFound',
  relatedRequired: 'commission.pool.related.add.relatedRequired',
  addRepeatedly: 'commission.pool.related.add.addRepeatedly',
  poolAgentChangeConfirm: 'commission.pool.related.add.agentDifferentConfirm',
  updateNextCommYearStart: 'commission.pool.related.add.updateNextCommDate',
  accountTypeGrouped: 'commission.pool.related.add.accountTypeGrouped',
  rejoinConfirm: 'commission.pool.related.list.rejoinConfirm',

  deleteRecordTitle: 'commission.pool.scale.recordDetails.deleteRecordTitle',
  deleteRecordMsg: 'commission.pool.scale.recordDetails.deleteRecordMsg',

  agentCodeRequired: 'commission.pool.agents.create.agentCodeRequired',
  shareHkpcRequired: 'commission.pool.agents.create.shareHkpcRequired',
  shareCommissionRequired: 'commission.pool.agents.create.shareCommissionRequired',
  saReportDateRequired: 'commission.pool.agents.create.saReportDateRequired',
  invalidAgentCode: 'commission.pool.agents.alert.invalidAgentCode',
  nonBrokerChannel: 'commission.pool.agents.alert.nonBrokerChannel',
  customerTerminated: 'This “Employer Account Code”/ “Member Account Code”. isn’t active status of current pool',
};

export const RELEATE_PAYTY_RELEASE_TAG = '9999-12-31';

export enum Status {
  PREVIOUS = 'PREVIOUS',
  CURRENT = 'CURRENT',
  FUTURE = 'FUTURE',
  DELETED = 'DELETED',
}

export const COMMISSION_POOL_TAB = [
  {
    label: 'commission.pool.summary.relatedParties',
    value: 'relatedParties',
    selected: true,
  },
  {
    label: 'commission.pool.summary.scaleRecordDetails',
    value: 'recordDetails',
    selected: false,
  },
  {
    label: 'commission.pool.summary.agentCompensation',
    value: 'agents',
    selected: false,
  },
  {
    label: 'commission.pool.summary.individualTransfer',
    value: 'individualTransfer',
    selected: false,
  },
];
