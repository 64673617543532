<div class="row l-pa-0">
  <p class="col-xs-12 cds-body1 l-mb-5">
    Batch upload for APE, VSF & AUM Trailer Fee Journal. Please input file with
    <span class="text-underline" (click)="requestedFormat()">requested format</span>.
  </p>
  <div class="col-xs-12">
    <app-cd-table-title class="l-pt-3 l-pb-3" [title]="'common.recordList' | lang" [total]="totalElements">
      <ng-template #tableAction>
        <app-permission [role]="permissionAccess.W" [permission]="permissionItem.COMM_APE_MANUAL_ADJ_NBV_RATIO">
          <cds-button (click)="upload()" [style]="'secondary'" size="sm">
            <span>File Upload</span>
          </cds-button>
        </app-permission>
      </ng-template>
    </app-cd-table-title>
    <app-data-table
      [frontPagination]="false"
      [fixed]="false"
      [isLoading]="loading"
      (reloadTable)="pageChange($event)"
      [pageConfig]="pageConfig"
      [dataSource]="dataDisplayed"
      [columnsConfig]="columnsConfig">
      <ng-template appDtBodyCellDef="conversedFileName" let-element>
        <span class="cds-body1 cds-demibold text-underline" (click)="fileDetail(element)">{{ element['fileName'] }}</span>
      </ng-template>
      <ng-template appDtBodyCellDef="action" let-element>
        <div class="l-d-f l-ai-cen">
          <cds-icon
            icon="form:delete"
            (click)="delete(element['id'])"
            class="cursor-pointer l-mr-1"
            *ngIf="element['status'] === uploadFileStatus.PENDING_VERIFICATION || element['status'] === uploadFileStatus.VALIDATED_PENDING_UPDATED"></cds-icon>
          <cds-button
            (click)="validate(element['id'], element['conversedFileName'])"
            [style]="'secondary'"
            size="sm"
            *ngIf="element['status'] === uploadFileStatus.PENDING_VERIFICATION"
            class="l-ml-6">
            <span>Validate</span>
          </cds-button>
        </div>
      </ng-template>
    </app-data-table>
  </div>
</div>
