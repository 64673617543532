/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit } from '@angular/core';
import { CDS_POPUP_DATA, MatDialogRef } from '../cd-popup.service';

@Component({
  selector: 'app-cd-popup-continue',
  templateUrl: './cd-popup-continue.component.html',
  styleUrls: ['./cd-popup-continue.component.scss'],
})
export class CdPopupContinueComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<CdPopupContinueComponent>,
    @Inject(CDS_POPUP_DATA)
    public data: {
      title: string;
      message: string;
      subMessage: string;
      continue: string;
      params: any;
    }
  ) {}

  ngOnInit() {}

  cancle() {
    this.dialogRef.close({ continue: false });
  }

  continue() {
    this.dialogRef.close({ continue: true });
  }
}
