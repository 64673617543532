/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BTN_CONFIG_SECONDARY } from 'src/app/config/btn.config';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { CommissionPoolService } from 'src/app/core/services/commission/commission-pool.service';
import { AccountTypesCommissionpool } from 'src/app/core/models/enum/account-type.enum';
import { UserAgreementPopupComponent } from '../user-agreement-popup.component';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { texts } from './pool-info.model';
import { deepCopy } from 'src/app/utils/copy';

@Component({
  selector: 'app-commission-pool-create',
  templateUrl: './pool-list.component.html',
  styleUrls: ['./pool-list.component.scss'],
})
export class CommissionPoolListComponent implements OnInit {
  btnCfg: CdsButtonConfig = BTN_CONFIG_SECONDARY;
  constructor(private router: Router, private poolService: CommissionPoolService, private cdsPopup: CdsPopupService) {}

  totalCounts = '1';
  isSearch = false;
  loading = false;
  createLoading = false;
  searchDisable = true;
  pageCount: IPaginationCount = { current: 1, count: 1 };

  addForm: any = {
    accountCode: '',
    accountType: '',
    disabled: true,
    createError: '',
    createCodeError: '',
  };
  accountTypes: any = AccountTypesCommissionpool;

  searchForm: any = {
    id: '',
    accountCode: '',
    accountName: '',
    currentEffective: false,
  };

  searchParams: any = {
    id: '',
    accountCode: '',
    accountName: '',
    currentEffective: false,
  };

  dataSource: any = [
    {
      id: '',
      list: [],
    },
  ];

  inputId(event: any) {
    event.target.value = event.target.value.replace(/[^\d\w\-]/g, ''); // eslint-disable-line
    this.searchForm.id = event.target.value;
  }

  inputAccountCode(event: any) {
    event.target.value = event.target.value.replace(/[^\d\w\-]/g, ''); // eslint-disable-line
    this.searchForm.accountCode = event.target.value;
  }

  inputAccountCode2(event: any) {
    event.target.value = event.target.value.replace(/[^\d\w\-]/g, ''); // eslint-disable-line
    this.addForm.accountCode = event.target.value;
  }

  inputAccountName(event: any) {
    event.target.value = event.target.value.replace(/[^\d\w\-]/g, ''); // eslint-disable-line
    this.searchForm.accountName = event.target.value;
  }

  searchChange(input: any) {
    if (input && (this.searchForm.id || this.searchForm.accountCode || this.searchForm.accountName || this.searchForm.currentEffective)) {
      this.searchDisable = false;
    } else {
      this.searchDisable = true;
    }
  }

  search() {
    if (this.searchDisable) {
      return;
    }
    this.searchParams = deepCopy(this.searchForm);
    this.loadTable(true);
  }

  reset() {
    this.searchDisable = true;
    this.searchForm = {
      id: '',
      accountCode: '',
      accountName: '',
      currentEffective: false,
    };
    this.searchParams = deepCopy(this.searchForm);
    this.loadTable(true);
  }

  pageChange(currentPage: number) {
    if (currentPage - 1 === this.searchForm.page) return;
    this.pageCount.current = currentPage;
    this.loadTable(false);
  }

  loadTable(reload: boolean) {
    if (reload) {
      this.pageCount.current = 1;
    }
    const searchForm: any = this.searchParams;
    searchForm.id = searchForm.id ? searchForm.id.trim() : '';
    searchForm.accountCode = searchForm.accountCode ? searchForm.accountCode.trim() : '';
    searchForm.accountName = searchForm.accountName ? searchForm.accountName.trim() : '';
    searchForm.page = this.pageCount.current - 1;
    searchForm.size = 10;
    searchForm.status = 'VALID';
    searchForm.sort = 'createTime,desc';
    this.dataSource = [];
    this.loading = true;
    this.poolService.selectList(searchForm).subscribe(resp => {
      this.loading = false;
      if (resp && resp.data && resp.result == 0) {
        this.dataSource = resp.data.content;
        this.totalCounts = String(resp.data.totalElements);
        this.pageCount = {
          current: resp.data.number + 1,
          count: resp.data.totalPages,
        };
      }
    });
  }

  dbClick(item: any) {
    localStorage.setItem('commissionPoolId', item.id);
    this.router.navigate(['/commission/pool-info/'], {
      queryParams: { commissionPoolId: item.id },
    });
  }

  deleteById(id: string) {
    this.poolService.deleteById(id).subscribe(resp => {
      if (resp) {
        this.loadTable(true);
      }
    });
  }

  createChange() {
    this.addForm.disabled = true;
    this.addForm.createError = '';
    if (this.addForm.accountType && this.addForm.accountCode.trim() && !this.addForm.createError) {
      this.addForm.disabled = false;
    }
  }

  reset2() {
    this.addForm.disabled = true;
    this.addForm.accountType = '';
    this.addForm.accountCode = '';
    this.addForm.createError = '';
    this.addForm.createCodeError = '';
  }

  create2() {
    if (this.addForm.disabled) {
      return;
    }
    const params: any = {
      accountType: this.addForm.accountType,
      accountCode: this.addForm.accountCode.trim(),
    };
    this.addForm.createError = '';
    this.addForm.createCodeError = '';
    this.createLoading = true;
    this.poolService.addSeparateCommissionPool(params).subscribe(resp => {
      this.createLoading = false;
      if (resp && resp.result == 0) {
        this.cdsPopup.open(UserAgreementPopupComponent, {
          data: {
            message: texts.alertSuccessMsg,
            continue: texts.alertSuccessContinue,
            type: 'alert',
          },
        });
      } else {
        if (resp.message && resp.message.indexOf('Account Code is Invalid') !== -1) {
          this.addForm.createCodeError = resp.message;
        } else {
          this.addForm.createError = resp.message;
        }
        this.addForm.disabled = true;
      }
    });
  }

  alert(type: string) {
    let text = '';
    switch (type) {
      case 'searchCode':
        text = 'commission.accountCode.futherInfo.searchArea';
        break;
      case 'searchName':
        text = 'commission.accountName.futherInfo.searchArea';
        break;
      case 'thAccountCode':
        text = 'commission.accountCode.futherInfo.tableArea';
        break;
      case 'createPool':
        text = 'commission.createPool.futherInfo';
        break;
      case 'createAccountCode':
        text = 'commission.accountCode.futherInfo.createPool';
        break;
    }
    this.cdsPopup.open(UserAgreementPopupComponent, {
      size: 'sm',
      data: {
        message: text,
        continue: texts.alertSuccessContinue,
        type: 'alert',
        actionRight: true,
      },
    });
  }

  ngOnInit(): void {
    this.loadTable(true);
  }
}
