export const AccountCodeExplain =
  "<div class='l-pl-4 l-pr-4'><span class='cds-h3-light l-pt-2'>Employer / Member Account Code</span><br><br>" +
  'This field is represented as Employer Account Code when the account type is <br>' +
  '• MPF-ER <br>' +
  '• ORSO-ER <br>' +
  '• MACAU-ER(PPS) <br>' +
  '• MACAU-ER(CPF) <br>' +
  '<br>' +
  'This field is represented as Member Account Code when the account type is <br>' +
  '• MPF-SEP <br>' +
  '• MPF-SEP(AGENT)  <br>' +
  '• MPF-FRC  <br>' +
  '• MPF-TVC <br>' +
  '• MPF-PAP <br>' +
  '• MACAU-PAP <br>' +
  '• MACAU-IVC <br><br>' +
  'No member account code of the MPF-ER/ORSO-ER/MACAU-ER(CPF)/MACAU-ER(PPS) will be under the Related Parties of the Commission Pool<br>';

export const RejoinIntraGroupExplain = `
  <div class="l-mb-4">Once updated to Rejoin / Intra Group, it is not allowed to untick the group as NB Account.</div>
  <div>If user need IT patch the group back to NB Account, please consider this account setup within first year or not.   If this account is setup within first year, please contact Finance team to special handling on the System Adjustment APE.</div>
  `;

export const ApeDependAccountExplain = `
  <div class="l-mb-4">This field is ONLY applied to the related party with the SAME commencement month of the main party and it isn’t subjected to the case which has processed System Annualize APE adjustment before.</div>
  <div class="l-mb-4">After having inputted the account number of Main party into this field, the Annualize APE will be referred to the Main party’s first contribution date.</div>
  <div>Only allow to remove the inputted “APE Depend Account” on the related party if this party is moved to another pool.</div>
  `;
