<div *ngIf="!group" [ngClass]="['cd-input', disable ? 'disabled' : '']">
  <label class="cds-body2">
    {{ label }}
    <input
      [ngClass]="[disable ? 'disabled' : '', isHasErrors ? 'ng-invalid ng-touched' : '']"
      [ngModel]="value"
      (ngModelChange)="change($event)"
      #theInput
      (blur)="onBlur()"
      [attr.disabled]="disable ? '' : null"
      [maxlength]="maxlength"
      placeholder="{{ placeholder }}"
      ngDefaultControl />
  </label>
  <div [ngClass]="['line', isFocus ? 'focus-line' : '', isHasErrors ? 'ng-invalid ng-touched' : '']"></div>
  <cds-assistive-text class="error-info" type="error" *ngIf="errorInfo.length > 0">
    <div class="cds-body2">
      {{ errorInfo }}
    </div>
  </cds-assistive-text>
  <cds-assistive-text class="error-info" type="error" *ngIf="!asyncValidatorLoading && asyncErrorInfo.length > 0">
    <div class="cds-body2">
      {{ asyncErrorInfo | lang }}
    </div>
  </cds-assistive-text>
  <cds-progress *ngIf="asyncValidatorLoading" mode="indeterminate"></cds-progress>
</div>

<div *ngIf="group" [formGroup]="group" [ngClass]="['cd-input', disable ? 'disabled' : '']">
  <label class="cds-body2">
    {{ label }}
    <input
      [formControlName]="controlName"
      [ngClass]="[disable ? 'disabled' : '', (group.get(controlName)?.touched && group.get(controlName)?.errors) || isHasErrors ? 'ng-invalid ng-touched' : '']"
      (ngModelChange)="change($event)"
      #theInput
      (blur)="onBlur()"
      [attr.disabled]="disable ? '' : null"
      [maxlength]="maxlength"
      placeholder="{{ placeholder }}"
      ngDefaultControl />
  </label>
  <div
    [ngClass]="[
      'line',
      isFocus ? 'focus-line' : '',
      (group.get(controlName)?.touched && group.get(controlName)?.errors) || isHasErrors ? 'ng-invalid ng-touched' : ''
    ]"></div>
</div>
