<div class="case-summary">
  <div class="l-pt-5 l-pb-5 div-border-bottom">
    <span class="prodtype-header">{{ 'productTypesummary.header' | lang }}</span>
  </div>
  <div class="l-pt-7 l-ml-7 l-d-f l-ai-cen">
    <cds-icon (click)="onBackSummayIconClick()" class="prodtype-back-icon" [config]="backSummaryIconConfig" [icon]="backSummaryIcon"> </cds-icon>
    <div class="prodtype-back-employer-name">
      <span (click)="onBackSummayIconClick()" class="cds-body1-demibold">Back to Product List</span>
    </div>
  </div>
  <div class="prodtype-drop-employer-name">
    <div class="padding-none">
      <div class="choose-product-type">
        <div class="l-mb-4">
          <span class="cds-body2">Choose product Type</span>
        </div>
        <app-cd-dropdown
          [placeholder]="'Select Product Type'"
          [disabled]="isAgreeSendIff"
          [selected]="prodTypeFormControl.value"
          (selectedChange)="prodTypeChange($event)"
          [toppingList]="prodTypeDropdownConfig.options"></app-cd-dropdown>
      </div>
    </div>
    <div class="spherical-wapper" *ngIf="prodTypeFormControl.value === SalesJourneyProdType.Individual">
      <div>
        <div
          class="l-d-f l-ai-cen l-jc-sb spherical-box"
          [ngClass]="[productTypeCount.pa.allPendingCount && productTypeCount.pa.allPendingCount > 0 ? 'gold' : '']"
          (click)="onClickGetDetailsByProductType('PA')">
          <div class="l-d-f l-ai-cen left">
            <div class="spherical l-d-f l-ai-cen">
              <div>
                <span class="cds-h6-demibold">{{ productTypeCount.pa.allPendingCount }}</span>
              </div>
            </div>
            <div class="l-ml-3">
              <span class="cds-h6-demibold">PA Pending items</span>
            </div>
          </div>
          <cds-icon class="l-ml-3" icon="arrow:chevron_r"></cds-icon>
        </div>
      </div>
      <div>
        <div
          class="l-d-f l-ai-cen l-jc-sb spherical-box"
          [ngClass]="[productTypeCount.tvc.allPendingCount && productTypeCount.tvc.allPendingCount > 0 ? 'gold' : '']"
          (click)="onClickGetDetailsByProductType('TVC')">
          <div class="l-d-f l-ai-cen left">
            <div class="spherical l-d-f l-ai-cen">
              <div>
                <span class="cds-h6-demibold">{{ productTypeCount.tvc.allPendingCount }}</span>
              </div>
            </div>
            <div class="l-ml-3">
              <span class="cds-h6-demibold">TVC Pending items</span>
            </div>
          </div>
          <cds-icon class="l-ml-3" icon="arrow:chevron_r"></cds-icon>
        </div>
      </div>
      <div>
        <div
          class="l-d-f l-ai-cen l-jc-sb spherical-box"
          [ngClass]="[productTypeCount.frc.allPendingCount && productTypeCount.frc.allPendingCount > 0 ? 'gold' : '']"
          (click)="onClickGetDetailsByProductType('FRC')">
          <div class="l-d-f l-ai-cen left">
            <div class="spherical l-d-f l-ai-cen">
              <div>
                <span class="cds-h6-demibold">{{ productTypeCount.frc.allPendingCount }}</span>
              </div>
            </div>
            <div class="l-ml-3">
              <span class="cds-h6-demibold">FRC Pending items</span>
            </div>
          </div>
          <cds-icon class="l-ml-3" icon="arrow:chevron_r"></cds-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-md-12 l-mt-7 l-pb-3 prodtype-search">
    <span class="cds-h2-light">Search record</span>
    <div class="row l-d-f padding-none">
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
        <span class="cds-body2 cds-dropdown-label ng-star-inserted label-color" [ngClass]="[isAgreeSendIff ? 'disable' : '']"> eMPF Ref# </span>
        <app-cd-input
          class="text-field"
          placeholder="Input eMPF Ref#"
          [(value)]="empfRefValue"
          [disable]="isAgreeSendIff"
          (valueChange)="empfRefChange($event)"></app-cd-input>
      </div>
      <div *ngIf="prodTypeFormControl.value !== SalesJourneyProdType.Corperate" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
        <span class="cds-body2 cds-dropdown-label ng-star-inserted label-color" [ngClass]="[isAgreeSendIff ? 'disable' : '']">
          {{ 'resend-eiff.clientName' | lang }}
        </span>
        <app-cd-input
          class="text-field"
          [(value)]="clientNameValue"
          [disable]="isAgreeSendIff"
          (valueChange)="clientNameChange($event)"
          [placeholder]="'common.input' | lang : { p1: 'resend-eiff.clientName' | lang }"></app-cd-input>
      </div>
      <div *ngIf="prodTypeFormControl.value === SalesJourneyProdType.Corperate" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
        <span class="cds-body2 cds-dropdown-label ng-star-inserted label-color" [ngClass]="[isAgreeSendIff ? 'disable' : '']"> Employer Name </span>
        <app-cd-input class="text-field" [(value)]="employerNameValue" [disable]="isAgreeSendIff" placeholder="Input Emlpoyer Name"></app-cd-input>
      </div>
      <app-date-range-box class="col-lg-6 col-xs-12 l-plr-0">
        <div *ngIf="!resetDatePicker" left>
          <cds-textfield
            class="prodtype-date-align"
            [(ngModel)]="declaredatefromValue"
            #searchBox
            (ngModelChange)="declaredatefChange(searchBox.value)"
            type="text"
            placeholder="dd/mm/yyyy"
            label="Declaration Date From"
            [cdsDatepicker]="pickerFromDate"
            [disabled]="isAgreeSendIff"
            [max]="declarationDateFromMax"
            [min]="declarationDateFromMin"></cds-textfield>
          <cds-datepicker #pickerFromDate="cdsDatepicker"></cds-datepicker>
          <cds-assistive-text *ngIf="declarationDateFromError" class="l-mt-3">{{ 'common.invalidDate' | lang }}</cds-assistive-text>
        </div>
        <div *ngIf="!resetDatePicker" right>
          <cds-textfield
            class="prodtype-date-align"
            [(ngModel)]="declaredatetoValue"
            #searchtodateBox
            (ngModelChange)="declaredatetoChange(searchtodateBox.value)"
            type="text"
            placeholder="dd/mm/yyyy"
            label="To"
            [disabled]="isAgreeSendIff"
            [max]="declarationDateToMax"
            [min]="declarationDateToMin"
            [cdsDatepicker]="pickerToDate"></cds-textfield>
          <cds-datepicker #pickerToDate="cdsDatepicker"></cds-datepicker>
          <cds-assistive-text *ngIf="declarationDateToError" class="l-mt-3">{{ 'common.invalidDate' | lang }}</cds-assistive-text>
        </div>
      </app-date-range-box>
    </div>
    <div class="row l-d-f padding-none">
      <div
        *ngIf="prodTypeFormControl.value !== SalesJourneyProdType.Corperate && !resetDatePicker"
        class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item prodtype-date-icon">
        <span class="cds-body2 cds-dropdown-label ng-star-inserted label-color" [ngClass]="[isAgreeSendIff ? 'disable' : '']">
          {{ 'case-summary.hkid' | lang }}
        </span>
        <app-cd-input
          class="text-field"
          [(value)]="hkidOrPassportNo"
          [disable]="isAgreeSendIff"
          [placeholder]="'common.input' | lang : { p1: 'case-summary.hkid' | lang }"></app-cd-input>
      </div>
      <div *ngIf="prodTypeFormControl.value === SalesJourneyProdType.Corperate" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item prodtype-date-icon">
        <span class="cds-body2 cds-dropdown-label ng-star-inserted label-color" [ngClass]="[isAgreeSendIff ? 'disable' : '']"> BR Number </span>
        <app-cd-input class="text-field" [disable]="isAgreeSendIff" [(value)]="brNoValue" placeholder="Input BR Number"></app-cd-input>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
        <div>
          <span class="cds-body2 cds-dropdown-label ng-star-inserted label-color" [ngClass]="[isAgreeSendIff ? 'disable' : '']"> IFF Status </span>
          <app-cd-multidropdown
            [style.width]="'100%'"
            placeholder="Select IFF status"
            [toppingList]="iffStatusoptionList"
            [disabled]="isAgreeSendIff"
            [(selected)]="iffStatusoptionsOrigin"></app-cd-multidropdown>
        </div>
      </div>
      <div *ngIf="prodTypeFormControl.value !== SalesJourneyProdType.Corperate" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
        <span class="cds-body2 cds-dropdown-label ng-star-inserted label-color" [ngClass]="[isAgreeSendIff ? 'disable' : '']"> Application Status </span>
        <app-cd-multidropdown
          [style.width]="'100%'"
          placeholder="Select application status"
          [toppingList]="appStatusoptionList"
          [disabled]="isAgreeSendIff"
          [(selected)]="appStatusoptionsOrigin"></app-cd-multidropdown>
      </div>
      <div *ngIf="prodTypeFormControl.value === SalesJourneyProdType.Corperate" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
        <span class="cds-body2 cds-dropdown-label ng-star-inserted label-color" [ngClass]="[isAgreeSendIff ? 'disable' : '']"> eMPF App Status </span>
        <app-cd-multidropdown
          [style.width]="'100%'"
          placeholder="Select IFF status"
          [toppingList]="appStatusoptionList"
          [disabled]="isAgreeSendIff"
          [(selected)]="appStatusoptionsOrigin"></app-cd-multidropdown>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item bottom-box">
        <cds-button [disabled]="isDisableSearch || isAgreeSendIff || isLoading" size="sm" class="l-mr-4" (click)="onClickSearch()">Search</cds-button>
        <cds-button [disabled]="isAgreeSendIff" [style]="'secondary'" size="sm" (click)="onClickReset()">Reset</cds-button>
      </div>
    </div>
  </div>

  <div class="l-mt-8">
    <span class="prodtype-search-and-result">{{ isAgreeSendIff ? 'Send IFF by SSMS in batch' : 'Record list' }}</span>
  </div>
  <div class="row l-d-f pd-lr" [ngClass]="[prodTypeFormControl.value === SalesJourneyProdType.Individual ? 'l-ai-fe' : 'l-ai-cen']">
    <div class="col-xs-12 col-lg-6 l-d-f l-fd-col l-mt-4">
      <span class="prodtype-record cds-body1">Total {{ totalRecords }} records </span>
      <div class="l-d-f l-ai-cen l-mt-5" *ngIf="prodTypeFormControl.value === SalesJourneyProdType.Individual">
        <div class="font-label l-mr-4">Filter by:</div>
        <div [ngClass]="['button-style', individualTypeSelected === IndividualType.ALL ? 'checked' : '']" (click)="checkRecordDetailTag(IndividualType.ALL)">
          All Product Type
        </div>
        <div [ngClass]="['button-style', individualTypeSelected === IndividualType.PA ? 'checked' : '']" (click)="checkRecordDetailTag(IndividualType.PA)">
          PA
        </div>
        <div [ngClass]="['button-style', individualTypeSelected === IndividualType.TVC ? 'checked' : '']" (click)="checkRecordDetailTag(IndividualType.TVC)">
          TVC
        </div>
        <div [ngClass]="['button-style', individualTypeSelected === IndividualType.FRC ? 'checked' : '']" (click)="checkRecordDetailTag(IndividualType.FRC)">
          FRC
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-lg-6 end-lg start-md l-mt-4">
      <cds-button *ngIf="!isAgreeSendIff" [disabled]="isDisableGenerateGeneralIff" [style]="'secondary'" (click)="onRouteDeclareIff()" size="sm"
        >Generate general IFF</cds-button
      >&nbsp;
      <cds-button *ngIf="!isAgreeSendIff" [disabled]="isDisableSendIff" [style]="'secondary'" class="l-ml-3" size="sm" (click)="onRoutesend()"
        >Send IFF by EMPF record</cds-button
      >
      <cds-button *ngIf="isAgreeSendIff" [style]="'secondary'" (click)="closeSendIff()" size="sm">Back to Summary</cds-button>
    </div>
  </div>
  <div class="l-mt-5" style="position: relative">
    <ng-container *ngIf="prodTypeFormControl.value !== SalesJourneyProdType.Corperate">
      <div *ngIf="isLoading && currentPageData.length === 0" class="progress-box l-d-f l-jc-cen">
        <cds-progress mode="indeterminate" [diameter]="60" [type]="'ring'" [text]="'common.loading' | lang" [color]="'ml'"></cds-progress>
      </div>
      <app-no-result-found
        [msg]="isAgreeSendIff ? 'common.noPtsAreFound' : ''"
        [style.display]="currentPageData.length === 0 && !isLoading ? 'block' : 'none'"></app-no-result-found>
      <div [style.display]="currentPageData.length > 0 ? 'unset' : 'none'" class="row l-plr-0">
        <div #tableBox class="table-wrapper" [ngClass]="[isLoading ? 'loading' : '']">
          <cds-table class="col-lg-12 row" [dataSource]="currentPageData" [class.full]="false">
            <cds-row *cdsRowDef="let row; index as i; columns: displayedColumns"></cds-row>
            <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
            <ng-container cdsColumnDef="isSelected" sticky>
              <cds-header-cell *cdsHeaderCellDef class="selected-column-width" [ngClass]="['no-border-right']">
                <cds-checkbox
                  *ngIf="!isRefreshAllChecked"
                  [disabled]="isDisableTopCheckboxNoEr"
                  [checked]="noErChangeListIsAllChecked()"
                  [indeterminate]="noErChangeListIsHalfChecked()"
                  (change)="onNoErAllCheckedChange($event)">
                </cds-checkbox>
              </cds-header-cell>
              <cds-cell
                *cdsCellDef="let element; let row; index as i"
                [ngClass]="['selected-column-width', element.isSelected ? 'column-checked' : '', 'no-border-right']">
                <cds-checkbox [disabled]="element.isDisable" [checked]="element.isSelected" (change)="onRowCheckOfNoErList($event, element)"> </cds-checkbox>
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="eMPFRef">
              <cds-header-cell *cdsHeaderCellDef class="eMPFRef-width">
                <div class="prodtype-displaytooltip">
                  {{ 'productTypesummary.eMPFRef' | lang }}
                  <cds-icon class="note" (click)="onDisplayAdviseRef()" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
                </div>
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="eMPFRef-width">
                <cds-link
                  (click)="saveData(element)"
                  (contextmenu)="saveData(element)"
                  [config]="{
                    type: 'hyperlink',
                    label: element.eMPFRef,
                    href: navigateToCaseDetail(element.salesJourneySummaryId, element.eMPFRef)
                  }">
                </cds-link>
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="ProductType">
              <cds-header-cell *cdsHeaderCellDef class="product-type-width"> {{ 'productTypesummary.ProductType' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="product-type-width">
                {{ element.ProductType }}
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="ClientName">
              <cds-header-cell *cdsHeaderCellDef class="client-name-width"> {{ 'productTypesummary.ClientName' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="client-name-width">
                {{ element.ClientName }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="hkidOrPassportNo">
              <cds-header-cell *cdsHeaderCellDef class="dob-width"> {{ 'case-summary.hkid' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="dob-width">
                {{ element.hkidOrPassportNo }}
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="IFFDeclarationDate">
              <cds-header-cell *cdsHeaderCellDef class="dob-width"> {{ 'productTypesummary.IFFDeclarationDate' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="dob-width">
                {{ element.IFFDeclarationDate }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="IFFStatus">
              <cds-header-cell *cdsHeaderCellDef class="iFF-status-width">
                <div class="prodtype-displaytooltip">
                  {{ 'productTypesummary.IFFStatus' | lang }}
                  <cds-icon class="note" (click)="onDisplayAdviseIFF()" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
                </div>
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="iFF-status-width">
                <app-status-light
                  [color]="caseSummaryService.iffStatus(element).color"
                  [text]="caseSummaryService.iffStatus(element).text | lang"></app-status-light>
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="eMPFAppStatus">
              <cds-header-cell *cdsHeaderCellDef class="eMPFApp-status-width">
                <div class="prodtype-displaytooltip">
                  {{ 'productTypesummary.eMPFAppStatus' | lang }}
                  <cds-icon class="note" (click)="onDisplayAdviseEmpf()" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
                </div>
              </cds-header-cell>

              <cds-cell *cdsCellDef="let element" class="eMPFApp-status-width">
                <app-status-light
                  [color]="caseSummaryService.empfAppStatus(element).color"
                  [text]="caseSummaryService.empfAppStatus(element).text"></app-status-light>
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="SplitCase">
              <cds-header-cell *cdsHeaderCellDef class="split-case-width"> {{ 'productTypesummary.SplitCase' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" [ngSwitch]="element.SplitCase" class="split-case-width">
                <div *ngSwitchCase="'1'">
                  <cds-icon class="prodtype-splitcaselogo" icon="general:collaboration"></cds-icon>
                </div>
                <div *ngSwitchDefault>
                  <cds-icon class="prodtype-splitcaselogo no-split-case" icon="general:collaboration"></cds-icon>
                </div>
              </cds-cell>
            </ng-container>
          </cds-table>
          <div *ngIf="isLoading" class="progress-box">
            <cds-progress mode="indeterminate" [diameter]="60" [type]="'ring'" [text]="'common.loading' | lang" [color]="'ml'"></cds-progress>
          </div>
        </div>
        <div class="col-sm-12 div-border-bottom prodtype-pageformat">
          <app-pagination
            [pageCount]="{
              current: currentPageNumOrigin,
              count: allPageData.length
            }"
            (pageChange)="pageChange($event)">
          </app-pagination>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="prodTypeFormControl.value === SalesJourneyProdType.Corperate">
      <div *ngIf="isLoading && employerPageData.length === 0" class="progress-box l-d-f l-jc-cen">
        <cds-progress mode="indeterminate" [diameter]="60" [type]="'ring'" [text]="'common.loading' | lang" [color]="'ml'"></cds-progress>
      </div>
      <app-no-result-found
        [msg]="isAgreeSendIff ? 'common.noPtsAreFound' : ''"
        [style.display]="employerPageData.length === 0 && !isLoading ? 'block' : 'none'"></app-no-result-found>
      <div [style.display]="employerPageData.length > 0 ? 'unset' : 'none'" class="row l-plr-0">
        <div #tableBox class="table-wrapper" [ngClass]="[isLoading ? 'loading' : '']">
          <cds-table class="col-lg-12 row" [dataSource]="employerPageData" [class.full]="false">
            <cds-row *cdsRowDef="let row; index as i; columns: employerColumns"></cds-row>
            <cds-header-row *cdsHeaderRowDef="employerColumns"></cds-header-row>
            <ng-container cdsColumnDef="isSelected" sticky>
              <cds-header-cell *cdsHeaderCellDef class="selected-column-width" [ngClass]="['no-border-right']">
                <cds-checkbox
                  [disabled]="isDisableTopCheckboxEr"
                  [checked]="erChangeListIsAllChecked()"
                  [indeterminate]="erChangeListIsHalfChecked()"
                  (change)="onErAllCheckedChange($event)">
                </cds-checkbox>
              </cds-header-cell>
              <cds-cell
                *cdsCellDef="let element; let row; index as i"
                [ngClass]="['selected-column-width', element.isSelected ? 'column-checked' : '', 'no-border-right']">
                <cds-checkbox [disabled]="element.isDisable" [checked]="element.isSelected" (change)="onRowCheckOfErList($event, element)"> </cds-checkbox>
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="eMPFRef">
              <cds-header-cell *cdsHeaderCellDef>
                <div class="prodtype-displaytooltip">
                  {{ 'productTypesummary.eMPFRef' | lang }}
                  <cds-icon class="note" (click)="onDisplayAdviseRef()" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
                </div>
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                <cds-link
                  (click)="saveErData(element)"
                  (contextmenu)="saveErData(element)"
                  [config]="{
                    type: 'hyperlink',
                    label: element.eMPFRef,
                    href: navigateToCaseDetail(element.salesJourneySummaryId, element.eMPFRef)
                  }">
                </cds-link>
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="EmployerName">
              <cds-header-cell *cdsHeaderCellDef> {{ 'productTypesummary.employerName' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                {{ getEmployerName(element) }}
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="BRNumber">
              <cds-header-cell *cdsHeaderCellDef> {{ 'productTypesummary.BRNumber' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                {{ element.BRNumber }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="IFFDeclarationDate">
              <cds-header-cell *cdsHeaderCellDef> {{ 'productTypesummary.IFFDeclarationDate' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                {{ element.IFFDeclarationDate }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="IFFStatus">
              <cds-header-cell *cdsHeaderCellDef>
                <div class="prodtype-displaytooltip">
                  {{ 'productTypesummary.IFFStatus' | lang }}
                  <cds-icon class="note" (click)="onDisplayAdviseIFF()" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
                </div>
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                <app-status-light
                  [color]="caseSummaryService.iffStatus(element).color"
                  [text]="caseSummaryService.iffStatus(element).text | lang"></app-status-light>
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="eMPFAppStatus">
              <cds-header-cell *cdsHeaderCellDef>
                <div class="prodtype-displaytooltip">
                  {{ 'productTypesummary.eMPFAppStatus' | lang }}
                  <cds-icon class="note" (click)="onDisplayAdviseEmpf()" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
                </div>
              </cds-header-cell>

              <cds-cell *cdsCellDef="let element">
                <app-status-light
                  [color]="caseSummaryService.empfAppStatus(element).color"
                  [text]="caseSummaryService.empfAppStatus(element).text"></app-status-light>
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="SplitCase">
              <cds-header-cell *cdsHeaderCellDef> {{ 'productTypesummary.SplitCase' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" [ngSwitch]="element.SplitCase">
                <div *ngSwitchCase="'1'">
                  <cds-icon class="prodtype-splitcaselogo" icon="general:collaboration"></cds-icon>
                </div>
                <div *ngSwitchDefault>
                  <cds-icon class="prodtype-splitcaselogo no-split-case" icon="general:collaboration"></cds-icon>
                </div>
              </cds-cell>
            </ng-container>
          </cds-table>
          <div *ngIf="isLoading" class="progress-box">
            <cds-progress mode="indeterminate" [diameter]="60" [type]="'ring'" [text]="'common.loading' | lang" [color]="'ml'"></cds-progress>
          </div>
        </div>
        <div class="col-sm-12 div-border-bottom prodtype-pageformat">
          <app-pagination
            [pageCount]="{
              current: currentPageNumOrigin,
              count: allPageDataOfEmployerColumns.length
            }"
            (pageChange)="pageChange($event)">
          </app-pagination>
        </div>
      </div>
    </ng-container>
  </div>

  <div *ngIf="isAgreeSendIff" class="footer">
    <div>
      <span class="cds-h4">Please select record(s) to continue.</span>
    </div>
    <div class="l-d-f l-ai-cen">
      <div>
        <span class="cds-h5-demibold">{{ getSelected }} item(s) selected</span>
      </div>
      <div class="l-ml-6">
        <cds-button size="md" [disabled]="disabledContinue" (click)="goSendIffInBatchSummary()">Continue</cds-button>
      </div>
    </div>
  </div>
</div>
