import { CdsOption } from '@cds/ng-core/configuration';

export const empfCountryOption: CdsOption[] = [
  { label: '+852 (HK)', value: '852' },
  { label: '+853 (MA)', value: '853' },
  { label: '+86 (CN)', value: '86' },
  {
    label: '+376(AD)',
    value: '376',
  },
  {
    label: '+1-268(AG)',
    value: '1-268',
  },
  {
    label: '+1-264(AI)',
    value: '1-264',
  },
  {
    label: '+355(AL)',
    value: '355',
  },
  {
    label: '+374(AM)',
    value: '374',
  },
  {
    label: '+599(AN)',
    value: '599',
  },
  {
    label: '+244(AO)',
    value: '244',
  },
  {
    label: '+672(AQ)',
    value: '672',
  },
  {
    label: '+54(AR)',
    value: '54',
  },
  {
    label: '+1-684(AS)',
    value: '1-684',
  },
  {
    label: '+43(AT)',
    value: '43',
  },
  {
    label: '+61(AU)',
    value: '61',
  },
  {
    label: '+297(AW)',
    value: '297',
  },
  {
    label: '+994(AZ)',
    value: '994',
  },
  {
    label: '+387(BA)',
    value: '387',
  },
  {
    label: '+1-246(BB)',
    value: '1-246',
  },
  {
    label: '+880(BD)',
    value: '880',
  },
  {
    label: '+32(BE)',
    value: '32',
  },
  {
    label: '+226(BF)',
    value: '226',
  },
  {
    label: '+359(BG)',
    value: '359',
  },
  {
    label: '+973(BH)',
    value: '973',
  },
  {
    label: '+257(BI)',
    value: '257',
  },
  {
    label: '+229(BJ)',
    value: '229',
  },
  {
    label: '+1-441(BM)',
    value: '1-441',
  },
  {
    label: '+673(BN)',
    value: '673',
  },
  {
    label: '+591(BO)',
    value: '591',
  },
  {
    label: '+599-7(BQ)',
    value: '599-7',
  },
  {
    label: '+55(BR)',
    value: '55',
  },
  {
    label: '+1-242(BS)',
    value: '1-242',
  },
  {
    label: '+975(BT)',
    value: '975',
  },
  {
    label: '+55(BV)',
    value: '55',
  },
  {
    label: '+267(BW)',
    value: '267',
  },
  {
    label: '+375(BY)',
    value: '375',
  },
  {
    label: '+501(BZ)',
    value: '501',
  },
  {
    label: '+1(CA)',
    value: '1',
  },
  {
    label: '+61(CC)',
    value: '61',
  },
  {
    label: '+243(CD)',
    value: '243',
  },
  {
    label: '+236(CF)',
    value: '236',
  },
  {
    label: '+242(CG)',
    value: '242',
  },
  {
    label: '+225(CI)',
    value: '225',
  },
  {
    label: '+682(CK)',
    value: '682',
  },
  {
    label: '+56(CL)',
    value: '56',
  },
  {
    label: '+237(CM)',
    value: '237',
  },
  {
    label: '+86(CN)',
    value: '86',
  },
  {
    label: '+57(CO)',
    value: '57',
  },
  {
    label: '+506(CR)',
    value: '506',
  },
  {
    label: '+53(CU)',
    value: '53',
  },
  {
    label: '+238(CV)',
    value: '238',
  },
  {
    label: '+599(CW)',
    value: '599',
  },
  {
    label: '+61(CX)',
    value: '61',
  },
  {
    label: '+357(CY)',
    value: '357',
  },
  {
    label: '+420(CZ)',
    value: '420',
  },
  {
    label: '+253(DJ)',
    value: '253',
  },
  {
    label: '+45(DK)',
    value: '45',
  },
  {
    label: '+1-767(DM)',
    value: '1-767',
  },
  {
    label: '+213(DZ)',
    value: '213',
  },
  {
    label: '+385(HR)',
    value: '385',
  },
  {
    label: '+246(IO)',
    value: '246',
  },
  {
    label: '+855(KH)',
    value: '855',
  },
  {
    label: '+269(KM)',
    value: '269',
  },
  {
    label: '+1-345(KY)',
    value: '1-345',
  },
  {
    label: '+235(TD)',
    value: '235',
  },
  {
    label: '+262(TF)',
    value: '262',
  },
  {
    label: '+1-809',
    value: '1-809',
  },
  {
    label: '+1-829',
    value: '1-829',
  },
  {
    label: '+971(AE)',
    value: '971',
  },
  {
    label: '+590(BL)',
    value: '590',
  },
  {
    label: '+691(FM)',
    value: '691',
  },
  {
    label: '+1-869(KN)',
    value: '1-869',
  },
  {
    label: '+1-758(LC)',
    value: '1-758',
  },
  {
    label: '+423(LI)',
    value: '423',
  },
  {
    label: '+370(LT)',
    value: '370',
  },
  {
    label: '+352(LU)',
    value: '352',
  },
  {
    label: '+212(MA)',
    value: '212',
  },
  {
    label: '+377(MC)',
    value: '377',
  },
  {
    label: '+373(MD)',
    value: '373',
  },
  {
    label: '+382(ME)',
    value: '382',
  },
  {
    label: '+590(MF)',
    value: '590',
  },
  {
    label: '+261(MG)',
    value: '261',
  },
  {
    label: '+692(MH)',
    value: '692',
  },
  {
    label: '+389(MK)',
    value: '389',
  },
  {
    label: '+223(ML)',
    value: '223',
  },
  {
    label: '+95(MM)',
    value: '95',
  },
  {
    label: '+976(MN)',
    value: '976',
  },
  {
    label: '+853(MO)',
    value: '853',
  },
  {
    label: '+1-670(MP)',
    value: '1-670',
  },
  {
    label: '+596(MQ)',
    value: '596',
  },
  {
    label: '+222(MR)',
    value: '222',
  },
  {
    label: '+1-664(MS)',
    value: '1-664',
  },
  {
    label: '+356(MT)',
    value: '356',
  },
  {
    label: '+230(MU)',
    value: '230',
  },
  {
    label: '+960(MV)',
    value: '960',
  },
  {
    label: '+265(MW)',
    value: '265',
  },
  {
    label: '+52(MX)',
    value: '52',
  },
  {
    label: '+60(MY)',
    value: '60',
  },
  {
    label: '+258(MZ)',
    value: '258',
  },
  {
    label: '+264(NA)',
    value: '264',
  },
  {
    label: '+687(NC)',
    value: '687',
  },
  {
    label: '+227(NE)',
    value: '227',
  },
  {
    label: '+672-3(NF)',
    value: '672-3',
  },
  {
    label: '+234(NG)',
    value: '234',
  },
  {
    label: '+505(NI)',
    value: '505',
  },
  {
    label: '+31(NL)',
    value: '31',
  },
  {
    label: '+47(NO)',
    value: '47',
  },
  {
    label: '+977(NP)',
    value: '977',
  },
  {
    label: '+674(NR)',
    value: '674',
  },
  {
    label: '+683(NU)',
    value: '683',
  },
  {
    label: '+64(NZ)',
    value: '64',
  },
  {
    label: '+968(OM)',
    value: '968',
  },
  {
    label: '+507(PA)',
    value: '507',
  },
  {
    label: '+51(PE)',
    value: '51',
  },
  {
    label: '+675(PG)',
    value: '675',
  },
  {
    label: '+63(PH)',
    value: '63',
  },
  {
    label: '+92(PK)',
    value: '92',
  },
  {
    label: '+48(PL)',
    value: '48',
  },
  {
    label: '+64(PN)',
    value: '64',
  },
  {
    label: '+1-939(PR)',
    value: '1-939',
  },
  {
    label: '+970(PS)',
    value: '970',
  },
  {
    label: '+351(PT)',
    value: '351',
  },
  {
    label: '+680(PW)',
    value: '680',
  },
  {
    label: '+595(PY)',
    value: '595',
  },
  {
    label: '+1-787',
    value: '1-787',
  },
  {
    label: '+358(AX)',
    value: '358',
  },
  {
    label: '+41(CH)',
    value: '41',
  },
  {
    label: '+49(DE)',
    value: '49',
  },
  {
    label: '+1-849(DO)',
    value: '1-849',
  },
  {
    label: '+593(EC)',
    value: '593',
  },
  {
    label: '+372(EE)',
    value: '372',
  },
  {
    label: '+20(EG)',
    value: '20',
  },
  {
    label: '+212(EH)',
    value: '212',
  },
  {
    label: '+291(ER)',
    value: '291',
  },
  {
    label: '+34(ES)',
    value: '34',
  },
  {
    label: '+251(ET)',
    value: '251',
  },
  {
    label: '+358(FI)',
    value: '358',
  },
  {
    label: '+679(FJ)',
    value: '679',
  },
  {
    label: '+500(FK)',
    value: '500',
  },
  {
    label: '+298(FO)',
    value: '298',
  },
  {
    label: '+33(FR)',
    value: '33',
  },
  {
    label: '+241(GA)',
    value: '241',
  },
  {
    label: '+44(GB)',
    value: '44',
  },
  {
    label: '+1-473(GD)',
    value: '1-473',
  },
  {
    label: '+995(GE)',
    value: '995',
  },
  {
    label: '+594(GF)',
    value: '594',
  },
  {
    label: '+44-1481(GG)',
    value: '44-1481',
  },
  {
    label: '+233(GH)',
    value: '233',
  },
  {
    label: '+350(GI)',
    value: '350',
  },
  {
    label: '+299(GL)',
    value: '299',
  },
  {
    label: '+220(GM)',
    value: '220',
  },
  {
    label: '+224(GN)',
    value: '224',
  },
  {
    label: '+590(GP)',
    value: '590',
  },
  {
    label: '+240(GQ)',
    value: '240',
  },
  {
    label: '+30(GR)',
    value: '30',
  },
  {
    label: '+500(GS)',
    value: '500',
  },
  {
    label: '+502(GT)',
    value: '502',
  },
  {
    label: '+1-671(GU)',
    value: '1-671',
  },
  {
    label: '+245(GW)',
    value: '245',
  },
  {
    label: '+592(GY)',
    value: '592',
  },
  {
    label: '+852(HK)',
    value: '852',
  },
  {
    label: '+672(HM)',
    value: '672',
  },
  {
    label: '+504(HN)',
    value: '504',
  },
  {
    label: '+509(HT)',
    value: '509',
  },
  {
    label: '+36(HU)',
    value: '36',
  },
  {
    label: '+62(ID)',
    value: '62',
  },
  {
    label: '+353(IE)',
    value: '353',
  },
  {
    label: '+972(IL)',
    value: '972',
  },
  {
    label: '+44-1624(IM)',
    value: '44-1624',
  },
  {
    label: '+91(IN)',
    value: '91',
  },
  {
    label: '+964(IQ)',
    value: '964',
  },
  {
    label: '+98(IR)',
    value: '98',
  },
  {
    label: '+354(IS)',
    value: '354',
  },
  {
    label: '+39(IT)',
    value: '39',
  },
  {
    label: '(IZ)',
    value: '',
  },
  {
    label: '+44-1534(JE)',
    value: '44-1534',
  },
  {
    label: '+1-876(JM)',
    value: '1-876',
  },
  {
    label: '+962(JO)',
    value: '962',
  },
  {
    label: '+81(JP)',
    value: '81',
  },
  {
    label: '+254(KE)',
    value: '254',
  },
  {
    label: '+996(KG)',
    value: '996',
  },
  {
    label: '+686(KI)',
    value: '686',
  },
  {
    label: '+850(KP)',
    value: '850',
  },
  {
    label: '+82(KR)',
    value: '82',
  },
  {
    label: '+965(KW)',
    value: '965',
  },
  {
    label: '+7(KZ)',
    value: '7',
  },
  {
    label: '+856(LA)',
    value: '856',
  },
  {
    label: '+961(LB)',
    value: '961',
  },
  {
    label: '+94(LK)',
    value: '94',
  },
  {
    label: '+231(LR)',
    value: '231',
  },
  {
    label: '+266(LS)',
    value: '266',
  },
  {
    label: '+371(LV)',
    value: '371',
  },
  {
    label: '+218(LY)',
    value: '218',
  },
  {
    label: '(OT)',
    value: '',
  },
  {
    label: '+689(PF)',
    value: '689',
  },
  {
    label: '+508(PM)',
    value: '508',
  },
  {
    label: '+974(QA)',
    value: '974',
  },
  {
    label: '+262(RE)',
    value: '262',
  },
  {
    label: '+40(RO)',
    value: '40',
  },
  {
    label: '+381(RS)',
    value: '381',
  },
  {
    label: '+7(RU)',
    value: '7',
  },
  {
    label: '+250(RW)',
    value: '250',
  },
  {
    label: '+966(SA)',
    value: '966',
  },
  {
    label: '+677(SB)',
    value: '677',
  },
  {
    label: '+248(SC)',
    value: '248',
  },
  {
    label: '+249(SD)',
    value: '249',
  },
  {
    label: '+46(SE)',
    value: '46',
  },
  {
    label: '+65(SG)',
    value: '65',
  },
  {
    label: '+290(SH)',
    value: '290',
  },
  {
    label: '+386(SI)',
    value: '386',
  },
  {
    label: '+47(SJ)',
    value: '47',
  },
  {
    label: '+421(SK)',
    value: '421',
  },
  {
    label: '+232(SL)',
    value: '232',
  },
  {
    label: '+378(SM)',
    value: '378',
  },
  {
    label: '+221(SN)',
    value: '221',
  },
  {
    label: '+252(SO)',
    value: '252',
  },
  {
    label: '+597(SR)',
    value: '597',
  },
  {
    label: '+211(SS)',
    value: '211',
  },
  {
    label: '+239(ST)',
    value: '239',
  },
  {
    label: '+503(SV)',
    value: '503',
  },
  {
    label: '+1-721(SX)',
    value: '1-721',
  },
  {
    label: '+963(SY)',
    value: '963',
  },
  {
    label: '+268(SZ)',
    value: '268',
  },
  {
    label: '+1-649(TC)',
    value: '1-649',
  },
  {
    label: '+228(TG)',
    value: '228',
  },
  {
    label: '+66(TH)',
    value: '66',
  },
  {
    label: '+992(TJ)',
    value: '992',
  },
  {
    label: '+690(TK)',
    value: '690',
  },
  {
    label: '+670(TL)',
    value: '670',
  },
  {
    label: '+993(TM)',
    value: '993',
  },
  {
    label: '+216(TN)',
    value: '216',
  },
  {
    label: '+676(TO)',
    value: '676',
  },
  {
    label: '+90(TR)',
    value: '90',
  },
  {
    label: '+1-868(TT)',
    value: '1-868',
  },
  {
    label: '+688(TV)',
    value: '688',
  },
  {
    label: '+886(TW)',
    value: '886',
  },
  {
    label: '+255(TZ)',
    value: '255',
  },
  {
    label: '+380(UA)',
    value: '380',
  },
  {
    label: '+256(UG)',
    value: '256',
  },
  {
    label: '(UM)',
    value: '',
  },
  {
    label: '+1(US)',
    value: '1',
  },
  {
    label: '+598(UY)',
    value: '598',
  },
  {
    label: '+998(UZ)',
    value: '998',
  },
  {
    label: '+379(VA)',
    value: '379',
  },
  {
    label: '+1-784(VC)',
    value: '1-784',
  },
  {
    label: '+58(VE)',
    value: '58',
  },
  {
    label: '+1-284(VG)',
    value: '1-284',
  },
  {
    label: '+1-340(VI)',
    value: '1-340',
  },
  {
    label: '+84(VN)',
    value: '84',
  },
  {
    label: '+678(VU)',
    value: '678',
  },
  {
    label: '+681(WF)',
    value: '681',
  },
  {
    label: '+685(WS)',
    value: '685',
  },
  {
    label: '+967(YE)',
    value: '967',
  },
  {
    label: '+262(YT)',
    value: '262',
  },
  {
    label: '+27(ZA)',
    value: '27',
  },
  {
    label: '+260(ZM)',
    value: '260',
  },
  {
    label: '+263(ZW)',
    value: '263',
  },
];
