/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BasicResponse } from 'src/app/core/models/response/response';

import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class FinancialJournalService {
  constructor(private http: HttpClient) {}

  getData(params: any): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}${environment.accountingServiceExt}${'/financial-journal/list'}`, params);
  }

  getAllData(params: any): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}${environment.accountingServiceExt}${'/financial-journal/listForDownload'}`, params);
  }
}
