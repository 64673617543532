import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { ActionIcons } from '@cds/ng-themes';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import moment from 'moment';
import { EMPF_APP_STATUS } from 'src/app/config/empf-app-status.config';
import { eMPF_IFF_STATUS } from 'src/app/config/iff-status.config';
import { EmpfAppStatusService } from 'src/app/core/services/empf-app-status/empf-app-status.service';
import { IffStatusService } from 'src/app/core/services/iff-status/iff-status.service';
import { dateRangeInvalidValidator, isSameOrBeforeToday } from 'src/app/core/validators';
import { atLeastOneQueryConditionValidator } from 'src/app/core/validators/common.validator';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { dateValidator } from 'src/app/shared/validators/validators';
import { copyText, getFilterParams, pageToTop } from 'src/app/utils/utils';
import { AdviseEmpfrefComponent } from '../../case-summary/advise-empfref/advise-empfref.component';
import { SalesJourneyProdType } from '../../case-summary/case-summary.model';
import { EmpfAppStatusPopupComponent } from '../../empf-app-status-popup/empf-app-status-popup.component';
import { IffStatusFlowPopupComponent } from '../../iff-status-flow-popup/iff-status-flow-popup.component';
import { EmpfAgentIndRecordItem, RefNoType } from '../md-iff-summary.model';

interface ReqeuestParams {
  domain: 'SEP';
  page: number;
  size: number;
  empfNo?: string;
  clientName?: string;
  declarationDateStart?: string;
  declarationDateEnd?: string;
  mdIffStatus?: number[];
  productTypes?: string[];
  empfStatus?: number[];
  hkidOrPassportNo?: string;
}

@Component({
  selector: 'app-empf-agent-sep',
  templateUrl: './empf-agent-sep.component.html',
  styleUrls: ['./empf-agent-sep.component.scss'],
})
export class EmpfAgentSepComponent implements OnInit {
  @Input() rolePriority?: string;
  copyText = copyText;
  reqeuestParams: ReqeuestParams = {
    domain: 'SEP',
    page: 1,
    size: 30,
  };
  @ViewChild('tableBox', { static: false })
  tableBox?: ElementRef<HTMLDivElement>;
  formGroup!: FormGroup;
  readonly displayedColumns = ['empfNo', 'mdNo', 'productType', 'clientName', 'hkIdOrPassport', 'declarationDate', 'mdIffStatus', 'empfStatus', 'isSplitAgent'];
  allPageData: EmpfAgentIndRecordItem[][] = [];
  currentPageNumOrigin = 1;
  resetDatePicker = false;
  private today = new Date();
  declarationDateFromMax = this.today;
  declarationDateToMin = new Date('2000/01/01');
  declarationDateToMax: Date = this.today;
  iffStatusoptionList: CdsOption[] = [];
  empfAppStatusoptionList: CdsOption[] = [];
  isLoading = false;
  totalElements = 0;
  totalPages = 0;
  iconConfig: CdsIconConfig = {
    size: 'sm',
    color: 'default',
  };
  infoIcon = ActionIcons.info_1;

  get currentPageIndex() {
    return this.currentPageNumOrigin - 1;
  }

  get currentPageData() {
    if (this.allPageData.length === 0) {
      return [];
    }
    return this.allPageData[this.currentPageIndex];
  }

  get checkIsHaveScroll() {
    if (this.tableBox) {
      return this.tableBox.nativeElement.scrollWidth > this.tableBox.nativeElement.clientWidth;
    }
    return false;
  }

  get declarationDateStart() {
    return this.formGroup.get('declarationDateStart') as FormControl;
  }

  get declarationDateEnd() {
    return this.formGroup.get('declarationDateEnd') as FormControl;
  }

  get empfNo() {
    return this.formGroup.get('empfNo') as FormControl;
  }

  get clientName() {
    return this.formGroup.get('clientName') as FormControl;
  }

  get hkidOrPassportNo() {
    return this.formGroup.get('hkidOrPassportNo') as FormControl;
  }

  get mdIffStatus() {
    return this.formGroup.get('mdIffStatus') as FormControl;
  }

  get empfStatus() {
    return this.formGroup.get('empfStatus') as FormControl;
  }

  get productTypes() {
    return this.formGroup.get('productTypes') as FormControl;
  }

  constructor(
    private cdsPopup: CdsPopupService,
    public iffStatusService: IffStatusService,
    public empfAppStatusService: EmpfAppStatusService,
    private router: Router,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService
  ) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup(
      {
        empfNo: new FormControl(),
        clientName: new FormControl(),
        declarationDateStart: new FormControl(null, {
          validators: [dateValidator('common.invalidDate'), isSameOrBeforeToday({ dateInvalid: 'common.invalidDate' })],
        }),
        declarationDateEnd: new FormControl(null, {
          validators: [dateValidator('common.invalidDate'), isSameOrBeforeToday({ dateInvalid: 'common.invalidDate' })],
        }),
        hkidOrPassportNo: new FormControl(),
        mdIffStatus: new FormControl([]),
        empfStatus: new FormControl([]),
        productTypes: new FormControl([]),
      },
      [dateRangeInvalidValidator('declarationDateStart', 'declarationDateEnd'), atLeastOneQueryConditionValidator()]
    );

    this.initIffStatusOptions();
    this.initEmpfAppStatusoptions();
    this.search();
  }

  private initIffStatusOptions() {
    this.iffStatusoptionList = eMPF_IFF_STATUS.map<CdsOption>(element => ({
      label: element.text,
      value: element.value,
    }));
  }

  private initEmpfAppStatusoptions() {
    this.empfAppStatusoptionList = EMPF_APP_STATUS.map<CdsOption>(element => ({
      label: element.text,
      value: element.value,
    }));
  }

  get isDisableSearch() {
    return this.formGroup.invalid;
  }

  declarationDateFromChange(val: string) {
    const dateObject = moment(val, 'DD/MM/YYYY');
    if (dateObject.isValid()) {
      this.declarationDateToMin = dateObject.toDate();
    }
  }

  declarationDateToChange(val: string) {
    const dateObject = moment(val, 'DD/MM/YYYY');
    if (dateObject.isValid()) {
      this.declarationDateFromMax = dateObject.toDate();
    }
  }

  @HostListener('document:keydown.enter', ['$event'])
  onDocumentEnter(): void {
    this.onClickSearch();
  }
  onClickSearch() {
    if (this.isDisableSearch || this.isLoading) {
      return;
    }

    this.reqeuestParams.page = 1;
    this.search();
  }

  onClickReset() {
    this.formGroup.reset({
      mdIffStatus: [],
      empfStatus: [],
    });
    this.onResetDatePicker();

    this.reqeuestParams = {
      domain: 'SEP',
      page: 1,
      size: 30,
    };
    this.search();
  }

  onGenerateNewMDReferenceNo() {
    this.router.navigate([
      '/salesjourney/generate-md/select-product-type',
      {
        refNoType: RefNoType.EMPF,
        tabIndex: 1,
        prodType: SalesJourneyProdType.SEP,
      },
    ]);
  }

  onDisplayAdviseIFF() {
    this.cdsPopup.open(IffStatusFlowPopupComponent, {
      size: 'sm',
    });
  }

  onDisplayAdviseRef() {
    this.cdsPopup.open(AdviseEmpfrefComponent, {
      size: 'sm',
    });
  }

  onDisplayAdviseEmpf() {
    this.cdsPopup.open(EmpfAppStatusPopupComponent, {
      size: 'sm',
    });
  }

  pageChange(num: number): void {
    pageToTop();
    this.reqeuestParams.page = num;
    this.search();
  }

  onGoCaseDetails(empfNo: string) {
    this.router.navigate([
      '/salesjourney/empf-case-details',
      empfNo,
      {
        refNoType: RefNoType.EMPF,
        tabIndex: 1,
        prodType: SalesJourneyProdType.SEP,
      },
    ]);
  }

  onResetDatePicker() {
    this.declarationDateFromMax = this.today;
    this.declarationDateToMin = new Date('2000/01/01');
    this.resetDatePicker = true;
    setTimeout(() => {
      this.resetDatePicker = false;
    }, 0);
  }

  private search() {
    this.reqeuestParams.declarationDateStart = undefined;
    this.reqeuestParams.declarationDateEnd = undefined;
    if (
      !this.declarationDateStart.hasError('dateInvalid') &&
      !this.declarationDateEnd.hasError('dateInvalid') &&
      !this.formGroup.hasError('dateRangeInvalidValidator')
    ) {
      this.reqeuestParams.declarationDateStart = this.declarationDateStart.value;
      this.reqeuestParams.declarationDateEnd = this.declarationDateEnd.value;
    }
    this.reqeuestParams.empfNo = this.empfNo.value;
    this.reqeuestParams.clientName = this.clientName.value;
    this.reqeuestParams.mdIffStatus = this.mdIffStatus.value?.map((item: { value: number }) => item.value);
    this.reqeuestParams.empfStatus = this.empfStatus.value?.map((item: { value: number }) => item.value);
    this.reqeuestParams.hkidOrPassportNo = this.hkidOrPassportNo.value;
    this.reqeuestParams.productTypes = ['SEP'];

    const params = getFilterParams(this.reqeuestParams);

    this.isLoading = true;
    this.cdHttpServeService
      .post<{
        content: EmpfAgentIndRecordItem[];
        totalElements: number;
        number: number;
        totalPages: number;
      }>('/ext/eb-ssms-sales-journey-service/empfIffSummaries/agent', params)
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            res.data.content = res.data.content || [];
            this.currentPageNumOrigin = res.data.number;
            this.allPageData[this.currentPageIndex] = res.data.content;
            this.totalElements = res.data.totalElements;
            this.totalPages = res.data.totalPages;

            const currentAllPageData = this.allPageData.length;
            if (this.totalPages > currentAllPageData) {
              for (let i = 0; i < this.totalPages - currentAllPageData; i++) {
                this.allPageData.push([]);
              }
            } else if (this.totalPages < currentAllPageData) {
              for (let i = 0; i < currentAllPageData - this.totalPages; i++) {
                this.allPageData.pop();
              }
            }
          }
          this.isLoading = false;
        },
        error: err => {
          this.alert.error('Error!', err);
          this.isLoading = false;
        },
      });
  }

  hkidOrPassportNoChange(event: string) {
    const value = this.hkidOrPassportNo.value.replace(/[\u4e00-\u9fa5]/gi, '');
    if (event !== value) {
      this.hkidOrPassportNo.setValue(value);
    }
  }
}
