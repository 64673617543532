export enum CustomerStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  TERMINATED = 'TERMD',
  RECEIVE = 'RECEIVE',
  FOLLOW_UP_IN_PRGRESS = 'FOLLOW_UP_IN_PRGRESS',
  ACTIVE = 'ACTIVE',
  TERMG = 'TERMG',
  TR_REJECT = 'TR_REJECT',
  CANCEL = 'CANCEL',
  RETAIN = 'RETAIN',
}
