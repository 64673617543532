<!-- <app-cd-page-title title="{{ 'Upload  Customer Data' | lang }}"> </app-cd-page-title> -->

<div class="row l-pt-7" style="display: none">
  <div class="col-xs-12 col-md-6 cds-white l-pa-5">
    <div class="l-mb-4 cds-bold l-pb-2">Upload Customer Data(Create Commission Pool in batches)</div>
    <cds-file-upload
      ngDefaultControl
      (change)="fileChange($event)"
      [formControl]="formControl"
      [uploading]="uploading"
      acceptedFiles="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
    </cds-file-upload>
    <div class="l-mt-2" *ngIf="errorMessage">
      <cds-assistive-text>
        {{ errorMessage | lang }}
      </cds-assistive-text>
    </div>
  </div>
</div>
<form [formGroup]="formGroup" class="row l-pt-7" (ngSubmit)="createPool()">
  <div class="row col-xs-12 col-md-6 cds-white l-pt-7">
    <div class="col-xs-12 l-mb-4 cds-bold l-pb-5">Create MPF Comm Pool for PTW period</div>
    <cds-form-field class="col-xs-12 col-md-10 l-pb-6">
      <label class="cds-body2 font-color-light3 l-d-ib l-mb-3">
        {{ 'commission.common.accountType' | lang }} <span class="cds-coral-text dark-1">*</span>
      </label>
      <cds-dropdown [config]="accountTypeConfig" [formControl]="accountType"> </cds-dropdown>
      <!-- <app-cd-autocomplete [placeholder]="accountTypeConfig.placeholder" [control]="accountType" [options]="accountTypeConfig.options"></app-cd-autocomplete> -->
    </cds-form-field>
    <cds-form-field class="col-xs-12 col-md-10 l-pb-6">
      <label class="cds-body2 font-color-light3 l-d-ib l-mb-3"> {{ 'common.business.acconutCode' | lang }} <span class="cds-coral-text dark-1">*</span> </label>
      <cds-textfield [placeholder]="'common.business.acconutCode.placeholder' | lang" [formControl]="accountCode"> </cds-textfield>
      <cds-assistive-text *ngIf="accountCode.errors">
        {{ accountCode.errors['error'] | lang }}
      </cds-assistive-text>
    </cds-form-field>

    <cds-form-field class="col-xs-12 col-md-10 l-pb-6">
      <label class="cds-body2 font-color-light3 l-d-ib l-mb-3">
        {{ 'common.business.commencementDate' | lang }} <span class="cds-coral-text dark-1">*</span>
      </label>
      <cds-textfield
        placeholder="{{ 'common.business.dateFormat.placeholder' | lang }}"
        [cdsDatepicker]="commencementDatePicker"
        formControlName="commencementDate">
      </cds-textfield>
      <cds-datepicker #commencementDatePicker="cdsDatepicker"></cds-datepicker>
      <cds-assistive-text *ngIf="commencementDate.errors && commencementDate.dirty">{{ commencementDate.errors['error'] | lang }}</cds-assistive-text>
    </cds-form-field>

    <cds-form-field class="col-xs-12 col-md-10 l-pb-6">
      <label class="cds-body2 font-color-light3 l-d-ib l-mb-3">
        {{ 'common.business.firstContributionDate' | lang }}
      </label>
      <cds-textfield
        placeholder="{{ 'common.business.dateFormat.placeholder' | lang }}"
        [cdsDatepicker]="firstContributionDatePicker"
        formControlName="firstContributionMonth">
      </cds-textfield>
      <cds-datepicker #firstContributionDatePicker="cdsDatepicker"></cds-datepicker>
      <cds-assistive-text *ngIf="firstContributionMonth.errors && firstContributionMonth.dirty">{{
        firstContributionMonth.errors['error'] | lang
      }}</cds-assistive-text>
    </cds-form-field>

    <cds-form-field class="col-xs-12 col-md-10 l-pb-6">
      <label class="cds-body2 font-color-light3 l-d-ib l-mb-3">
        {{ 'common.business.servicingAgent' | lang }} <span class="cds-coral-text dark-1">*</span>
      </label>
      <cds-textfield [placeholder]="'common.business.servicingAgent.placehold' | lang" [formControl]="servicingAgents"> </cds-textfield>
      <cds-assistive-text *ngIf="servicingAgents.errors">
        {{ servicingAgents.errors['error'] | lang }}
      </cds-assistive-text>
    </cds-form-field>

    <cds-form-field class="col-xs-12 col-md-10 l-pb-6">
      <label class="cds-body2 font-color-light3 l-d-ib l-mb-3"> {{ 'common.business.nameAccount' | lang }} <span class="cds-coral-text dark-1">*</span> </label>
      <cds-textfield [placeholder]="'common.business.nameAccount.placehold' | lang" [formControl]="name"> </cds-textfield>
      <cds-assistive-text *ngIf="name.errors">
        {{ name.errors['error'] | lang }}
      </cds-assistive-text>
    </cds-form-field>

    <div class="col-xs-12 col-md-10 l-pb-6">
      <cds-button class="l-mr-4" type="submit" [disabled]="disabled" size="md">{{ 'commission.createPool' | lang }} </cds-button>
      <cds-button (click)="reset()" [style]="'secondary'" size="md">{{ 'common.reset' | lang }} </cds-button>
    </div>
  </div>
</form>
