<div class="row l-pt-2 l-pb-2" [class.static-class]="!sticky" [class.sticky-class]="sticky">
  <div class="col-xs-12 l-jc-sb l-ai-cen flex-container">
    <div class="row col-xs l-plr-0">
      <div [ngClass]="sticky ? '' : 'col-xs'" class="row l-plr-0">
        <div class="title-icon">
          <cds-icon color="#00A758" width="50px" height="50px" icon="environment:office_building"></cds-icon>
        </div>
        <div class="col-xs">
          <div class="row l-plr-0">
            <div class="col-xs-12">
              <div *ngIf="sticky">
                <span class="cds-h4-demibold">{{ employerProfileDetail?.companyName || '&nbsp;' }}</span>
                <span class="cds-body1-demibold">
                  {{ employerProfileDetail?.companyChineseName || '&nbsp;' }}
                </span>
              </div>

              <div *ngIf="!sticky">
                <div class="cds-h4-demibold font-weight-6 l-mb-0">{{ employerProfileDetail?.companyName || '&nbsp;' }}</div>
                <div class="cds-body1-demibold font-weight-6 l-mb-0">
                  {{ employerProfileDetail?.companyChineseName || '&nbsp;' }}
                </div>
              </div>

              <div class="row middle-xs l-plr-0 l-pt-1">
                <app-icon-status [value]="employerProfileDetail?.employerStatus" [statusMapObj]="employerStatusMapObj"></app-icon-status>
              </div>

              <div *ngIf="employerProfileDetail?.latestUpdateFromEMPF && !sticky" class="row middle-xs l-plr-0 l-pt-1 cds-body2">
                <span class="l-mr-1">Latest update from eMPF:</span>
                <span class="l-mr-1"> {{ updateDate | fn : employerProfileDetail?.latestUpdateFromEMPF }}</span>
                <span>(HKT)</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row code-w l-plr-0" *ngIf="sticky">
        <app-row-item
          class="col-xs-6"
          label="er.employer-payroll-group"
          [data]="payrollGroup.payrollGroupName"
          *ngIf="payrollGroup"
          class="l-ml-8"></app-row-item>

        <app-row-item
          class="col-xs-6"
          label="er.profile.employer-account-code"
          [data]="employerProfileDetail?.employerAccountCode"
          class="l-ml-8"></app-row-item>
      </div>
    </div>

    <div *ngIf="showErInfoBtn" class="col-xs-3 l-plr-0 l-jc-fe back-button-area">
      <div class="icon-size">
        <cds-icon size="sm" color="#ff6666" icon="action:button_right_filled" (click)="goBack()"></cds-icon>
      </div>
      <button class="button-border-none-font" (click)="goBack()">
        {{ (sticky ? 'er.profile.employer-info-overview-page' : 'er.profile.view-all-info') | lang }}
      </button>
    </div>

    <div class="row col-xs-3 l-jc-fe l-plr-0 button-area" *ngIf="showQuickAccessBtn">
      <cds-button size="sm" (click)="quickAccess()">
        <div class="icon-button">
          <cds-icon size="sm" color="#FFF" icon="action:more_options"></cds-icon>
          <span>Quick Acess</span>
        </div>
      </cds-button>
      <app-employer-detail-quick-access
        [id]="id"
        (changeShowQuickAccess)="quickAccess($event)"
        (erProfileInfoDetailShow)="erProfileInfoDetailShow.emit()"
        [employerProfileDetail]="employerProfileDetail"
        *ngIf="showQuickAccess">
      </app-employer-detail-quick-access>
    </div>
  </div>
</div>
