<div class="l-pa-7">
  <div class="l-mt-3 submit-success">
    <cds-icon class="button-checkmark" icon="action:check"></cds-icon>
  </div>
  <div class="l-mt-5">
    <span class="cds-h4-demibold">
      {{ 'generate.md.has-been-sent-customer' | lang }}
    </span>
  </div>
  <div class="l-mt-3">
    <cds-assistive-text type="error">{{ 'generate.md.no-changes-submitted' | lang }}</cds-assistive-text>
  </div>
  <div class="l-mt-5">
    <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">{{ 'generate.md.iff-reference-number' | lang }}</span>
  </div>
  <div class="l-mt-1 l-d-f" *ngFor="let item of data.ids">
    <div>
      <span class="cds-body1-demibold">{{ item }}</span>
    </div>
    <app-copy-button class="l-ml-3" [value]="item"></app-copy-button>
  </div>
  <div style="margin-top: 56px">
    <cds-button [config]="buttonConfig" (click)="gotIt()" label="{{ 'common.mingBai' | lang }}"></cds-button>
  </div>
</div>
