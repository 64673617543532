<div class="row l-pt-7" *ngIf="!_edited">
  <div class="col-xs-4">
    <cds-dropdown [(ngModel)]="searchDTO.payMethod" (ngModelChange)="typeChange($event)" [config]="transferTypeConfig"></cds-dropdown>
  </div>
</div>
<div class="row l-pt-7">
  <div class="l-mr-6 l-mb-2">
    <div class="col-xs-12 l-pb-2">
      <span class="cds-h2-light">{{ 'list.record-list' | lang }}</span>
    </div>
    <div class="col-xs-12">
      <span class="cds-body1">{{ 'page.total' | lang }} {{ _totalCount }} {{ 'page.records' | lang }}</span>
    </div>
  </div>
</div>

<div class="row" *ngIf="!_edited">
  <div class="col-xs-12 row between-xs bottom-xs l-pb-2">
    <div class="col-xs row l-plr-0 middle-xs">
      <div class="cds-color-navy-light3">
        <span>{{ 'cheque.list.number-of-records-shown' | lang }}</span>
      </div>
      <div class="row col-xs-6 col-md">
        <div
          class="page-size l-mr-2 l-ml-2 pointer"
          *ngFor="let size of pageSelectSize; let i = index"
          [ngClass]="_pageSelected === i ? 'size-selected' : ''"
          (click)="sizeSelect(i)">
          <span class="cds-body2">{{ size }}</span>
        </div>
      </div>
    </div>

    <div class="l-pr-4">
      <cds-button [disabled]="exportSelectedDisabled" (click)="exportSelected()" [config]="exportSelectedButtonConfig">Export Selected</cds-button>
    </div>
    <div>
      <div class="col-xs-12 end-xs">
        <div><span class="cds-body-1">Total amount of transaction:</span></div>
        <div class="color-green l-pt-2">
          <span *ngIf="_currSelected !== 0" class="cds-body-hero-demibold">{{ _currList[_currSelected].type }} {{ transactionAmount | number : '1.2-2' }}</span>
          <span *ngIf="_currSelected === 0">N/A</span>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xs-12 row">
    <div class="cds-color-navy-light3 l-pt-4">
      <span>{{ 'cheque.list.currency' | lang }}</span>
    </div>
    <div class="row col-xs">
      <div class="row col-xs-12 l-plr-0">
        <div
          class="page-size l-ma-2 pointer"
          *ngFor="let item of _currList; let i = index"
          [ngClass]="item.selected ? 'size-selected' : ''"
          (click)="currSelected(i)">
          <span class="cds-body2">{{ item.type }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xs-12 row">
    <div class="cds-color-navy-light3 l-pt-4">
      <span>{{ 'cheque.list.status' | lang }}</span>
    </div>
    <div class="row col-xs">
      <div class="row col-xs-12 l-plr-0">
        <div
          class="page-size l-ma-2 pointer"
          *ngFor="let item of _paymentStatusList; let i = index"
          [ngClass]="item.selected ? 'size-selected' : ''"
          (click)="paymentStatusSelecte(i)">
          <span class="cds-body2">{{ item.label }}</span>
        </div>
      </div>
      <div (click)="resetStatus()" class="row col-xs-12 middle-xs pointer l-pt-2 l-pb-2">
        <cds-icon class="l-pr-2" color="#00a758" icon="arrow:update_recycle"></cds-icon>
        <span class="cds-h6-demibold l-mb-0">Reset Status Filters</span>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="searchDTO.payMethod === 'BTL'">
  <div class="row l-pt-4" *ngIf="!_edited">
    <app-loading *ngIf="_progress" [overlayInPopup]="true"></app-loading>
    <div class="col-xs-12">
      <div #tableBox class="table-x">
        <cds-table [dataSource]="dataSource" cdsSort (cdsSortChange)="onSortChange($event)" [class.full]="true">
          <ng-container cdsColumnDef="selected" sticky>
            <cds-header-cell *cdsHeaderCellDef>
              <cds-checkbox [checked]="_selectedAll" [indeterminate]="_checkedDatasourceMap.size > 0" (change)="triggerAll()"></cds-checkbox>
            </cds-header-cell>
            <cds-cell class="none-width" *cdsCellDef="let element; index as i">
              <cds-checkbox (change)="trigger(i)" [checked]="element.checked"></cds-checkbox>
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="payeeName" sticky>
            <cds-header-cell cds-sort-header [ngClass]="checkIsHaveScroll ? 'shadow-right' : ''" *cdsHeaderCellDef> Payee Name </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '', checkIsHaveScroll ? 'shadow-right' : '', 'break-name']" *cdsCellDef="let element">
              <div>{{ element.payeeName }}</div>
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="paymentStatus" sticky>
            <cds-header-cell *cdsHeaderCellDef> Payment Status </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              <div class="status">
                <div class="status-icon" [ngStyle]="{ background: getColor(element.recordStatus) }"></div>
                <div class="l-pr-2"></div>
                <div>{{ getPaymentStatus | fn : element.recordStatus }}</div>
                <cds-assistive-text iconHeight="24px" class="l-pl-2" *ngIf="element.outstandingFlag"></cds-assistive-text>
              </div>
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="amt" sticky>
            <cds-header-cell *cdsHeaderCellDef [ngClass]="checkIsHaveScroll ? 'shadow-right' : ''" cds-sort-header>Amount</cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '', checkIsHaveScroll ? 'shadow-right' : '']" *cdsCellDef="let element">
              <div>{{ element.curr }} {{ element.amt | number : '1.2-2' | mynumber }}</div>
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="bankName">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef> Bank Name </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.bankName }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="bankCode">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef> Bank Code </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.bankCode }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="bankBranchCode">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef> Bank Branch Code </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.bankBranchCode }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="bankAcctNo">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef>Bank Account No.</cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.bankAcctNo }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="feedReceiveDate">
            <cds-header-cell class="feed-receive-date-width" cds-sort-header *cdsHeaderCellDef> Payment Instruction File Date </cds-header-cell>
            <cds-cell class="feed-receive-date-width" [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.feedReceiveDate | ssmsDate }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="isReissue">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef> Reissuance </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.isReissue }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="reissueReason">
            <cds-header-cell *cdsHeaderCellDef> Reissurance Reason </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '', 'break']" *cdsCellDef="let element">
              {{ formatReason(element.reissueReason) }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="payRefNo">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef> Payment Ref No. </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.payRefNo }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="appRefNo">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef> Application Reference No. </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              <span class="green-line pointer">{{ element.appRefNo }}</span>
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="exptPayIssue">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef> Expected Payment Issue Date on Statement </cds-header-cell>
            <cds-cell [ngClass]="element.checked ? 'bg-green-light-4' : ''" *cdsCellDef="let element">
              {{ formatDate2(element.exptPayIssue) }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="chequeIssueDate">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef> Cheque Issue Date </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.chequeIssueDate | ssmsDate }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="lastUpdateDate">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef> Status Update Date </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.lastUpdateDate | ssmsDate }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="schemeCode">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef> Scheme </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.schemeCode }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="transactionIdentifier">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef> Lawson Transaction Identifie </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.transactionIdentifier }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="remarks">
            <cds-header-cell *cdsHeaderCellDef> Remarks </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '', 'break']" *cdsCellDef="let element">
              {{ element.remarks }}
            </cds-cell>
          </ng-container>

          <cds-header-row *cdsHeaderRowDef="displayedColumnsBTL"></cds-header-row>
          <cds-row *cdsRowDef="let row; columns: displayedColumnsBTL"></cds-row>
        </cds-table>
      </div>
    </div>

    <div *ngIf="!_progress && (!dataSource || dataSource.length === 0)" class="col-xs-12 l-pt-7 l-pb-7">
      <app-no-result-found></app-no-result-found>
    </div>

    <div *ngIf="dataSource.length" class="col-xs-12 l-pt-4 l-pb-4 l-d-f l-jc-sb">
      <app-pagination [pageCount]="pageCount" [isLoading]="_progress" [useOf]="true" (pageChange)="loadTable()"> </app-pagination>
    </div>
  </div>

  <div class="row l-pt-4" *ngIf="_edited">
    <app-loading *ngIf="_progress" [overlayInPopup]="true"></app-loading>
    <div class="col-xs-12">
      <div #tableBox class="table-x">
        <cds-table [dataSource]="checkedDatasource" [class.full]="true">
          <ng-container cdsColumnDef="payeeName" sticky>
            <cds-header-cell [ngClass]="checkIsHaveScroll ? 'shadow-right' : ''" *cdsHeaderCellDef> Payee Name </cds-header-cell>
            <cds-cell [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '', 'break-name']" *cdsCellDef="let element">
              <div>{{ element.payeeName }}</div>
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="paymentStatus" sticky>
            <cds-header-cell *cdsHeaderCellDef> Payment Status </cds-header-cell>
            <cds-cell class="min-150" *cdsCellDef="let element; let index = index">
              <ng-container *ngIf="!_confirmed">
                <ng-container *ngIf="element.downConfig">
                  <div [ngClass]="['l-pl-2', index === 0 ? 'edit-dropdown-first' : 'edit-dropdown']">
                    <cds-dropdown [(ngModel)]="element.recordStatus" [config]="element.downConfig"></cds-dropdown>
                  </div>
                </ng-container>
                <ng-container *ngIf="!element.downConfig">
                  <div class="status">
                    <div class="status-icon" [ngStyle]="{ background: getColor(element.recordStatus) }"></div>
                    <div class="l-pr-2"></div>
                    <div>{{ element.recordStatus }}</div>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="_confirmed">
                <div>{{ element.recordStatus }}</div>
              </ng-container>
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="amt" sticky>
            <cds-header-cell *cdsHeaderCellDef [ngClass]="checkIsHaveScroll ? 'shadow-right' : ''">Amount</cds-header-cell>
            <cds-cell [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']" *cdsCellDef="let element">
              <div>{{ element.curr }} {{ element.amt | number : '1.2-2' | mynumber }}</div>
            </cds-cell>
          </ng-container>

          <ng-container cdsColumnDef="bankName">
            <cds-header-cell *cdsHeaderCellDef> Bank Name </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.bankName }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="bankCode">
            <cds-header-cell *cdsHeaderCellDef> Bank Code </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.bankCode }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="bankBranchCode">
            <cds-header-cell *cdsHeaderCellDef> Bank Branch Code </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.bankBranchCode }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="bankAcctNo">
            <cds-header-cell *cdsHeaderCellDef>Bank Account No.</cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.bankAcctNo }}
            </cds-cell>
          </ng-container>

          <ng-container cdsColumnDef="feedReceiveDate">
            <cds-header-cell class="feed-receive-date-width" *cdsHeaderCellDef> Payment Instruction File Date </cds-header-cell>
            <cds-cell class="feed-receive-date-width" *cdsCellDef="let element">
              {{ element.feedReceiveDate | ssmsDate }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="isReissue">
            <cds-header-cell *cdsHeaderCellDef> Reissuance </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.isReissue }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="reissueReason">
            <cds-header-cell *cdsHeaderCellDef> Reissurance Reason </cds-header-cell>
            <cds-cell class="break" *cdsCellDef="let element">
              {{ formatReason(element.reissueReason) }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="payRefNo">
            <cds-header-cell *cdsHeaderCellDef> Payment Ref No. </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.payRefNo }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="appRefNo">
            <cds-header-cell *cdsHeaderCellDef> Application Reference No. </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.appRefNo }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="exptPayIssue">
            <cds-header-cell *cdsHeaderCellDef> Expected Payment Issue Date on Statement </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ formatDate2(element.exptPayIssue) }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="chequeIssueDate">
            <cds-header-cell *cdsHeaderCellDef> Cheque Issue Date </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.chequeIssueDate | ssmsDate }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="lastUpdateDate">
            <cds-header-cell *cdsHeaderCellDef> Status Update Date </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.lastUpdateDate | ssmsDate }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="schemeCode">
            <cds-header-cell *cdsHeaderCellDef> Scheme </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.schemeCode }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="transactionIdentifier">
            <cds-header-cell *cdsHeaderCellDef> Lawson Transaction Identifie </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              <ng-container *ngIf="!_confirmed">
                <cds-textfield [(ngModel)]="element.transactionIdentifier" placeholder=""></cds-textfield>
              </ng-container>
              <ng-container *ngIf="_confirmed">
                {{ element.transactionIdentifier }}
              </ng-container>
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="remarks">
            <cds-header-cell *cdsHeaderCellDef> Remarks </cds-header-cell>
            <cds-cell class="break" *cdsCellDef="let element">
              <ng-container *ngIf="!_confirmed">
                <cds-textfield [(ngModel)]="element.remarks" placeholder=""></cds-textfield>
              </ng-container>
              <ng-container *ngIf="_confirmed">
                {{ element.remarks }}
              </ng-container>
            </cds-cell>
          </ng-container>

          <cds-header-row *cdsHeaderRowDef="_removeSelect(displayedColumnsBTL)"></cds-header-row>
          <cds-row class="line-no-wrap" *cdsRowDef="let row; columns: _removeSelect(displayedColumnsBTL)"></cds-row>
        </cds-table>
      </div>
    </div>

    <div *ngIf="!_confirmed" class="row">
      <div class="col-xs-12 row">
        <div class="search-padding l-pb-2 l-pt-5">
          <cds-button
            [fullWidth]="true"
            [config]="resetEditConfig"
            label="{{ 'list.reset' | lang }}"
            [disabled]="_resetEditDisable"
            (click)="editReset()"></cds-button>
        </div>
        <div class="search-padding l-pb-2 l-pt-5">
          <cds-button
            [fullWidth]="true"
            [config]="saveEditConfig"
            [disabled]="_saveDisable"
            label="{{ 'list.save' | lang }}"
            (click)="toConfirm()"></cds-button>
        </div>
      </div>
    </div>

    <div *ngIf="_confirmed" class="row">
      <div class="col-xs-12 row">
        <div class="search-padding l-pb-2 l-pt-5">
          <cds-button [fullWidth]="true" [config]="resetEditConfig" label="{{ 'list.back-to-edit' | lang }}" (click)="backListClick()"></cds-button>
        </div>
        <div class="search-padding l-pb-2 l-pt-5">
          <cds-button
            [fullWidth]="true"
            [config]="saveEditConfig"
            label="{{ 'list.confirm' | lang }}"
            [disabled]="_saveDisable"
            (click)="confirmClick()"></cds-button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="searchDTO.payMethod === 'BTO'">
  <div class="row l-pt-4" *ngIf="!_edited">
    <app-loading *ngIf="_progress" [overlayInPopup]="true"></app-loading>
    <div class="col-xs-12">
      <div #tableBox class="table-x">
        <cds-table [dataSource]="dataSource" cdsSort (cdsSortChange)="onSortChange($event)" [class.full]="true">
          <ng-container cdsColumnDef="selected" sticky>
            <cds-header-cell *cdsHeaderCellDef>
              <cds-checkbox [checked]="_selectedAll" [indeterminate]="_checkedDatasourceMap.size > 0" (change)="triggerAll()"></cds-checkbox>
            </cds-header-cell>
            <cds-cell class="none-width" *cdsCellDef="let element; index as i">
              <cds-checkbox (change)="trigger(i)" [checked]="element.checked"></cds-checkbox>
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="payeeName" sticky>
            <cds-header-cell cds-sort-header [ngClass]="checkIsHaveScroll ? 'shadow-right' : ''" *cdsHeaderCellDef> Payee Name </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '', checkIsHaveScroll ? 'shadow-right' : '', 'break-name']" *cdsCellDef="let element">
              <div>{{ element.payeeName }}</div>
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="paymentStatus" sticky>
            <cds-header-cell *cdsHeaderCellDef> Payment Status </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              <div class="status">
                <div class="status-icon" [ngStyle]="{ background: getColor(element.recordStatus) }"></div>
                <div class="l-pr-2"></div>
                <div>{{ getPaymentStatus | fn : element.recordStatus }}</div>
                <cds-assistive-text iconHeight="24px" class="l-pl-2" *ngIf="element.outstandingFlag"></cds-assistive-text>
              </div>
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="amt" sticky>
            <cds-header-cell *cdsHeaderCellDef [ngClass]="checkIsHaveScroll ? 'shadow-right' : ''" cds-sort-header>Amount</cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '', checkIsHaveScroll ? 'shadow-right' : '']" *cdsCellDef="let element">
              <div>{{ element.curr }} {{ element.amt | number : '1.2-2' | mynumber }}</div>
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="overseaBankName">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef>Beneficiary Bank Name</cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.overseaBankName }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="overseaSwift">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef>Beneficiary Bank SWIFT Code</cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.overseaSwift }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="otherSettlementInfo">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef>Beneficiary Bank IBAN/ ABA/ UID no.</cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.otherSettlementInfo }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="overseaBankAddr">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef>Beneficiary Bank Address</cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.overseaBankAddr }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="overseaBankCountry">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef>Beneficiary Bank Country</cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.overseaBankCountry }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="overseaBankAcctNo">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef>Beneficiary Bank Account No.</cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.overseaBankAcctNo }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="agentBankName">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef>Intermediary Bank Name</cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.agentBankName }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="agentSwift">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef>Intermediary Bank SWIFT Code</cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.agentSwift }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="feedReceiveDate">
            <cds-header-cell class="feed-receive-date-width" cds-sort-header *cdsHeaderCellDef> Payment Instruction File Date </cds-header-cell>
            <cds-cell class="feed-receive-date-width" [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.feedReceiveDate | ssmsDate }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="isReissue">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef> Reissuance </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.isReissue }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="reissueReason">
            <cds-header-cell *cdsHeaderCellDef> Reissurance Reason </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '', 'break']" *cdsCellDef="let element">
              {{ formatReason(element.reissueReason) }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="payRefNo">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef> Payment Ref No. </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.payRefNo }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="appRefNo">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef> Application Reference No. </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              <span class="green-line pointer">{{ element.appRefNo }}</span>
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="exptPayIssue">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef> Expected Payment Issue Date on Statement </cds-header-cell>
            <cds-cell [ngClass]="element.checked ? 'bg-green-light-4' : ''" *cdsCellDef="let element">
              {{ formatDate2(element.exptPayIssue) }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="chequeIssueDate">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef> Cheque Issue Date </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.chequeIssueDate | ssmsDate }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="lastUpdateDate">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef> Status Update Date </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.lastUpdateDate | ssmsDate }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="schemeCode">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef> Scheme </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.schemeCode }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="transactionIdentifier">
            <cds-header-cell cds-sort-header *cdsHeaderCellDef> Lawson Transaction Identifie </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
              {{ element.transactionIdentifier }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="remarks">
            <cds-header-cell *cdsHeaderCellDef> Remarks </cds-header-cell>
            <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '', 'break']" *cdsCellDef="let element">
              {{ element.remarks }}
            </cds-cell>
          </ng-container>

          <cds-header-row *cdsHeaderRowDef="displayedColumnsBTO"></cds-header-row>
          <cds-row *cdsRowDef="let row; columns: displayedColumnsBTO"></cds-row>
        </cds-table>
      </div>
    </div>

    <div *ngIf="!_progress && (!dataSource || dataSource.length === 0)" class="col-xs-12 l-pt-7 l-pb-7">
      <app-no-result-found></app-no-result-found>
    </div>

    <div *ngIf="dataSource.length" class="col-xs-12 l-pt-4 l-pb-4 l-d-f l-jc-sb">
      <app-pagination [pageCount]="pageCount" [isLoading]="_progress" [useOf]="true" (pageChange)="loadTable()"> </app-pagination>
    </div>
  </div>

  <div class="row l-pt-4" *ngIf="_edited">
    <app-loading *ngIf="_progress" [overlayInPopup]="true"></app-loading>
    <div class="col-xs-12">
      <div #tableBox class="table-x">
        <cds-table [dataSource]="checkedDatasource" [class.full]="true">
          <ng-container cdsColumnDef="payeeName" sticky>
            <cds-header-cell [ngClass]="checkIsHaveScroll ? 'shadow-right' : ''" *cdsHeaderCellDef> Payee Name </cds-header-cell>
            <cds-cell [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '', 'break-name']" *cdsCellDef="let element">
              <div>{{ element.payeeName }}</div>
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="paymentStatus" sticky>
            <cds-header-cell *cdsHeaderCellDef> Payment Status </cds-header-cell>
            <cds-cell class="min-150" *cdsCellDef="let element; let index = index">
              <ng-container *ngIf="!_confirmed">
                <ng-container *ngIf="element.downConfig">
                  <div [ngClass]="['l-pl-2', index === 0 ? 'edit-dropdown-first' : 'edit-dropdown']">
                    <cds-dropdown [(ngModel)]="element.recordStatus" [config]="element.downConfig"></cds-dropdown>
                  </div>
                </ng-container>
                <ng-container *ngIf="!element.downConfig">
                  <div class="status">
                    <div class="status-icon" [ngStyle]="{ background: getColor(element.recordStatus) }"></div>
                    <div class="l-pr-2"></div>
                    <div>{{ element.recordStatus }}</div>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="_confirmed">
                <div>{{ element.recordStatus }}</div>
              </ng-container>
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="amt" sticky>
            <cds-header-cell *cdsHeaderCellDef [ngClass]="checkIsHaveScroll ? 'shadow-right' : ''">Amount</cds-header-cell>
            <cds-cell [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']" *cdsCellDef="let element">
              <div>{{ element.curr }} {{ element.amt | number : '1.2-2' | mynumber }}</div>
            </cds-cell>
          </ng-container>

          <ng-container cdsColumnDef="overseaBankName">
            <cds-header-cell *cdsHeaderCellDef>Beneficiary Bank Name</cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.overseaBankName }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="overseaSwift">
            <cds-header-cell *cdsHeaderCellDef>Beneficiary Bank SWIFT Code</cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.overseaSwift }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="otherSettlementInfo">
            <cds-header-cell *cdsHeaderCellDef>Beneficiary Bank IBAN/ ABA/ UID no.</cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.otherSettlementInfo }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="overseaBankAddr">
            <cds-header-cell *cdsHeaderCellDef>Beneficiary Bank Address</cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.overseaBankAddr }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="overseaBankCountry">
            <cds-header-cell *cdsHeaderCellDef>Beneficiary Bank Country</cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.overseaBankCountry }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="overseaBankAcctNo">
            <cds-header-cell *cdsHeaderCellDef>Beneficiary Bank Account No.</cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.overseaBankAcctNo }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="agentBankName">
            <cds-header-cell *cdsHeaderCellDef>Intermediary Bank Name</cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.agentBankName }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="agentSwift">
            <cds-header-cell *cdsHeaderCellDef>Intermediary Bank SWIFT Code</cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.agentSwift }}
            </cds-cell>
          </ng-container>

          <ng-container cdsColumnDef="feedReceiveDate">
            <cds-header-cell class="feed-receive-date-width" *cdsHeaderCellDef> Payment Instruction File Date </cds-header-cell>
            <cds-cell class="feed-receive-date-width" *cdsCellDef="let element">
              {{ element.feedReceiveDate | ssmsDate }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="isReissue">
            <cds-header-cell *cdsHeaderCellDef> Reissuance </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.isReissue }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="reissueReason">
            <cds-header-cell *cdsHeaderCellDef> Reissurance Reason </cds-header-cell>
            <cds-cell class="break" *cdsCellDef="let element">
              {{ formatReason(element.reissueReason) }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="payRefNo">
            <cds-header-cell *cdsHeaderCellDef> Payment Ref No. </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.payRefNo }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="appRefNo">
            <cds-header-cell *cdsHeaderCellDef> Application Reference No. </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.appRefNo }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="exptPayIssue">
            <cds-header-cell *cdsHeaderCellDef> Expected Payment Issue Date on Statement </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ formatDate2(element.exptPayIssue) }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="chequeIssueDate">
            <cds-header-cell *cdsHeaderCellDef> Cheque Issue Date </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.chequeIssueDate | ssmsDate }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="lastUpdateDate">
            <cds-header-cell *cdsHeaderCellDef> Status Update Date </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.lastUpdateDate | ssmsDate }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="schemeCode">
            <cds-header-cell *cdsHeaderCellDef> Scheme </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.schemeCode }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="transactionIdentifier">
            <cds-header-cell *cdsHeaderCellDef> Lawson Transaction Identifier </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              <ng-container *ngIf="!_confirmed">
                <cds-textfield [(ngModel)]="element.transactionIdentifier" placeholder=""></cds-textfield>
              </ng-container>
              <ng-container *ngIf="_confirmed">
                {{ element.transactionIdentifier }}
              </ng-container>
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="remarks">
            <cds-header-cell *cdsHeaderCellDef> Remarks </cds-header-cell>
            <cds-cell class="break" *cdsCellDef="let element">
              <ng-container *ngIf="!_confirmed">
                <cds-textfield [(ngModel)]="element.remarks" placeholder=""></cds-textfield>
              </ng-container>
              <ng-container *ngIf="_confirmed">
                {{ element.remarks }}
              </ng-container>
            </cds-cell>
          </ng-container>

          <cds-header-row *cdsHeaderRowDef="_removeSelect(displayedColumnsBTO)"></cds-header-row>
          <cds-row class="line-no-wrap" *cdsRowDef="let row; columns: _removeSelect(displayedColumnsBTO)"></cds-row>
        </cds-table>
      </div>
    </div>

    <div *ngIf="!_confirmed" class="row">
      <div class="col-xs-12 row">
        <div class="search-padding l-pb-2 l-pt-5">
          <cds-button
            [fullWidth]="true"
            [config]="resetEditConfig"
            label="{{ 'list.reset' | lang }}"
            [disabled]="_resetEditDisable"
            (click)="editReset()"></cds-button>
        </div>
        <div class="search-padding l-pb-2 l-pt-5">
          <cds-button
            [fullWidth]="true"
            [config]="saveEditConfig"
            [disabled]="_saveDisable"
            label="{{ 'list.save' | lang }}"
            (click)="toConfirm()"></cds-button>
        </div>
      </div>
    </div>

    <div *ngIf="_confirmed" class="row">
      <div class="col-xs-12 row">
        <div class="search-padding l-pb-2 l-pt-5">
          <cds-button [fullWidth]="true" [config]="resetEditConfig" label="{{ 'list.back-to-edit' | lang }}" (click)="backListClick()"></cds-button>
        </div>
        <div class="search-padding l-pb-2 l-pt-5">
          <cds-button
            [fullWidth]="true"
            [config]="saveEditConfig"
            label="{{ 'list.confirm' | lang }}"
            [disabled]="_saveDisable"
            (click)="confirmClick()"></cds-button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div *ngIf="_toolBar && _checkedDatasourceMap.size > 0" class="operation-bar" [style.right]="scrollBarWidth" [style.left]="isMobile ? 0 : '80px'">
  <div class="cds-h4 l-mb-0" *ngIf="!isMobile">Please select record(s) to</div>
  <div class="button-area">
    <div class="cds-h5 l-mb-0 font-weight-6 l-mr-6">
      <span>{{ _checkedDatasourceMap.size }}</span> item(s) selected
    </div>
    <ng-container *ngIf="!_edited">
      <cds-button (click)="editTrigger()" [config]="editSelectedButtonConfig">Edit Selected</cds-button>
    </ng-container>
    <ng-container *ngIf="_edited">
      <cds-button (click)="editTrigger(true)" [config]="editSelectedButtonConfig">Cancel Edit</cds-button>
    </ng-container>
  </div>
</div>

<app-cd-download-progress [show]="_downloading"></app-cd-download-progress>
