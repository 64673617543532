import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SalesJourneyProdType } from '../../case-summary/case-summary.model';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { ActionIcons } from '@cds/ng-themes';

export enum Domain {
  individual = 'individual',
  employer = 'employer',
  sep = 'sep',
}

@Component({
  selector: 'app-select-product-type-for-md',
  templateUrl: './select-product-type-for-md.component.html',
  styleUrls: ['./select-product-type-for-md.component.scss'],
})
export class SelectProductTypeForMdComponent implements OnInit, OnDestroy {
  Domain = Domain;
  domain = Domain.individual;
  @ViewChild('displayContent') displayContent!: ElementRef<HTMLDivElement>;
  currentStep = 1;
  isScrollBottom = false;
  tabIndex!: number;
  prodType?: SalesJourneyProdType;
  backIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };
  backIcon = ActionIcons.button_left_filled;

  onScoll = () => {
    const scrollHeight = this.displayContent.nativeElement.scrollHeight;
    const clientHeight = this.displayContent.nativeElement.clientHeight;

    if (!this.isScrollBottom && this.displayContent.nativeElement.scrollTop > scrollHeight - clientHeight - 10) {
      this.isScrollBottom = true;
    }
  };

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.tabIndex = Number(this.route.snapshot.paramMap.get('tabIndex'));
    this.prodType = (this.route.snapshot.paramMap.get('prodType') as SalesJourneyProdType) || undefined;
  }

  ngOnDestroy(): void {
    this.displayContent?.nativeElement.removeEventListener('scroll', this.onScoll);
  }

  toEdit() {
    if (!this.isScrollBottom) {
      return;
    }
    if (this.domain === Domain.individual) {
      this.router.navigate(['/salesjourney/generate-md/individual', { domain: Domain.individual, tabIndex: this.tabIndex, prodType: this.prodType }]);
    } else if (this.domain === Domain.sep) {
      this.router.navigate(['/salesjourney/generate-md/individual', { domain: Domain.sep, tabIndex: this.tabIndex, prodType: this.prodType }]);
    } else {
      this.router.navigate([
        '/salesjourney/generate-md/employer',
        {
          tabIndex: this.tabIndex,
          prodType: this.prodType,
        },
      ]);
    }
  }

  showDeclaration(domain: Domain) {
    this.domain = domain;
    this.currentStep = 2;
    setTimeout(() => {
      this.displayContent?.nativeElement.addEventListener('scroll', this.onScoll);
    }, 0);
  }

  onBack() {
    this.router.navigate([
      '/salesjourney/md-iff-summary',
      {
        tabIndex: this.tabIndex,
        prodType: this.prodType,
      },
    ]);
  }

  backPrePage() {
    this.currentStep = 1;
    this.isScrollBottom = false;
  }
}
