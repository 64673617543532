/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { UserAgreementPopupComponent } from '../../user-agreement-popup.component';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { BTN_CONFIG_SECONDARY } from 'src/app/config/btn.config';

import { deepCopy } from 'src/app/utils/copy';
import { fromISO } from 'src/app/utils/dateUtils';
import { str2Obj } from 'src/app/utils/utils';

import { CommissionCommonService } from 'src/app/core/services/commission/commission-common.service';
import { CommissionPoolScaleService } from 'src/app/core/services/commission/commission-pool-scale.service';
import { RecordDetailList, ScaleMap } from './record-details.model';
import { AccumulationType } from 'src/app/core/models/enum/accumulation-type.enum';
import { ClawBackFlagMap } from 'src/app/core/models/enum/claw-back-flag.enum';
import { texts, Status } from '../pool-info.model';
import Big from 'big.js';
import { CdsPillConfig } from '@cds/ng-core/pill';

import { BREAKPOINT_TYPE_OPTIONS, CALCULATION_TYPE_OPTIONS } from 'src/app/views/commission/scale/commission-scale.config';

import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';

interface ScaleRecord {
  id: string;
  value?: string;
  label?: string;
  millis: number;
  status?: string;
  name?: string;
  effectiveDate?: string;
  createBy?: any;
  disabled?: boolean;
  active?: boolean;
  sendEmail?: boolean;
  lastUpdateBy?: any;
  lastUpdateName?: string;
  lastUpdateTime?: string;
}

@Component({
  selector: 'app-commission-record-details',
  templateUrl: './record-details.component.html',
  styleUrls: ['./record-details.component.scss'],
})
export class CommissionRecordDetailsComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  roleW = PermissionAccess.W;
  roleE = PermissionAccess.E;
  permissionN = PermissionItem.COMM_INFO_SCALE_RECORD;
  constructor(
    private cdsPopup: CdsPopupService,
    private router: Router,
    private poolScaleService: CommissionPoolScaleService,
    private commonService: CommissionCommonService
  ) {
    this.subscription = commonService.summary$.subscribe(res => {
      this.summaryInfo = JSON.parse(JSON.stringify(res));
      this.editRecordDetailStatus = false;
      this.editError = '';
    });
  }

  accumulationType = AccumulationType;
  pillConfig: CdsPillConfig = {
    label: 'Sample',
    removable: true,
  };
  @Input() commissionPoolId = '';
  summaryInfo: any = {};
  btnCfg: CdsButtonConfig = BTN_CONFIG_SECONDARY;
  status = Status;
  currentClickOpenIndex = -1;
  totalCounts = 8;
  checkedAll = true;
  loading = false;
  scaleRecord: ScaleRecord = {
    id: '',
    effectiveDate: '',
    millis: 0,
    createBy: { name: '' },
  };
  recordHistoryPage = 0;
  totalPages = 0;
  recordHistoryList: ScaleRecord[] = [];
  scaleInfoLoading = false;
  scrollLoading = false;
  deleteDisplay = false;
  editDisplay = false;

  recordInfoList: any[] = deepCopy(RecordDetailList);
  editRecordDetailStatus = false;
  editError = '';
  saveDisabled = true;
  isConfirm = false;
  originalDetail = {};
  recordDetailForm: any = {
    editScale: '',
    mthlyClawBack: 0,
    accumulations: [],
  };

  createRecord() {
    const params = {
      commissionPoolId: this.commissionPoolId,
      scaleDetailId: '',
    };
    this.recordHistoryList.forEach(item => {
      if (item.status == this.status.CURRENT) {
        params.scaleDetailId = item.id;
      }
    });
    if (this.recordInfoList) {
      this.router.navigate(['/commission/record-create', params]);
    }
  }

  reloadRecordList() {
    this.recordHistoryPage = 0;
    this.recordHistoryList = [];
    this.scaleRecord = {
      id: '',
      effectiveDate: '',
      millis: 0,
      createBy: { name: '' },
    };
    this.getScaleRecordList();
  }

  getScaleRecordList() {
    if (!this.scrollLoading) {
      this.scrollLoading = true;
      this.recordHistoryPage++;
      const params = {
        page: this.recordHistoryPage - 1,
        size: 9999,
        commissionPoolId: this.commissionPoolId,
        sort: 'effectiveDate',
        order: 'asc',
      };
      this.loading = true;
      this.poolScaleService.getScaleRecordList(params).subscribe(resp => {
        this.loading = false;
        this.scrollLoading = false;
        if (resp && resp.result == 0 && resp.data.content) {
          this.totalPages = resp.data.totalPages;
          if (resp.data.content) {
            this.recordHistoryList = this.recordHistoryList.concat(resp.data.content);
            this.recordHistoryList.forEach(item => {
              if (item.status == this.status.CURRENT && item.active) {
                this.scaleRecord = item;
              }
              if (!item.active) {
                item.status = this.status.DELETED;
                if (item.lastUpdateBy) {
                  item.lastUpdateName = item.lastUpdateBy.name;
                }
              }
            });
            this.getScaleRecordInfo();
          }
        }
      });
    }
  }

  getPoolAccumulations(accumulations: any[]) {
    const respAccumulations: any[] = accumulations;
    respAccumulations.forEach(respAcc => {
      this.recordInfoList.forEach(scale => {
        const scaleAcc: any[] = scale.accumulations;
        if (respAcc && respAcc.scaleType == scale.scaleType) {
          scaleAcc.forEach(sa => {
            if (respAcc && respAcc.scaleAccumulationType == sa.accumulationType) {
              if (sa.accumulationType == AccumulationType.PLAN_HIGH || sa.accumulationType == AccumulationType.RELEASED_CYTD) {
                sa.amount = new Big(respAcc.amount).toFixed(0);
              } else {
                sa.amount = new Big(respAcc.amount).toFixed(2);
              }
            }
          });
        }
      });
    });
  }

  getScaleRecordInfo() {
    this.deleteDisplay = false;
    this.editDisplay = false;
    this.scaleInfoLoading = true;
    this.editRecordDetailStatus = false;
    if (!this.scaleRecord.id) {
      this.scaleInfoLoading = false;
      return;
    }
    if (this.scaleRecord.id && this.recordHistoryList) {
      this.recordHistoryList.forEach(item => {
        if (item.id == this.scaleRecord.id) {
          this.scaleRecord = deepCopy(item);
        }
      });
    }
    if (this.scaleRecord.status == Status.CURRENT) {
      this.editDisplay = true;
    }
    this.recordInfoList = deepCopy(RecordDetailList);
    this.poolScaleService.getScaleRecordInfo(this.scaleRecord.id).subscribe(res => {
      const resp = str2Obj(res.body, ['amount', 'clawBackAmount']);
      this.scaleInfoLoading = false;
      if (resp && resp.result == 0 && resp.data) {
        const scaleList: any[] = resp.data.commissionPoolScaleItems ? resp.data.commissionPoolScaleItems : [];
        const accumulations: any[] = resp.data.commissionPoolScaleAccumulations ? resp.data.commissionPoolScaleAccumulations : [];
        if (accumulations && accumulations.length > 0) {
          this.getPoolAccumulations(accumulations);
        }
        if (this.scaleRecord.status == Status.FUTURE) {
          this.deleteDisplay = true;
        }
        const list = this.recordHistoryList.filter(item => item.active);
        if (list.length == 1 && list[0].status == Status.CURRENT) {
          this.deleteDisplay = true;
        }
        this.recordInfoList.forEach(item => {
          scaleList.forEach(scale => {
            if (scale && item.scaleType == scale.scaleType) {
              if (item.scaleType == 'FS_PC' && resp.data.clawBackAmount != null && resp.data.clawBackMonth != null) {
                item.clawBackAmount = new Big(resp.data.clawBackAmount).toFixed(0);
                item.clawBackMonth = resp.data.clawBackMonth;
                if (resp.data.annualizeFlag) {
                  item.annualizeFlagStr = ClawBackFlagMap.get(resp.data.annualizeFlag);
                }
              }
              item.scaleId = scale.commissionScaleId;
              item.calcBasis = scale.calcBasis;
              if (scale && scale.commissionScaleDto && scale.commissionScaleDto.info) {
                item.scaleCode = scale.commissionScaleDto.info.scaleCode;
                item.currPolYrCommRate = scale.commissionScaleDto.info.crfRate ? scale.commissionScaleDto.info.crfRate : '';
                if (scale.commissionScaleDto.info.calculationType) {
                  CALCULATION_TYPE_OPTIONS.forEach(f => {
                    if (f.value == scale.commissionScaleDto.info.calculationType) {
                      item.calcType = f.label;
                    }
                  });
                }
                if (scale.commissionScaleDto.info.breakpointType) {
                  BREAKPOINT_TYPE_OPTIONS.forEach(f => {
                    if (f.value == scale.commissionScaleDto.info.breakpointType) {
                      item.breakpointType = f.label;
                    }
                  });
                }
                item.breakpoints = [];
                if (scale.commissionScaleDto.breakPoint) {
                  for (const [key, value] of Object.entries(scale.commissionScaleDto.breakPoint)) {
                    const point: any = {};
                    point.year = key;
                    point.list = [];
                    const s: any = value;
                    for (const [key2, value2] of Object.entries(s)) {
                      const j: any = {};
                      j.breakpoints = key2;
                      const k: any = value2;
                      j.displayAmount = true;
                      if (!k.amount) {
                        j.amount = null;
                      } else {
                        j.amount = new Big(k.amount).round(2, Big.roundDown).toFixed(2);
                      }
                      j.percentage = String(new Big(k.percentage).toFixed(4));
                      point.list.push(j);
                    }
                    if (point.list && point.list.length == 1) {
                      point.list[0].displayAmount = false;
                    }
                    item.breakpoints.push(point);
                  }
                }
              }
            }
          });
        });
      }
    });
  }

  deleteRecord() {
    let data = {
      title: texts.deleteRecordTitle,
      message: texts.deleteRecordMsg,
      cancel: texts.cancel,
      continue: texts.continue,
      type: 'confirm',
      params: {
        p1: fromISO(this.scaleRecord.effectiveDate),
        p2: this.scaleRecord.createBy.name,
      },
    };
    this.recordInfoList.forEach(item => {
      const accumulations: any[] = item.accumulations;
      accumulations.forEach(a => {
        if (a.accumulationType == AccumulationType.PREMIUM_PTD && a.amount > 0) {
          data = deepCopy({
            message: 'commission.pool.scale.recordDetails.deleteError',
            continue: texts.alertSuccessContinue,
            type: 'alert',
          });
        }
      });
    });
    const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
      size: 'lg',
      data: data,
    });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm && confirm.agree && data.type == 'confirm') {
        this.poolScaleService.deleteHistory(this.scaleRecord.id).subscribe(resp => {
          if (resp && resp.result == 0) {
            this.reloadRecordList();
            this.commonService.announceScale(null);
          }
        });
      }
    });
  }

  checkRecordDetailTag(scale: string) {
    this.editRecordDetailStatus = false;
    if (scale == 'all') {
      if (!this.checkedAll) {
        this.checkedAll = true;
        this.recordInfoList.forEach(item => {
          item.checked = true;
        });
      } else {
        this.checkedAll = false;
        this.recordInfoList.forEach(item => {
          item.checked = false;
        });
      }
    } else {
      this.checkedAll = true;
      this.recordInfoList.forEach(item => {
        if (item.scale == scale) {
          if (item.checked) {
            item.checked = false;
          } else {
            item.checked = true;
          }
        }
        if (!item.checked) {
          this.checkedAll = false;
        }
      });
    }
  }

  editRecordDetail(recordDetail: any) {
    this.editError = '';
    if (this.summaryInfo.frozen) {
      this.cdsPopup.open(UserAgreementPopupComponent, {
        data: {
          message: '<span class="cds-h3-light">Not allow to edit the Scale Record Detail under commission frozen status</span>',
          continue: texts.alertSuccessContinue,
          type: 'alert',
        },
      });
      return;
    }
    this.editRecordDetailStatus = true;
    this.saveDisabled = true;
    this.isConfirm = false;

    this.recordDetailForm.commissionPoolScaleId = this.scaleRecord.id;
    this.recordDetailForm.scaleType = recordDetail.scaleType;
    this.recordDetailForm.clawBackAmount = recordDetail.clawBackAmount;
    this.recordDetailForm.clawBackMonth = recordDetail.clawBackMonth;
    this.recordDetailForm.clawBackAmountError = '';
    this.recordDetailForm.clawBackMonthError = '';
    this.recordDetailForm.accumulations = deepCopy(recordDetail.accumulations);
    this.originalDetail = deepCopy(this.recordDetailForm);
  }

  cancelEditRecordDetail() {
    const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
      data: {
        message: texts.cancelMsg,
        cancel: texts.cancel,
        continue: texts.continue,
        type: 'confirm',
      },
    });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm && confirm.agree) {
        this.editRecordDetailStatus = false;
      }
    });
  }

  reset() {
    this.saveDisabled = true;
    this.recordDetailForm = deepCopy(this.originalDetail);
  }

  editScaleInfoSave() {
    if (this.saveDisabled) {
      return;
    }
    this.isConfirm = true;
  }

  backToEdit() {
    this.isConfirm = false;
  }

  confirm() {
    if (this.saveDisabled) {
      return;
    }
    const list: any[] = [];
    const accumulations: any[] = this.recordDetailForm.accumulations;
    accumulations.forEach(item => {
      const accumulation = {
        scaleAccumulationType: item.accumulationType,
        amount: item.amount,
      };
      list.push(accumulation);
    });
    const params = {
      commissionPoolId: this.commissionPoolId,
      commissionPoolScaleId: this.recordDetailForm.commissionPoolScaleId,
      scaleType: this.recordDetailForm.scaleType,
      clawBackAmount: this.recordDetailForm.clawBackAmount,
      clawBackMonth: this.recordDetailForm.clawBackMonth,
      list: list,
    };
    this.scaleInfoLoading = true;
    this.poolScaleService.editScaleInfo(params).subscribe(resp => {
      this.scaleInfoLoading = false;
      let popData, result: number;
      if (resp && resp.data && resp.result == 0) {
        popData = {
          message: texts.alertSuccessMsg,
          continue: texts.alertSuccessContinue,
          type: 'alert',
        };
        result = resp.result;
      } else {
        popData = {
          message: texts.alertFailedMsg,
          continue: texts.alertFailedContinue,
          type: 'alert',
          params: { respMsg: resp.message },
        };
        result = resp.result;
      }
      const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, { data: popData });
      popupRef.afterClosed().subscribe(confirm => {
        if (confirm && confirm.agree) {
          if (result == 0) {
            this.getScaleRecordInfo();
            this.commonService.announceScale(null);
          }
        }
      });
    });
  }

  inputClawBackAmount(event: any) {
    if (event && event.target.value) {
      let amount = event.target.value;
      if (amount === '-' || amount === '.') {
        amount = 0;
      }
      if (new Big(amount).lt(new Big('99999999999999999999.50'))) {
        amount = new Big(amount).round(0, Big.roundHalfUp).toFixed(0);
      } else {
        amount = '99999999999999999999';
      }
      this.recordDetailForm.clawBackAmount = amount;
    } else {
      this.recordDetailForm.clawBackAmount = 0;
    }
    this.formControl();
  }

  inputClawBackMonth(event: any) {
    if (event && !event.target.value) {
      this.recordDetailForm.clawBackMonth = 0;
    }
    this.formControl();
  }

  inputAccumulation(event: any, accumulationType: string) {
    let value = event.target.value;
    if (value === '-' || value === '.') {
      value = 0;
    }
    let amount = new Big(value ? value : 0).toFixed(2);
    const accumulations: any[] = this.recordDetailForm.accumulations;
    if (accumulationType == AccumulationType.PLAN_HIGH || accumulationType == AccumulationType.RELEASED_CYTD || accumulationType == 'mthlyClawBack') {
      if (new Big(amount).lt(new Big('99999999999999999999.50'))) {
        amount = new Big(amount).round(0, Big.roundHalfUp).toFixed(0);
      } else {
        amount = '99999999999999999999';
      }
    }
    event.target.value = String(amount);
    this.saveDisabled = false;
    const accumulation = accumulations.filter(item => item.accumulationType == accumulationType);
    if (accumulation && accumulation.length == 1) {
      accumulation[0].amount = String(amount);
      accumulation[0].errorMsg = '';
      if (
        amount.indexOf('-') !== -1 &&
        accumulation[0].accumulationType != AccumulationType.GROSS_PREM_CYTD &&
        accumulation[0].accumulationType != AccumulationType.RELEASED_CYTD &&
        accumulation[0].accumulationType != AccumulationType.PREMIUM_CYTD
      ) {
        accumulation[0].errorMsg = ScaleMap.get(this.recordDetailForm.scaleType) + ': Not allow edit to be negative amount on ' + accumulation[0].label;
      }
      const cytd = accumulations.filter(item => item.accumulationType == AccumulationType.PREMIUM_CYTD);
      const ptd = accumulations.filter(item => item.accumulationType == AccumulationType.PREMIUM_PTD);
      if (cytd && cytd.length == 1 && ptd && ptd.length == 1) {
        cytd[0].errorMsg = '';
        if (ptd && cytd && new Big(ptd[0].amount).lt(new Big(cytd[0].amount))) {
          cytd[0].errorMsg = ScaleMap.get(this.recordDetailForm.scaleType) + ': ' + cytd[0].label + ' cannot be larger than ' + ptd[0].label;
        }
      }
    }
    this.formControl();
  }

  formControl() {
    this.recordDetailForm.clawBackAmountError = '';
    this.recordDetailForm.clawBackMonthError = '';
    if (this.recordDetailForm.clawBackMonth < 0 || this.recordDetailForm.clawBackMonth > 12) {
      this.recordDetailForm.clawBackMonthError = 'Claw back month must in the range between 0 and 12';
    } else if (this.recordDetailForm.clawBackAmount == 0 && this.recordDetailForm.clawBackMonth > 0) {
      this.recordDetailForm.clawBackMonthError = 'Claw back month must be 0 if the Claw back amount is 0';
    }
    let b = false;
    if (this.recordDetailForm.clawBackAmountError) {
      b = true;
    }
    if (this.recordDetailForm.clawBackMonthError) {
      b = true;
    }
    if (this.recordDetailForm.clawBackMonthError) {
      b = true;
    }
    const accumulations: any[] = this.recordDetailForm.accumulations;
    accumulations.forEach(item => {
      if (item.errorMsg) {
        b = true;
      }
    });
    if (JSON.stringify(this.originalDetail) == JSON.stringify(this.recordDetailForm)) {
      b = true;
    }
    this.saveDisabled = b;
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.commonService.applySummaryInfo(null);
    this.reloadRecordList();
  }

  backTop() {
    document.getElementsByClassName('cds-navigation-content')[0].scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}
