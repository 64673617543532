export enum UploadFileStatus {
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  VALIDATED_PENDING_UPDATED = 'VALIDATED_PENDING_UPDATED',
  SUCCESS_UPDATE = 'SUCCESS_UPDATE',
}

export const UploadFileStatusText = new Map([
  [UploadFileStatus.PENDING_VERIFICATION, 'Pending Verification'],
  [UploadFileStatus.VALIDATED_PENDING_UPDATED, 'Validated,Pending Updated'],
  [UploadFileStatus.SUCCESS_UPDATE, 'Success Updated'],
]);
