/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsCheckboxListConfig } from '@cds/ng-core/checkbox';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsLangService } from '@cds/ng-core/lang';
import { ActionIcons } from '@cds/ng-themes';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { MatDialogRef } from '@cds/ng-web-components/popup';
import { Subscription } from 'rxjs';
import { ValidateService } from 'src/app/core/services/validate/validate.service';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { CdPopupService } from 'src/app/shared/cd-popup';
import { CdRadioComponent, CdRadioConfig } from 'src/app/shared/cd-radio/cd-radio.component';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { WillBeLoseInputComponent } from 'src/app/shared/will-be-lose-input/will-be-lose-input.component';
import { arraysAreEqual, pageToTop } from 'src/app/utils/utils';
import { PreferredLanguageType, SalesJourneyProdType } from '../../case-summary/case-summary.model';
import { empfCountryOption } from '../../case-summary/declaration-iff-er/empf-declaration-form.config';
import { ResetWarningComponent } from '../../iff-paper-form/reset-warning/reset-warning.component';
import { ProductType } from '../../md-iff-summary/md-iff-summary.model';
import { HasBeenSentCustomerPopupComponent } from '../has-been-sent-customer-popup/has-been-sent-customer-popup.component';
import { PendingSplitAgentConfirPopupComponent } from '../pending-split-agent-confir-popup/pending-split-agent-confir-popup.component';
import { Domain } from '../select-product-type-for-md/select-product-type-for-md.component';
import { NotifyService } from 'src/app/core/services/notify.service';

enum Show {
  individuals = 'individuals',
  intermediary = 'intermediary',
}

interface AgentInfo {
  agentId: string;
  agentCode: string;
  agentName: string;
  agentNameTC: string;
  mobileNumber: string;
  emailAddress: string;
  branchCode: string;
  agentCategory: string;
  licenseNumber: string;
  licenseCode: string;
  licenseStatus: string;
  status: string;
  shortEmail?: string;
  shortPhone?: string;
}

interface AddMemberParams {
  email?: string;
  phoneCountry?: string;
  phone?: string;
  isRegulatedActivities?: boolean;
  commChannel?: 'SMS' | 'EMAIL';
  secondAgent?: {
    agentCode?: string;
    isRegulatedActivities?: boolean;
    commChannel?: 'SMS' | 'EMAIL';
    language?: 'CN' | 'EN';
  };
  thirdAgent?: {
    agentCode?: string;
    isRegulatedActivities?: boolean;
    commChannel?: 'SMS' | 'EMAIL';
    language?: 'CN' | 'EN';
  };
  language?: 'CN' | 'EN';
  productTypes?: string[];
  fastName?: string;
  lastName?: string;
  hkId?: string;
  passportNo?: string;
}

@Component({
  selector: 'app-individual',
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.scss'],
})
export class IndividualComponent implements OnInit, OnDestroy {
  @ViewChild('cdRadioPreferredLanguage') cdRadioPreferredLanguage!: CdRadioComponent;
  @ViewChild('radioRelevantRegulatedActivities') radioRelevantRegulatedActivities!: CdRadioComponent;
  @ViewChild('radioSecondAgentRelevantRegulatedActivities') radioSecondAgentRelevantRegulatedActivities!: CdRadioComponent;
  @ViewChild('radioSecondAgentClientContact') radioSecondAgentClientContact?: CdRadioComponent;
  @ViewChild('radioSecondAgentLanguage') radioSecondAgentLanguage?: CdRadioComponent;
  PreferredLanguageType = PreferredLanguageType;
  domain?: Domain;
  Domain = Domain;
  Show = Show;
  show = Show.individuals;
  isConfirming = false;
  backIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };
  backIcon = ActionIcons.button_left_filled;
  individualsForm!: FormGroup;
  isTryConfirm = false;
  language = 'EN';
  lanChangeSubscription?: Subscription;
  clientContactConfig: CdRadioConfig = {
    options: [
      {
        label: 'Email',
        value: 'E',
        disable: false,
      },
      {
        label: 'Phone Number',
        value: 'M',
        disable: false,
      },
    ],
  };
  clientContact = '';
  countrycodeConfig: CdsDropdownConfig = {
    options: empfCountryOption,
    placeholder: '+852 (HK)',
    color: 'ml',
  };
  private readonly CN_PHONE = /^1[345678]\d{9}$/;

  preferredLanguageConfig = {
    options: [
      {
        label: 'common.chinese',
        value: PreferredLanguageType.Chinese,
        disable: true,
      },
      {
        label: 'common.english',
        value: PreferredLanguageType.English,
        disable: true,
      },
    ],
  };

  idTypeConfigOptions = [
    { label: this.cdsLangService.translate('generate.md.hkid'), value: '0' },
    { label: this.cdsLangService.translate('generate.md.passport-no'), value: '1' },
  ];

  relevantRegulatedActivitiesValue = '';
  relevantRegulatedActivitiesConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.yes'),
        value: 'y',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.no'),
        value: 'n',
        disable: false,
      },
    ],
  };
  resetDatePicker = false;
  secondAgentInfo?: AgentInfo;
  secondAgentRelevantRegulatedActivities = '';
  secondAgentRelevantRegulatedActivitiesConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.yes'),
        value: 'Y',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.no'),
        value: 'N',
        disable: false,
      },
    ],
  };
  secondAgentCodeError = false;

  secondAgentClientContact = '';
  secondAgentClientContactConfig?: CdRadioConfig;

  secondAgentLanguage = '';
  secondAgentLanguageConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.chinese'),
        value: 'ZH',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.english'),
        value: 'EN',
        disable: false,
      },
    ],
  };
  productTypeListConfig: CdsCheckboxListConfig = {
    options: [
      {
        label: ProductType.PA,
        value: ProductType.PA,
      },
      {
        label: ProductType.TVC,
        value: ProductType.TVC,
      },
      {
        label: ProductType.SVC,
        value: ProductType.SVC,
      },
    ],
    orientation: 'vertical',
  };

  defaultFormGroupValues: any = null;
  tabIndex!: number;
  prodType?: SalesJourneyProdType;

  get preferredLanguage() {
    return this.individualsForm.get('preferredLanguage') as FormControl;
  }

  get customerSurname() {
    return this.individualsForm.get('customerSurname') as FormControl;
  }

  get customerGivenName() {
    return this.individualsForm.get('customerGivenName') as FormControl;
  }

  get isCustomerError() {
    if (!this.isTryConfirm) {
      return false;
    }
    if (!this.customerSurname && !this.customerGivenName) {
      return true;
    } else if ((this.customerSurname && !this.customerGivenName) || (!this.customerSurname && this.customerGivenName)) {
      return true;
    }
    return false;
  }

  get hkidOrPassportNo() {
    return this.individualsForm.get('hkidOrPassportNo') as FormControl;
  }

  get idType() {
    return this.individualsForm.get('idType') as FormControl;
  }

  get getSecondAgentName() {
    if (!this.secondAgentInfo) {
      return 'N/A';
    }
    if (this.cdsLangService.currentLang === 'en') {
      return this.secondAgentInfo.agentName || this.secondAgentInfo.agentNameTC;
    } else {
      return this.secondAgentInfo.agentNameTC || this.secondAgentInfo.agentName;
    }
  }

  get clientPhoneNumber() {
    return this.individualsForm.get('clientPhoneNumber') as FormControl;
  }

  get clientCountryCode() {
    return this.individualsForm.get('clientCountryCode') as FormControl;
  }

  get clientEmailAddress() {
    return this.individualsForm.get('clientEmailAddress') as FormControl;
  }

  get productTypes() {
    return this.individualsForm.get('productTypes') as FormControl;
  }

  get secondAgentCode() {
    return this.individualsForm.get('secondAgentCode') as FormControl;
  }

  get isChangedAnyValues() {
    if (this.defaultFormGroupValues) {
      if (
        !(
          this.defaultFormGroupValues.customerSurname === this.customerSurname.value &&
          this.defaultFormGroupValues.customerGivenName === this.customerGivenName.value &&
          this.defaultFormGroupValues.idType === this.idType.value &&
          this.defaultFormGroupValues.hkidOrPassportNo === this.hkidOrPassportNo.value &&
          this.defaultFormGroupValues.clientEmailAddress === this.clientEmailAddress.value &&
          this.defaultFormGroupValues.clientCountryCode === this.clientCountryCode.value &&
          this.defaultFormGroupValues.clientPhoneNumber === this.clientPhoneNumber.value &&
          this.defaultFormGroupValues.preferredLanguage === this.preferredLanguage.value &&
          arraysAreEqual(this.defaultFormGroupValues.productTypes.value, this.productTypes.value) &&
          this.relevantRegulatedActivitiesValue === '' &&
          this.defaultFormGroupValues.secondAgentCode === this.secondAgentCode.value
        )
      ) {
        return true;
      }
    }
    return false;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public cdsLangService: CdsLangService,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    private languageChangeService: LanguageChangeService,
    private cdsPopup: CdPopupService,
    private validateService: ValidateService,
    private notifyService: NotifyService
  ) {}

  hKIDPassportNoChange() {
    this.hkidOrPassportNo.setValue('');
    this.hkidOrPassportNo.updateValueAndValidity();
  }

  hkidOrPassportNoChange(event: string) {
    const value = this.hkidOrPassportNo.value?.replace(/[\u4e00-\u9fa5]/gi, '');
    if (event !== value) {
      this.hkidOrPassportNo.setValue(value);
    }

    if (/^[a-zA-Z]{1,2}\d{6}\([aA\d]\)$/.exec(value)) {
      if (this.idType.value === '0') {
        this.cdHttpServeService.post(`/ext/eb-ssms-sales-journey-service/hkidCheckDigit`, { hkId: value.trim() }).subscribe({
          next: res => {
            if (!(res.result === 0 && res.data)) {
              this.hkidOrPassportNo.setErrors({ hkidFormatError: true });
            }
          },
          error: err => {
            this.hkidOrPassportNo.setErrors({ hkidFormatError: true });
            this.alert.error('Error!', err);
          },
        });
      }
    }
  }

  clientCountryCodeChange() {
    this.clientPhoneNumber.updateValueAndValidity();
  }

  clientContactChange() {
    this.preferredLanguage.setValue(undefined);
    this.preferredLanguageConfig = {
      options: [
        {
          label: 'common.chinese',
          value: PreferredLanguageType.Chinese,
          disable: false,
        },
        {
          label: 'common.english',
          value: PreferredLanguageType.English,
          disable: false,
        },
      ],
    };
    this.cdRadioPreferredLanguage?.refreshConfig();
    if (this.clientContact === 'E') {
      this.clientPhoneNumber.updateValueAndValidity();
    }
  }

  lanSubjectChange = (lan: string) => {
    this.language = lan === 'en' ? 'EN' : 'TC';

    this.clientContactConfig = {
      options: [
        {
          label: this.cdsLangService.translate('generate.md.email'),
          value: 'E',
          disable: false,
        },
        {
          label: this.cdsLangService.translate('generate.md.phoneNumber'),
          value: 'M',
          disable: false,
        },
      ],
    };

    this.idTypeConfigOptions = [
      { label: this.cdsLangService.translate('generate.md.hkid'), value: '0' },
      { label: this.cdsLangService.translate('generate.md.passport-no'), value: '1' },
    ];

    this.refreshSecondAgentClientContactConfig();
    this.refreshRelevantRegulatedActivitiesConfig();
    this.refreshSecondAgentRelevantRegulatedActivitiesConfig();
    this.refreshSecondAgentLanguageConfig();
  };

  private refreshSecondAgentLanguageConfig() {
    this.secondAgentLanguageConfig.options[0].label = this.cdsLangService.translate('common.chinese');
    this.secondAgentLanguageConfig.options[1].label = this.cdsLangService.translate('common.english');
    this.radioSecondAgentLanguage?.refreshConfig();
  }

  private refreshSecondAgentRelevantRegulatedActivitiesConfig() {
    this.secondAgentRelevantRegulatedActivitiesConfig.options[0].label = this.cdsLangService.translate('common.yes');
    this.secondAgentRelevantRegulatedActivitiesConfig.options[1].label = this.cdsLangService.translate('common.no');
    this.radioSecondAgentRelevantRegulatedActivities?.refreshConfig();
  }

  private beforeunload = (event: BeforeUnloadEvent) => {
    if (this.isChangedAnyValues) {
      event.preventDefault();
      event.returnValue = '';
    }
  };

  private popstate = () => {
    const popupRef: MatDialogRef<WillBeLoseInputComponent> = this.cdsPopup.open(WillBeLoseInputComponent, {
      size: 'sm',
    });
    popupRef.afterClosed().subscribe(({ agree }) => {
      if (agree) {
        this.router.navigate([
          '/salesjourney/md-iff-summary',
          {
            tabIndex: this.tabIndex,
            prodType: this.prodType,
          },
        ]);
      } else {
        history.pushState(null, '', document.URL);
      }
    });
  };

  ngOnInit(): void {
    window.addEventListener('beforeunload', this.beforeunload);
    history.pushState(null, '', document.URL);
    window.addEventListener('popstate', this.popstate);

    this.tabIndex = Number(this.route.snapshot.paramMap.get('tabIndex'));
    this.prodType = (this.route.snapshot.paramMap.get('prodType') as SalesJourneyProdType) || undefined;

    this.domain = (this.route.snapshot.paramMap.get('domain') as Domain) || undefined;
    this.defaultFormGroupValues = {
      customerSurname: '',
      customerGivenName: '',
      idType: '0',
      hkidOrPassportNo: '',
      clientEmailAddress: '',
      clientCountryCode: '852',
      clientPhoneNumber: '',
      preferredLanguage: '',
      productTypes: { value: [], disabled: false },
      secondAgentCode: '',
    };
    if (this.domain === Domain.sep) {
      this.defaultFormGroupValues.productTypes.value.push(ProductType.SEP);
    }
    this.lanChangeSubscription = this.languageChangeService.lanSubject.subscribe(this.lanSubjectChange);

    this.individualsForm = new FormGroup({
      customerSurname: new FormControl(this.defaultFormGroupValues.customerSurname, [Validators.required]),
      customerGivenName: new FormControl(this.defaultFormGroupValues.customerGivenName, [Validators.required]),
      idType: new FormControl(this.defaultFormGroupValues.idType),
      hkidOrPassportNo: new FormControl(this.defaultFormGroupValues.hkidOrPassportNo, [Validators.required, this.indFormHkidOrPassportNoVali]),
      clientEmailAddress: new FormControl(this.defaultFormGroupValues.clientEmailAddress, [this.clientEmailVali]),
      clientCountryCode: new FormControl(this.defaultFormGroupValues.clientCountryCode),
      clientPhoneNumber: new FormControl(this.defaultFormGroupValues.clientPhoneNumber, [this.idFormClientPhoneNumberVali]),
      preferredLanguage: new FormControl(this.defaultFormGroupValues.preferredLanguage, [this.idFormPreferredLanguageVali]),
      productTypes: new FormControl(this.defaultFormGroupValues.productTypes, [Validators.required]),
      secondAgentCode: new FormControl(this.defaultFormGroupValues.secondAgentCode, {
        asyncValidators: [this.validateService.agentValidate],
      }),
    });
  }

  ngOnDestroy(): void {
    this.lanChangeSubscription?.unsubscribe();
    window.removeEventListener('beforeunload', this.beforeunload);
    window.removeEventListener('popstate', this.popstate);
  }

  idFormPreferredLanguageVali = (control: AbstractControl): ValidationErrors | null => {
    if ((this.clientContact === 'M' && control.value) || this.clientContact === 'E' || !this.clientContact) {
      return null;
    }
    return { formatError: true };
  };

  clientEmailVali = (option: AbstractControl): ValidationErrors | null => {
    if (this.clientContact === 'E') {
      if (!option.value) {
        return { required: true };
      }

      if (typeof option.value === 'string') {
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.exec(option.value) ? null : { email: true };
      }
    }
    return null;
  };

  idFormClientPhoneNumberVali = (option: AbstractControl): ValidationErrors | null => {
    if (this.clientContact === 'M') {
      if (!option.value) {
        return { required: true };
      }

      if (typeof option.value === 'string') {
        if (['852', '853'].includes(this.clientCountryCode.value)) {
          if (option.value.length === 8 && ['4', '5', '6', '7', '8', '9'].includes(option.value.substring(0, 1))) {
            return null;
          }
        }

        if (['86'].includes(this.clientCountryCode.value)) {
          if (option.value.length === 11) {
            const e = this.CN_PHONE.test(option.value);
            if (e) {
              return null;
            }
          }
        }

        if (!['852', '853', '86'].includes(this.clientCountryCode.value)) {
          return null;
        }
      }

      return { errorPhoneNumber: true };
    }

    return null;
  };

  indFormHkidOrPassportNoVali = (control: AbstractControl): ValidationErrors | null => {
    if (control.value === '' || control.value == undefined) {
      return null;
    }
    let input: string = control.value;
    input = input.trim();
    let isValid: RegExpExecArray | null | boolean;
    if (this.individualsForm?.get('idType')?.value === '1') {
      isValid = /^[a-zA-Z0-9()-]*$/.exec(input);
      return isValid ? null : { passportFormatError: true };
    } else {
      isValid = /^[a-zA-Z]{1,2}\d{6}\([aA\d]\)$/.exec(input);
      return isValid ? null : { hkidFormatError: true };
    }
  };

  onBack() {
    if (!this.isChangedAnyValues) {
      this.router.navigate([
        '/salesjourney/md-iff-summary',
        {
          tabIndex: this.tabIndex,
          prodType: this.prodType,
        },
      ]);
    } else {
      const popupRef: MatDialogRef<WillBeLoseInputComponent> = this.cdsPopup.open(WillBeLoseInputComponent, {
        size: 'sm',
      });
      popupRef.afterClosed().subscribe(({ agree }) => {
        if (agree) {
          this.router.navigate([
            '/salesjourney/md-iff-summary',
            {
              tabIndex: this.tabIndex,
              prodType: this.prodType,
            },
          ]);
        }
      });
    }
  }

  reset() {
    if (!this.isChangedAnyValues) {
      return;
    }
    const popupRef: MatDialogRef<ResetWarningComponent> = this.cdsPopup.open(ResetWarningComponent, {
      size: 'sm',
    });
    popupRef.afterClosed().subscribe(this.resetWarningComponentPopupCloseCall);
  }

  resetWarningComponentPopupCloseCall = (confirm: { agree: boolean }) => {
    if (confirm.agree) {
      this.secondAgentCodeError = false;
      this.individualsForm.reset(this.defaultFormGroupValues);
      this.productTypes.setValue(this.defaultFormGroupValues.productTypes.value);
      this.isTryConfirm = false;
      this.relevantRegulatedActivitiesValue = '';
      this.resetDatePicker = true;
      this.clientContact = '';
      this.secondAgentInfo = undefined;
      setTimeout(() => {
        this.resetDatePicker = false;
      }, 0);
      this.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: true,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: true,
          },
        ],
      };
      this.cdRadioPreferredLanguage?.refreshConfig();
    }
  };

  backToEdit1() {
    this.show = Show.individuals;
    this.isTryConfirm = false;
    this.isConfirming = false;
    this.setRelevantRegulatedActivitiesEnable();
    pageToTop();
    setTimeout(() => {
      this.productTypes.enable();
    }, 0);
  }

  backToEdit2() {
    if (this.secondAgentInfo) {
      this.show = Show.intermediary;
      if (this.relevantRegulatedActivitiesValue === 'n') {
        this.secondAgentRelevantRegulatedActivities = 'N';
        this.secondAgentRelevantRegulatedActivitiesConfig.options[0].disable = true;
        this.secondAgentRelevantRegulatedActivitiesConfig.options[1].disable = true;
        this.refreshSecondAgentRelevantRegulatedActivitiesConfig();
      } else {
        this.secondAgentRelevantRegulatedActivitiesConfig.options[0].disable = false;
        this.secondAgentRelevantRegulatedActivitiesConfig.options[1].disable = false;
        this.refreshSecondAgentRelevantRegulatedActivitiesConfig();
      }
    } else {
      this.isTryConfirm = false;
      this.isConfirming = false;
      this.setRelevantRegulatedActivitiesEnable();
      setTimeout(() => {
        this.productTypes.enable();
      }, 0);
    }
    pageToTop();
  }

  next() {
    if (this.isDisableNext) {
      return;
    }
    this.isTryConfirm = true;
    this.secondAgentInfo = undefined;
    this.secondAgentRelevantRegulatedActivities = '';
    this.secondAgentLanguage = '';
    this.secondAgentClientContact = '';
    this.secondAgentRelevantRegulatedActivitiesConfig.options[0].disable = false;
    this.secondAgentRelevantRegulatedActivitiesConfig.options[1].disable = false;
    this.refreshSecondAgentRelevantRegulatedActivitiesConfig();

    if (this.individualsForm.valid) {
      if (this.secondAgentCode.value) {
        this.getAgentInfoByAgentCode(this.secondAgentCode.value)
          .then(agentInfo => {
            this.isConfirming = true;
            this.productTypes.disable();
            this.setRelevantRegulatedActivitiesDisable();
            this.show = Show.intermediary;
            pageToTop();
            if (this.relevantRegulatedActivitiesValue === 'n') {
              this.secondAgentRelevantRegulatedActivities = 'N';
              this.secondAgentRelevantRegulatedActivitiesConfig.options[0].disable = true;
              this.secondAgentRelevantRegulatedActivitiesConfig.options[1].disable = true;
              this.refreshSecondAgentRelevantRegulatedActivitiesConfig();
            }

            this.secondAgentInfo = agentInfo;
            this.refreshSecondAgentClientContactConfig();
          })
          .catch(() => {
            this.secondAgentCodeError = true;
            return;
          });
      } else {
        this.isConfirming = true;
        this.productTypes.disable();
        this.setRelevantRegulatedActivitiesDisable();
        pageToTop();
      }
    }
  }

  private refreshSecondAgentClientContactConfig() {
    if (!this.secondAgentInfo) {
      return;
    }
    this.secondAgentInfo.shortEmail = '';
    if (this.secondAgentInfo.emailAddress) {
      const ea = this.secondAgentInfo.emailAddress || '';
      const endIndex = ea.indexOf('@');
      this.secondAgentInfo.shortEmail = ea.substring(0, 4) + '*****' + ea.substring(endIndex);
    }
    this.secondAgentInfo.shortPhone = '';
    if (this.secondAgentInfo.mobileNumber && this.secondAgentInfo.mobileNumber.length) {
      this.secondAgentInfo.shortPhone = '****' + this.secondAgentInfo.mobileNumber.slice(4);
    }

    this.secondAgentClientContactConfig = {
      options: [
        {
          label: `${this.cdsLangService.translate('generate.md.registered-email-address')}${
            this.secondAgentInfo.shortEmail ? ' (' + this.secondAgentInfo.shortEmail + ')' : ''
          }`,
          value: 'E',
          disable: !this.secondAgentInfo.shortEmail,
        },
        {
          label: `${this.cdsLangService.translate('generate.md.registered-phone-number')}${
            this.secondAgentInfo.shortPhone ? ' (+852) ' + this.secondAgentInfo.shortPhone : ''
          }`,
          value: 'M',
          disable: !this.secondAgentInfo.shortPhone,
        },
      ],
    };

    if (!this.secondAgentInfo.shortEmail && this.secondAgentInfo.shortPhone) {
      this.secondAgentClientContact = 'M';
    } else if (this.secondAgentInfo.shortEmail && !this.secondAgentInfo.shortPhone) {
      this.secondAgentClientContact = 'E';
    }
    this.radioSecondAgentClientContact?.refreshConfig();
  }

  private getAgentInfoByAgentCode(agentCode: string) {
    this.notifyService.screenLoadingSubject.next(true);
    return new Promise<AgentInfo>((resolve, reject) => {
      this.cdHttpServeService.post<AgentInfo[]>(`/ext/eb-ssms/agent-service/agent/infos/agentCd`, [agentCode]).subscribe({
        next: res => {
          if (res.result === 0) {
            if (res.data && res.data.length) {
              resolve(res.data[0]);
            }
          }
          reject();
          this.notifyService.screenLoadingSubject.next(false);
        },
        error: () => {
          reject();
          this.notifyService.screenLoadingSubject.next(false);
        },
      });
    });
  }

  private refreshRelevantRegulatedActivitiesConfig() {
    this.relevantRegulatedActivitiesConfig.options[0].label = this.cdsLangService.translate('common.yes');
    this.relevantRegulatedActivitiesConfig.options[1].label = this.cdsLangService.translate('common.no');
    this.radioRelevantRegulatedActivities?.refreshConfig();
  }

  private setRelevantRegulatedActivitiesDisable() {
    this.relevantRegulatedActivitiesConfig = {
      options: [
        {
          label: this.cdsLangService.translate('common.yes'),
          value: 'y',
          disable: true,
        },
        {
          label: this.cdsLangService.translate('common.no'),
          value: 'n',
          disable: true,
        },
      ],
    };
    this.radioRelevantRegulatedActivities?.refreshConfig();
  }

  private setRelevantRegulatedActivitiesEnable() {
    this.relevantRegulatedActivitiesConfig = {
      options: [
        {
          label: this.cdsLangService.translate('common.yes'),
          value: 'y',
          disable: false,
        },
        {
          label: this.cdsLangService.translate('common.no'),
          value: 'n',
          disable: false,
        },
      ],
    };
    this.radioRelevantRegulatedActivities?.refreshConfig();
  }

  isAllowPast() {
    if (this.relevantRegulatedActivitiesValue === '' || !this.customerSurname || !this.customerGivenName) {
      return false;
    }

    if (this.idType.value === '0' && !this.hkidOrPassportNo.valid) {
      return false;
    }

    if (this.clientContact === '') {
      return false;
    }
    if (this.clientContact === 'E' && !this.clientEmailAddress.valid) {
      return false;
    } else if (this.clientContact === 'M' && !this.clientPhoneNumber.valid) {
      return false;
    }

    return true;
  }

  get isDisableNext() {
    return this.individualsForm.invalid || this.clientContact === '' || this.relevantRegulatedActivitiesValue === '';
  }

  get isDisableIntermediaryNext() {
    if (this.secondAgentRelevantRegulatedActivities === '') {
      return true;
    }
    if (this.secondAgentRelevantRegulatedActivities === 'Y') {
      if (this.secondAgentClientContact === '') {
        return true;
      }
      if (this.secondAgentClientContact === 'M' && this.secondAgentLanguage === '') {
        return true;
      }
    }
    return false;
  }

  secondAgentClientContactChange() {
    this.secondAgentLanguage = '';
  }

  intermediaryNext() {
    if (this.isDisableIntermediaryNext) {
      return;
    }
    this.isConfirming = true;
    this.show = Show.individuals;
    this.setRelevantRegulatedActivitiesDisable();
    this.secondAgentRelevantRegulatedActivitiesConfig.options[0].disable = true;
    this.secondAgentRelevantRegulatedActivitiesConfig.options[1].disable = true;
    this.refreshSecondAgentRelevantRegulatedActivitiesConfig();
    pageToTop();
  }

  get getSecondAgentLanguage() {
    if (this.secondAgentLanguage === 'ZH') {
      return this.secondAgentLanguageConfig.options[0].label;
    } else if (this.secondAgentLanguage === 'EN') {
      return this.secondAgentLanguageConfig.options[1].label;
    }
    return undefined;
  }

  submit() {
    const params: AddMemberParams = {
      lastName: this.customerSurname.value,
      fastName: this.customerGivenName.value,
    };
    if (this.idType.value === '0') {
      params.hkId = this.hkidOrPassportNo.value;
    } else {
      params.passportNo = this.hkidOrPassportNo.value;
    }
    if (this.clientContact === 'E') {
      params.commChannel = 'EMAIL';
      params.email = this.clientEmailAddress.value;
    } else {
      params.commChannel = 'SMS';
      params.phoneCountry = this.clientCountryCode.value;
      params.phone = this.clientPhoneNumber.value;
      params.language = this.preferredLanguage.value === PreferredLanguageType.Chinese ? 'CN' : 'EN';
    }
    params.isRegulatedActivities = this.relevantRegulatedActivitiesValue === 'y';
    params.productTypes = this.productTypes.value;

    if (this.secondAgentInfo) {
      params.secondAgent = {};
      params.secondAgent.agentCode = this.secondAgentInfo.agentCode;
      params.secondAgent.isRegulatedActivities = this.secondAgentRelevantRegulatedActivities === 'Y';
      if (params.secondAgent.isRegulatedActivities) {
        params.secondAgent.commChannel = this.secondAgentClientContact === 'E' ? 'EMAIL' : 'SMS';
        if (this.secondAgentClientContact === 'M') {
          params.secondAgent.language = this.secondAgentLanguage === 'EN' ? 'EN' : 'CN';
        }
      }
    }

    this.notifyService.screenLoadingSubject.next(true);
    this.cdHttpServeService
      .post<{
        mainId: string;
        ids: JSON;
      }>(`/ext/eb-ssms-sales-journey-service/md/add/member`, params)
      .subscribe({
        next: res => {
          if (res.result === 0) {
            if (this.secondAgentRelevantRegulatedActivities === 'Y') {
              this.cdsPopup
                .open(PendingSplitAgentConfirPopupComponent, {
                  size: 'md',
                  data: {
                    mdNo: res.data.mainId,
                    ids: res.data.ids ? Object.values(res.data.ids) : [],
                  },
                })
                .afterClosed()
                .subscribe(() => {
                  this.router.navigate([
                    '/salesjourney/md-iff-summary',
                    {
                      tabIndex: 0,
                      prodType: this.domain === Domain.sep ? SalesJourneyProdType.SEP : SalesJourneyProdType.Individual,
                    },
                  ]);
                });
            } else {
              this.cdsPopup
                .open(HasBeenSentCustomerPopupComponent, {
                  size: 'md',
                  data: {
                    mdNo: res.data.mainId,
                    ids: res.data.ids ? Object.values(res.data.ids) : [],
                  },
                })
                .afterClosed()
                .subscribe(() => {
                  this.router.navigate([
                    '/salesjourney/md-iff-summary',
                    {
                      tabIndex: 0,
                      prodType: this.domain === Domain.sep ? SalesJourneyProdType.SEP : SalesJourneyProdType.Individual,
                    },
                  ]);
                });
            }
          } else {
            this.alert.error('Error!', res.message);
          }
          this.notifyService.screenLoadingSubject.next(false);
        },
        error: err => {
          this.alert.error('Error!', err);
          this.notifyService.screenLoadingSubject.next(false);
        },
      });
  }
}
