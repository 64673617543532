type JsonKeys<T> = T extends object ? keyof T : never;

export const WIDGETS_VIEW_ALL_GOAL = {
  WidgetsQuickLinksComponent: undefined,
  WidgetsProductionCreditComponent: undefined,
  WidgetsCampaignResultComponent: undefined,
  WidgetsCampaignOverviewComponent: undefined,
  WidgetsProductionCreditByDistrictComponent: undefined,
  WidgetsPendingIffComponent: '/salesjourney/md-iff-summary/select-product-type',
};

export type WIDGETS_COMPONENT_NAME = JsonKeys<typeof WIDGETS_VIEW_ALL_GOAL>;
