import { ColumnConfig } from 'src/app/shared/data-table';
export const PAGE_SIZE = 10;
export const I18N_KEY = {
  COMMON_ACTION_CANCLE: 'common.action.cancel',
  COMMON_CANCLE: 'common.cancel',
  COMMON_CONTINUE: 'common.continue',
  COMMON_GOT: 'common.gotItWithExclamation',
  COMMON_ACTION_SUCCESS: 'common.action.success',
  SHARING_ERROR: 'commission.appeal.pendingTransaction.shareSum',
  SCALE_CODE_NULL: 'commission.appeal.pendingTransaction.scaleCode.empty',
  SCALE_CODE_INVALID: 'commission.common.scaleCode.invalid',
  SCALE_CODE_LIMIT: 'commission.appeal.pendingTransaction.scaleCode.limit',
  AGENT_CODE_NULL: 'commission.appeal.pendingTransaction.agentCode.empty',
  AGENT_CODE_INVALID: 'commission.appeal.pendingTransaction.agentCode.invalid',
  AGENT_CODE_DUPLICATE: 'commission.appeal.pendingTransaction.agentCode.duplicate',
};

export const PENDING_TRANSACTION_COLUMNS: Array<ColumnConfig> = [
  {
    key: 'edit',
    title: '',
    sticky: true,
  },
  {
    key: 'receivedDate',
    title: 'commission.appeal.pendingTransaction.receivedDate',
    sticky: true,
  },
  {
    key: 'referenceNo',
    title: 'commission.common.referenceNo',
    sticky: true,
  },
  {
    key: 'accountNo',
    title: 'common.business.acconutCode',
    stickyEnd: true,
  },
  { key: 'accountType', title: 'commission.appeal.pendingTransaction.accountType' },
  { key: 'formType', title: 'commission.appeal.pendingTransaction.formType' },
  { key: 'agentCode', title: 'commission.appeal.pendingTransaction.agentCode' },
  { key: 'commFlagLabel', title: 'commission.appeal.pendingTransaction.commFlag' },
  {
    key: 'trfsppcCode',
    title: 'commission.appeal.pendingTransaction.SPHKPCScaleCode',
  },
  {
    key: 'trfspCode',
    title: 'commission.appeal.pendingTransaction.SPCOMMScaleCode',
  },
  {
    key: 'fshkpcCode',
    title: 'commission.appeal.pendingTransaction.FSHKPCScaleCode',
  },
  { key: 'sharingPC', title: 'commission.appeal.pendingTransaction.sharingPC' },
  {
    key: 'sharingComm',
    title: 'commission.appeal.pendingTransaction.sharingComm',
  },
  { key: 'appealDate', title: 'commission.appeal.pendingTransaction.appealDate' },
  {
    key: 'cancelledOrRejectedDate',
    title: 'commission.appeal.pendingTransaction.cancelledOrRejectedDate',
  },
];

export enum COMM_FLAGS {
  AGENCY_FULL_COMM = 'Agency-Full Comm',
  AGENCY_PARTIAL_COMM1 = 'Agency-Partial Comm1',
  AGENCY_PARTIAL_COMM2 = 'Agency-Partial Comm2',
  AGENCY_WSM_PARTIAL_COMM1 = 'Agency-WSM Partial Comm1',
  AGENCY_WSM_PARTIAL_COMM2 = 'Agency-WSM Partial Comm2',
  AGENCY_WSM_PARTIAL_COMM3 = 'Agency-WSM Partial Comm3',
  AGENCY_WSM_PARTIAL_COMM4 = 'Agency-WSM Partial Comm4',
  AGENCY_NO_COMM = 'Agency-No Comm',
  IA_BROKER_FULL_COMM = 'IA Broker-Full Comm',
  IA_BROKER_PARTIAL_COMM1 = 'IA Broker-Partial Comm1',
  IA_BROKER_PARTIAL_COMM2 = 'IA Broker-Partial Comm2',
  IA_BROKER_NO_COMM = 'IA Broker-No Comm',
  BROKER_FULL_COMM = 'Broker-Full Comm',
  SCB_FULL_COMM = 'SCB-Full Comm',
  DBS_FULL_COMM = 'DBS-Full Comm',
  CORPORATE_FULL_COMM = 'Corporate-Full Comm',
  CORPORATE_PARTIAL_COMM3 = 'Corporate-Partial Comm3',
  INTERNAL_TRANSFER_NO_COMM = 'Internal Transfer-No Comm',
  AGENCY_SHKP_PARTIAL_COMM1 = 'Agency-SHKP Partial Comm1',
  AGENCY_SHKP_PARTIAL_COMM2 = 'Agency-SHKP Partial Comm2',
  AGENCY_SHKP_PARTIAL_COMM3 = 'Agency-SHKP Partial Comm3',
  CORPORATE_NO_COMM = 'Corporate – No Comm',
  OTHER = 'Other',
}

export const COMM_FLAGS_OTHER = {
  label: 'Other',
  value: 'OTHER',
};
export const BLANK = 'BLANK';
