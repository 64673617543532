import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';
import { CdsDatepickerModule } from '@cds/ng-web-components/datepicker';
import { CdsFormFieldModule } from '@cds/ng-web-components/form-field';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsPopupModule } from '@cds/ng-web-components/popup';
import { ApeJournalComponent } from './ape-journal.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    CdsDropdownModule,
    CdsDatepickerModule,
    CdsLanguageModule,
    CdsFormFieldModule,
    CdsTextfieldModule,
    CdsAssistiveTextModule,
    CdsButtonModule,
    CdsPopupModule,
    CdsIconModule,
  ],
  declarations: [ApeJournalComponent],
})
export class ApeJournalModule {}
