import { CdsOption } from '@cds/ng-core/configuration';
import { AccountTypesJournal } from 'src/app/core/models/enum/account-type.enum';
import { ApeJournalChannels } from 'src/app/core/models/enum/agent-channel.enum';
import { DateTypeEnum } from 'src/app/core/models/enum/date-type.enum';
import { TransactionType } from 'src/app/core/models/enum/transaction-type.enum';
import { ColumnConfig } from 'src/app/shared/data-table';

export const DATE_TYPE_OPTS: CdsOption[] = [
  { label: 'Run Date', value: DateTypeEnum.RUN_DATE },
  { label: 'Txn Effective Date', value: DateTypeEnum.TXN_EFFECTIVE_DATE },
];
export const ACCOUNT_TYPE_OPTIONS: CdsOption[] = AccountTypesJournal;
export const TRANSACTION_TYPE_OPTIONS: CdsOption[] = [
  { label: 'APE_ANN_PREM', value: TransactionType.APE_ANN_PREM },
  { label: 'APE_CONTR_115', value: TransactionType.APE_CONTR_115 },
  { label: 'APE_CONTR_133', value: TransactionType.APE_CONTR_133 },
  { label: 'APE_CONTR_112', value: TransactionType.APE_CONTR_112 },
  { label: 'APE_PLAN_TRF', value: TransactionType.APE_PLAN_TRF },
  { label: 'APE_IND_TRF', value: TransactionType.APE_IND_TRF },
  { label: 'APE_COMM_ADJ_112', value: TransactionType.APE_COMM_ADJ_112 },
  { label: 'APE_COMM_ADJ_112P', value: TransactionType.APE_COMM_ADJ_112P },
  { label: 'APE_COMM_ADJ_112I', value: TransactionType.APE_COMM_ADJ_112I },
  { label: 'APE_COMM_ADJ_115', value: TransactionType.APE_COMM_ADJ_115 },
  { label: 'APE_COMM_ADJ_133', value: TransactionType.APE_COMM_ADJ_133 },
  { label: 'SYS_ADJ_APE_RECON', value: TransactionType.SYS_ADJ_APE_RECON },
  { label: 'SYS_ADJ_APE_REJOIN', value: TransactionType.SYS_ADJ_APE_REJOIN },
  { label: 'SYS_ADJ_APE_TERM', value: TransactionType.SYS_ADJ_APE_TERM },
  { label: 'MAN_ADJ_112', value: TransactionType.MAN_ADJ_112 },
  { label: 'MAN_ADJ_112P', value: TransactionType.MAN_ADJ_112P },
  { label: 'MAN_ADJ_112I', value: TransactionType.MAN_ADJ_112I },
  { label: 'MAN_ADJ_115', value: TransactionType.MAN_ADJ_115 },
  { label: 'MAN_ADJ_133', value: TransactionType.MAN_ADJ_133 },
  { label: 'MAN_ADJ_141ANN', value: TransactionType.MAN_ADJ_141ANN },
  { label: 'MAN_ADJ_935ANN', value: TransactionType.MAN_ADJ_935ANN },
  { label: 'SYS_TRAILER_FEE', value: TransactionType.SYS_TRAILER_FEE },
  { label: 'MAN_ADJ_136FEE', value: TransactionType.MAN_ADJ_136FEE },
  // { label: 'APE_CONTR_REGULAR', value: TransactionType.APE_CONTR_REGULAR },
  // { label: 'APE_CONTR_LUMPSUM', value: TransactionType.APE_CONTR_LUMPSUM },
  // { label: 'APE_TRF_IN_PE', value: TransactionType.APE_TRF_IN_PE },
  // { label: 'APE_TRF_IN_PM', value: TransactionType.APE_TRF_IN_PM },
  // { label: 'APE_TRF_IN_PC', value: TransactionType.APE_TRF_IN_PC },
  // { label: 'APE_TRF_IN_PP', value: TransactionType.APE_TRF_IN_PP },
  // { label: 'APE_TRF_IN_PT', value: TransactionType.APE_TRF_IN_PT },
  // { label: 'APE_COMM_ADJUST', value: TransactionType.APE_COMM_ADJUST },
  // { label: 'SYSTEM_ADJUST_APE', value: TransactionType.SYSTEM_ADJUST_APE },
  // { label: 'MANUAL_ADJUST_112', value: TransactionType.MANUAL_ADJUST_112 },
  // { label: 'MANUAL_ADJUST_141', value: TransactionType.MANUAL_ADJUST_141 },
  // { label: 'SYSTEM_TRAILER_FEE', value: TransactionType.SYSTEM_TRAILER_FEE },
  // { label: 'MANUAL_ADJUST_136', value: TransactionType.MANUAL_ADJUST_136 },
];
export const CHANNEL_OPTIONS: CdsOption[] = ApeJournalChannels;

export const APE_JOURNAL_COLUMNS: Array<ColumnConfig> = [
  { key: 'runDate', sticky: true, title: 'commission.common.runDate' },
  { key: 'txnEffectiveDate', sticky: true, title: 'commission.journal.txnEffectiveDate' },
  { key: 'employerAccountCode', sticky: true, title: 'commission.common.accountNo' },
  { key: 'memberAccountCode', title: 'commission.common.memberNo' },
  { key: 'accountType', title: 'commission.common.accountType' },
  { key: 'paySubmitRefNo', title: 'commission.apeJournal.paySubmitRefNo' },
  { key: 'groupTransRefNo', title: 'commission.apeJournal.groupTransRefNo' },
  { key: 'transactionType', title: 'commission.common.transactionType' },
  { key: 'pacFlag', title: 'commission.common.pacFlag' },
  { key: 'processor', title: 'commission.common.processor' },
  { key: 'originalErMerCode', title: 'common.journal.originalErMerCode' },
  // { key: 'specialIndicator', title: 'commission.apeJournal.specialIndicator' },
  { key: 'agentChannel', title: 'commission.common.channel' },
  { key: 'branch', title: 'commission.common.branch' },
  { key: 'agentCode', title: 'commission.common.agentCode' },
  { key: 'share', title: 'commission.journal.share' },
  { key: 'apeType', title: 'commission.apeJournal.apeType' },
  { key: 'commissionCode', title: 'commission.journal.commCode' },
  { key: 'apeAmount', title: 'commission.apeJournal.apeAmount' },
  { key: 'apeSystemAdjust', title: 'commission.common.apeSystemAdjust' },
  { key: 'vsfAmount', title: 'commission.common.vsfAmount' },
  { key: 'averageAumAmount', title: 'commission.common.averageAumAmount' },
  { key: 'trailerFee', title: 'commission.common.trailerFee' },
];

export const EXCEL_COLUMNS: Array<string> = [
  'Run date',
  'Txn Effective Date',
  'Employer Account Code',
  'Member Account code',
  'Account Type',
  'Transaction Type',
  'Payment Sub / Trans Ref No.',
  'Group Transaction Ref No.',
  'PAC Flag',
  'Processor',
  'Special indictor',
  'Channel',
  'Branch Code',
  'Agent Code',
  // 'Agent Name',
  'Comm Code',
  'Share',
  'APE Type',
  'APE Amount',
  'APE System Adjust',
  'VSF Amount',
  // 'Opening AUM',
  // 'Closing AUM',
  'Average AUM Amount',
  'Trailer Fee',
];

export const PAGE_SIZE = 10;
export const FILE_NAME = 'APE_Journal_report';
export const FILE_HEADER = 'Report Date:';
export const DOWN_LOAD_MAX = 200000;

export const I18N_KEY = {
  DATE_From_NULL: 'commission.journal.dateFromNull',
  DATE_TO_NULL: 'commission.journal.dateToNull',
  INVALID_RUN_DATE: 'commission.common.runDate.invalid',
  DATE_RANGE_INCOMPLETE: 'commission.common.dateRangeError',
  INVALID_DATE_RANGE: 'commission.common.dateRangeInvalid',
  ACCOUNT_NO_INVALID: 'commission.common.accountNo.invalid',
  MEMBER_NO_INVALID: 'commission.common.memberNo.invalid',
  AGENT_CODE_INVALID: 'commission.common.agentCode.invalid',
  DOWN_LOAD_MAX_LIMMIT: 'commission.journal.download.limmit',
  DOWN_LOAD_MAX_FAIL: 'commission.journal.download.fail',
  COMMON_GOT: 'common.gotItWithExclamation',
};
