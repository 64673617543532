<div class="iff-declined-dashboard">
  <div class="page-title">
    <span class="cds-body2-demibold title">{{ 'iff-declined-dashboard.iff-declined-dashboard' | lang }}</span>
  </div>
  <div class="page-content">
    <div>
      <span class="cds-h2-light">{{ 'iff-declined-dashboard.iff-decline-summary' | lang }}</span>
    </div>
    <div class="l-mt-2">
      <span class="cds-body1">{{ 'iff-declined-dashboard.records-of-the-current-year-is-displayed' | lang }}.</span>
    </div>
    <div class="l-mt-2">
      <span class="cds-body2" style="color: var(--cds-text-placeholder)">Latest update: 9:05am 25/05/2022 (HKT)</span>
    </div>
    <div class="declined-reason-box">
      <div class="pie-chart">
        <div *ngIf="!isLoadPieChart && (!data || total === 0)" class="grey-pie"></div>
        <cds-pie-chart
          *ngIf="!isLoadPieChart && data && total > 0"
          [size]="'md'"
          [keylineWidth]="2"
          [data]="data"
          [chartColors]="chartColors"
          [color]="'ml'"
          [colorMode]="'categorical'">
        </cds-pie-chart>
      </div>
      <div class="declined-reason-data-list">
        <div class="total-declined-cases-box l-mb-5">
          <div>
            <span class="cds-body1">Total no. of unique declined cases:</span>
          </div>
          <div class="l-ml-3">
            <span class="cds-h2-demibold" style="color: var(--cds-color-dark-1-coral)">{{ uniqueCaseCount !== undefined ? uniqueCaseCount : 'N/A' }}</span>
          </div>
        </div>
        <cds-table [dataSource]="declinedReasonData" [class.full]="false">
          <cds-row *cdsRowDef="let row; index as i; columns: declinedReasonColumns"></cds-row>
          <cds-header-row *cdsHeaderRowDef="declinedReasonColumns"></cds-header-row>
          <ng-container cdsColumnDef="declinedReason">
            <cds-header-cell *cdsHeaderCellDef>
              <div>
                <span style="color: var(--cds-text-disabled)">
                  {{ 'iff-declined-dashboard.declined-reason' | lang }}
                </span>
              </div>
            </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              <div class="l-d-f l-ai-cen">
                <div>
                  <div class="piece" [ngStyle]="{ 'background-color': element.color }"></div>
                </div>
                <div class="l-ml-2">
                  <span class="cds-body1 content-text">{{ element.declinedReason | lang }}</span>
                </div>
              </div>
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="noOfCases">
            <cds-header-cell *cdsHeaderCellDef>
              <div>
                <span style="color: var(--cds-text-disabled)">
                  {{ 'iff-declined-dashboard.no-of-cases' | lang }}
                </span>
              </div>
            </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              <div>
                <span class="cds-body1 content-text">{{ element.noOfCases }}</span>
              </div>
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="percentage">
            <cds-header-cell *cdsHeaderCellDef>
              <div>
                <span style="color: var(--cds-text-disabled)">
                  {{ 'iff-declined-dashboard.percentage' | lang }}
                </span>
              </div>
            </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              <div>
                <span class="cds-body1 content-text">{{ element.percentage }}</span>
              </div>
            </cds-cell>
          </ng-container>
        </cds-table>
      </div>
    </div>
    <div class="search-record-box">
      <div>
        <span class="cds-h2-light">{{ 'iff-declined-dashboard.search-record' | lang }}</span>
      </div>
      <div class="row l-d-f padding-none" *ngIf="requestParams">
        <div class="row l-plr-0 col-xs-12">
          <app-date-range-box class="col-lg-6 col-xs-12 l-plr-0">
            <div left *ngIf="!resetDatePicker">
              <cds-textfield
                [(ngModel)]="requestParams.iffDeclineDateFrom"
                #iffDeclineDateFromBox
                (ngModelChange)="iffDeclineDateFromChange(iffDeclineDateFromBox.value)"
                type="text"
                placeholder="DD/MM/YYYY"
                label="{{ 'iff-declined-dashboard.iff-declined-date-from' | lang }}"
                [cdsDatepicker]="pickerIffDeclineDateFrom"
                [max]="iffDeclineDateFromMax"></cds-textfield>
              <cds-datepicker #pickerIffDeclineDateFrom="cdsDatepicker"></cds-datepicker>
              <cds-assistive-text *ngIf="iffDeclineDateFromError" class="l-mt-3">{{ 'common.invalidDate' | lang }}</cds-assistive-text>
            </div>
            <div right *ngIf="!resetDatePicker">
              <cds-textfield
                [(ngModel)]="requestParams.iffDeclineDateTo"
                #iffDeclineDateToBox
                (ngModelChange)="iffDeclineDateToChange(iffDeclineDateToBox.value)"
                type="text"
                placeholder="DD/MM/YYYY"
                label="{{ 'iff-declined-dashboard.to' | lang }}"
                [cdsDatepicker]="pickerIffDeclineDateTo"
                [max]="iffDeclineDateToMax"
                [min]="iffDeclineDateToMin"></cds-textfield>
              <cds-datepicker #pickerIffDeclineDateTo="cdsDatepicker"></cds-datepicker>
              <cds-assistive-text *ngIf="iffDeclineDateToError" class="l-mt-3">{{ 'common.invalidDate' | lang }}</cds-assistive-text>
            </div>
          </app-date-range-box>
          <app-date-range-box class="col-lg-6 col-xs-12 l-plr-0">
            <div left *ngIf="!resetDatePicker">
              <cds-textfield
                [(ngModel)]="requestParams.iffExpiryDateFrom"
                #iffExpiryDateFromBox
                (ngModelChange)="iffExpiryDateFromChange(iffExpiryDateFromBox.value)"
                type="text"
                placeholder="DD/MM/YYYY"
                label="{{ 'iff-declined-dashboard.iff-expiry-date-from' | lang }}"
                [cdsDatepicker]="pickerIffExpireDateFrom"
                [max]="iffExpiryDateFromMax"></cds-textfield>
              <cds-datepicker #pickerIffExpireDateFrom="cdsDatepicker"></cds-datepicker>
              <cds-assistive-text *ngIf="iffExpiryDateFromError" class="l-mt-3">{{ 'common.invalidDate' | lang }}</cds-assistive-text>
            </div>
            <div right *ngIf="!resetDatePicker">
              <cds-textfield
                [(ngModel)]="requestParams.iffExpiryDateTo"
                #iffExpiryDateToBox
                (ngModelChange)="iffExpiryDateToChange(iffExpiryDateToBox.value)"
                type="text"
                placeholder="DD/MM/YYYY"
                label="{{ 'iff-declined-dashboard.to' | lang }}"
                [cdsDatepicker]="pickerIffExpireDateTo"
                [min]="iffExpiryDateToMin"></cds-textfield>
              <cds-datepicker #pickerIffExpireDateTo="cdsDatepicker"></cds-datepicker>
              <cds-assistive-text *ngIf="iffExpiryDateToError" class="l-mt-3">{{ 'common.invalidDate' | lang }}</cds-assistive-text>
            </div>
          </app-date-range-box>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'sales-support-summary.abm' | lang }}</span>
          <app-cd-multidropdown
            [style.width]="'100%'"
            [placeholder]="'common.plsSelect' | lang : { p1: 'sales-support-summary.abm' | lang }"
            [toppingList]="abmOptionList"
            [(selected)]="abmStatusoptionsOrigin"></app-cd-multidropdown>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'iff-declined-dashboard.product-type' | lang }}</span>
          <app-cd-multidropdown
            [style.width]="'100%'"
            [toppingList]="productTypeList"
            [(selected)]="productTypeOrigin"
            [placeholder]="'common.plsSelect' | lang : { p1: 'iff-declined-dashboard.product-type' | lang }"></app-cd-multidropdown>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <div class="l-d-f l-ai-cen">
            <div class="l-mr-2">
              <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'iff-declined-dashboard.district-code' | lang }}</span>
            </div>
            <cds-icon class="note" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
          </div>
          <div class="district-code-input-box">
            <app-cd-input
              class="text-field"
              [regExpLimit]="'^[0-9]*$'"
              [(value)]="districtCodeValue"
              [placeholder]="'common.input' | lang : { p1: 'iff-declined-dashboard.district-code' | lang }"></app-cd-input>
            <div (click)="addToSearchDistrictCodeList()" class="add-button">
              <span style="color: var(--cds-color-m-green)" class="cds-body1-demibold">Add</span>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'iff-declined-dashboard.agent-code' | lang }}</span>
          <app-cd-input
            class="text-field"
            [regExpLimit]="'^[0-9]{0,6}$'"
            [(value)]="requestParams.agentCode"
            [placeholder]="'common.input' | lang : { p1: 'iff-declined-dashboard.agent-code' | lang }"></app-cd-input>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'iff-declined-dashboard.declined-reason' | lang }}</span>
          <app-cd-multidropdown
            [style.width]="'100%'"
            [toppingList]="iffDeclinedReasonList"
            [(selected)]="iffDeclinedReasonOrigin"
            [placeholder]="'common.plsSelect' | lang : { p1: 'iff-declined-dashboard.declined-reason' | lang }"></app-cd-multidropdown>
        </div>
      </div>
      <div *ngIf="requestParams.districtCode.length > 0" class="l-d-f l-ai-cen l-fw-w">
        <div style="margin-top: 1.25rem">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'iff-declined-dashboard.district-code' | lang }}:</span>
        </div>
        <div *ngFor="let item of requestParams.districtCode" class="district-code-light" style="margin-top: 1.25rem">
          <div class="l-mr-2">
            <span class="cds-body2" style="color: var(--cds-text-inverse)">{{ item }}</span>
          </div>
          <cds-icon
            (click)="removeSearchDistrictCodeList(item)"
            style="--cds-icon-color: var(--cds-text-inverse)"
            class="cursor-pointer"
            icon="action:cross"></cds-icon>
        </div>
      </div>
      <div class="row l-d-f padding-none">
        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item bottom-box" style="margin-top: 1.5rem">
          <cds-button [disabled]="isDisableSearch || isLoading" size="sm" class="l-mr-4" (click)="onClickSearch()">{{ 'common.search1' | lang }}</cds-button>
          <cds-button [style]="'secondary'" size="sm" (click)="onClickReset()">{{ 'common.reset1' | lang }}</cds-button>
        </div>
      </div>
    </div>
    <div class="l-d-f l-jc-sb l-ai-cen l-mt-7">
      <div>
        <div>
          <span class="cds-h2-light">{{ 'iff-declined-dashboard.record-list' | lang }}</span>
        </div>
        <div class="l-mt-2">
          <span class="cds-body1">{{ 'iff-declined-dashboard.total-records' | lang : { p1: (totalElements | number) || '' } }}</span>
        </div>
      </div>
      <div class="l-mr-5">
        <cds-button [disabled]="isExportBtnDisabled" [style]="'secondary'" size="sm" (click)="exportResult()">{{ 'common.export' | lang }}</cds-button>
      </div>
    </div>
    <div class="l-d-f l-ai-cen l-mt-5" *ngIf="currentPageData.length > 0">
      <div class="l-mr-4">
        <span class="cds-body2" style="color: var(--cds-text-disabled)">Filter by:</span>
      </div>
      <div [ngClass]="['button-style', resolveStatusSelected === ResolvedStatus.all ? 'checked' : '']" (click)="checkedResolveStatusTag(ResolvedStatus.all)">
        All
      </div>
      <div
        [ngClass]="['button-style', resolveStatusSelected === ResolvedStatus.resolved ? 'checked' : '']"
        (click)="checkedResolveStatusTag(ResolvedStatus.resolved)">
        Resolved Cases
      </div>
      <div
        [ngClass]="['button-style', resolveStatusSelected === ResolvedStatus.unresolved ? 'checked' : '']"
        (click)="checkedResolveStatusTag(ResolvedStatus.unresolved)">
        Unresolved Cases
      </div>
    </div>
    <div class="l-mt-5">
      <div *ngIf="isLoading && currentPageData.length === 0" class="progress-box l-d-f l-jc-cen">
        <cds-progress mode="indeterminate" [diameter]="60" [type]="'ring'" [text]="'common.loading' | lang" [color]="'ml'"></cds-progress>
      </div>
      <app-no-result-found msg="No matching record found" [style.display]="currentPageData.length === 0 && !isLoading ? 'block' : 'none'"></app-no-result-found>
      <div [style.display]="currentPageData.length > 0 ? 'unset' : 'none'" class="row l-plr-0">
        <div #tableBox class="table-wrapper" [ngClass]="[isLoading ? 'loading' : '']">
          <cds-table class="col-lg-12 row l-plr-0" [dataSource]="currentPageData" [class.full]="false">
            <cds-row *cdsRowDef="let row; index as i; columns: displayedColumns"></cds-row>
            <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
            <ng-container cdsColumnDef="empfRefNo" sticky>
              <cds-header-cell *cdsHeaderCellDef class="e-width" [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']">
                <div class="prodtype-displaytooltip">
                  <span>{{ 'iff-declined-dashboard.empf-ref' | lang }}</span>
                  <cds-icon class="note" (click)="onDisplayAdviseRef()" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
                </div>
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="e-width" [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']">
                <app-cd-fake-link style="word-break: break-all" (callBack)="goCaseDetails(element)" [text]="element.empfRefNo"></app-cd-fake-link>
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="districtCd">
              <cds-header-cell *cdsHeaderCellDef class="district-code-width">{{ 'iff-declined-dashboard.district-code' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="district-code-width">
                {{ element.districtCd }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="agentCd">
              <cds-header-cell *cdsHeaderCellDef class="agent-code-width">{{ 'iff-declined-dashboard.agent-code' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="agent-code-width">
                <div class="l-d-f l-ai-cen">
                  <div>
                    <span>{{ element.agentCd }}</span>
                  </div>
                  <div (click)="openOutlook(element)" class="icon-email-box cursor-pointer l-ml-6">
                    <cds-icon icon="contact:email" class="icon-email"></cds-icon>
                  </div>
                </div>
                <div *ngIf="element.emailTriggeredBy" class="l-mt-4">
                  <div>
                    <span class="cds-detail2" style="color: var(--cds-text-placeholder)">Latest sent out:</span>
                  </div>
                  <div>
                    <span class="cds-detail2" style="color: var(--cds-text-placeholder)"
                      >{{ element.emailTriggeredDt | date : 'hh:mm a, dd/MM/yyyy' }} (HKT),</span
                    >
                  </div>
                  <div>
                    <span class="cds-detail2" style="color: var(--cds-text-placeholder)">by {{ element.emailTriggeredBy }}</span>
                  </div>
                </div>
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="productType">
              <cds-header-cell *cdsHeaderCellDef class="product-type-width">{{ 'iff-declined-dashboard.product-type' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="product-type-width">
                {{ element.productType }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="clientOrEmployerName">
              <cds-header-cell *cdsHeaderCellDef class="client-employer-name-width">{{ 'iff-declined-dashboard.client-employer-name' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="client-employer-name-width">
                {{ element.clientOrEmployerName }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="hkidOrPassportNoOrBrNo">
              <cds-header-cell *cdsHeaderCellDef class="hkid-passport-no-width">{{ 'iff-declined-dashboard.hkid-passport-no' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="hkid-passport-no-width">
                {{ element.hkidOrPassportNoOrBrNo }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="declineReason">
              <cds-header-cell *cdsHeaderCellDef>
                <div style="width: max-content">
                  <span>{{ 'iff-declined-dashboard.declined-reason' | lang }}</span>
                </div>
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                <div style="width: max-content" *ngFor="let item of element.declineReason">
                  <div class="l-d-f l-ai-cen">
                    <div>
                      <div
                        style="width: 10px; height: 10px"
                        [ngStyle]="{ 'background-color': iffDeclinedDashboardService.iffDeclinedReasonMatch(item)?.color }"></div>
                    </div>
                    <div class="l-ml-2">
                      <span class="cds-body1 content-text">{{ iffDeclinedDashboardService.iffDeclinedReasonMatch(item)?.text }}</span>
                    </div>
                  </div>
                </div>
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="iffDeclineDt">
              <cds-header-cell *cdsHeaderCellDef class="iff-declined-date-width">{{ 'iff-declined-dashboard.iff-declined-date' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="iff-declined-date-width">
                {{ element.iffDeclineDt }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="iffExpiryDt">
              <cds-header-cell *cdsHeaderCellDef class="iff-expiry-date-width">{{ 'iff-declined-dashboard.iff-expiry-date' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="iff-expiry-date-width">
                {{ element.iffExpiryDt }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="iffSubChannel">
              <cds-header-cell *cdsHeaderCellDef class="iff-expiry-date-width">{{ 'iff-declined-dashboard.iff-sub-channel' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="iff-expiry-date-width">
                {{ element.iffSubChannel }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="transferInDetails">
              <cds-header-cell *cdsHeaderCellDef class="transfer-in-details-width">
                <div class="prodtype-displaytooltip">
                  <span>{{ 'iff-declined-dashboard.transfer-in-details' | lang }}</span>
                  <cds-icon class="note" (click)="onDisplayTrusteeDetailsPopup()" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
                </div>
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="transfer-in-details-width">
                {{ element.transferInDetails }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="action">
              <cds-header-cell *cdsHeaderCellDef class="action-width">
                <div class="prodtype-displaytooltip">
                  <span>{{ 'iff-declined-dashboard.action' | lang }}</span>
                  <div #tooltip>
                    <cds-icon
                      (mouseenter)="onCanDelegateAmtToggle($event)"
                      (mouseleave)="onCanDelegateAmtToggle($event)"
                      class="note"
                      [icon]="infoIcon"
                      [config]="iconConfig"
                      [cdsTooltip]="
                        cdsLangService.currentLang === 'en' ? 'Once record is resolved, it’s non reversible.' : 'Once record is resolved, it’s non reversible.'
                      "></cds-icon>
                  </div>
                </div>
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="action-width">
                <div *ngIf="!element.resovled">
                  <cds-button [style]="'secondary'" size="sm" (click)="resolve(element.salesJourneySummaryId)">Resolve</cds-button>
                </div>
                <div *ngIf="element.resovled">
                  <cds-button [disabled]="true" size="sm">Resolved</cds-button>
                  <div class="l-mt-2">
                    <span class="cds-detail2" style="color: var(--cds-color-light-4-dark-navy)">Latest resolved:</span>
                  </div>
                  <div>
                    <span class="cds-detail2" style="color: var(--cds-color-light-4-dark-navy)"
                      >{{ element.resolvedDt | date : 'hh:mm a, dd/MM/yyyy' }} (HKT), by {{ element.resolvedBy }}</span
                    >
                  </div>
                </div>
              </cds-cell>
            </ng-container>
          </cds-table>
          <div *ngIf="isLoading" class="progress-box">
            <cds-progress mode="indeterminate" [diameter]="60" [type]="'ring'" [text]="'common.loading' | lang" [color]="'ml'"></cds-progress>
          </div>
        </div>
        <div class="col-sm-12 l-mt-4">
          <app-pagination
            [pageCount]="{
              current: currentPageNumOrigin,
              count: totalPages
            }"
            (pageChange)="pageChange($event)">
          </app-pagination>
        </div>
      </div>
    </div>
  </div>
  <app-cd-download-progress [show]="isCsvDownLoading" (close1)="onClickX()"></app-cd-download-progress>
</div>
