export enum NbvRatio {
  MPF_ER_AC = 'MPF_ER_AC',
  MPF_ER_TA = 'MPF_ER_TA',
  MPF_ER_IT_MPF_PAP = 'MPF_ER_IT_MPF_PAP',
  MPF_TVC = 'MPF_TVC',
  MPF_SEP = 'MPF_SEP',
  MPF_FRC = 'MPF_FRC',
  OR_MA_PPS_AC = 'OR_MA_PPS_AC',
  OR_MA_PPS_TA = 'OR_MA_PPS_TA',
  MA_CPF_AC = 'MA_CPF_AC',
  MA_CPF_TA = 'MA_CPF_TA',
  MA_PAP = 'MA_PAP',
  MA_TVC = 'MA_TVC',
}
