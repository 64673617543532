import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ape-manual',
  templateUrl: './ape-manual.component.html',
  styleUrls: ['./ape-manual.component.scss'],
})
export class ApeManualComponent implements OnInit {
  tabSelected = 0;
  constructor() {}

  ngOnInit() {}
}
