<div class="widgets-pending-iff">
  <div style="height: 100%">
    <cds-tab-group style="position: relative; top: -14px">
      <cds-tab label="Individual">
        <div class="l-ml-5 l-mr-5 l-mt-4">
          <div class="l-d-f l-mb-4">
            <div [ngClass]="['button-style', individualTypeSelected === IndividualType.PA ? 'checked' : '']" (click)="checkRecordDetailTag(IndividualType.PA)">
              PA
            </div>
            <div
              [ngClass]="['button-style', individualTypeSelected === IndividualType.TVC ? 'checked' : '']"
              (click)="checkRecordDetailTag(IndividualType.TVC)">
              TVC
            </div>
            <div
              [ngClass]="['button-style', individualTypeSelected === IndividualType.SVC ? 'checked' : '']"
              (click)="checkRecordDetailTag(IndividualType.SVC)">
              SVC
            </div>
          </div>
          <div *ngIf="individualTypeSelected === IndividualType.PA">
            <div class="l-d-f l-ai-cen l-jc-sb item">
              <div class="l-d-f l-ai-cen">
                <cds-icon style="width: 30px; height: 30px" icon="arrow:right"></cds-icon>
                <div style="margin-left: 10px">
                  <span class="cds-detail1-demibold">Pending to map</span>
                </div>
              </div>
              <div class="number-box" [ngClass]="[getColor(productTypePending?.PA?.pendingToMap)]">
                <span class="cds-body2-demibold">{{ productTypePending?.PA?.pendingToMap }}</span>
              </div>
            </div>
            <div class="l-d-f l-ai-cen l-jc-sb item">
              <div class="l-d-f l-ai-cen">
                <cds-icon style="width: 30px; height: 30px" icon="people:login_1"></cds-icon>
                <div style="margin-left: 10px">
                  <span class="cds-detail1-demibold">Pending customer submission</span>
                </div>
              </div>
              <div class="number-box" [ngClass]="[getColor(productTypePending?.PA?.pendingCusSubmission)]">
                <span class="cds-body2-demibold">{{ productTypePending?.PA?.pendingCusSubmission }}</span>
              </div>
            </div>
            <div class="l-d-f l-ai-cen l-jc-sb item">
              <div class="l-d-f l-ai-cen">
                <cds-icon style="width: 30px; height: 30px" icon="general:collaboration"></cds-icon>
                <div style="margin-left: 10px">
                  <span class="cds-detail1-demibold">Pending split agent</span>
                </div>
              </div>
              <div class="number-box" [ngClass]="[getColor(productTypePending?.PA?.pending2ndAgent)]">
                <span class="cds-body2-demibold">{{ productTypePending?.PA?.pending2ndAgent }}</span>
              </div>
            </div>
          </div>
          <div *ngIf="individualTypeSelected === IndividualType.TVC">
            <div class="l-d-f l-ai-cen l-jc-sb item">
              <div class="l-d-f l-ai-cen">
                <cds-icon style="width: 30px; height: 30px" icon="arrow:right"></cds-icon>
                <div style="margin-left: 10px">
                  <span class="cds-detail1-demibold">Pending to map</span>
                </div>
              </div>
              <div class="number-box" [ngClass]="[getColor(productTypePending?.TVC?.pendingToMap)]">
                <span class="cds-body2-demibold">{{ productTypePending?.TVC?.pendingToMap }}</span>
              </div>
            </div>
            <div class="l-d-f l-ai-cen l-jc-sb item">
              <div class="l-d-f l-ai-cen">
                <cds-icon style="width: 30px; height: 30px" icon="people:login_1"></cds-icon>
                <div style="margin-left: 10px">
                  <span class="cds-detail1-demibold">Pending customer submission</span>
                </div>
              </div>
              <div class="number-box" [ngClass]="[getColor(productTypePending?.TVC?.pendingCusSubmission)]">
                <span class="cds-body2-demibold">{{ productTypePending?.TVC?.pendingCusSubmission }}</span>
              </div>
            </div>
            <div class="l-d-f l-ai-cen l-jc-sb item">
              <div class="l-d-f l-ai-cen">
                <cds-icon style="width: 30px; height: 30px" icon="general:collaboration"></cds-icon>
                <div style="margin-left: 10px">
                  <span class="cds-detail1-demibold">Pending split agent</span>
                </div>
              </div>
              <div class="number-box" [ngClass]="[getColor(productTypePending?.TVC?.pending2ndAgent)]">
                <span class="cds-body2-demibold">{{ productTypePending?.TVC?.pending2ndAgent }}</span>
              </div>
            </div>
          </div>
          <div *ngIf="individualTypeSelected === IndividualType.SVC">
            <div class="l-d-f l-ai-cen l-jc-sb item">
              <div class="l-d-f l-ai-cen">
                <cds-icon style="width: 30px; height: 30px" icon="arrow:right"></cds-icon>
                <div style="margin-left: 10px">
                  <span class="cds-detail1-demibold">Pending to map</span>
                </div>
              </div>
              <div class="number-box" [ngClass]="[getColor(productTypePending?.SVC?.pendingToMap)]">
                <span class="cds-body2-demibold">{{ productTypePending?.SVC?.pendingToMap }}</span>
              </div>
            </div>
            <div class="l-d-f l-ai-cen l-jc-sb item">
              <div class="l-d-f l-ai-cen">
                <cds-icon style="width: 30px; height: 30px" icon="people:login_1"></cds-icon>
                <div style="margin-left: 10px">
                  <span class="cds-detail1-demibold">Pending customer submission</span>
                </div>
              </div>
              <div class="number-box" [ngClass]="[getColor(productTypePending?.SVC?.pendingCusSubmission)]">
                <span class="cds-body2-demibold">{{ productTypePending?.SVC?.pendingCusSubmission }}</span>
              </div>
            </div>
            <div class="l-d-f l-ai-cen l-jc-sb item">
              <div class="l-d-f l-ai-cen">
                <cds-icon style="width: 30px; height: 30px" icon="general:collaboration"></cds-icon>
                <div style="margin-left: 10px">
                  <span class="cds-detail1-demibold">Pending split agent</span>
                </div>
              </div>
              <div class="number-box" [ngClass]="[getColor(productTypePending?.SVC?.pending2ndAgent)]">
                <span class="cds-body2-demibold">{{ productTypePending?.SVC?.pending2ndAgent }}</span>
              </div>
            </div>
          </div>
        </div>
      </cds-tab>
      <cds-tab label="Corporate">
        <div class="l-ml-5 l-mr-5 l-mt-4">
          <div>
            <div class="l-d-f l-ai-cen l-jc-sb item">
              <div class="l-d-f l-ai-cen">
                <cds-icon style="width: 30px; height: 30px" icon="arrow:right"></cds-icon>
                <div style="margin-left: 10px">
                  <span class="cds-detail1-demibold">Pending to map</span>
                </div>
              </div>
              <div class="number-box" [ngClass]="[getColor(productTypePending?.ER?.pendingToMap)]">
                <span class="cds-body2-demibold">{{ productTypePending?.ER?.pendingToMap }}</span>
              </div>
            </div>
            <div class="l-d-f l-ai-cen l-jc-sb item">
              <div class="l-d-f l-ai-cen">
                <cds-icon style="width: 30px; height: 30px" icon="people:login_1"></cds-icon>
                <div style="margin-left: 10px">
                  <span class="cds-detail1-demibold">Pending customer submission</span>
                </div>
              </div>
              <div class="number-box" [ngClass]="[getColor(productTypePending?.ER?.pendingCusSubmission)]">
                <span class="cds-body2-demibold">{{ productTypePending?.ER?.pendingCusSubmission }}</span>
              </div>
            </div>
            <div class="l-d-f l-ai-cen l-jc-sb item">
              <div class="l-d-f l-ai-cen">
                <cds-icon style="width: 30px; height: 30px" icon="general:collaboration"></cds-icon>
                <div style="margin-left: 10px">
                  <span class="cds-detail1-demibold">Pending split agent</span>
                </div>
              </div>
              <div class="number-box" [ngClass]="[getColor(productTypePending?.ER?.pending2ndAgent)]">
                <span class="cds-body2-demibold">{{ productTypePending?.ER?.pending2ndAgent }}</span>
              </div>
            </div>
          </div>
        </div>
      </cds-tab>
      <cds-tab label="SEP">
        <div class="l-ml-5 l-mr-5 l-mt-4">
          <div>
            <div class="l-d-f l-ai-cen l-jc-sb item">
              <div class="l-d-f l-ai-cen">
                <cds-icon style="width: 30px; height: 30px" icon="arrow:right"></cds-icon>
                <div style="margin-left: 10px">
                  <span class="cds-detail1-demibold">Pending to map</span>
                </div>
              </div>
              <div class="number-box" [ngClass]="[getColor(productTypePending?.SEP?.pendingToMap)]">
                <span class="cds-body2-demibold">{{ productTypePending?.SEP?.pendingToMap }}</span>
              </div>
            </div>
            <div class="l-d-f l-ai-cen l-jc-sb item">
              <div class="l-d-f l-ai-cen">
                <cds-icon style="width: 30px; height: 30px" icon="people:login_1"></cds-icon>
                <div style="margin-left: 10px">
                  <span class="cds-detail1-demibold">Pending customer submission</span>
                </div>
              </div>
              <div class="number-box" [ngClass]="[getColor(productTypePending?.SEP?.pendingCusSubmission)]">
                <span class="cds-body2-demibold">{{ productTypePending?.SEP?.pendingCusSubmission }}</span>
              </div>
            </div>
            <div class="l-d-f l-ai-cen l-jc-sb item">
              <div class="l-d-f l-ai-cen">
                <cds-icon style="width: 30px; height: 30px" icon="general:collaboration"></cds-icon>
                <div style="margin-left: 10px">
                  <span class="cds-detail1-demibold">Pending split agent</span>
                </div>
              </div>
              <div class="number-box" [ngClass]="[getColor(productTypePending?.SEP?.pending2ndAgent)]">
                <span class="cds-body2-demibold">{{ productTypePending?.SEP?.pending2ndAgent }}</span>
              </div>
            </div>
          </div>
        </div>
      </cds-tab>
    </cds-tab-group>
  </div>
</div>
