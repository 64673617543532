import { Injectable } from '@angular/core';
import { APIPaths } from '../_common/constant';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Alerts } from '../_common/alerts';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(public httpClient: HttpClient) {}

  getChatHistory() {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.gethistory).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getAllRoomForHistory() {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getAllRoomForHistory).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getCloseChatList(cso: any, agent: any, type: any, keyword: any, page: any) {
    let param = new HttpParams()
      .set('status', 'CLOSED')
      .set('cso', cso)
      .set('agent', agent)
      .set('type', type)
      .set('keyword', keyword)
      .set('page', page?.current - 1)
      .set('limit', page?.pageSize);
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getChatListForSupervisor, { params: param }).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getCloseChatListForSupervisor(cso: any, agent: any, type: any, keyword: any, page: any) {
    let param = new HttpParams()
      .set('status', 'CLOSED')
      .set('cso', cso)
      .set('agent', agent)
      .set('type', type)
      .set('keyword', keyword)
      .set('page', page?.current - 1)
      .set('limit', page?.pageSize);
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getChatListForSupervisor, { params: param }).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  downloadChatHistor(roomId: any) {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.downloadChatHistor + `?roomId=${roomId}`, { responseType: 'blob' }).pipe(
      map(value => value),
      map(onSuccess)
    );
  }
}
