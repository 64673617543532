/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BasicResponse } from 'src/app/core/models/response/response';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UploadCustomerDataService {
  constructor(private http: HttpClient) {}

  addCommissionEvents(params: any): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/customer-manual/add`, params);
  }

  uploadFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}${'/customer-manual/upload'}`, formData);
  }
}
