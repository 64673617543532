import { Menu } from '../core/models/menu';

export const MENUS: Menu[] = [
  // { icon: 'general:home_1', label: 'Home', subMenu: [{ subLabel: 'Role Manage', subLink: '/system/role-list' }, { subLabel: 'Commission Scale Set up', subLink: '/commission/commission-scale-list' }, { subLabel: 'Scheme Master', subLink: '/home/scheme-list' }] },
  // { icon: 'form:id', label: 'Pofile enquiry', subMenu: [{ subLabel: 'Submenu 3' }, { subLabel: 'Submenu 4' }] },
  // { icon: 'finance:bonus', label: 'Bonus rebate' },
  {
    icon: 'contact:advisor_1',
    label: 'Agent maintenance',
    subMenu: [
      { subLabel: 'Agent Delegation', subLink: '/agent/employer-summary' },
      {
        subLabel: 'Agent Profile List',
        subLink: '/report-center/agent-profile-list',
      },
    ],
  },
  {
    icon: 'contact:advisor_1',
    label: 'Announcement',
    id: 'announcement',
    subMenu: [
      {
        subLabel: 'Announcement Center',
        subLink: '/annuncement/announcement-center',
      },
    ],
  },

  {
    icon: 'contact:advisor_1',
    label: 'Chatbot',
    id: 'chatbot',
    subMenu: [
      { subLabel: 'Chatbot CSO Portal', subLink: '/chatbot/cso' },
      { subLabel: 'Chatbot Admin', subLink: '/chatbot-admin' },
      { subLabel: 'Chatbot Supervisor', subLink: '/chatbot-supervisor' },
    ],
  },

  {
    icon: 'finance:transfer_of_existing_assets',
    label: 'Commission',
    id: 'commission',
    subMenu: [
      { subLabel: 'APE & VSF, AUM & Trailer Fee Journal', subLink: '/commission/ape-journal' },
      { subLabel: 'APE Manual Adjustment & NBV Ratio', subLink: '/commission/ape-manual' },
      { subLabel: 'Batch Upload For Commission Adjustment', subLink: '/commission/batch-upload-adjustment' },
      { subLabel: 'Commission Adjustment', subLink: '/commission/adjustment' },
      {
        subLabel: 'Commission Adjustment Amount',
        subLink: '/commission/adjustment-amount',
      },
      { subLabel: 'Commission Error Log', subLink: '/commission/error-log' },
      {
        subLabel: 'Commission Info Maintenance',
        subLink: '/commission/pool-list',
      },
      { subLabel: 'Commission Journal', subLink: '/commission/journal' },
      {
        subLabel: 'Commission Scale Set up',
        subLink: '/commission/scale-list',
      },
      { subLabel: 'Hold on Compensation Report', subLink: '/commission/hold-on-report' },
      {
        subLabel: 'Scheme Master Maintenance',
        subLink: '/commission/scheme-list',
      },
      { subLabel: 'Withdrawal claw back', subLink: '/commission/withdrawal-claw-back' },
    ],
  },
  {
    icon: 'investment:accumulate',
    label: 'Mobile Subscription',
    subMenu: [
      {
        subLabel: 'MobileSubscriptionList',
        subLink: '/mobile-subscription/list',
      },
    ],
  },
  {
    icon: 'finance:payment',
    label: 'Payment journey',
    subMenu: [
      {
        subLabel: 'Payment detail',
        subLink: '/payment/details',
      },
      {
        subLabel: 'Financial journal',
        subLink: '/payment/financial',
      },
    ],
  },

  {
    id: 'profileList',
    icon: 'finance:transfer_of_existing_assets',
    label: 'ProfileList',
    subMenu: [
      {
        subLabel: 'EEProfileList',
        subLink: '/employee/list',
      },
      {
        subLabel: 'ERProfileList',
        subLink: '/employer/list',
      },
      {
        subLabel: 'Activity Record List',
        subLink: '/employer/activity-record',
      },
      {
        subLabel: 'SQ Log Master List',
        subLink: '/employer/sq-log-master-list',
      },
      {
        subLabel: 'Agent SEP Enrolment List',
        subLink: '/employer/agent-sep',
      },
      {
        subLabel: 'Enquiry and upload page on interest rate/ dividend rate/ unit price',
        subLink: '/employer/fund-benifit',
      },
      {
        subLabel: 'Campaign Master List',
        subLink: '/campaign/list',
      },
    ],
  },
  {
    icon: 'contact:advisor_1',
    label: 'Report Center',
    id: 'reportCenter',
    subMenu: [
      {
        subLabel: 'Report Center',
        subLink: '/report-center',
      },
      {
        subLabel: 'Fund and Contribution related report Extraction',
        subLink: '/report-center/report-extraction',
      },
      {
        subLabel: 'Report - Commission',
        subLink: '/report-center/report-commission',
      },
      {
        subLabel: 'Report - SHKP Payment Issuance',
        subLink: '/report-center/payment-report-shkp',
      },
    ],
  },
  {
    icon: 'contact:advisor_1',
    label: 'Sales Journey',
    id: 'salesJourney',
    subMenu: [
      {
        subLabel: 'product type summary',
        subLink: '/salesjourney/product-type-summary/select-product-type',
      },
      {
        subLabel: 'sales support summary',
        subLink: '/salesjourney/sales-support-summary',
      },
      {
        subLabel: 'IFF Paper Form',
        subLink: '/salesjourney/iff-paper-form/select-product-type',
      },
      {
        subLabel: 'Super Admin',
        subLink: '/salesjourney/super-admin',
      },
      {
        subLabel: 'All eIFF Summary',
        subLink: '/salesjourney/app-paper-summary',
      },
      {
        subLabel: 'WSM Case Summary Page',
        subLink: '/salesjourney/wsm-case-summary',
      },
      {
        subLabel: 'IFF Declined Dashboard',
        subLink: '/salesjourney/iff-declined-dashboard',
      },
      {
        subLabel: 'MD_IFF summary',
        subLink: '/salesjourney/md-iff-summary/select-product-type',
      },
      {
        subLabel: 'MD_IFF summary',
        subLink: '/salesjourney/support-md-iff-summary',
      },
    ],
  },
  {
    icon: 'investment:roll_over_plan',
    label: 'SHKP',
    subMenu: [
      {
        subLabel: 'Agent Performance Report for staff - Termination Case',
        subLink: '/agent-performance/list',
      },
      {
        subLabel: 'Agent Performance Report for staff - Enrolment Case',
        subLink: '/agent-performance-enrolment/list',
      },
      {
        subLabel: 'Agent Performance Report for Head - Termination Case',
        subLink: '/agent-performance/head',
      },
      {
        subLabel: 'Agent Performance Report for Head - Enrolment Case',
        subLink: '/agent-performance-enrolment/head',
      },
      {
        subLabel: 'Agent Follow Up List – Termination Case',
        subLink: '/termination/list',
      },
      {
        subLabel: 'Agent Follow Up List – Enrolment Case',
        subLink: '/enrolment/list',
      },
      {
        subLabel: 'SHKP Company and Pre-assigned Agent List',
        subLink: '/agentList/list',
      },
      {
        subLabel: 'SHKP Benefit Payment',
        subLink: '/shkp/benefit-payment/list',
      },
      {
        subLabel: 'SCB Statement Upload',
        subLink: '/shkp/scb-statement-upload',
      },
      {
        subLabel: 'PTW Benefit Payment',
        subLink: '/ptw/benefit-payment/list',
      },
      {
        subLabel: 'SHKP Auto Rollover Member Record',
        subLink: '/shk/auto-rollover/list',
      },
      {
        subLabel: 'SHKP Auto Rollover Payment Record',
        subLink: '/shk/auto-rollover-payment/list',
      },
      {
        subLabel: 'SHKP Agent Case Re-assignment',
        subLink: '/shk/agent-reassignment',
      },
    ],
  },
  {
    icon: 'contact:advisor_1',
    label: 'Upload Center',
    id: 'uploadCenter',
    subMenu: [
      {
        subLabel: 'AML Upload Center',
        subLink: '/high-risk-file-upload-center',
      },
      {
        subLabel: 'Upload Center',
        subLink: '/upload-center',
      },
      {
        subLabel: 'Upload MPF Comm Pool',
        subLink: '/upload-center/customer-data',
      },
    ],
  },

  {
    icon: 'action:gear',
    label: 'Settings',
    subMenu: [
      { subLabel: 'Role Manage', subLink: '/system/role-list' },
      { subLabel: 'Report Permission', subLink: '/system/reporting-role-list' },
      { subLabel: 'Calendar', subLink: '/system/calendar' },
      {
        subLabel: 'Commission Default Scale',
        subLink: '/system/default-scale-list',
      },
      {
        subLabel: 'Audit History',
        subLink: '/system/audit-history',
      },
    ],
  },
];
