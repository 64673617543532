import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminLayoutComponent } from '../layout/admin-layout/admin-layout.component';

import { CommissionScaleListComponent } from './scale/commission-scale-list.component';
import { CommissionPoolInfoComponent } from './summary/pool-info.component';
import { CommissionPoolListComponent } from './summary/pool-list.component';

import { PermissionAccess, PermissionItem } from '../../core/models/enum/permission.enum';
import { CommissionScaleInfoComponent } from './scale/commission-scale-info/commission-scale-info.component';
import { AdjustmentComponent } from './adjustment/adjustment.component';
import { AgentRecordAddComponent } from './summary/agent/agent-record-add/agent-record-add.component';
import { AgentRecordEditComponent } from './summary/agent/agent-record-edit/agent-record-edit.component';
import { JournalComponent } from './journal/journal.component';
import { HoldOnReportComponent } from './hold-on-report/hold-on-report.component';
import { SchemeMasterComponent } from './scheme-master/scheme-master.component';
import { SchemeMasterCreateComponent } from './scheme-master/scheme-master-create/scheme-master-create.component';
import { SchemeMasterEditComponent } from './scheme-master/scheme-master-edit/scheme-master-edit.component';
import { AddScaleRecordsComponent } from './summary/record-details/add-scale-records/add-scale-records.component';
import { ErrorLogComponent } from './error-log/error-log.component';
import { BatchUploadAdjustmentComponent } from './batch-upload-adjustment/batch-upload-adjustment.component';
import { ApeJournalComponent } from './ape-journal/ape-journal.component';
import { ApeManualComponent } from './ape-manual/ape-manual.component';

const routes: Routes = [
  {
    path: 'commission',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'batch-upload-adjustment',
        component: BatchUploadAdjustmentComponent,
        data: {
          roles: {
            [PermissionItem.COMM_ADJ_BATCH_UPLOAD]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'scale-list',
        component: CommissionScaleListComponent,
        data: {
          roles: {
            [PermissionItem.COMMISSION_SCALE_MANAGEMENT]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'scale-info',
        component: CommissionScaleInfoComponent,
        data: {
          roles: {
            [PermissionItem.COMMISSION_SCALE_MANAGEMENT]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'pool-list',
        component: CommissionPoolListComponent,
        data: {
          roles: {
            [PermissionItem.COMM_INFO_MAIN_COMM_SUMMARY]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'pool-info',
        component: CommissionPoolInfoComponent,
        data: {
          roles: {
            [PermissionItem.COMM_INFO_MAIN_COMM_SUMMARY]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'record-create',
        component: AddScaleRecordsComponent,
        data: {
          roles: {
            [PermissionItem.COMM_INFO_SCALE_RECORD]: PermissionAccess.W,
          },
        },
      },
      {
        path: 'agent-record-add',
        component: AgentRecordAddComponent,
        data: {
          roles: {
            [PermissionItem.COMM_INFO_AGENT_CHANGE]: PermissionAccess.W,
          },
        },
      },
      {
        path: 'agent-record-edit',
        component: AgentRecordEditComponent,
        data: {
          roles: {
            [PermissionItem.COMM_INFO_AGENT_CHANGE]: PermissionAccess.W,
          },
        },
      },
      {
        path: 'adjustment',
        component: AdjustmentComponent,
        data: {
          roles: {
            [PermissionItem.COMM_ADJ_NORMAL]: PermissionAccess.W,
            [PermissionItem.COMM_ADJ_INDIVIDUAL]: PermissionAccess.W,
            [PermissionItem.COMM_ADJ_APPEAL]: PermissionAccess.W,
          },
        },
      },
      {
        path: 'journal',
        component: JournalComponent,
        data: {
          roles: {
            [PermissionItem.COMMISSION_JOURNAL]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'hold-on-report',
        component: HoldOnReportComponent,
        data: {
          roles: {
            [PermissionItem.COMM_HOLD_ON_COMPENSATION_REPORT]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'scheme-list',
        component: SchemeMasterComponent,
        data: {
          roles: {
            [PermissionItem.COMMISSION_SCHEME_MASTER_RECORD]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'scheme-create',
        component: SchemeMasterCreateComponent,
        data: {
          roles: {
            [PermissionItem.COMMISSION_SCHEME_MASTER_RECORD]: PermissionAccess.W,
          },
        },
      },
      {
        path: 'scheme-edit',
        component: SchemeMasterEditComponent,
        data: {
          roles: {
            [PermissionItem.COMMISSION_SCHEME_MASTER_RECORD]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'error-log',
        component: ErrorLogComponent,
        data: {
          roles: {
            [PermissionItem.COMMISSION_ERROR_LOG]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'ape-journal',
        component: ApeJournalComponent,
        data: {
          roles: {
            [PermissionItem.COMM_APE_JOURNAL]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'ape-manual',
        component: ApeManualComponent,
        data: {
          roles: {
            [PermissionItem.COMM_APE_MANUAL_ADJ_NBV_RATIO]: PermissionAccess.R,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CommissionRoutingModule {}
