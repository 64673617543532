import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filterParams } from 'src/app/core/models/request';
import { PageResponse, BasicResponse } from 'src/app/core/models/response/response';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ChequeDTO, ChequeSearch, ChequeSearchDTO, ScbStatementDTO, ScbStatementSearchDTO, ScbUploadResponse } from './benefit-payment';

@Injectable({
  providedIn: 'root',
})
export class PaymentServicePTW {
  private payment_url = `${environment.apiPrefix}/ext/eb-ssms/payment-service/`;
  private paymentIssuanceUrl = this.payment_url + 'ptw/payment-issuance';
  private paymentIssuanceAmountUrl = this.paymentIssuanceUrl + '/amount';
  private paymentIssuanceUpdateList = this.paymentIssuanceUrl + '/updateList';
  private exportPdfUrl = this.paymentIssuanceUrl + '/exportPdf';
  private exportPdfByStatusUrl = this.paymentIssuanceUrl + '/exportPdfByStatus';
  private paymentIssuancePrintCountUrl = this.paymentIssuanceUrl + '/printCount';
  private scbStatementUrl = this.payment_url + 'ptw/payment-statement';
  private scbStatementUploadUrl = this.scbStatementUrl + '/upload';
  private fileNameCheckUrl = this.scbStatementUrl + '/file/';
  private getChqNoUrl = this.paymentIssuanceUrl + '/outChequeNo/';

  constructor(private http: HttpClient) {}

  getPaymentIssuance(params: ChequeSearchDTO): Observable<PageResponse<ChequeDTO>> {
    return this.http.get<PageResponse<ChequeDTO>>(this.paymentIssuanceUrl, {
      params: filterParams(params),
    });
  }

  getPaymentIssuanceAmount(params: ChequeSearch): Observable<BasicResponse<number>> {
    return this.http.get<BasicResponse<number>>(this.paymentIssuanceAmountUrl, {
      params: filterParams(params),
    });
  }

  changePaymentIssuance(data: ChequeDTO[]) {
    return this.http.put<BasicResponse<boolean>>(this.paymentIssuanceUpdateList, data);
  }

  paymentIssuancePrintCount(ids: string[]) {
    return this.http.post<BasicResponse<boolean>>(this.paymentIssuancePrintCountUrl, ids);
  }

  exportPdf(ids: string[]) {
    return this.http.post<BasicResponse<string>>(this.exportPdfUrl, ids);
  }

  exportPdfStatus(statusList: string[]) {
    return this.http.post<BasicResponse<string>>(this.exportPdfByStatusUrl, statusList);
  }

  getScbStatmentList(params: ScbStatementSearchDTO) {
    return this.http.get<PageResponse<ScbStatementDTO>>(this.scbStatementUrl, {
      params: filterParams(params),
    });
  }

  uploadScb(filePath: File) {
    const formData = new FormData();
    formData.append('file', filePath);
    return this.http.post<BasicResponse<ScbUploadResponse>>(this.scbStatementUploadUrl, formData);
  }

  fileNameCheck(name: string): Observable<BasicResponse<boolean>> {
    return this.http.get<BasicResponse<boolean>>(this.fileNameCheckUrl + name);
  }

  getByChqNo(chqNo: string) {
    return this.http.get<BasicResponse<ChequeDTO>>(this.getChqNoUrl + chqNo);
  }
}
