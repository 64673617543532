import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsTextfieldConfig } from '@cds/ng-core/textfield';
import { SubActivityOpts, AccountTypesHoldOnReport } from 'src/app/core/models/enum';
import { ColumnConfig } from 'src/app/shared/data-table';

export const ACCOUNT_NO_CONFIG: CdsTextfieldConfig = {
  type: 'text',
  label: 'commission.common.accountNo',
  placeholder: 'commission.common.accountNo.placeholder',
};

export const MEMBER_NO_CONFIG: CdsTextfieldConfig = {
  type: 'text',
  label: 'commission.common.memberNo',
  placeholder: 'commission.common.placeholder.memberNo',
};

export const ACCOUNT_TYPE_CONFIG: CdsDropdownConfig = {
  label: 'commission.common.accountType',
  placeholder: 'commission.common.accountType.placeholder',
  options: AccountTypesHoldOnReport,
};

export const ACTIVITY_CODE_CONFIG: CdsDropdownConfig = {
  label: 'commission.common.subActivityCode',
  placeholder: 'commission.common.activityCode.placeholder',
  options: [{ label: 'All', value: 'all' }, ...SubActivityOpts],
};

export const REASON_CONFIG: CdsDropdownConfig = {
  label: 'commission.holdOnReport.reason',
  placeholder: 'commission.holdOnReport.placeholder.reason',
  options: [
    { label: 'All', value: 'all' },
    { label: 'No Available Pool', value: 'NO_COMMISSION_POOL' },
    {
      label: 'Scale / Agent Not found or Sharing issue on Agent',
      value: 'NO_EFFECTIVE_SCALE_OR_AGENT',
    },
    { label: 'Frozen Pool', value: 'FROZEN_POOL' },
  ],
};

// export const FROZEN_CONFIG: CdsCheckboxConfig = {
//   label: 'commission.holdOnReport.frozen',
//   checked: false,
// };

export const I18N_KEY = {
  DOWN_LOAD_MAX_FAIL: 'commission.journal.download.fail',
  COMMON_GOT: 'common.gotItWithExclamation',
  INVALID_ACCONTNO: 'commission.common.accountNo.invalid',
  INVALID_MEMBERNO: 'commission.common.memberNo.invalid',
};

export const HOLD_ON_REPOERTS_COLUMNS: Array<ColumnConfig> = [
  {
    key: 'referenceNo',
    title: 'commission.common.referenceNo',
    sticky: true,
  },
  {
    key: 'accountType',
    title: 'commission.common.accountType',
    stickyEnd: true,
  },
  { key: 'subActivityCd', title: 'commission.common.subActivityCode' },
  {
    key: 'efctvDate',
    title: 'commission.holdOnReport.effectiveDate',
  },
  { key: 'employerAccountNo', title: 'commission.common.accountNo' },
  { key: 'memberAccountNo', title: 'commission.common.memberNo' },
  { key: 'totalPremium', title: 'commission.holdOnReport.premium' },
  { key: 'commissionPoolId', title: 'commission.common.commPoolNo' },
  { key: 'freezeDate', title: 'commission.holdOnReport.freezeDate' },
  { key: 'processId', title: 'commission.holdOnReport.processID' },
  { key: 'holdOnReason', title: 'commission.holdOnReport.reason' },
];

export const PAGE_SIZE = 10;
export const DOWN_LOAD_MAX = 200000;

export const EXCEL_COLUMNS: Array<string> = [
  'Reference No.',
  'Account Type',
  'Sub Activative Code',
  'Transaction Effective Date',
  'Employer Account Code',
  'Member Account Code',
  'Total Premium',
  'Comm Pool No',
  'Freeze Date',
  'Process ID',
  'Hold-on Reason',
];
