<div class="will-be-lost">
  <div class="cd-waring-popup">
    <div>
      <div innerHtml="{{ 'common.action.will-be-lose-input' | lang }}"></div>
    </div>
    <div class="botton-list">
      <cds-button [style]="'secondary'" size="sm" (click)="onCancel()">{{ 'common.cancel' | lang }}</cds-button>
      <cds-button size="sm" (click)="onContinue()">{{ 'common.confirm-continue' | lang }}</cds-button>
    </div>
  </div>
</div>
