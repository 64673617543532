import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { DateTime } from 'luxon';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { PermissionService } from 'src/app/core/services/permission.service';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { ContinuePopupComponent } from 'src/app/shared/continue-popup/continue-popup.component';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { CommentsHistoryComponent } from 'src/app/views/employer/commonts-history/comments-history.component';
import { SpecialManagementPopupComponent } from 'src/app/views/employer/special-management-popup/special-management-popup.component';
import {
  AccountTypeE,
  BusinessNatureE,
  businessNatureMapObj,
  CommentHistory,
  CommunicationTypeE,
  communicationTypeMapObj,
  CompanyTypeE,
  companyTypeMapObj,
  CurrencyE,
  currencyMapObj,
  EmployerProfileDetail,
  EmployerStatusE,
  employerStatusMapObj,
  erAccountTypeMap,
  LanguageTypeE,
  languageTypeMapObj,
  LspspoffsetSeqE,
  lspspoffsetSeqMapObj,
  OrsoAppSignalE,
  orsoAppSignalMapObj,
  RegistrationTypeE,
  registrationTypeMapObj,
  RelationshipTotalCount,
  SchemeMergeSignalE,
  schemeMergeSignalMapObj,
  SourceOfFundAndWealthE,
  sourceOfFundAndWealthMapObj,
  SubmitChannelE,
  submitChannelMapObj,
} from '../employer';
import { EmployerProfileInvestedComponent } from '../employer-profile-invested/employer-profile-invested.component';
import { EmployerService } from '../employer.service';

@Component({
  selector: 'app-employer-profile-information',
  templateUrl: './employer-profile-information.component.html',
  styleUrls: ['./employer-profile-information.component.scss'],
})
export class EmployerProfileInformationComponent implements OnInit, OnChanges {
  constructor(
    public service: EmployerService,
    private router: Router,
    private cdsPopup: CdsPopupService,
    private permissionService: PermissionService,
    private langService: CdsLangService,
    private toastAlert: ToastAlertService
  ) {}

  @Input() detailShow = false;
  others = false;
  @Input() data?: EmployerProfileDetail;
  @Input() relationshipTotalCount?: RelationshipTotalCount = {};
  commentsShow = false;
  commentsData = '';
  comments?: CommentHistory;

  resetConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
    disabled: true,
  };
  confirmConfig: CdsButtonConfig = {
    size: 'sm',
    disabled: true,
  };

  isStaff = false;
  haveSpecialOfferPermission = false;
  haveActivityCodePermission = false;

  activityScore = 0;

  PermissionAccess = PermissionAccess;
  PermissionItem = PermissionItem;

  employerStatusMapObj = employerStatusMapObj;

  workflowReferenceNos: string[] = [];

  EmployerStatusE = EmployerStatusE;

  get showTerminationDetail() {
    const status = this.data?.employerStatus;
    return status === EmployerStatusE.TERMD || status === EmployerStatusE.TERMG;
  }

  ngOnInit(): void {
    this.getPermission();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue) {
      // this.getProfileDetail(changes['data'].currentValue.id);
      const workfolowData = this.data?.workflowReferenceNo;
      this.workflowReferenceNos = workfolowData ? workfolowData.split(',') : [];
      if (this.haveActivityCodePermission) {
        this.getActivityScore(changes['data'].currentValue.id);
      }
    }
  }

  goToWorkFlow(workflowRef?: string) {
    console.log(workflowRef, '>>');
  }

  getPermission() {
    this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.CUSTOMER_EMPLOYER).then(havePermission => {
      if (havePermission) {
        this.isStaff = true;
      }
    });
    this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.CUSTOMER_EMPLOYER_ADDITIONAL_INFORMATION).then(havePermission => {
      if (havePermission) {
        this.haveSpecialOfferPermission = true;
      }
    });
    this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.CUSTOMER_EMPLOYER_ACTIVITY_CODE).then(havePermission => {
      if (havePermission) {
        this.haveActivityCodePermission = true;
      }
    });
  }

  validating(val: string) {
    const reg = /[\u4e00-\u9fa5]/g;
    val = val.replace(reg, '');

    this.resetConfig = {
      size: 'sm',
      style: 'secondary',
      disabled: this.data?.comments?.comments === val,
    };
    this.confirmConfig = {
      size: 'sm',
      disabled: this.data?.comments?.comments === val,
    };

    if (val.length > 1000) {
      val = val.slice(0, 1000);
      this.commentsShow = false;
      this.commentsData = val;
      setTimeout(() => {
        this.commentsShow = true;
      });
    } else {
      this.commentsData = val;
    }
  }

  reset() {
    if (this.resetConfig.disabled) return;

    this.cdsPopup
      .open(ContinuePopupComponent, {
        size: 'sm',
        data: { message: this.langService.translate('common.action.cancel.italic') },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.commentsData = this.data?.comments?.comments || '';
          this.validating(this.commentsData);
        }
      });
  }

  confirm() {
    if (!this.data) {
      return;
    }
    if (this.confirmConfig.disabled) return;
    if (!this.data?.comments) {
      this.data.comments = {
        customerId: this.data?.id,
      };
    }
    this.data.comments.comments = this.commentsData;
    this.service.updateComments(this.data.comments).subscribe(data => {
      if (data.result === 0 && this.data) {
        this.cdsPopup
          .open(AlertPopupComponent, {
            size: 'sm',
            data: {
              message: this.langService.translate('common.action.success.italic'),
              buttonName: this.langService.translate('common.gotItWithExclamation'),
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.commentsShow = false;
            this.validating(this.commentsData);
            this.getProfileDetail(this.data?.id as string);
          });
      } else {
        this.toastError(data.message);
      }
    });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  showInfo() {
    this.cdsPopup.open(EmployerProfileInvestedComponent, {
      size: 'lg',
      data: {
        investedFundsData: this.data?.investedFundsInformationList,
      },
    });
  }

  showOfferManagement() {
    this.cdsPopup.open(SpecialManagementPopupComponent, {
      size: 'lg',
      data: {
        employerProfileDetail: this.data,
      },
    });
  }
  showCommentsLog() {
    this.cdsPopup.open(CommentsHistoryComponent, {
      size: 'lg',
      data: {
        customerId: this.data?.id,
      },
    });
  }

  editTrigger() {
    if (this.commentsShow && !this.resetConfig.disabled) {
      this.cdsPopup
        .open(ContinuePopupComponent, {
          size: 'sm',
          data: { message: this.langService.translate('common.action.cancel.italic') },
        })
        .afterClosed()
        .subscribe(result => {
          if (result?.agree) {
            this.commentsShow = !this.commentsShow;
            this.commentsData = this.data?.comments?.comments || '';
            this.validating(this.commentsData);
          }
        });
    } else {
      this.commentsShow = !this.commentsShow;
      this.commentsData = this.data?.comments?.comments || '';
      this.validating(this.commentsData);
    }
  }

  detailTrigger() {
    this.detailShow = !this.detailShow;
  }

  updateDateGen(value: string) {
    const dateTime = DateTime.fromISO(value);
    if (dateTime.isValid) {
      return dateTime.toFormat('hh:mma dd/MM/yyyy (Z') + 'T)';
    }
    return '';
  }

  getProfileDetail(id: string) {
    this.service.getProfileDetail(id).subscribe(data => {
      if (data.result === 0) {
        this.data = data.data;
        const workfolowData = data.data?.workflowReferenceNo;
        this.workflowReferenceNos = workfolowData ? workfolowData.split(',') : [];
      }
    });
  }

  getActivityScore(id: string) {
    this.service.getErActivityScore(id).subscribe(data => {
      if (data.result === 0) {
        this.activityScore = data.data as number;
      }
    });
  }

  goToActivityCodeDetail(id: string | undefined) {
    this.router.navigate(['/employer/employer-activity-record'], {
      queryParams: { id },
    });
  }

  gotoPool(commissionPoolId: string | undefined) {
    this.router.navigate(['/commission/pool-info'], {
      queryParams: { commissionPoolId },
    });
  }

  poolIdRender(id?: string) {
    if (id) {
      return id.split('-')[0];
    }
    return '';
  }

  dbClick(id: string) {
    this.router.navigate(['/commission/pool-info/'], {
      queryParams: { commissionPoolId: id },
    });
  }

  employerStatusRender(status: EmployerStatusE) {
    return employerStatusMapObj[status]?.text || '';
  }

  accountTypeRender(type?: AccountTypeE) {
    if (!type) {
      return '';
    }
    return erAccountTypeMap[type] || '';
  }

  registrationTypeRender(type: RegistrationTypeE) {
    return registrationTypeMapObj[type] || '';
  }

  companyTypeRender(type: CompanyTypeE) {
    return companyTypeMapObj[type] || '';
  }

  businessNatureRender(type: BusinessNatureE) {
    return businessNatureMapObj[type] || '';
  }

  orsoAppSignalRender(type: OrsoAppSignalE) {
    return orsoAppSignalMapObj[type] || '';
  }

  languageTypeRender(type: LanguageTypeE) {
    return languageTypeMapObj[type] || '';
  }

  currencyRender(type?: CurrencyE) {
    if (!type) {
      return '';
    }
    return currencyMapObj[type] || '';
  }

  communicationTypeRender(type: CommunicationTypeE) {
    return communicationTypeMapObj[type] || '';
  }

  submitChannelRender(type: SubmitChannelE) {
    return submitChannelMapObj[type] || '';
  }

  lspspoffsetSeqRender(type: LspspoffsetSeqE) {
    return lspspoffsetSeqMapObj[type] || '';
  }

  schemeMergeSignalRender(val: SchemeMergeSignalE) {
    return schemeMergeSignalMapObj[val] || '';
  }

  sourceOfFundAndWealthRender(val: SourceOfFundAndWealthE) {
    return sourceOfFundAndWealthMapObj[val] || '';
  }
}
