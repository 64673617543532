import { Component, HostListener, OnInit } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { PermissionService } from 'src/app/core/services/permission.service';
import { FormControl, FormGroup } from '@angular/forms';
import { configPrimary, configSecondary, BaseChequeSearch, I18N_KEY, maxDate, minDate, SchemeCode } from '../../benefit-payment';
import { PaymentServicePTW } from '../../paymentPTW.service';
import { dateValidator } from 'src/app/core/validators';

@Component({
  selector: 'app-cheque-printing-list-ptw',
  templateUrl: './cheque-printing-list-ptw.component.html',
  styleUrls: ['./cheque-printing-list-ptw.component.scss'],
})
export class ChequePrintingListPTWComponent implements OnInit {
  constructor(private cdsPopup: CdsPopupService, private service: PaymentServicePTW, private permissionService: PermissionService) {}

  searchButtonConfig: CdsButtonConfig = configPrimary();

  resetButtonConfig: CdsButtonConfig = configSecondary();

  _valid = true;

  searchDTO: BaseChequeSearch = {
    schemeCodeList: [SchemeCode.ORSO, SchemeCode.MACAUCPF, SchemeCode.MACAUPPS],
  };

  isMobile = false;

  I18N_KEY = I18N_KEY;
  maxDate = maxDate;
  minDate = minDate;

  _search = 0;
  _tabIndex = 0;

  _schemeList = {
    orso: true,
    macaupps: true,
    macaucpf: true,
  };

  form = new FormGroup({
    instructionFileDateFrom: new FormControl('', [dateValidator()]),
    instructionFileDateTo: new FormControl('', [dateValidator()]),
    // instructionFileDate: new FormControl('', [dateValidator()]),
    payRefNo: new FormControl('', []),
    appRefNo: new FormControl('', []),
    payeeName: new FormControl('', []),
    isReissue: new FormControl(),
    orso: new FormControl(true),
    macaupps: new FormControl(true),
    macaucpf: new FormControl(true),
    transactionIdentifier: new FormControl('', []),
  });

  ngOnInit(): void {
    this.onResize();
    this.initForm();
  }

  reissueChnage(val: boolean) {
    this.searchDTO.isReissue = val ? 'Y' : undefined;
    this._valid = true;
  }

  schemeChange(val: boolean, name: string) {
    this._valid = true;
    switch (name) {
      case 'orso':
        this._schemeList.orso = val;
        break;
      case 'macaupps':
        this._schemeList.macaupps = val;
        break;
      case 'macaucpf':
        this._schemeList.macaucpf = val;
        break;
    }
    const _selectedList: string[] = [];
    if (this._schemeList.orso) {
      _selectedList.push(SchemeCode.ORSO);
    }
    if (this._schemeList.macaupps) {
      _selectedList.push(SchemeCode.MACAUPPS);
    }
    if (this._schemeList.macaucpf) {
      _selectedList.push(SchemeCode.MACAUCPF);
    }
    this.searchDTO.schemeCodeList = _selectedList;
  }

  async validFn() {
    return this.form.valid && this._valid;
  }

  search() {
    this.validFn().then(r => {
      if (r) {
        this._search = this._search + 1;
      }
    });
  }
  reset() {
    this.initForm();
  }

  initForm() {
    this.searchDTO = {
      schemeCodeList: [SchemeCode.ORSO, SchemeCode.MACAUCPF, SchemeCode.MACAUPPS],
    };
    this._search = 0;
    this._schemeList.orso = true;
    this._schemeList.macaupps = true;
    this._schemeList.macaucpf = true;
    this.validating();
  }

  selectedTabIndexChange(index: number) {
    this._tabIndex = index;
  }

  validating() {
    if (
      !!this.searchDTO.instructionFileDateFrom ||
      !!this.searchDTO.instructionFileDateTo ||
      !!this.searchDTO.payRefNo ||
      !!this.searchDTO.appRefNo ||
      !!this.searchDTO.payeeName ||
      !!this.searchDTO.isReissue ||
      !!this.searchDTO.transactionIdentifier
    ) {
      this._valid = true;
    } else {
      this._valid = false;
    }
  }

  inputChange(val: string, label: string) {
    switch (label) {
      case 'payRefNo':
        this.searchDTO.payRefNo = val;
        break;
      case 'instructionFileDateFrom':
        this.searchDTO.instructionFileDateFrom = val;
        break;
      case 'instructionFileDateTo':
        this.searchDTO.instructionFileDateTo = val;
        break;
      case 'appRefNo':
        this.searchDTO.appRefNo = val;
        break;
      case 'payeeName':
        this.searchDTO.payeeName = val;
        break;
      case 'transactionIdentifier':
        this.searchDTO.transactionIdentifier = val;
        break;
    }
    this.validating();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 1024) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
}
