/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnConfig } from 'src/app/shared/data-table';

export const I18N_KEY = {
  COMMON_GOT: 'common.gotItWithExclamation',
  INVALID_DATE_FORMAT: 'commission.errorLog.occurDateInvalid',
  INVALID_DATE_REANGE: 'commission.common.dateRangeInvalid',
  ERROR_DATE_REANGE: 'commission.common.dateRangeError',
};

export const COMMISSION_REPORT_COLUMNS: Array<ColumnConfig> = [
  { key: 'reportDate', title: 'commission.reportCenter.reportDate' },
  { key: 'subCategory', title: 'commission.reportCenter.subCategory' },
  { key: 'fileName', title: 'commission.reportCenter.fileName' },
  { key: 'action', title: 'commission.reportCenter.action' },
];

export const SubCategoryOptions: any[] = [
  { label: 'COMM_COMMON', value: 'COMM_COMMON' },
  { label: 'COMM_SCB_RELATED', value: 'COMM_SCB_RELATED' },
  { label: 'COMM_DBS_RELATED', value: 'COMM_DBS_RELATED' },
];
