<div class="row l-pt-4">
  <div class="col-xs-4">
    <cds-dropdown [(ngModel)]="_type" (ngModelChange)="typeChange($event)" [config]="transferTypeConfig"></cds-dropdown>
  </div>
</div>

<div *ngIf="_type === PaymentReportType.STATUS_REPORT" class="row l-pt-7">
  <div class="col-xs-12">
    <cds-tab-group type="box" (selectedTabIndexChange)="selectedTabIndexChange($event)">
      <cds-tab label="Cheque Issuance"></cds-tab>
      <cds-tab label="Bank Transfer"></cds-tab>
    </cds-tab-group>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 l-pt-5">
    <span class="cds-h2-light">{{ 'list.search-record' | lang }}</span>
  </div>
</div>

<ng-container *ngIf="_type === PaymentReportType.RECORD_REPORT">
  <div class="row" *ngIf="form" [formGroup]="form">
    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
      <cds-textfield
        formControlName="payRefNo"
        [(ngModel)]="searchDTO.payRefNo"
        (input)="inputChange(payRefNo.value, 'payRefNo')"
        (compositionend)="inputChange(payRefNo.value, 'payRefNo')"
        #payRefNo
        label="{{ 'report.shkp.payment-ref-no' | lang }}"
        placeholder="{{ 'report.shkp.payment-ref-no-input' | lang }}"></cds-textfield>
      <cds-assistive-text class="l-pt-4" *ngIf="_showError">{{ 'common.noResultFound' | lang }} </cds-assistive-text>
    </div>
    <cds-button
      class="l-pr-2 l-pl-2 l-pt-2"
      [disabled]="!form.valid || !_valid"
      [config]="searchButtonConfig"
      label="{{ 'report.generate-report' | lang }}"
      (click)="searchFn()"></cds-button>
    <cds-button class="l-pr-2 l-pl-2 l-pt-2" [disabled]="!_valid" [config]="resetButtonConfig" label="{{ 'list.reset' | lang }}" (click)="reset()"></cds-button>
  </div>
</ng-container>

<ng-container *ngIf="_type === PaymentReportType.STATUS_REPORT">
  <div class="row" *ngIf="form1" [formGroup]="form1">
    <div class="row col-xs-6 l-plr-0">
      <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12 l-pt-4 l-pb-4 l-plr-0 date-picker-area relative">
        <div class="col-xs-6 date-picker">
          <cds-textfield
            formControlName="dateFrom"
            placeholder="DD/MM/YYYY"
            label="Payment Instruction File Date From"
            [max]="maxDate(searchDTO.dateEnd)"
            [min]="getMinDate()"
            [(ngModel)]="searchDTO.dateFrom"
            (ngModelChange)="validating()"
            [cdsDatepicker]="startPicker">
          </cds-textfield>
          <cds-datepicker #startPicker="cdsDatepicker"></cds-datepicker>

          <cds-assistive-text class="l-pt-3" *ngIf="form.get('dateFrom')?.invalid">{{ 'create.dateError' | lang }} </cds-assistive-text>
        </div>
        <div class="connector">-</div>
        <div class="col-xs-6 date-picker">
          <cds-textfield
            formControlName="dateEnd"
            placeholder="DD/MM/YYYY"
            label="To"
            [min]="minDate(searchDTO.dateFrom)"
            [(ngModel)]="searchDTO.dateEnd"
            (ngModelChange)="validating()"
            [cdsDatepicker]="endPicker">
          </cds-textfield>
          <cds-datepicker #endPicker="cdsDatepicker"></cds-datepicker>
          <cds-assistive-text class="l-pt-3" *ngIf="form.get('dateEnd')?.invalid">{{ 'create.dateError' | lang }} </cds-assistive-text>
        </div>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 l-pt-4 l-pb-4">
        <cds-dropdown
          formControlName="statusFrom"
          [(ngModel)]="searchDTO.statusFrom"
          (ngModelChange)="statusChange($event, 'statusFrom')"
          [config]="statusFromConfig"></cds-dropdown>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 l-pt-4 l-pb-4">
        <cds-dropdown
          formControlName="statusTo"
          [(ngModel)]="searchDTO.statusTo"
          (ngModelChange)="statusChange($event, 'statusTo')"
          [config]="statusToConfig"></cds-dropdown>
      </div>
    </div>
    <div class="row col-xs-6 l-pb-4 l-plr-0 bottom-xs">
      <cds-button
        class="l-pr-2 l-pl-2 l-pt-2"
        [disabled]="!form.valid || !_valid"
        [config]="searchButtonConfig"
        label="{{ 'report.generate-report' | lang }}"
        (click)="searchFn()"></cds-button>
      <cds-button
        class="l-pr-2 l-pl-2 l-pt-2"
        [disabled]="!_valid"
        [config]="resetButtonConfig"
        label="{{ 'list.reset' | lang }}"
        (click)="reset()"></cds-button>
    </div>

    <div class="col-xs-12">
      <cds-assistive-text class="l-pt-4" *ngIf="_showError">{{ 'common.noResultFound' | lang }} </cds-assistive-text>
    </div>
  </div>
</ng-container>

<div class="row l-pt-7">
  <div class="l-mr-6 l-mb-2">
    <div class="col-xs-12 l-pb-2">
      <span class="cds-h2-light">{{ 'list.history-log' | lang }}</span>
    </div>
    <div class="col-xs-12">
      <span class="cds-body1">{{ 'page.total' | lang }} {{ pageConfig.totalCount }} {{ 'page.records' | lang }}</span>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 l-pt-2">
    <app-data-table
      [frontPagination]="false"
      [isLoading]="_progress"
      [pageConfig]="pageConfig"
      [fixed]="false"
      [overlay]="true"
      [dataSource]="dataSource"
      [columnsConfig]="columnsConfig"
      (reloadTable)="reloadTable($event)">
      <ng-template appDtBodyCellDef="createdAt" let-element>
        {{ element['createdAt'] | ssmsDate }}
      </ng-template>
    </app-data-table>
  </div>
</div>

<app-cd-download-progress [show]="_downloading"></app-cd-download-progress>
