/* eslint-disable @typescript-eslint/no-explicit-any */
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { IPageableRequest } from 'src/app/core/models/request';
import { IPageResponse } from 'src/app/core/models/response/response';
import { CdMultidropdownTopping } from 'src/app/shared/cd-multidropdown/cd-multidropdown.model';

export const I18N_KEY = {
  INVALID_DATE: 'common.invalidDate',
  INVALID_DESCRIPTION: 'employer.invalid-description',
  INVALID_DESCRIPTION_CHINESE: 'employer.invalid-description-chinese',
  INVALID_USERNAME: 'employer.invalid-username',
  PROVIDER_DULPLICATED: 'employer.provider-dulplicated',
};
export function initPage() {
  return {
    page: 0,
    size: 10,
  };
}
export function configPrimary(): CdsButtonConfig {
  return { style: 'primary' };
}
export function configSecondary(): CdsButtonConfig {
  return { style: 'secondary' };
}

export interface ChargeBreakpoint {
  breakpoint?: number;
  percentage?: number;
}

export interface ChargeItem {
  chargeCode?: string;
  deductType?: number;
  expChargeLastPolicyYear?: number;
  expChargeCurrentPolicyYear?: number;
  contributionLastAnniversary?: string;
  contYearToDate?: string;
  investAcctFee?: string;
  expensesChargeWaiverAmount?: string;
  breakpointType?: number;
  breakpointList?: ChargeBreakpoint[];
  perLifeCharge?: string;
  min?: string;
  max?: string;
  lifeCountChargeWaiverAmount?: string;
  policyFeeType?: string;
  policyFee?: string;
}
export interface ChargeInfoClass {
  effectiveDate?: string;
  flatRateDate?: string;
  chargeApplicationType?: number;
  chargeList?: ChargeItem[];
}

export interface ChargeInfoRequest extends ErBaseRequest {
  chargeApplicationType?: string;
  effectiveDate?: string;
}

export interface Breakpoint {
  breakPoint?: string;
  val?: string;
}

export interface ChargeInfoSelected {
  label: string;
  value: string;
  dropName: string;
  codeName: string;
  debuctTypeMap: Map<number, string>;
}

export interface InterestRateSearch extends IPageableRequest {
  fundCode?: string;
  year?: string;
}

export interface EmployeeBreakpoint {
  breakpoint?: string;
  percentage?: string;
  flatAmount?: string;
}

export interface FormulaClass {
  formulaClassNo?: string;
  formulaClassName?: string;
  roundSignal?: number;
  noPayLeave?: number;
  erCountType?: string;
  eeContCalc?: number;
  erContCalc?: number;
  eeMaxMthCont?: string;
  erMaxMthCont?: string;
  employeeBreakpointType?: number;
  employeeBreakpointList?: EmployeeBreakpoint[];
  employerBreakpointType?: number;
  employerBreakpointList?: EmployeeBreakpoint[];
}

export enum RateType {
  NORMAL_RATE = 'NORMAL_RATE',
  WITHDRAWAL_RATE = 'WITHDRAWAL_RATE',
  INTERIM_RATE = 'INTERIM_RATE',
}

export enum InterestRateStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
}

export interface FundCodeMapping {
  id?: string;
  code?: string;
  name?: string;
  zhName?: string;
}

export interface InterestRateFundDTO {
  fundCode?: string;
  year?: number;
  month?: number;
}
export interface InterestRateHistory {
  fundId?: string;
  month?: number;
  year?: number;
  type?: RateType;
  value?: string;
  recordSent?: string;
}
export interface InterestRate {
  id?: string;
  fundId?: string;
  month?: number;
  year?: number;
  value?: string;
  status?: InterestRateStatus;
  recordSent?: string;
  normalRate?: string;
  withdrawalRate?: string;
  interimRateOld?: string;
  normalRateOld?: string;
  withdrawalRateOld?: string;
  interimRate?: string;
  code?: string;
  name?: string;
  zhName?: string;
}

export interface Policy {
  lob: string;
  policyNo: string;
  status: string;
  role: string;
}

export interface InvertedFundsInfo {
  fundCode: string;
  fundDescription: string;
  unitBalace: string;
}

export interface PaymentTranscation {
  stmtNo: string;
  date: string;
  receivedDate: string;
  paymentAmount: string;
  remarks: string;
}

export interface Outstand {
  endDate: string;
  fsee: string;
  opt: string;
  fser: string;
  charges: string;
  ttl: string;
}

export interface ContactPersionList {
  enName: string;
  zhName: string;
  post: string;
}

export interface PayrollGroup {
  itName: string;
  groupNo: string;
  show?: boolean;
}

export interface VestingRuleDetail {
  mnthOfServiceFrm?: number;
  mnthOfServiceTo?: number;
  vestRt?: number;
  conRt?: number;
  conAmt?: number;
  vcCapdAmt?: number;
}

export interface EmployerProfile {
  id: string;
  aggregateInvestedAmount: string;
  annualizedContribution: string;
  businessNature: string;
  companyChineseName: string;
  companyName: string;
  companyType: string;
  employerStatus: string;
  lastUpdatedDateOfEmployerStatus: string;
  mpfaReferenceNumber: string;
  orsoRegDate: string;
  registrationNumber: string;
  schemeTerminationEffectiveDate: string;
  subsidNo: string;
  terminationCompletionDate: string;
  totalLives: string;
  trusteeEntityCode: string;
  vipCode: string;
  businessAddress: AddressDTO;
  registeredOfficeAddress: AddressDTO;
}

export interface ERInvestedFundsInformation {
  fundCode?: string;
  fundDescription?: string;
  fundDescriptionCN?: string;
  fundPrice?: string;
  unitCount?: string;
  totalBalance?: string;
}
export interface EmployerProfileDetail {
  id: string;
  companyName: string;
  companyChineseName: string;
  employerStatus: EmployerStatusE;
  totalLives: number;
  score: number;
  relationshipScore?: number;
  employerAccountCode: string;
  originalEmployerCode: string;
  empfId: string;
  mpfaReferenceNumber: string;
  registrationNumber: string;
  subsidNo: string;
  lastUpdatedDateOfEmployerStatus: string;
  businessNature: BusinessNatureE;
  orsoRegDate: string;
  vipCode: string;
  companyType: CompanyTypeE;
  annualizedContribution: string;
  aggregateInvestedAmount: string;
  ivrSig: string;
  unsubscribeSignal: string;
  orsoAppSignal: OrsoAppSignalE;
  schemeTerminationEffectiveDate: string;
  terminationCompletionDate: string;
  commencementDate: string;
  employerStatus2: string;
  lastUpdatedDateOfEmployerStatus2: string;
  certNoType: string;
  enrolmentSubmissionChannel: SubmitChannelE;
  enrolmentReferenceNumber?: string;
  lsFlag: string;
  currency: CurrencyE;
  companyGroupCode: string;
  maxOptPercent: string;
  spclGroupType: string;
  spclGroupSubType: string;
  branchNo: string;
  annualPrem: string;
  dateOfIncorporation: string;
  addition: string;
  registrationType: RegistrationTypeE;
  lsp_spOffsetSequence: string;
  mpfExemptNo: string;
  certificateOfIncorporationNumber: string;
  placeOfIncorporation: string;
  firstDefaultPp: string;
  annualPerm: string;
  languageType: LanguageTypeE;
  communicationType: CommunicationTypeE;
  directMarketingConsentFlag: string;
  comments: CommentHistory;
  sourceOfFund_wealth: string;
  otherSourceOfFund_wealth: string;
  originOfInitialSourceOfWealth: string;
  originOfOnGoingSourceOfFunds_wealth: string;
  investedFundsInformationList: ERInvestedFundsInformation[];
  lineOfBusiness: string;
  accountType: AccountTypeE;
  commissionPoolNumber: string;
  updatedAt: string;
  schemeMergeSignal: SchemeMergeSignalE;
  policyTypeOrPooledRetirementCode: string;
  nameOfORSOScheme: string;
  latestUpdateFromEMPF: string;
  workflowReferenceNo: string;
  //
  // addition: string;
  // aggregateInvestedAmount: string;
  // annualPrem: string;
  // annualPerm: string;
  // annualizedContribution: string;
  // branchNo: string;
  // businessNature: string;
  // certNoType: string;
  // certificateOfIncorporationNumber: string;
  // commencementDate: string;
  // comments: CommentHistory;
  // communicationType: string;
  // companyChineseName: string;
  // companyGroupCode: string;
  // companyName: string;
  // companyType: string;
  // consentToDirectMarketing: string;
  // currency: string;
  // dateOfIncorporation: string;
  // employerEnrolmentSubmissionChannel: string;
  // employerStatus: EmployerStatusE;
  // employerStatus2: string;
  // firstDefaultPp: string;
  // languageTypeId: string;
  // lastUpdatedDateOfEmployerStatus: string;
  // lastUpdatedDateOfEmployerStatus2: string;
  // lsFlag: string;
  // lsp_spOffsetSequence: string;
  // maxOptPercent: string;
  // mpfExemptNo: string;
  // mpfaReferenceNumber: string;
  // originOfInitialSourceOfWealth: string;
  // originOfOnGoingSourceOfFunds_wealth: string;
  // orsoRegDate: string;
  // otherSourceOfFund_wealth: string;
  // placeOfIncorporation: string;
  // registrationNumber: string;
  // registrationType: string;
  // sourceOfFund_wealth: string;
  // spclGroupType: string;
  // subsidNo: string;
  // totalLives: string;
  // trusteeEntityCode: string;
  // vipCode: string;
  // businessAddress: AddressDTO;
  // registeredOfficeAddress: AddressDTO;
  // schemeTerminationEffectiveDate: string;
  // terminationCompletionDate: string;
  // ivrSig: string;
  // unsubscribeSignal: string;
}
export interface AddressDTO {
  addressType: ErAddressTypeE;
  building: string;
  city: string;
  country: string;
  district: string;
  floor: string;
  block: string;
  room: string;
  street: string;
  effectiveDate: string;
  statusCode: 'ACTIVE' | 'INVALID';
  remarks?: string;
  zhAddrFlg?: 'Y' | 'N';
  formatTyp?: 'FREE' | 'STD';
}

export enum ErAddressTypeE {
  REGISTERED = 'REGISTERED',
  RESIDENTIAL = 'RESIDENTIAL',
  BUSINESS = 'BUSINESS',
  CORRESP = 'CORRESP',
  SALES_PROPERTY = 'SALES_PROPERTY',
  OVERSEAS = 'OVERSEAS',
}

export const erAddressTypeMapObj = {
  [ErAddressTypeE.CORRESP]: {
    text: 'Correspondence Address',
    order: 0,
  },
  [ErAddressTypeE.RESIDENTIAL]: {
    text: 'Residential Address',
    order: 1,
  },
  [ErAddressTypeE.REGISTERED]: {
    text: 'Registered Office Address',
    order: 2,
  },
  [ErAddressTypeE.BUSINESS]: {
    text: 'Business Address',
    order: 3,
  },
  [ErAddressTypeE.OVERSEAS]: {
    text: 'Overseas Settlement Address',
    order: 4,
  },
  [ErAddressTypeE.SALES_PROPERTY]: {
    text: 'Address of the Sale of Property',
    order: 5,
  },
};

export const inValidStatusMapObj = {
  INVALID: {
    text: 'Invalid',
    color: '#8E90A2',
  },
  ACTIVE: {
    text: 'Active',
    color: '#00A758',
  },
};

export interface ContactPersion {
  contactPersonType: RelatedPersonTypeE;
  firstName: string;
  lastName?: string;
  chineseFirstName: string;
  chineseLastName?: string;
  jobPosition: string;
  primaryContact?: Contact;
  secondaryContact?: Contact;
  thirdContact?: Contact;
  fourthContact?: Contact;
  fifthContact?: Contact;
  emailList: Email[];
  phoneList: Phone[];
}

export interface Email {
  emailAddress?: string;
  lastUpdate?: number;
  status?: number;
}

export interface Phone {
  countryCode?: string;
  phoneExt?: number;
  phoneNo?: number;
  typeCode: PhoneType;
  status: string;
  lastUpdate: string;
}

export interface Contact {
  emailAddress: string;
  mobileCountryCode: string;
  mobileNumber: string;
  contactCountryCode: string;
  contactNumber: string;
  extCountryCode: string;
  extNumber: string;
  faxCountryCode: string;
  faxNumber: string;
}

export interface BillingClass {
  id: string;
  erAcctCd?: string;
  payrollGroupName: string;
  entityCode?: string;
  payrollGroupId?: string;
  employerStatus?: string;
  registeredOfficeAddress?: AddressDTO;
  lastRegisteredOfficeAddress?: string;
  businessAddress?: AddressDTO;
  lastBusinessAddress?: string;
  lives?: string;
  // billHit?: string;
  // stmtHit?: string;
  // lineOfBusiness?: string;
  // contSig?: string;
  mcn?: string;
  effDate?: string;
  termDate?: string;
  addressList: AddressDTO[];
  contributionFrequency?: ContributionFrequencyE;
}

export enum ContributionFrequencyE {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  SEMI_MONTHLY = 'SEMI_MONTHLY',
  MONTHLY = 'MONTHLY',
  BI_MONTHLY = 'BI_MONTHLY',
  QUARTERLY = 'QUARTERLY',
  SEMI_ANNUALLY = 'SEMI_ANNUALLY',
  ANNUALLY = 'ANNUALLY',
  FORTNIGHTLY = 'FORTNIGHTLY',
}

export const contributionFrequencyMapObj = {
  [ContributionFrequencyE.DAILY]: 'Daily',
  [ContributionFrequencyE.WEEKLY]: 'Weekly',
  [ContributionFrequencyE.SEMI_MONTHLY]: 'Semi-Monthly',
  [ContributionFrequencyE.MONTHLY]: 'Monthly',
  [ContributionFrequencyE.BI_MONTHLY]: 'Bi-Monthly',
  [ContributionFrequencyE.QUARTERLY]: 'Quarterly',
  [ContributionFrequencyE.SEMI_ANNUALLY]: 'Half-Yearly',
  [ContributionFrequencyE.ANNUALLY]: 'Annually',
  [ContributionFrequencyE.FORTNIGHTLY]: 'Fortnightly',
};

export interface CommentHistory {
  customerId?: string;
  comments?: string;
  updatedAt?: string;
  updateDate?: string;
  updateBy?: string;
  createdName?: string;
  updatedName?: string;
}

export interface SchemeInfo {
  annualizedPrem?: string;
  forfeitureHandling?: string;
  contFreq?: string;
  fundCurrency?: string;
  allocationMethod?: string;
  erNewBuFlag?: string;
  effectiveDate?: string;
  fundList?: Fund[];
  vestingScale?: VestingScale;
  contactPersonList: ContactPersion[];
  otherContactPersonList: ContactPersion[];
  activeLives: number;
}

export enum ForfeitureHandlingE {
  REFUND = 'Forfeiture Refund',
  OFFSET_FUTURE_CONTR = 'Accumulated to Forfeiture Account in unit / balance',
  DSTRB_TO_EE = 'Distribution to existing employees',
  REINV = 'Accumulated to Forfeiture Account and reinvest',
}

export enum AllocationMethodE {
  ER_MCVC_BAL = 'In proportion of employer’s mandatory and voluntary contribution balance of each eligible member',
  ER_VC_BAL = 'In proportion of employer’s voluntary contribution balance of each eligible member',
  ALL_BAL = 'In proportion of total account balance of each eligible member',
  EVEN = 'Evenly distributed to all eligible members',
}

export interface Fund {
  fundCode: string;
  fundNm?: string;
  fundDescription?: string;
  fundDescriptionCN?: string;
  commencementDate?: string;
  terminationDate?: string;
  fundUnit?: string;
  totalBalance?: string;
  unitPrice?: string;
  bidPriceDisplay?: string;
  fundSuspension?: string;
  fundUsed?: string;
  latestUpdate?: string;
  fsee_EEMCPercent?: string;
  fser_ERMCPercent?: string;
  optPercent?: string;
  psee_EEVCPercent?: string;
  pser_ERVCPercent?: string;
  bonusFtrPercent?: string;
  show?: boolean;
  fundPercent?: FundPercent;
}

export interface FundPercent {
  [key: string]: number | null;
}

export interface PaymentDetail {
  contributionFrequency?: string;
  firstContributionDate?: string;
  secondContributionDate?: string;
  ddaDefaultPaymentMethodFlag?: string;
  bankHolderName?: string;
  bankCode?: string;
  branchCodeOfBank?: string;
  bankAccountNumber?: string;
  debtorReferenceType?: string;
  debtorReferenceNumber?: string;
  lastUpdateDateOfDdaStatus?: string;
  debitDay?: string;
  paymentMaximumLimit?: string;
  ddaExpiryDate?: string;
  status?: string;
  limitMod?: string;
}

export interface VestingScale {
  calMnthServiceTyp?: CalMnthServiceTypE;
  calBaseUnt?: CalBaseUntE;
}

export enum SubacctTypE {
  ERVC = 'ERVC',
  ERVC2 = 'ERVC2',
  ERVC3 = 'ERVC3',
  ERVC4 = 'ERVC4',
  ERVC5 = 'ERVC5',
  ERVC6 = 'ERVC6',
  ERVC7 = 'ERVC7',
  EEVC = 'EEVC',
  EEVC2 = 'EEVC2',
}

export const subacctTypMapObj = {
  [SubacctTypE.ERVC]: 'Employer voluntary contributions 1',
  [SubacctTypE.ERVC2]: 'Employer voluntary contributions 2',
  [SubacctTypE.ERVC3]: 'Employer voluntary contributions 3',
  [SubacctTypE.ERVC4]: 'Employer voluntary contributions 4',
  [SubacctTypE.ERVC5]: 'Employer voluntary contributions 5',
  [SubacctTypE.ERVC6]: 'Employer voluntary contributions 6',
  [SubacctTypE.ERVC7]: 'Employer voluntary contributions 7',
  [SubacctTypE.EEVC]: 'Employee voluntary contributions 1',
  [SubacctTypE.EEVC2]: 'Employee voluntary contributions 2',
};

export enum CalBaseUntE {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  DAY = 'DAY',
}

export const calBaseUntMapObj = {
  [CalBaseUntE.YEAR]: 'Year',
  [CalBaseUntE.MONTH]: 'Month',
  [CalBaseUntE.DAY]: 'Day',
};

export enum CalMnthServiceTypE {
  EMP = 'EMP',
  DJS = 'DJS',
  MEM_CAT = 'MEM_CAT',
  ADOE = 'ADOE',
  DATE_BY_ER = 'DATE_BY_ER',
  VC_SAME_MC = 'VC_SAME_MC',
}

export const calMnthServiceTypMapObj = {
  [CalMnthServiceTypE.EMP]: 'From the date of employment',
  [CalMnthServiceTypE.DJS]: 'From the date of joining the scheme',
  [CalMnthServiceTypE.MEM_CAT]: 'From the date of joining the category of members',
  [CalMnthServiceTypE.ADOE]: 'From the date after specified period after date of employment',
  [CalMnthServiceTypE.DATE_BY_ER]: 'From the vesting entitlement date specified by ER',
  [CalMnthServiceTypE.VC_SAME_MC]: 'From the same start date as Mandatory Contribution',
};

export const definitionOfServiceTypMapObj = {
  [CalMnthServiceTypE.EMP]: 'From date of employment',
  [CalMnthServiceTypE.DJS]: 'From date join scheme',
  [CalMnthServiceTypE.MEM_CAT]: 'From the date of joining the category of members',
  [CalMnthServiceTypE.ADOE]: 'From the date after specified period after date of employment',
  [CalMnthServiceTypE.DATE_BY_ER]: 'From the date specified by Employer',
  [CalMnthServiceTypE.VC_SAME_MC]: 'From the same start date as Mandatory Contribution',
};

export interface ErBaseRequest {
  id?: string;
  entityCode?: string;
  payrollGroup?: string;
  payrollGroupId?: string;
}

export interface ErSearchRequest extends IPageableRequest {
  employerNo?: string;
  eMpfId?: string;
  employerName?: string;
  lineOfBusiness?: string;
  _totalLiveModel?: number[];
  startTotalLives?: number;
  endTotalLives?: number;
  intermediatoryCode?: string;
  aggregateInvestedAmountCurrency?: string;
  aggregateInvestedAmountFrom?: string | number;
  aggregateInvestedAmountTo?: string | number;
  origErAcctCd?: string;
}

export interface AccountInfoAmountRequest extends IPageableRequest {
  amountType?: string;
  endDate?: string;
  subAcctType?: string;
  erAcctCd?: string;
  id?: string;
  filterType?: string;
  payrollGroupId?: string;
  startDate?: string;
  runDate?: string;
  billRefNo?: string;
}

export interface AbstractExpand {
  expandData?: object;
}

export interface ErRecord extends AbstractExpand {
  id: string;
  employerNo?: string;
  empfId?: string;
  employerName: string;
  employerChineseName: string;
  lineOfBusiness?: string;
  totalLives?: number;
  relationshipScore?: number | null;
  score?: number;
  commissionAgentCode?: string;
  servicingAgentCode?: string;
  parentCompany?: string;
  employerStatus?: string;
  aggregateInvestedAmount?: string | null;
  annualizedContribution?: string | null;
  systemId?: string;
}

export interface searchCompanyGroupRequest {
  employerNo?: string;
  employerName?: string;
  employerChineseName?: string;
  employerId: string;
}

export interface ProfileAgentInfo {
  registrationNumber: string;
  agentName: string;
  agentCode: string;
  branchCode: string;
  mobile: string;
  office: string;
  email: string;
  status: string;
}
export interface ErRecordDetail {
  employerNo: string;
  commencementDate: string;
  rsSubmissionChannel: SubmitChannelE;
  servicingAgent: ProfileAgentInfo;
  commissionAgent: ProfileAgentInfo;
  relatedCompanies: ErRecord[];
  paymentMethod: string;
}

export interface BillPaymentDetail {
  referenceNo: string;
  chequeinBankInDate: string;
  paymentClearedDate: string;
  paidAmount: string;
}

export interface ContributionDetails {
  contributionPeriodStartDate?: string;
  contributionPeriodEndDate?: string;
  billingReferenceNumber: string;
  billReferenceNumber?: string;
  billingStatusType?: string;
  billingStatusCode?: string;
  contributionFrequency: string;
  outstandingAmountOfPayment: number;
  remittanceStatementReceivedDate: string;
  lives: number;
  contLives: number;
  runDate?: string;
  receiptReferenceNumberList?: string[];
  paymentSubmissionReferenceNumberList?: string[];
  paymentWorkflowReferenceNoList?: string[];
  workFlowReferenceNo?: string;
  billContributionAmount?: ContributionAmount;
  reportedMemberContributionAmount?: ContributionAmount;
  paidAmount?: ContributionAmount;
  outstandingPaymentAmount?: ContributionAmount;
  employerAccountCode?: string;
  billReferenceNos?: BillReference[] | EeBillReference[];
  submissionReferenceNo?: string;
  receiptReferenceNo?: string;
  paymentSubmissionReferenceNo?: string;
  vcCdr?: string;
  cdr?: string;
  salaryTypeId?: string;
  incomeOrBasicSalary?: string;
}

export type ContributionDetailsCategory =
  | 'memberContributionAmountForTheBill'
  | 'reportedMemberContributionAmount'
  | 'paidContributionAmount'
  | 'outstandingAmountOfThePayment';

export enum ContributionDetailsCategoryE {
  memberContributionAmountForTheBill = 'account.memberContributionAmountForTheBill',
  reportedMemberContributionAmount = 'account.reportedMemberContributionAmount',
  paidContributionAmount = 'account.paidContributionAmount',
  outstandingAmountOfThePayment = 'account.outstandingAmountOfThePayment',
}

export interface ContributionAmount {
  category?: ContributionDetailsCategory;
  ermc?: number;
  eemc?: number;

  ervc?: number;
  eevc?: number;
  ervc2?: number;
  ervc3?: number;
  ervc4?: number;
  ervc5?: number;
  ervc6?: number;
  ervc7?: number;
  eevc2?: number;
  tvc?: number;
  svc?: number;
  sepmc?: number;
  sepvc?: number;
  surcharge?: number;
  // surchargeMemberAmountList?: MemberAmount[];
  // ermcMemberAmountList?: MemberAmount[];
  // eemcMemberAmountList?: MemberAmount[];
  // eevcMemberAmountList?: MemberAmount[];
  // ervcMemberAmountList?: MemberAmount[];
}

export enum ContributionAmountListMappingE {
  ermc = 'ermcMemberAmountList',
  eemc = 'eemcMemberAmountList',
  surcharge = 'surchargeMemberAmountList',
  ervc = 'ervcMemberAmountList',
  eevc = 'eevcMemberAmountList',
}

export type ContributionAmountType =
  | 'ERMC'
  | 'EEMC'
  | 'SURCHARGE'
  | 'ERVC'
  | 'EEVC'
  | 'ERVC2'
  | 'ERVC3'
  | 'ERVC4'
  | 'ERVC5'
  | 'ERVC6'
  | 'ERVC7'
  | 'EEVC2'
  | 'TVC'
  | 'SVC'
  | 'SEPMC'
  | 'SEPVC'
  | 'ERMCFSER'
  | 'EEMCFSEE'
  | 'ERVCPSER'
  | 'EEVCPSEE';

export enum ContributionAmountTypeE {
  ERMC = 'ERMC',
  EEMC = 'EEMC',
  SURCHARGE = 'SURCHARGE',
  ERVC = 'ERVC',
  EEVC = 'EEVC',
  ERVC2 = 'ERVC2',
  ERVC3 = 'ERVC3',
  ERVC4 = 'ERVC4',
  ERVC5 = 'ERVC5',
  ERVC6 = 'ERVC6',
  ERVC7 = 'ERVC7',
  EEVC2 = 'EEVC2',
  TVC = 'TVC',
  SVC = 'SVC',
  SEPMC = 'SEPMC',
  SEPVC = 'SEPVC',
  ERMCFSER = 'ERMCFSER',
  EEMCFSEE = 'EEMCFSEE',
  ERVCPSER = 'ERVCPSER',
  EEVCPSEE = 'EEVCPSEE',
}

export interface MemberAmount {
  memberCode: string;
  memberName?: string;
  amount?: number;
  chineseFirstName?: string;
  chineseLastName?: string;
  firstName?: string;
  lastName?: string;
  statusOfDefaultContribution?: string;
  rectifiedType?: string;
}

export interface SuspenseRecord {
  id?: string;
  paymentSubmissionReferenceNo?: string;
  paymentReferenceNo?: string;
  paymentReceivedDate?: string;
  suspenseAmount?: number;
  avaliableAmount?: number;
  taggedAmount?: number;
  paymentMethod?: string;
  // chequeNo: string;
}

export interface RelevantIncome {
  id: string;
  frequencyTypeId: string;
  income: string;
  contributionType: string;
  effectiveDate: string;
}

export interface Surcharge {
  surchargeEr: string;
  surchargeEe: string;
  paidDate: string;
  dudate: string;
}

export interface AgentInfo {
  servicingAgent: any;
  commissionAgent: AgentInfoDetail[];
  delegatedAgent: any;
  showCommission?: boolean;
  showDelegated?: boolean;
}

export interface AgentInfoDetail {
  registrationNumber?: string;
  agentName?: string;
  agentCode?: string;
  branchCode?: string;
  mobile?: string;
  office?: string;
  email?: string;
  status?: AgentStatusE;
  delegationDate?: string;
  agentNameChi?: string;
  show?: boolean;
}

export interface PaymentNotice {
  contributionPeriodStartDate?: string;
  contributionPeriodEndDate?: string;
  erProfilePaymentNoticeListResponses?: ErProfilePaymentNoticeListResponses[];
}

export interface PaymentNoticeRes {
  list?: PaymentNotice[];
}

export interface ErProfilePaymentNoticeListResponses {
  typeOfNotice: string;
  paymentNoticeReceivedDate: string;
  noticeReferenceNumber: string;
  noticeIssueDate: string;
  firstPaymentDueDate: string;
  secondPaymentDueDate: string;
  appealSubmissionDate: string;
  appealResultStatus: string;
  dateOfAppealResult: string;
  mpfaObjectionCaseNumber: string;
  appealResultText: string;
}

export interface PaymentNoticeTableItem {
  typeOfNotice: string;
  paymentNoticeReceivedDate: string;
  noticeReferenceNumber: string;
  noticeIssueDate: string;
  firstPaymentDueDate: string;
  secondPaymentDueDate: string;
}

export interface AppealDetails {
  appealSubmissionDate: string;
  appealResultStatus: string;
  dateOfAppealResult: string;
  mpfaObjectionCaseNumber: string;
  appealResultText: string;
}

export interface PaymentNoticeRequest {
  startDate?: string;
  id?: string;
  erAcctCd?: string;
  type: 'month' | 'day';
  conMnth?: string;
}

export interface OutstandingAmoutOfPayment {
  trusteeMemberName: string;
  trusteeMemberNumber: string;
  outstandingAmountOfPayment: string;
}

export interface SpecialQuoteRequest extends IPageableRequest {
  sqSubmissionDateMonth?: number;
  sqSubmissionDateYear?: number;
  employerAccountCode?: string;
  employerName?: string;
  sqReferenceNo?: string;
  sqSubmissionDateFrom?: string;
  sqSubmissionDateTo?: string;
}

export interface SpecialQuote {
  id?: string;
  sqReferenceNo?: string;
  quoteType?: QuoteTypeE;
  sqSubmissionDate?: string;
  customerIds?: string[];
  customerId?: string;
  inEdit?: boolean;
  companyList?: CdMultidropdownTopping[];
  remarks?: string;
  showRemarks?: boolean;
  showAllRemarks?: boolean;
  showAllButton?: boolean;
  version?: number;
  quoteStatus?: QuoteStatusE;
  employerAccountCode?: string;
  employerName?: string;
  employerChineseName?: string;
  updatedAt?: string;
  lastStatusUpdatedDate?: string;
}

export enum QuoteTypeE {
  ADD_NB = 'ADD_NB',
  CGF_GUARANTEE_RATE = 'CGF_GUARANTEE_RATE',
  COMBINE_EXISTING = 'COMBINE_EXISTING',
  GROUP_SPLIT = 'GROUP_SPLIT',
  INJECTION = 'INJECTION',
  INTRA_GROUP_TRANSFER = 'INTRA_GROUP_TRANSFER',
  REQUOTE_BU = 'REQUOTE_BU',
  REQUOTE_TRF_PC_COMM = 'REQUOTE_TRF_PC_COMM',
  RETENTION = 'RETENTION',
  WAIVE_MVA = 'WAIVE_MVA',
  OTHERS = 'OTHERS',
}

export const SpecialQuoteTypeOptions = [
  { label: 'Add NB', value: QuoteTypeE.ADD_NB },
  { label: 'CGF Guarantee Rate', value: QuoteTypeE.CGF_GUARANTEE_RATE },
  { label: 'Combine Existing', value: QuoteTypeE.COMBINE_EXISTING },
  { label: 'Group Split', value: QuoteTypeE.GROUP_SPLIT },
  { label: 'Injection', value: QuoteTypeE.INJECTION },
  { label: 'Intra-Group Transfer', value: QuoteTypeE.INTRA_GROUP_TRANSFER },
  { label: 'Requote BU', value: QuoteTypeE.REQUOTE_BU },
  { label: 'Requote Trf PC & Comm', value: QuoteTypeE.REQUOTE_TRF_PC_COMM },
  { label: 'Retention', value: QuoteTypeE.RETENTION },
  { label: 'Waive MVA', value: QuoteTypeE.WAIVE_MVA },
  { label: 'Others', value: QuoteTypeE.OTHERS },
];

export enum QuoteStatusE {
  SQ_OFFER_PENDING_FOR_FINANCE_REPLY = 'SQ_OFFER_PENDING_FOR_FINANCE_REPLY',
  SQ_OFFER_PENDING_FOR_CR__AGENT_REPLY = 'SQ_OFFER_PENDING_FOR_CR__AGENT_REPLY',
  SQ_OFFER_REJECTED_BY_CR__AGENT__CLIENT = 'SQ_OFFER_REJECTED_BY_CR__AGENT__CLIENT',
  CALCULATOR_PENDING_FOR_FINANCE_REPLY = 'CALCULATOR_PENDING_FOR_FINANCE_REPLY',
  CALCULATOR_PENDING_FOR_CR__AGENT_REPLY = 'CALCULATOR_PENDING_FOR_CR__AGENT_REPLY',
  OFFER_LETTER_PENDING_FOR_CR_MANAGER_REVIEW = 'OFFER_LETTER_PENDING_FOR_CR_MANAGER_REVIEW',
  OFFER_LETTER_SENT_TO_CLIENT_COMPLETED = 'OFFER_LETTER_SENT_TO_CLIENT_COMPLETED',
}

export const SpecialQuoteStatusOptions = [
  { label: 'SQ Offer – Pending for Finance Reply', value: QuoteStatusE.SQ_OFFER_PENDING_FOR_FINANCE_REPLY },
  { label: 'SQ Offer – Pending for CR/ Agent reply', value: QuoteStatusE.SQ_OFFER_PENDING_FOR_CR__AGENT_REPLY },
  { label: 'SQ Offer – Rejected by CR/ Agent/ Client', value: QuoteStatusE.SQ_OFFER_REJECTED_BY_CR__AGENT__CLIENT },
  { label: 'Calculator – Pending for Finance reply', value: QuoteStatusE.CALCULATOR_PENDING_FOR_FINANCE_REPLY },
  { label: 'Calculator – Pending for CR/ Agent reply', value: QuoteStatusE.CALCULATOR_PENDING_FOR_CR__AGENT_REPLY },
  { label: 'Offer letter – Pending for CR Manager review', value: QuoteStatusE.OFFER_LETTER_PENDING_FOR_CR_MANAGER_REVIEW },
  { label: 'Offer letter – Sent to Client (Completed)', value: QuoteStatusE.OFFER_LETTER_SENT_TO_CLIENT_COMPLETED },
];

export enum RelatedPersonTypeE {
  CLAIMANT = 'Claimant',
  ORO = 'ORO Contact',
  TIB = 'TIB Conact',
  PRIMARY_CONTACT = 'Primary Contact Person',
  SECOND_CONTACT = 'Second Contact Person',
  AP = 'Authorized Person',
  DIRECTOR = 'Director',
  BENE_OWNER = 'Beneficial Owner',
  PARTNER = 'Partner',
  SCHEME_TRUSTEE = 'Scheme Trustee',
  SOLE_PROPRIETORSHIP = 'Sole Proprietorship',
  CONTROLLING_PERSON = 'Controlling Person',
  LIQUIDATOR = 'Liquidator',
  OTHER = 'Other contact person',
  PRIMARY_TIB = 'primary TIB',
  PRIMARY_ORO = 'primary oro',
  SECOND_TIB = 'second TIB',
  SECOND_ORO = 'second oro',
  THIRD_PAYOR = 'Third Party Payor',
}

export enum PhoneTypeE {
  PHONE = 'Telephone',
  MOBILE = 'Mobile',
  MOBILE2 = '2nd Mobile',
  TEL_OVERSEAS = 'Claimant Overseas Telephone',
  FAX_OVERSEAS = 'Claimant Overseas Fax no',
  FAX = 'Fax',
  PHONE2 = 'PHONE2',
  PHONE3 = 'PHONE3',
  PHONE4 = 'PHONE4',
}

export type PhoneType = 'PHONE' | 'MOBILE' | 'MOBILE2' | 'TEL_OVERSEAS' | 'FAX_OVERSEAS' | 'FAX' | 'PHONE2' | 'PHONE3' | 'PHONE4';

export interface ContactPersonView {
  email?: Email[];
  PHONE?: Phone[];
  PHONE2?: Phone[];
  PHONE3?: Phone[];
  PHONE4?: Phone[];
  MOBILE?: Phone[];
  MOBILE2?: Phone[];
  TEL_OVERSEAS?: Phone[];
  FAX_OVERSEAS?: Phone[];
  FAX?: Phone[];
}

export enum ContributionSortByE {
  All = 'All',
  DC_REPORTED = 'DC_REPORTED',
  DC_NOT_REPORTED = 'DC_NOT_REPORTED',
  DC_GENERATED = 'DC_GENERATED',
  Reported_Contribution_0 = 'Reported_Contribution_0',
  Fully_Settled = 'Fully_Settled',
  VC_Outstanding_Only = 'VC_Outstanding_Only',
}

export interface BillReference {
  // id?: string;
  // runDate?: string;
  // billStatusType?: string;
  // remittanceStatementReceivedDate?: string;
  // paymentReferenceNo?: string;
  // paymentSubmissionReferenceNo?: string;
  // memberContributionAmountForTheBill?: ContributionAmount;
  // reportedMemberContributionAmount?: ContributionAmount;
  // paidContributionAmount?: ContributionAmount;
  // outstandingAmountOfThePayment?: ContributionAmount;
  billReferenceNumber: string;
  list: ContributionDetails[];
}

export interface EeBillReference {
  billReferenceNo: string;
  runDateInfos: ContributionDetails[];
}

export interface MemberLevel {
  memberAccountCode?: string;
  memberName?: string;
  totalPaidContribution?: number;
  paymentReceivedDate?: string;
  chineseFirstName?: string;
  chineseLastName?: string;
  id?: string;
  legalId?: string;
  legalIdType?: string;
}

// export interface MultiServiceProvider {
//   providerName: string;
// }

export interface AccountBalance {
  forfeitureAccount?: number;
  reservedAccount?: number;
  suspenseRecord?: number;
  // frontend
  accountBalance?: number;
  records?: IPageResponse<SuspenseRecord>;
}

export type SuspenseRecordSearchRequestKey = 'id' | 'paymentSubmissionReferenceNo' | 'paymentReferenceNo' | 'paymentReceivedDate';

export interface SuspenseRecordSearchRequest extends IPageableRequest {
  receiptReferenceNo?: string;
  paymentSubmissionReferenceNo?: string;
  paymentReceivedDate?: string;
  paymentMethod?: string;
  sort?: string;
  id?: string;
  payrollGroupId?: string;
}

export interface ColumnSort {
  key: string;
  value: string;
}

export type PaymentRecordSearchRequestKey =
  | 'paymentSubmissionReferenceNo'
  | 'paymentReferenceNo'
  | 'contributionStartDate'
  | 'contributionEndDate'
  | 'billReferenceNo';

export interface PaymentRecordSearchRequest extends IPageableRequest {
  paymentSubmissionReferenceNo?: string;
  receiptReferenceNo?: string;
  contributionPeriodFrom?: string;
  contributionPeriodTo?: string;
  billReferenceNo?: string;
  id?: string;
  payrollGroupId?: string;
}

export interface PaymentRecord {
  paymentSubmissionReferenceNo?: string;
  receiptReferenceNo?: string;
  billReferenceNo?: string;
  contributionPeriod?: string;
  contributionPeriodFrom?: string;
  contributionPeriodTo?: string;
  statusCode?: number;
  paymentMethod?: string;
  company?: string;
  chequeBankInDate?: string;
  chequeDate?: string;
  paymentClearedDate?: string;
  suspenseId?: string;
}

export interface PaymentSubmission {
  employerAccountCode?: string;
  paymentSubmissionReferenceNo?: string;
  companyEnName?: string;
  companyZhName?: string;
  suspenseId?: string;
  workflowReferenceNo?: string;
  workflowReferenceNoList?: string[];
  otherTransactionReferenceNo?: string;
  otherTransactionReferenceNoList?: string[];
}

export interface WorkflowDTO {
  memberAccountCode?: string;
  memberFirstName?: string;
  memberLastName?: string;
  memberFirstZhName?: string;
  memberLastZhName?: string;
  memberEmpfId?: string;
  workflowReferenceNo?: string;
  statusCode?: string;
  type?: string;
  createDate?: string;
  followupRejectDate?: string;
  followupRejectReason?: string;
}

export interface BankInformation {
  bankCode?: string;
  branchCode?: string;
  bankAccountNo?: string;
  paymentMethod?: string;
  chequeNo?: string;
  chequeBankInDate?: string;
  paymentClearedDate?: string;
  paymentReceivedDate?: string;
  receivedAmount?: number;
  payerName?: string;
  chequeDirectMailingToeMpfFlag?: string;
  trusteeBankCode?: string;
  trusteeBranchCode?: string;
  trusteeBankAccountNo?: string;
}

export interface PaymentRecordsDetailPaymentInfo {
  receiptReferenceNo?: string;
  status?: ReiceiptRefNoStatusE;
}

export enum ReiceiptRefNoStatusE {
  CLEARED = 'CLEARED',
  REJECT = 'REJECT',
  RECEIVE = 'RECEIVE',
  PENDING = 'PENDING',
  UNIDENTIFIED = 'UNIDENTIFIED',
  TAGGED = 'TAGGED',
}

export const reiceiptRefNoStatusMapObj = {
  [ReiceiptRefNoStatusE.CLEARED]: {
    text: 'CLEARED',
    color: '#00A758',
  },
  [ReiceiptRefNoStatusE.REJECT]: {
    text: 'REJECT',
    color: '#8E90A2',
  },
  [ReiceiptRefNoStatusE.PENDING]: {
    text: 'PENDING',
    color: '#F49600',
  },
  [ReiceiptRefNoStatusE.RECEIVE]: {
    text: 'RECEIVE',
    color: '#6F5693',
  },
  [ReiceiptRefNoStatusE.UNIDENTIFIED]: {
    text: 'UNIDENTIFIED',
    color: '#28D7CB',
  },
  [ReiceiptRefNoStatusE.TAGGED]: {
    text: 'TAGGED',
    color: '#EC6453',
  },
};

export interface HandledBy {
  relationshipManager: string;
  relationshipTeamManger: string;
  cso: string;
  csoTeamManager: string;
  mspArrangement: string;
  arrangementDescription: string;
  optionFrequency: string;
  optFrequencyDescription: string;
  lastOptDate: string;
}

export type PaymentMemberLevelRequestKey = 'memberAccountCode' | 'memberName' | 'countributionDate';

export interface PaymentMemberLevelRequest extends IPageableRequest {
  memberAccountCode?: string;
  memberName?: string;
  // frontend only
  countributionDate?: string;
  // frontend only
  contributionPeriod?: string;
  contributionPeriodFrom?: string;
  contributionPeriodTo?: string;
  payrollGroupId?: string;
  receiptReferenceNo?: string;
  id?: string;
  subRefNo?: string;
}

export interface PaymentMemberLevel {
  memberAccountCode?: string;
  firstName?: string;
  lastName?: string;
  firsZhtName?: string;
  lastZhName?: string;
  contributionStart?: string;
  contributionEnd?: string;
  receivedAmount?: number;
}

export interface PaymentContribution {
  contributionStart?: string;
  contributionEnd?: string;
}

export enum ActivityTypeE {
  ALL = '',
  EMPLOYER = 'ER',
  EMPLOYEE = 'EE',
  SPECIAL = 'SP',
}

export const activityTypeptions: CdsOption[] = [
  {
    label: 'Employer',
    value: ActivityTypeE.EMPLOYER,
  },
  {
    label: 'Employee',
    value: ActivityTypeE.EMPLOYEE,
  },
  {
    label: 'Special',
    value: ActivityTypeE.SPECIAL,
  },
];

export const activityTypeAllOptions: CdsOption[] = [
  {
    label: 'All',
    value: ActivityTypeE.ALL,
  },
  ...activityTypeptions,
];

export type ActivitySearchRequestKey = 'activityCode' | 'description' | 'createStartDate' | 'createEndDate' | 'score' | 'activityType';

export interface ActivitySearchRequest extends IPageableRequest {
  activityCode?: string;
  description?: string;
  createStartDate?: string;
  createEndDate?: string;
  fromDate?: string;
  toDate?: string;
  score?: string;
  activityType?: ActivityTypeE;
  employerId?: string;
}

export interface Activity {
  id?: string;
  activityCode?: string;
  description?: string;
  score?: number;
  createdAt?: string;
  activityType?: ActivityTypeE | '';
  completionDate?: string;
  inEdit?: boolean;
  version?: number;
  employerId?: string;
  activityId?: string;
}

export interface SpecialManagement {
  id?: string;
  multiNationalClient: boolean;
  multiRelationshipManager: string;
  multiRelationshipTeamManager: string;
  providerNames?: MultiServiceProvider[];
  providers?: MultiServiceProvidersE[];
  relationshipManager: string;
  relationshipTeamManager: string;
  cso: string;
  csoTeamManager: string;
  mspArrangement: string;
  arrangementDescription: string;
  memberOptionFrequency: string;
  optFrequencyDescription: string;
  lastOptDate: string;
  version?: string;
}

export interface MultiServiceProvider {
  id?: string;
  arrangementId?: string;
  provider: MultiServiceProvidersE;
}

export enum MultiServiceProvidersE {
  AIA_COMPANY_TRUSTEE_LIMITED = 'AIA_COMPANY_TRUSTEE_LIMITED',
  BANK_OF_COMMUNICATIONS_TRUSTEE_LIMITED = 'BANK_OF_COMMUNICATIONS_TRUSTEE_LIMITED',
  BANK_CONSORTIUM_TRUST_COMPANY_LIMITED = 'BANK_CONSORTIUM_TRUST_COMPANY_LIMITED',
  BANK_OF_EAST_ASIA_TRUSTEES_LIMITED = 'BANK_OF_EAST_ASIA_TRUSTEES_LIMITED',
  BOCI_PRUDENTIAL_TRUSTEE_LIMITED = 'BOCI_PRUDENTIAL_TRUSTEE_LIMITED',
  CHINA_LIFE_TRUSTEES_LIMITED = 'CHINA_LIFE_TRUSTEES_LIMITED',
  HSBC_PROVIDENT_FUND_TRUSTEE_HONG_KONG_LIMITED = 'HSBC_PROVIDENT_FUND_TRUSTEE_HONG_KONG_LIMITED',
  MANULIFE_PROVIDENT_FUNDS_TRUST_COMPANY_LIMITED = 'MANULIFE_PROVIDENT_FUNDS_TRUST_COMPANY_LIMITED',
  PRINCIPAL_TRUST_COMPANY_ASIA_LIMITED = 'PRINCIPAL_TRUST_COMPANY_ASIA_LIMITED',
  RBC_INVESTOR_SERVICES_TRUST_HONG_KONG_LIMITED = 'RBC_INVESTOR_SERVICES_TRUST_HONG_KONG_LIMITED',
  SUN_LIFE_PENSION_TRUST_LIMITED = 'SUN_LIFE_PENSION_TRUST_LIMITED',
  SUN_LIFE_TRUSTEE_COMPANY_LIMITED = 'SUN_LIFE_TRUSTEE_COMPANY_LIMITED',
  YF_LIFE_TRUSTEES_LIMITED = 'YF_LIFE_TRUSTEES_LIMITED',
  INFORMATION_NOT_AVAILABLE = 'INFORMATION_NOT_AVAILABLE',
}

export enum MspArrangementE {
  BOTH_CONT_AND_ASSETS_AT_MIL = 'BOTH_CONT_AND_ASSETS_AT_MIL',
  CONT_AT_MIL_BUT_ASSET_AT_OTHER_PROVIDER = 'CONT_AT_MIL_BUT_ASSET_AT_OTHER_PROVIDER',
  BOTH_CONT_AND_ASSET_TO_OTHER_PROVIDER = 'BOTH_CONT_AND_ASSET_TO_OTHER_PROVIDER',
  CONT_TO_OTHER_PROVIDER_BUT_ASSET_AT_MIL = 'CONT_TO_OTHER_PROVIDER_BUT_ASSET_AT_MIL',
  OTHERS = 'OTHERS',
  INFORMATION_NOT_AVAILABLE = 'INFORMATION_NOT_AVAILABLE',
}

export enum MemberOptionFrequencyE {
  ONE_OFF_AT_INCEPTION = 'ONE_OFF_AT_INCEPTION',
  ANNUAL = 'ANNUAL',
  SEMI_ANNUAL = 'SEMI_ANNUAL',
  OTHERS_PLEASE_SPECIFY = 'OTHERS_PLEASE_SPECIFY',
  INFORMATION_NOT_AVAILABLE = 'INFORMATION_NOT_AVAILABLE',
}

export interface RelationshipTotalCount {
  totalRelationshipScore?: string;
  totalAggregateInvestedAmount?: string;
  totalAnnualizedContribution?: string;
}

export enum AgentStatusE {
  ACTIVE = 'ACTIVE',
  TERMINATE = 'TERMINATE',
  TRANSFER = 'TRANSFER',
  RETIRED = 'RETIRED',
  DECEASED = 'DECEASED',
  DISABLE = 'DISABLE',
  LOSS = 'LOSS',
  DUMMY = 'DUMMY',
}

export const agentStatusMapObj = {
  [AgentStatusE.ACTIVE]: {
    text: 'Active',
    color: '#07A455',
  },
  [AgentStatusE.TERMINATE]: {
    text: 'Terminated',
    color: '#8E91A0',
  },
  [AgentStatusE.TRANSFER]: {
    text: 'Transfer',
    color: '#22DBC9',
  },
  [AgentStatusE.RETIRED]: {
    text: 'Retired',
    color: '#6F5690',
  },
  [AgentStatusE.DECEASED]: {
    text: 'Deceased',
    color: '#0004BE',
  },
  [AgentStatusE.DISABLE]: {
    text: 'Disable',
    color: '#F99500',
  },
  [AgentStatusE.LOSS]: {
    text: 'Loss',
    color: '#C5423A',
  },
  [AgentStatusE.DUMMY]: {
    text: 'Dummy',
    color: '#60D48B',
  },
};

export enum EmployerStatusE {
  RECEIVE = 'RECEIVE',
  FOLLOW_UP_IN_PRGRESS = 'FOLLOW_UP_IN_PRGRESS',
  ACTIVE = 'ACTIVE',
  TERMG = 'TERMG',
  TERMD = 'TERMD',
  TR_REJECT = 'TR_REJECT',
  CANCEL = 'CANCEL',
  RETAIN = 'RETAIN',
  TEMP = 'TEMP',
  PROVISIONAL = 'PROVISIONAL',
}

export const employerStatusMapObj = {
  [EmployerStatusE.RECEIVE]: {
    text: 'Received and pending enrolment',
    color: '#28D7CB',
  },
  [EmployerStatusE.FOLLOW_UP_IN_PRGRESS]: {
    text: 'In progress, Unclean and need follow up action',
    color: '#F49600',
  },
  [EmployerStatusE.ACTIVE]: {
    text: 'Active',
    color: '#00A758',
  },
  [EmployerStatusE.TERMG]: {
    text: 'Terminating',
    color: '#6F5693',
  },
  [EmployerStatusE.TERMD]: {
    text: 'Terminated',
    color: '#D03A39',
  },
  [EmployerStatusE.TR_REJECT]: {
    text: 'Rejected by Trustee',
    color: '#8E90A2',
  },
  [EmployerStatusE.CANCEL]: {
    text: 'Cancelled by client',
    color: '#EC6453',
  },
  [EmployerStatusE.RETAIN]: {
    text: 'Retain ESS Member',
    color: '#8E90A2',
  },
  [EmployerStatusE.TEMP]: {
    text: 'Temporary after AEOI',
    color: '#8E90A2',
  },
  [EmployerStatusE.PROVISIONAL]: {
    text: 'Provisional with invested monies before AEOI',
    color: '#8E90A2',
  },
};

export enum EnrolmentStatusE {
  PENDING = 'Pending',
  COMPELETED = 'Completed',
}

export const enrolmentStatusMapObj = {
  [EnrolmentStatusE.PENDING]: {
    text: 'Pending',
    color: '#F49600',
  },
  [EnrolmentStatusE.COMPELETED]: {
    text: 'Completed',
    color: '#00A758',
  },
};

export enum AccountTypeE {
  ER = 'ER',
  SEP = 'SEP',
  TVC = 'TVC',
  SVC = 'SVC',
  PA = 'PA',
}

export const accountTypeMapObj = {
  [AccountTypeE.ER]: 'MPF-ER',
  [AccountTypeE.SEP]: 'Self-Employed Person',
  [AccountTypeE.TVC]: 'Tax Deductible Voluntary',
  [AccountTypeE.SVC]: 'Special Voluntary Contribution',
  [AccountTypeE.PA]: 'Personal Account Holder',
};

export enum ErAccountTypeE {
  MPF = 'MPF',
  ORSO = 'ORSO',
  Macau = 'Macau',
}

export const erAccountTypeMapObj = {
  [ErAccountTypeE.MPF]: 'MPF-ER',
  [ErAccountTypeE.ORSO]: 'ORSO-ER',
  [ErAccountTypeE.Macau]: 'Macau-ER',
};

export const erAccountTypeMap = {
  [AccountTypeE.ER]: 'Employer',
  [AccountTypeE.SEP]: 'Self-Employed Person',
  [AccountTypeE.TVC]: 'Tax Deductible Voluntary Contribution',
  [AccountTypeE.SVC]: 'Special Voluntary Contribution',
  [AccountTypeE.PA]: 'Personal Account Holder',
};

export enum RegistrationTypeE {
  BR = 'BR',
  EDU = 'EDU',
  IRD = 'IRD',
  TU = 'TU',
  SO = 'SO',
  OTHERS = 'OTHERS',
}

export const registrationTypeMapObj = {
  [RegistrationTypeE.BR]: 'Business Registration',
  [RegistrationTypeE.EDU]: 'Education Institution',
  [RegistrationTypeE.IRD]: 'Inland Revenue Department (Charitable Organisations)',
  [RegistrationTypeE.TU]: 'Registry of Trade Unions',
  [RegistrationTypeE.SO]: 'Societies Office of Hong Kong Police',
  [RegistrationTypeE.OTHERS]: 'Others',
};

export enum CompanyTypeE {
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',
  PARTNERSHIP = 'PARTNERSHIP',
  PARTNERSHIP_LTD = 'PARTNERSHIP_LTD',
  PUBLIC_LTD = 'PUBLIC_LTD',
  REP_OFFICE = 'REP_OFFICE',
  PRIVATE_LTD = 'PRIVATE_LTD',
  TRADE_UNION = 'TRADE_UNION',
  EDU_INST = 'EDU_INST',
  CHAR_ORG = 'CHAR_ORG',
  NP_ORG = 'NP_ORG',
  LIMITED = 'LIMITED',
  COOP = 'COOP',
  GVN_AGN = 'GVN_AGN',
  OTHERS = 'OTHERS',
}

export const companyTypeMapObj = {
  [CompanyTypeE.SOLE_PROPRIETORSHIP]: 'Sole Proprietorship',
  [CompanyTypeE.PARTNERSHIP]: 'Partnership',
  [CompanyTypeE.PARTNERSHIP_LTD]: 'Limited Partnership',
  [CompanyTypeE.PUBLIC_LTD]: 'Public Limited Company',
  [CompanyTypeE.REP_OFFICE]: 'Representative Office / Foreign Company Office',
  [CompanyTypeE.PRIVATE_LTD]: 'Private Limited Company',
  [CompanyTypeE.TRADE_UNION]: 'Trade Union',
  [CompanyTypeE.EDU_INST]: 'Educational Institution',
  [CompanyTypeE.CHAR_ORG]: 'Charitable Organization',
  [CompanyTypeE.NP_ORG]: 'Non-Profit Organization',
  [CompanyTypeE.LIMITED]: 'Limited Company',
  [CompanyTypeE.COOP]: 'Cooperative Societies',
  [CompanyTypeE.GVN_AGN]: 'Government Agency',
  [CompanyTypeE.OTHERS]: 'Other Company Type',
};

export enum BusinessNatureE {
  CATR = 'CATR',
  CONS = 'CONS',
  MANF = 'MANF',
  FINA = 'FINA',
  COMM = 'COMM',
  WRIE = 'WRIE',
  TRAN = 'TRAN',
  CLEN = 'CLEN',
  SECU = 'SECU',
  HAIR = 'HAIR',
  OTHR = 'OTHR',
  UNKN = 'UNKN',
  COMN = 'COMN',
  EDU = 'EDU',
  HOTEL = 'HOTEL',
  PERSONAL = 'PERSONAL',
  JEWELLERY = 'JEWELLERY',
  PHARMACEUTE = 'PHARMACEUTE',
  ESTATE = 'ESTATE',
  SALES = 'SALES',
  TEXTILE = 'TEXTILE',
}

export const businessNatureMapObj = {
  [BusinessNatureE.CATR]: 'Catering',
  [BusinessNatureE.CONS]: 'Construction',
  [BusinessNatureE.MANF]: 'Manufacturing',
  [BusinessNatureE.FINA]: 'Financing/Insurance/Real Estate/Business Services',
  [BusinessNatureE.COMM]: 'Community/Social/Personal Services',
  [BusinessNatureE.WRIE]: 'Wholesale/Retail/Import and Export Trades',
  [BusinessNatureE.TRAN]: 'Transport',
  [BusinessNatureE.CLEN]: 'Cleaning',
  [BusinessNatureE.SECU]: 'Security Guard',
  [BusinessNatureE.HAIR]: 'Hairdressing and Beauty',
  [BusinessNatureE.OTHR]: 'Others',
  [BusinessNatureE.UNKN]: 'Unknown',
  [BusinessNatureE.COMN]: 'Communication',
  [BusinessNatureE.EDU]: 'Unknown',
  [BusinessNatureE.HOTEL]: 'Hotel/Boarding houses',
  [BusinessNatureE.PERSONAL]: 'Personal/Household services',
  [BusinessNatureE.JEWELLERY]: 'Jewellery/Precious metals/Art dealers',
  [BusinessNatureE.PHARMACEUTE]: 'Pharmaceutical industry',
  [BusinessNatureE.ESTATE]: 'Real estate',
  [BusinessNatureE.SALES]: 'Sales/Rental of vehicles & equipment',
  [BusinessNatureE.TEXTILE]: 'Textile business',
};

export enum OrsoAppSignalE {
  F = 'F',
  T = 'T',
  N = 'N',
  Y = 'Y',
  E = 'E',
  NA = 'NA',
}

export const orsoAppSignalMapObj = {
  [OrsoAppSignalE.F]: 'F-ORSO EXEMPT',
  [OrsoAppSignalE.T]: 'T-TRUSTED ORSO',
  [OrsoAppSignalE.N]: 'N-NON-TRUSTED',
  [OrsoAppSignalE.Y]: 'Y-MPF EXEMPT',
  [OrsoAppSignalE.E]: 'E-MPFORSO EXEM',
  [OrsoAppSignalE.NA]: 'N/A',
};

export enum LanguageTypeE {
  EN = 'EN',
  ZH_HK = 'ZH-HK',
  ZH_CN = 'ZH-CN',
  BI = 'BI',
}

export const languageTypeMapObj = {
  [LanguageTypeE.EN]: 'English',
  [LanguageTypeE.ZH_HK]: 'Traditional Chinese',
  [LanguageTypeE.ZH_CN]: 'Simplified Chinese',
  [LanguageTypeE.BI]: 'Bilingual',
};

export enum CommunicationTypeE {
  EMAIL = 'EMAIL',
  PAPER = 'PAPER',
  SMS = 'SMS',
  APP_PUSH = 'APP_PUSH',
}

export const communicationTypeMapObj = {
  [CommunicationTypeE.EMAIL]: 'Email',
  [CommunicationTypeE.PAPER]: 'Paper',
  [CommunicationTypeE.SMS]: 'SMS',
  [CommunicationTypeE.APP_PUSH]: 'App Push',
};

export enum SubmitChannelE {
  WEB_PORTAL = 'WEB_PORTAL',
  MOBILE_APP = 'MOBILE_APP',
  PAPER_FORM = 'PAPER_FORM',
  THIRD_PARTY_PORTAL = 'THIRD_PARTY_PORTAL',
  BATCH = 'BATCH',
  KIOSK = 'KIOSK',
  CONTACT_CENTER = 'CONTACT_CENTER',
  MPFA_PORTAL = 'MPFA_PORTAL',
  TRUSTEE_PORTAL = 'TRUSTEE_PORTAL',
}

export const submitChannelMapObj = {
  [SubmitChannelE.WEB_PORTAL]: 'Web Portal',
  [SubmitChannelE.MOBILE_APP]: 'Mobile App',
  [SubmitChannelE.PAPER_FORM]: 'Paper form',
  [SubmitChannelE.THIRD_PARTY_PORTAL]: 'Third Party Portal',
  [SubmitChannelE.BATCH]: 'Batch',
  [SubmitChannelE.KIOSK]: 'Kiosk',
  [SubmitChannelE.CONTACT_CENTER]: 'Contact Center',
  [SubmitChannelE.MPFA_PORTAL]: 'MPFA Portal',
  [SubmitChannelE.TRUSTEE_PORTAL]: 'Trustee Portal',
};

export enum LspspoffsetSeqE {
  OFFSET_MC_VC = 'OFFSET_MC_VC',
  OFFSET_VC_MC = 'OFFSET_VC_MC',
}

export const lspspoffsetSeqMapObj = {
  [LspspoffsetSeqE.OFFSET_MC_VC]: 'Offset MC then VC',
  [LspspoffsetSeqE.OFFSET_VC_MC]: 'Offset VC then MC',
};

export enum CurrencyE {
  HKD = 'HKD',
  USD = 'USD',
  MOP = 'MOP',
}

export const currencyMapObj = {
  [CurrencyE.HKD]: 'Hong Kong Dollar',
  [CurrencyE.MOP]: 'Macau Dollar',
  [CurrencyE.USD]: 'US Dollar',
};

export const aggregateInvestedAmountCurrencyDropdownOptions: CdsOption[] = [
  {
    label: 'HKD',
    value: CurrencyE.HKD,
  },
  {
    label: 'USD',
    value: CurrencyE.USD,
  },
  {
    label: 'MOP',
    value: CurrencyE.MOP,
  },
];

export enum PaymentMethodE {
  PHYSICAL_CHEQUE = 'PHYSICAL_CHEQUE',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  FORFEIT_ACCT_OFFSET = 'FORFEIT_ACCT_OFFSET',
  RESERVE_ACCT_OFFSET = 'RESERVE_ACCT_OFFSET',
  FPS = 'FPS',
  E_CHEQUE = 'E_CHEQUE',
  CASHIER_ORDER = 'CASHIER_ORDER',
  PPS = 'PPS',
  CREDIT_TRANSFER = 'CREDIT_TRANSFER',
  CASH = 'CASH',
  CMU = 'CMU',
  OTHERS = 'OTHERS',
  MPFA_TRANSFER = 'MPFA_TRANSFER',
  BILL_PYM = 'BILL_PYM',
}

export const paymentMethodOptions: CdsOption[] = [
  {
    label: 'Physical Cheque',
    value: PaymentMethodE.PHYSICAL_CHEQUE,
  },
  {
    label: 'Direct Debit',
    value: PaymentMethodE.DIRECT_DEBIT,
  },
  {
    label: 'Offset From Forfeiture Account',
    value: PaymentMethodE.FORFEIT_ACCT_OFFSET,
  },
  {
    label: 'Offset From Reserve Account',
    value: PaymentMethodE.RESERVE_ACCT_OFFSET,
  },
  {
    label: 'FPS',
    value: PaymentMethodE.FPS,
  },
  {
    label: 'e-Cheque',
    value: PaymentMethodE.E_CHEQUE,
  },
  {
    label: 'Cashier Order',
    value: PaymentMethodE.CASHIER_ORDER,
  },
  {
    label: 'PPS',
    value: PaymentMethodE.PPS,
  },
  {
    label: 'Credit Transfer',
    value: PaymentMethodE.CREDIT_TRANSFER,
  },
  {
    label: ' Cash Deposit',
    value: PaymentMethodE.CASH,
  },
  {
    label: 'CMU',
    value: PaymentMethodE.CMU,
  },
  {
    label: 'Others',
    value: PaymentMethodE.OTHERS,
  },
  {
    label: 'MPFA Transfer',
    value: PaymentMethodE.MPFA_TRANSFER,
  },
  {
    label: 'Bill Payment',
    value: PaymentMethodE.BILL_PYM,
  },
];

export enum ContributionStatusE {
  'NEW' = 'NEW',
  'GENERATED' = 'GENERATED',
  'REPORTED' = 'REPORTED',
  'EXCEPTION' = 'EXCEPTION',
  'VOID' = 'VOID',
  'NOT_REPORTED' = 'NOT_REPORTED',
  'CANCELLED' = 'CANCELLED',
  'NA' = 'N/A',
}

export const contributionStatusOptions: CdsOption[] = [
  {
    label: 'New - newly identified DC, pending report to MPFA',
    value: ContributionStatusE.NEW,
  },
  {
    label: 'Generated - reported to MPFA, pending to validate',
    value: ContributionStatusE.GENERATED,
  },
  {
    label: 'DC REPORTED – successfully reported to MPFA',
    value: ContributionStatusE.REPORTED,
  },
  {
    label: 'Exception - rejected by MPFA, pending follow-up/ re-submission',
    value: ContributionStatusE.EXCEPTION,
  },
  {
    label: 'Void - void status',
    value: ContributionStatusE.VOID,
  },
  {
    label: 'Not Reported - not reported to MPFA',
    value: ContributionStatusE.NOT_REPORTED,
  },
  {
    label: 'Cancelled - cancelled upon client’s requests with verification by eMPF, no submission is required',
    value: ContributionStatusE.CANCELLED,
  },
  {
    label: 'N/A - Insufficient voluntary contribution payment received',
    value: ContributionStatusE.NA,
  },
];

export enum RectifiedTypeE {
  'FPAID' = 'FPAID',
  'PPAID' = 'PPAID',
  'RENOT' = 'RENOT',
  'READM' = 'READM',
  'CHCPD' = 'CHCPD',
}

export const rectifiedTypeOptions: CdsOption[] = [
  {
    label: '"FPAID" - Full Payment / Rectification',
    value: RectifiedTypeE.FPAID,
  },
  {
    label: '"ppaid" - Partial Payment / Rectification',
    value: RectifiedTypeE.PPAID,
  },
  {
    label: '"RENOT" - Resolved Problem of NOT',
    value: RectifiedTypeE.RENOT,
  },
  {
    label: '"READM" - Resolved Other Admin. Problems',
    value: RectifiedTypeE.READM,
  },
  {
    label: '"CHCPD" - Changed Contribution Period Details',
    value: RectifiedTypeE.CHCPD,
  },
];
export interface AgentSep {
  number?: number;
  id?: string;
  createdAt?: string;
  sepCommDate?: string;
  hkid?: string;
  passport?: string;
  dob?: string;
  userId?: string;
  userIdType?: string;
  agentCode?: string;
  agentName?: string;
  agentNameChi?: string;
  check?: boolean;
  mbAcctCd?: string;
}

export interface AgentSepCheckResult {
  row: number;
  field: string;
  type: AgentSepCheckResultTypeE;
  message: string;
}

export enum AgentSepCheckResultTypeE {
  WARN = 'Warning',
  ERR = 'Error',
}

export interface AgentSepFileCheckRes {
  result: boolean;
  tips: AgentSepCheckResult[];
}

export const agentSepUserIdTypeOptions: CdsOption[] = [
  {
    label: 'HKID',
    value: 'hkid',
  },
  {
    label: 'Passport',
    value: 'passport',
  },
];

export const agentSepUserIdTypeConfig: CdsDropdownConfig = {
  options: agentSepUserIdTypeOptions,
};

export enum SchemeMergeSignalE {
  Y = 'Y',
  S = 'S',
  C = 'C',
}

export const schemeMergeSignalMapObj = {
  [SchemeMergeSignalE.Y]: 'LS to GS',
  [SchemeMergeSignalE.S]: 'SCB to GS',
  [SchemeMergeSignalE.C]: 'SCB combine with GS',
};

export enum SourceOfFundAndWealthE {
  SALARY = 'SALARY',
  PERSON_SAVE = 'PERSON_SAVE',
  BIZ_INCOME_PROFITS = 'BIZ_INCOME_PROFITS',
  INHERITANCE = 'INHERITANCE',
  SALE_OF_PROPERTY = 'SALE_OF_PROPERTY',
  INV_RETURN_MATURED = 'INV_RETURN_MATURED',
  OTHERS = 'OTHERS',
}

export const sourceOfFundAndWealthMapObj = {
  [SourceOfFundAndWealthE.SALARY]: 'Salary',
  [SourceOfFundAndWealthE.PERSON_SAVE]: 'Personal savings',
  [SourceOfFundAndWealthE.BIZ_INCOME_PROFITS]: 'Business income and profits',
  [SourceOfFundAndWealthE.INHERITANCE]: 'Inheritance',
  [SourceOfFundAndWealthE.SALE_OF_PROPERTY]: 'Sale of property',
  [SourceOfFundAndWealthE.INV_RETURN_MATURED]: 'Investment return / Investment matured',
  [SourceOfFundAndWealthE.OTHERS]: 'Others',
};

export interface VestingRulesList {
  managerList?: VestingRule[];
  officerList?: VestingRule[];
  staffList?: VestingRule[];
  allMembersList?: VestingRule[];
}

export interface VestingRule {
  // frontend only
  role?: string;
  vestSeqNo?: number;
  erGrdMbClss?: string;
  effDate?: string;
  termDate?: string;
  calMnthServiceTyp?: string;
  calBaseUnt?: string;
  subacctTyp?: string;
  vcNorRetireAge?: number;
  vcEarRetireAge?: number;
  vestTermReason?: string;
  mnthOfServiceFrm?: number;
  mnthOfServiceTo?: number;
  vestRt?: number;
  vestingClassNo?: number;
  vestingClassName?: string;
}

export interface VestingRuleOrso {
  vestingClassNo?: number;
  vestingClassName?: string;
  noPayLeave?: NoPayLeaveE;
  deathBenefitVesting?: OrsoVestingE;
  earlyRetirementVesting?: OrsoVestingE;
  disabilityVesting?: OrsoVestingE;
  discontinuedVesting?: OrsoVestingE;
  minimumVestingAge?: number;
  fullVestingAge?: number;
  serviceYearOrMonthMinimumVesting?: number;
  serviceYearOrMonthFullVesting?: number;
  participationYearOrMonthMinimumVesting?: number;
  requirementTypeMinimumVesting?: RequirementTypeE;
  requirementTypeFullVesting?: RequirementTypeE;
  participationYearOrMonthFullVesting?: number;
  rules: VestingScheme[];
}

export interface VestingRuleMpfRes {
  erVestingRulesResponse?: VestingRuleMpf;
  terminationReasonList?: MpfTermination[];
  // only for frontend
  structuredTerminationList?: StructuredTermination[];
}

export interface StructuredTermination {
  reasons: VestTermReasonE[];
  // without vestTermReason
  dataSource: MpfTermination[];
}

export interface MpfTermination {
  mnthOfServiceFrm?: number;
  mnthOfServiceTo?: number;
  vestRt?: number;
  vestTermReason?: VestTermReasonE;
}
export interface ReasonTable {
  completedMonthOfServiceFrom: number;
  completedMonthOfServiceTo: number;
  vestingRate?: string;
}
export interface TerminationReason {
  reasonHeader?: VestTermReasonE[];
  reasonTable?: ReasonTable[];
}
export interface VestingRuleMpfDTO {
  vestingSequenceNumber?: string;
  employerGradeMemberClass?: string;
  effectiveDate?: string;
  effectiveEndDate?: string;
  calculationOfCompleteMonthOfService?: string;
  calculationBaseUnit?: string;
  subAccountType?: string[];
  vcNormalRetirementAge?: string;
  vcEarlyRetirementAge?: string;
  terminationReasonList?: TerminationReason[];
}

export interface RelevantIncomeDTO {
  completedMonthOfServiceFrom?: string;
  completedMonthOfServiceTo?: string;
  rangeOfTheRelevantIncomeAgeFrom?: string;
  rangeOfTheRelevantIncomeAgeTo?: string;
  contributionAmount?: string;
  cappedAmount?: string;
}

export interface VoluntaryRuleMpfDTO {
  employerGradeMemberClass?: string;
  effectiveDate?: string;
  effectiveEndDate?: string;
  definitionOfService?: CalMnthServiceTypE;
  definitionOfIncome?: string;
  subAccountType?: string[];
  voluntaryContributionRuleInCommonStandard?: string;
  specifiedPeriodTypeOfADOE?: CalBaseUntE;
  valueOfSpecifiedPeriodOfADOE?: string;
  relevantIncomeList?: RelevantIncomeDTO[];
}

export interface VestingRuleMpf {
  vestSeqNo?: number;
  erGrdMbClss?: VestingClassE;
  effDate?: string;
  termDate?: string;
  calBaseUnt?: string;
  calMnthServiceTyp?: CalMnthServiceTypE;
  subacctTyp?: SubacctTypE;
  vcNorRetireAge?: string;
  vcEarRetireAge?: string;
}

export enum VestingClassE {
  MANAGER = 'MANAGER',
  OFFICER = 'OFFICER',
  // To be determined
  GENERAL_STAFF = 'GENERAL STAFF',
}

export const VestingClassMap = new Map([
  [VestingClassE.MANAGER, 'Manger'],
  [VestingClassE.OFFICER, 'Officer'],
  [VestingClassE.GENERAL_STAFF, 'General Staff'],
]);

export interface VestingScheme {
  name?: string;
  vestingSchemeBreakpoints?: VestingSchemeBreakpoint[];
}

export interface VestingSchemeBreakpoint {
  vestingSchemeBreakpoint1?: string;
  vestingSchemeBreakpoint2?: string;
  vestingSchemeBreakpoint3?: string;
  vestingSchemeBreakpoint4?: string;
  vestingSchemeBreakpoint5?: string;
  vestingSchemeBreakpoint6?: string;
  vestingSchemeBreakpoint7?: string;
  vestingSchemeBreakpoint8?: string;
  vestingSchemeBreakpoint9?: string;
  vestingSchemeBreakpoint10?: string;
  vestingSchemeBreakpoint11?: string;
  vestingSchemeBreakpoint12?: string;
  vestingSchemeBreakpoint13?: string;
  vestingSchemeBreakpoint14?: string;
  vestingSchemeBreakpoint15?: string;
  vestingSchemeBreakpoint16?: string;
  vestingSchemeBreakpoint17?: string;
  vestingSchemeBreakpoint18?: string;
  vestingSchemeBreakpoint19?: string;
  vestingSchemeBreakpoint20?: string;
  vestingSchemePercentage1?: number;
  vestingSchemePercentage2?: number;
  vestingSchemePercentage3?: number;
  vestingSchemePercentage4?: number;
  vestingSchemePercentage5?: number;
  vestingSchemePercentage6?: number;
  vestingSchemePercentage7?: number;
  vestingSchemePercentage8?: number;
  vestingSchemePercentage9?: number;
  vestingSchemePercentage10?: number;
  vestingSchemePercentage11?: number;
  vestingSchemePercentage12?: number;
  vestingSchemePercentage13?: number;
  vestingSchemePercentage14?: number;
  vestingSchemePercentage15?: number;
  vestingSchemePercentage16?: number;
  vestingSchemePercentage17?: number;
  vestingSchemePercentage18?: number;
  vestingSchemePercentage19?: number;
  vestingSchemePercentage20?: number;
}

export enum NoPayLeaveE {
  NA = 0,
  INCLUDED_FOR_VESTING = 1,
  EXCLUED_FOR_VESTING = 2,
}

export const NoPayLeaveMap = new Map([
  [NoPayLeaveE.NA, 'NA'],
  [NoPayLeaveE.INCLUDED_FOR_VESTING, 'Included for vesting'],
  [NoPayLeaveE.EXCLUED_FOR_VESTING, 'Excluded for vesting'],
]);

export enum OrsoVestingE {
  ZERO_PERCENT = 1,
  ONE_HUNDRED_PERCENT = 2,
  SAME_AS_TERMINATION = 3,
  SPECIFIED_PERCENT = 9,
}

export const OrsoVestingMap = new Map([
  [OrsoVestingE.ZERO_PERCENT, '0%'],
  [OrsoVestingE.ONE_HUNDRED_PERCENT, '100%'],
  [OrsoVestingE.SAME_AS_TERMINATION, 'Same as Termination'],
  [OrsoVestingE.SPECIFIED_PERCENT, 'Specified %'],
]);

export enum RequirementTypeE {
  NA = 0,
  REQUEST_1_OF_3 = 1,
  REQUEST_2_OF_3 = 2,
  REQUEST_ALL_3 = 3,
}

export const RequirementTypeMap = new Map([
  [RequirementTypeE.NA, 'NA'],
  [RequirementTypeE.REQUEST_1_OF_3, 'REQUEST 1 OF 3'],
  [RequirementTypeE.REQUEST_2_OF_3, 'REQUEST 2 OF 3'],
  [RequirementTypeE.REQUEST_ALL_3, 'REQUEST ALL 3'],
]);

export enum VestingSchemeBreakpointTypeE {
  NA = 0,
  SERVICE = 10,
  PARTICIPATION = 11,
  AGE = 12,
}

export const VestingSchemeBreakpointTypeMap = new Map<number, string>([
  [VestingSchemeBreakpointTypeE.NA, 'NA'],
  [VestingSchemeBreakpointTypeE.SERVICE, 'Service'],
  [VestingSchemeBreakpointTypeE.PARTICIPATION, 'Participation'],
  [VestingSchemeBreakpointTypeE.AGE, 'Age'],
]);

export interface VoluntaryRule {
  // frontend only
  role?: string;
  erGrdMbClss?: string;
  effDate?: string;
  termDate?: string;
  serviceDef?: string;
  incomeDef?: string;
  subacctTyp?: string;
  commonStdVcRule?: string;
  adoePrdTyp?: string;
  adoePrdValue?: number;
  mnthOfServiceFrm?: number;
  mnthOfServiceTo?: number;
  rngFrm?: number;
  rngTo?: number;
  conRt?: number;
  conAmt?: number;
  vcCapdAmt?: number;
  // for frontend
  rules?: VestingRuleDetail[];
}

export enum VestTermReasonE {
  RSN = 'RSN',
  RTM = 'RTM',
  ERM = 'ERM',
  DTH = 'DTH',
  ILH = 'ILH',
  TIC = 'TIC',
  RDD = 'RDD',
}

export const vestTermReasonMapObj = {
  [VestTermReasonE.RSN]: 'Resignation',
  [VestTermReasonE.RTM]: 'Retirement',
  [VestTermReasonE.ERM]: 'Early Retirement',
  [VestTermReasonE.DTH]: 'Death',
  [VestTermReasonE.ILH]: 'On ground of ill health',
  [VestTermReasonE.TIC]: 'Total Incapacity',
  [VestTermReasonE.RDD]: 'Redundancy',
};

export enum IncomeTypeE {
  BS = 'BS',
  RI = 'RI',
}

export const incomeTypeMapObj = {
  [IncomeTypeE.BS]: 'Basic Salary only',
  [IncomeTypeE.RI]: 'Relevant Income as defined under MPF legislation',
};

export interface AgentSepWarn {
  sepCommDate?: string;
  dob?: string;
  userIdType?: string;
  userId?: string;
}
