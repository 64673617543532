/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import { CommissionAgentService } from 'src/app/core/services/commission/commission-agent.service';
import { ResponseResult } from 'src/app/core/models/response/response-result';

@Injectable({ providedIn: 'root' })
export class ServiceAgentAsyncValidator implements AsyncValidator {
  constructor(private commissionAgentService: CommissionAgentService) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    const value = control.value.trim();
    if (!value || !control.dirty) {
      return of(null);
    }
    return this.commissionAgentService.getAgentByCode(value).pipe(
      map(res => {
        if (res && res.result === ResponseResult.SUCCESS) {
          return null;
        }
        return { error: 'common.business.servicingAgent.invalid' };
      }),
      catchError(() => of(null))
    );
  }
}
