import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { ActionIcons } from '@cds/ng-themes';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { CdsToastService } from '@cds/ng-web-components/toast';
import moment from 'moment';
import { MD_IFF_STATUS } from 'src/app/config/iff-status.config';
import { IffStatusService } from 'src/app/core/services/iff-status/iff-status.service';
import { dateRangeInvalidValidator, isSameOrBeforeToday } from 'src/app/core/validators';
import { atLeastOneQueryConditionValidator } from 'src/app/core/validators/common.validator';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { dateValidator } from 'src/app/shared/validators/validators';
import { copyText, getFilterParams, pageToTop } from 'src/app/utils/utils';
import { AddMpfReferenceNumberPopupComponent } from '../../add-mpf-reference-number-popup/add-mpf-reference-number-popup.component';
import { AdviseEmpfrefComponent } from '../../case-summary/advise-empfref/advise-empfref.component';
import { IffStatusFlowPopupComponent } from '../../iff-status-flow-popup/iff-status-flow-popup.component';
import { MdiffAgentErRecordItem, RefNoType } from '../md-iff-summary.model';
import { SalesJourneyProdType } from '../../case-summary/case-summary.model';

interface ReqeuestParams {
  domain: 'ER';
  page: number;
  size: number;
  mdNo?: string;
  companyName?: string;
  brNo?: string;
  declarationDateStart?: string;
  declarationDateEnd?: string;
  mdIffStatus?: number[];
}

@Component({
  selector: 'app-mdiff-agent-er',
  templateUrl: './mdiff-agent-er.component.html',
  styleUrls: ['./mdiff-agent-er.component.scss'],
})
export class MdiffAgentErComponent implements OnInit {
  @Input() rolePriority?: string;
  copyText = copyText;
  formGroup!: FormGroup;
  reqeuestParams: ReqeuestParams = {
    domain: 'ER',
    page: 1,
    size: 30,
  };
  readonly displayedColumns = ['mdNo', 'employerName', 'brNumber', 'declarationDate', 'mdIffStatus', 'isSplitAgent', 'AddeMPFRefNo'];
  allPageData: MdiffAgentErRecordItem[][] = [];
  totalPages = 0;
  currentPageNumOrigin = 1;
  resetDatePicker = false;
  private today = new Date();
  declarationDateFromMax = this.today;
  declarationDateToMin = new Date('2000/01/01');
  declarationDateToMax: Date = this.today;
  iffStatusoptionList: CdsOption[] = [];
  isLoading = false;
  totalElements = 0;
  iconConfig: CdsIconConfig = {
    size: 'sm',
    color: 'default',
  };
  infoIcon = ActionIcons.info_1;
  @ViewChild('tableBox', { static: false })
  tableBox?: ElementRef<HTMLDivElement>;

  get currentPageIndex() {
    return this.currentPageNumOrigin - 1;
  }

  get currentPageData() {
    if (this.allPageData.length === 0) {
      return [];
    }
    return this.allPageData[this.currentPageIndex];
  }

  get declarationDateStart() {
    return this.formGroup.get('declarationDateStart') as FormControl;
  }

  get declarationDateEnd() {
    return this.formGroup.get('declarationDateEnd') as FormControl;
  }

  get mdIffStatus() {
    return this.formGroup.get('mdIffStatus') as FormControl;
  }

  get mdNo() {
    return this.formGroup.get('mdNo') as FormControl;
  }

  get companyName() {
    return this.formGroup.get('companyName') as FormControl;
  }

  get brNo() {
    return this.formGroup.get('brNo') as FormControl;
  }

  constructor(
    private cdsPopup: CdsPopupService,
    public iffStatusService: IffStatusService,
    private toast: CdsToastService,
    private router: Router,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService
  ) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup(
      {
        mdNo: new FormControl(),
        companyName: new FormControl(),
        declarationDateStart: new FormControl(null, {
          validators: [dateValidator('common.invalidDate'), isSameOrBeforeToday({ dateInvalid: 'common.invalidDate' })],
        }),
        declarationDateEnd: new FormControl(null, {
          validators: [dateValidator('common.invalidDate'), isSameOrBeforeToday({ dateInvalid: 'common.invalidDate' })],
        }),
        brNo: new FormControl(),
        mdIffStatus: new FormControl([]),
      },
      [dateRangeInvalidValidator('declarationDateStart', 'declarationDateEnd'), atLeastOneQueryConditionValidator()]
    );

    this.initIffStatusOptions();
    this.search();
  }

  get isDisableSearch() {
    return this.formGroup.invalid;
  }

  @HostListener('document:keydown.enter', ['$event'])
  onDocumentEnter(): void {
    this.onClickSearch();
  }
  onClickSearch() {
    if (this.isDisableSearch || this.isLoading) {
      return;
    }

    this.reqeuestParams.page = 1;
    this.search();
  }

  onClickReset() {
    this.formGroup.reset({
      mdIffStatus: [],
    });
    this.onResetDatePicker();

    this.reqeuestParams = {
      domain: 'ER',
      page: 1,
      size: 30,
    };
    this.search();
  }

  private initIffStatusOptions() {
    this.iffStatusoptionList = MD_IFF_STATUS.map<CdsOption>(element => ({
      label: element.text,
      value: element.value,
    }));
  }

  declarationDateFromChange(val: string) {
    const dateObject = moment(val, 'DD/MM/YYYY');
    if (dateObject.isValid()) {
      this.declarationDateToMin = dateObject.toDate();
    }
  }

  declarationDateToChange(val: string) {
    const dateObject = moment(val, 'DD/MM/YYYY');
    if (dateObject.isValid()) {
      this.declarationDateFromMax = dateObject.toDate();
    }
  }

  onGenerateNewMDReferenceNo() {
    this.router.navigate([
      '/salesjourney/generate-md/select-product-type',
      {
        refNoType: RefNoType.MD,
        tabIndex: 0,
        prodType: SalesJourneyProdType.Corperate,
      },
    ]);
  }

  onOpenAddEmpfRefNoPopup(md: MdiffAgentErRecordItem) {
    this.cdsPopup.open(AddMpfReferenceNumberPopupComponent, {
      size: 'sm',
      data: {
        mdNo: md.mdNo,
        prodType: 'ER',
      },
    });
  }

  onDisplayAdviseIFF() {
    this.cdsPopup.open(IffStatusFlowPopupComponent, {
      size: 'sm',
    });
  }

  onDisplayAdviseRef() {
    this.cdsPopup.open(AdviseEmpfrefComponent, {
      size: 'sm',
    });
  }

  pageChange(num: number): void {
    pageToTop();
    this.reqeuestParams.page = num;
    this.search();
  }

  onGoCaseDetails(mdNo: string) {
    this.router.navigate([
      '/salesjourney/er-empf-case-details',
      mdNo,
      {
        refNoType: RefNoType.MD,
        tabIndex: 0,
        prodType: SalesJourneyProdType.Corperate,
      },
    ]);
  }

  private search() {
    this.reqeuestParams.declarationDateEnd = undefined;
    this.reqeuestParams.declarationDateStart = undefined;
    if (
      !this.declarationDateStart.hasError('dateInvalid') &&
      !this.declarationDateEnd.hasError('dateInvalid') &&
      !this.formGroup.hasError('dateRangeInvalidValidator')
    ) {
      this.reqeuestParams.declarationDateEnd = this.declarationDateEnd.value;
      this.reqeuestParams.declarationDateStart = this.declarationDateStart.value;
    }
    this.reqeuestParams.brNo = this.brNo.value;
    this.reqeuestParams.companyName = this.companyName.value;
    this.reqeuestParams.mdIffStatus = this.mdIffStatus.value?.map((item: { value: number }) => item.value);
    this.reqeuestParams.mdNo = this.mdNo.value;
    const params = getFilterParams(this.reqeuestParams);

    this.isLoading = true;
    this.cdHttpServeService
      .post<{
        content: MdiffAgentErRecordItem[];
        totalElements: number;
        number: number;
        totalPages: number;
      }>('/ext/eb-ssms-sales-journey-service/mdIffSummaries/agent', params)
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            res.data.content = res.data.content || [];
            this.currentPageNumOrigin = res.data.number;
            this.allPageData[this.currentPageIndex] = res.data.content;
            this.totalElements = res.data.totalElements;
            this.totalPages = res.data.totalPages;

            const currentAllPageData = this.allPageData.length;
            if (this.totalPages > currentAllPageData) {
              for (let i = 0; i < this.totalPages - currentAllPageData; i++) {
                this.allPageData.push([]);
              }
            } else if (this.totalPages < currentAllPageData) {
              for (let i = 0; i < currentAllPageData - this.totalPages; i++) {
                this.allPageData.pop();
              }
            }
          }
          this.isLoading = false;
        },
        error: err => {
          this.alert.error('Error!', err);
          this.isLoading = false;
        },
      });
  }

  onResetDatePicker() {
    this.declarationDateFromMax = this.today;
    this.declarationDateToMin = new Date('2000/01/01');
    this.resetDatePicker = true;
    setTimeout(() => {
      this.resetDatePicker = false;
    }, 0);
  }

  get checkIsHaveScroll() {
    if (this.tableBox) {
      return this.tableBox.nativeElement.scrollWidth > this.tableBox.nativeElement.clientWidth;
    }
    return false;
  }
}
