import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PageConfig } from 'src/app/shared/data-table';
import { ChatService } from 'src/app/views/chatbot/_service/chat.service';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss'],
})
export class ChatListComponent implements OnInit {
  tab = 0;
  activeChat: any = [];
  pendingChat: any = [];
  closeChat: any = [];
  closeChatFilter: any = [];
  botChat: any = [];
  loading: boolean = false;
  count = 0;
  rows = [];
  activheaders = [
    { key: 'agent', title: 'CSO' },
    { key: 'userc', title: 'AGENT' },
    { key: 'liveChatDuration', title: 'Live chat duration(Minutes)' },
    { key: 'status', title: 'Status' },
  ];

  pendingheaders = [
    { key: 'userc', title: 'AGENT' },
    { key: 'waitingDuration', title: 'Waiting Duration(Minutes)' },
    { key: 'status', title: 'Status' },
  ];
  closeheaders = [
    { key: 'agent', title: 'CSO' },
    { key: 'userc', title: 'AGENT' },
    { key: 'liveChatDuration', title: 'Live chat duration(Minutes)' },
    { key: 'status', title: 'Status' },
  ];
  botheaders = [
    { key: 'userc', title: 'AGENT' },
    { key: 'lastTopic', title: 'Last topic enquired' },
    { key: 'waitingDuration', title: 'Duration (Minutes)' },
    { key: 'msgDate', title: 'Chatbot Start Datetime' },
  ];

  pageConfig: PageConfig = {
    current: 1,
    pageSize: 2000,
    totalCount: 1000,
  };
  constructor(public chatService: ChatService, public cdk: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.getActiveChatList();
  }
  tabChange(item: any) {
    this.pageConfig.current = 1;
    if (item.index == 0) {
      this.getActiveChatList();
    }
    if (item.index == 1) {
      this.getPendingChatList();
    }
    if (item.index == 2) {
      this.getCloseChatList();
    }
    if (item.index == 3) {
      this.getBotChatList();
    }
  }

  handlePageChange(event: { current: number; pageSize: number }) {
    if (this.pageConfig.current !== event.current) {
      this.pageConfig.current = event.current;
      this.pageConfig.pageSize = event.pageSize;
      if (this.tab == 0) {
        this.getActiveChatList();
      }
      if (this.tab == 1) {
        this.getPendingChatList();
      }
      if (this.tab == 2) {
        this.getCloseChatList();
      }
      if (this.tab == 3) {
        this.getBotChatList();
      }
    }
  }

  getActiveChatList() {
    this.count = 0;
    this.loading = true;
    this.rows = [];
    this.chatService.getActiveChatListForSupervisor(this.pageConfig).subscribe(resp => {
      this.loading = false;
      if (resp.data) {
        this.rows = resp.data;
        this.count = resp?.total_count;
      }
    });
  }
  pendingrows = [];
  getPendingChatList() {
    this.count = 0;
    this.loading = true;
    this.pendingrows = [];
    this.chatService.getPendingChatList(this.pageConfig).subscribe(resp => {
      this.loading = false;
      if (resp.data) {
        this.pendingrows = resp.data;
        this.count = resp?.total_count;
      }
      this.cdk.detectChanges();
    });
  }
  closerows = [];
  getCloseChatList() {
    this.count = 0;

    this.loading = true;
    this.closerows = [];
    this.chatService.getCloseChatListForSupervisor('', this.pageConfig).subscribe(resp => {
      this.loading = false;
      if (resp.data) {
        this.closerows = resp.data;
        this.count = resp?.total_count;
      }
      this.cdk.detectChanges();
    });
  }
  botrows = [];
  getBotChatList() {
    this.count = 0;
    this.loading = true;
    this.botrows = [];
    this.chatService.getBotChatList(this.pageConfig).subscribe(resp => {
      this.loading = false;
      if (resp.data) {
        this.botrows = resp.data;
        this.count = resp?.total_count;
      }
    });
  }

  findUser(user: any, type: any) {
    if (type == 1) {
      let username = user.find((item: any) => item.type == 'CUSTOMER_SERVICING_AGENT')?.name;
      return username ? username : '-';
    } else if (type == 0) {
      let username = user.find((item: any) => item.type != 'CUSTOMER_SERVICING_AGENT')?.name;
      return username ? username : '-';
    }
  }
}
