/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import moment from 'moment';
import { finalize } from 'rxjs';
import { AccountTypesCommissionpool } from 'src/app/core/models/enum';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { agentCodeValidator, dateValidator, numberValidator, requiredValidator } from 'src/app/core/validators';
import { CdPopupService, MatDialogRef } from 'src/app/shared/cd-popup';
import { ServiceAgentAsyncValidator } from './service-agent-async.validators';
import { UploadCustomerDataService } from './upload-customer-data.service';

@Component({
  selector: 'app-upload-customer-data',
  templateUrl: './upload-customer-data.component.html',
  styleUrls: ['./upload-customer-data.component.scss'],
})
export class UploadCustomerDataComponent implements OnInit {
  accountTypeConfig: CdsDropdownConfig = {
    placeholder: 'commission.common.accountType.placeholder',
    options: AccountTypesCommissionpool,
  };

  formGroup: FormGroup = new FormGroup({
    accountType: new FormControl('', {
      initialValueIsDefault: true,
    }),
    accountCode: new FormControl('', {
      initialValueIsDefault: true,
      validators: [
        requiredValidator({
          error: 'common.business.acconutCode.placeholder',
        }),
        numberValidator({
          error: 'Employer/Member Account Code is invalid',
        }),
      ],
    }),
    commencementDate: new FormControl('', {
      initialValueIsDefault: true,
      validators: [
        requiredValidator({
          error: 'Please input Commencement Date',
        }),
        dateValidator({
          error: 'common.business.date.invalid',
        }),
      ],
    }),
    firstContributionMonth: new FormControl('', {
      initialValueIsDefault: true,
      validators: [
        dateValidator({
          error: 'common.business.date.invalid',
        }),
      ],
    }),
    servicingAgents: new FormControl('', {
      initialValueIsDefault: true,
      validators: [
        requiredValidator({
          error: 'common.business.servicingAgent.placehold',
        }),
        agentCodeValidator({
          error: 'commission.common.agentCode.invalid',
        }),
      ],
      asyncValidators: [this.serviceAgentAsyncValidator.validate.bind(this.serviceAgentAsyncValidator)],
    }),
    name: new FormControl('', {
      initialValueIsDefault: true,
      validators: [
        requiredValidator({
          error: 'common.business.nameAccount.placehold',
        }),
      ],
    }),
  });

  get accountType() {
    return this.formGroup.get('accountType') as FormControl;
  }

  get accountCode() {
    return this.formGroup.get('accountCode') as FormControl;
  }

  get commencementDate() {
    return this.formGroup.get('commencementDate') as FormControl;
  }

  get firstContributionMonth() {
    return this.formGroup.get('firstContributionMonth') as FormControl;
  }

  get servicingAgents() {
    return this.formGroup.get('servicingAgents') as FormControl;
  }

  get name() {
    return this.formGroup.get('name') as FormControl;
  }

  get disabled() {
    let tag = true;
    const controls = this.formGroup.controls;
    const keys = Object.keys(controls);
    const isRequired = !this.name.value || !this.servicingAgents.value || !this.accountCode.value || !this.accountType.value || !this.commencementDate.value;
    keys.forEach((key: string) => {
      const defaultValue = (controls[key] as FormControl).defaultValue;
      const controlValue = (controls[key] as FormControl).value;
      const value = controlValue.trim();
      if (defaultValue !== value) {
        tag = false;
      }
    });
    return isRequired || tag || this.formGroup.invalid || this.isCreating;
  }

  formControl: FormControl = new FormControl();

  file!: File;
  uploading = false;
  errorMessage = '';
  isCreating = false;

  constructor(
    private serviceAgentAsyncValidator: ServiceAgentAsyncValidator,
    private uploadCustomerDataService: UploadCustomerDataService,
    private cdsPopup: CdPopupService
  ) {}

  ngOnInit() {}

  createPool() {
    if (this.isCreating) {
      return;
    }
    this.isCreating = true;
    const params = this.createParams();
    this.uploadCustomerDataService
      .addCommissionEvents(params)
      .pipe(
        finalize(() => {
          this.isCreating = false;
        })
      )
      .subscribe(res => {
        const popupRef: MatDialogRef<any> = this.cdsPopup.openCommon({
          data: {
            message: res.result == ResponseResult.SUCCESS ? 'common.action.success' : res.message,
          },
        });
        popupRef.afterClosed().subscribe(() => {});
      });
  }

  reset() {
    this.formGroup.reset();
  }

  createParams() {
    const value = { ...this.formGroup.value };
    const params: any = {};
    const keys: string[] = Object.keys(value);
    keys.forEach(key => {
      if (value[key]) {
        params[key] = value[key];
      }
    });
    if (params.commencementDate) {
      params.commencementDate = moment(params.commencementDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    if (params.firstContributionMonth) {
      params.firstContributionMonth = moment(params.firstContributionMonth, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    return params;
  }

  fileChange(event: any) {
    this.file = event?.target?.files[0];
    if (
      !this.file ||
      !this.file.size ||
      !this.file.type ||
      !this.file.name ||
      this.file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      this.errorMessage = 'Invalid file selected.';
      return;
    }
    if (this.file.size > 10485760) {
      this.errorMessage = 'Upload file size not exceeding 10MB';
      return;
    }
    this.errorMessage = '';
    this.upload();
  }

  upload() {
    this.uploading = true;
    this.uploadCustomerDataService
      .uploadFile(this.file)
      .pipe(
        finalize(() => {
          this.uploading = false;
        })
      )
      .subscribe(res => {
        if (res.result == ResponseResult.SUCCESS) {
          const popupRef: MatDialogRef<any> = this.cdsPopup.openCommon({
            data: {
              message: 'common.action.success',
            },
          });
          popupRef.afterClosed().subscribe(() => {});
        } else {
          this.errorMessage = res.message;
        }
      });
  }
}
