<div class="col-xs-12 col-md-12 l-pt-7 l-pb-6 l-plr-0">
  <div class="l-mb-2 l-d-f l-ai-cen cursor-pointer">
    <span class="cds-h2 cds-light l-mb-0 l-mr-7"> {{ 'commission.pool.related.list.title' | lang }} </span>
    <div *ngIf="!isEdit && (role | permission : permission | async)" (click)="edit()" class="l-d-f l-ai-cen">
      <cds-icon class="l-mr-2" [height]="'50'" icon="action:edit"></cds-icon>
      <div class="cds-h6 cds-demibold l-mb-0">
        {{ 'commission.pool.common.edit' | lang }}
      </div>
    </div>
    <div *ngIf="isEdit" (click)="cancelEdit()" class="l-d-f l-ai-cen cursor-pointer">
      <cds-icon class="l-mr-2" [height]="'50'" icon="action:edit"></cds-icon>
      <div class="cds-h6 cds-demibold l-mb-0">
        {{ 'commission.pool.common.cancelEdit' | lang }}
      </div>
    </div>
  </div>

  <div class="cds-body2 cds-regular cds-dark-navy-text light-4 l-mb-5">
    {{ 'commission.pool.related.list.lastUpdate' | lang }}
    {{ lastUpdateTime | ssmsDateTime }}(HKT),by {{ lastUpdateBy }}
  </div>

  <form class="l-d-f quick-search l-ai-fe" [formGroup]="formGroup">
    <div class="l-mr-7">
      <div class="l-p-rel input-box">
        <input
          [formControl]="account"
          class="cds-body-hero-light l-pr-7"
          placeholder="{{ 'commission.pool.related.quickSearch.placeholder' | lang }}"
          type="text" />
        <cds-icon icon="form:search"></cds-icon>
      </div>
    </div>
    <cds-button (click)="quickSearch()" type="submit" [disabled]="quickSearchDisabled" [style]="'primary'">{{ 'common.quickSearch' | lang }} </cds-button>
  </form>
</div>

<div>
  <div *ngIf="loading" style="min-height: 196px">
    <cds-progress mode="indeterminate" class="l-pl-6"></cds-progress>
  </div>

  <div *ngIf="!loading" style="overflow-x: auto; overflow-y: hidden" class="l-d-f l-plr-0">
    <table class="table-shadow table-fixed">
      <tr>
        <th scope="col" fixed class="l-pa-4 cds-body2 cds-demibold l-mb-0 text-center">
          <div style="width: 50px">{{ 'commission.pool.related.list.th.mainParty' | lang }}</div>
        </th>
        <th scope="col" class="l-pa-4 cds-body2 cds-demibold l-mb-0 text-left">
          <div class="l-d-f" style="width: 150px">
            <div>
              {{ 'commission.pool.related.list.th.accountCode' | lang }}
            </div>
            <div style="width: 30px; margin: auto">
              <cds-icon class="l-pl-1" size="sm" (click)="accountExplain()" style="cursor: pointer; min-width: initial" icon="action:info_1"></cds-icon>
            </div>
          </div>
        </th>
        <th scope="col" class="l-pa-4 cds-body2 cds-demibold l-mb-0 text-left">
          <div>
            {{ 'commission.pool.related.list.th.type' | lang }}
          </div>
        </th>
      </tr>
      <ng-container *ngFor="let item of dataSource; let j = index">
        <tr>
          <td fixed class="l-pa-4 text-center">
            <cds-checkbox [(ngModel)]="item.mainParty" [disabled]="true"></cds-checkbox>
          </td>
          <td class="l-pa-4">
            <div *ngIf="item.accountCode" class="text-expand db-click" (click)="sss(item.accountCode)">
              {{ item.accountCode }}
            </div>
            <div *ngIf="!item.accountCode" class="text-expand db-click">-</div>
          </td>
          <td class="l-pa-4 text-td">
            <div>{{ item.accountTypeStr }}</div>
          </td>
        </tr>
      </ng-container>
    </table>
    <div class="table-left">
      <table>
        <tr>
          <th scope="col" class="l-pa-4 l-pl-8 cds-body2 cds-demibold l-mb-0 text-left">
            <div>{{ 'commission.pool.related.list.th.name' | lang }}</div>
          </th>
          <th scope="col" class="l-pa-4 cds-body2 cds-demibold l-mb-0 text-left">
            <div style="width: 150px">{{ 'commission.pool.related.list.th.commencementDate' | lang }}</div>
          </th>
          <th scope="col" class="l-pa-4 cds-body2 cds-demibold l-mb-0 text-left">
            <div style="width: 150px">{{ 'commission.pool.related.list.th.firstContributionDate' | lang }}</div>
          </th>
          <th scope="col" class="l-pa-4 cds-body2 cds-demibold l-mb-0 text-left">
            <div style="width: 100px">{{ 'commission.pool.related.list.th.servicingAgents' | lang }}</div>
          </th>
          <th scope="col" class="l-pa-4 cds-body2 cds-demibold l-mb-0 text-left">
            <div style="width: 100px" class="l-d-f">
              <span>{{ 'commission.pool.related.list.th.rejoinIntraGroup' | lang }}</span>
              <div style="width: 30px; margin: auto">
                <cds-icon
                  class="l-pl-1"
                  size="sm"
                  (click)="rejoinIntraGroupExplain()"
                  style="cursor: pointer; min-width: initial"
                  icon="action:info_1"></cds-icon>
              </div>
            </div>
          </th>
          <th scope="col" class="l-pa-4 cds-body2 cds-demibold l-mb-0 text-left">
            <div style="width: 100px">{{ 'commission.pool.related.list.th.contAdjPend' | lang }}</div>
          </th>
          <th scope="col" class="l-pa-4 cds-body2 cds-demibold l-mb-0 text-left">
            <div>{{ 'commission.pool.related.list.th.offset' | lang }}</div>
          </th>
          <th scope="col" class="l-pa-4 cds-body2 cds-demibold l-mb-0 text-left">
            <div style="width: 150px">{{ 'commission.pool.related.list.th.firstPcReleaseDate' | lang }}</div>
          </th>
          <th scope="col" class="l-pa-4 cds-body2 cds-demibold l-mb-0 text-left">
            <div style="width: 150px" class="l-d-f">
              <span>{{ 'commission.pool.related.list.th.apeDependAccount' | lang }}</span>
              <div style="width: 30px; margin: auto">
                <cds-icon
                  class="l-pl-1"
                  size="sm"
                  (click)="apeDependAccountExplain()"
                  style="cursor: pointer; min-width: initial"
                  icon="action:info_1"></cds-icon>
              </div>
            </div>
          </th>
          <th scope="col" class="l-pa-4 cds-body2 cds-demibold l-mb-0 text-left">
            <div style="width: 150px">{{ 'commission.pool.related.list.th.sysAdjustmentDate' | lang }}</div>
          </th>
          <th scope="col" class="l-pa-4 cds-body2 cds-demibold l-mb-0 text-left">
            <div style="width: 100px">{{ 'commission.pool.related.list.th.lastDate' | lang }}</div>
          </th>
        </tr>
        <ng-container *ngFor="let item of dataSource; let j = index">
          <tr>
            <td class="l-pa-4 l-pl-8">
              <div class="text-expand">
                {{ item.accountName }}
              </div>
            </td>
            <td class="l-pa-4 text-td">
              {{ item.commencementDate | ssmsDate }}
            </td>
            <td class="l-pa-4 text-td">
              {{ item.firstContributionDate | ssmsDate }}
            </td>
            <td class="l-pa-4">
              <span class="cds-body1-demibold db-click">{{ item.servicingAgents }}</span>
            </td>
            <td class="l-pa-4">
              <cds-checkbox
                [(ngModel)]="item.rejoin"
                (click)="formChange('rejoin', item, j)"
                [disabled]="!isEdit || isConfirm || item.isRejoinDisable"></cds-checkbox>
            </td>
            <td class="l-pa-4">
              <ng-template #contAdjPendDisplay>
                <span>{{ item.contAdjPend }}</span>
              </ng-template>
              <input
                *ngIf="isEdit && !isConfirm && item.contAdjPendEdit && (role | permission : permission | async); else contAdjPendDisplay"
                oninput="value=value.match(/(^(\d){1,20}\.(\d){0,2})|(^(\d){1,20})/g) || ''"
                [(ngModel)]="item.contAdjPend"
                (change)="pendChange($event, j)"
                placeholder=""
                class="text-field"
                style="width: 200px" />
            </td>
            <td class="l-pa-4">
              <span>/{{ item.offset }}</span>
            </td>
            <td class="l-pa-4 text-td">
              {{ item.firstPcReleaseDate | ssmsDate }}
            </td>
            <td class="l-pa-4 text-td">
              <ng-template #apeDependAccount>
                <span>{{ item.apeDependAccount }}</span>
              </ng-template>
              <input
                *ngIf="item.isApeDepAccEditable && isEdit && !isConfirm && (role | permission : permission2 | async); else apeDependAccount"
                [(ngModel)]="item.apeDependAccount"
                (change)="apeDependAccountChange($event, j)"
                placeholder=""
                class="text-field"
                style="width: 150px" />
              <div *ngIf="item.apeDependAccountError">
                <cds-assistive-text class="l-pt-2">
                  {{ 'commission.pool.related.list.th.apeDependAccountError' | lang }}
                </cds-assistive-text>
              </div>
              <div *ngIf="item.apeDependAccountWarning && !item.apeDependAccountError">
                <cds-assistive-text class="l-pt-2" type="warning">
                  {{ 'commission.pool.related.list.th.apeDependAccountWarning' | lang }}
                </cds-assistive-text>
              </div>
            </td>
            <td class="l-pa-4 text-td">
              {{ item.sysAdjustmentDate | ssmsDate }}
            </td>
            <td class="l-pa-4">
              <span>{{ item.lastDate | ssmsDate }}</span>
            </td>
          </tr>
        </ng-container>
      </table>
      <div *ngIf="dataSource.length === 0" style="float: left">
        <app-no-result-found></app-no-result-found>
      </div>
    </div>
  </div>

  <div *ngIf="pageCount.count !== 0 && !isEdit" class="col-xs-12 col-md-12 l-pt-4 l-pb-4">
    <app-pagination [pageCount]="pageCount" (pageChange)="pageChange($event)" [useOf]="true"></app-pagination>
  </div>

  <div *ngIf="!isEdit && (role | permission : permission2 | async)" class="col-xs-12 col-md-12 l-pt-4 l-pb-4 l-d-f cds-white">
    <div class="l-d-f l-ai-cen cursor-pointer" (click)="addNew()">
      <div class="icon-add">
        <cds-icon size="sm" color="white" icon="action:button_plus"></cds-icon>
      </div>
      <span class="cds-body2 cds-demibold l-mb-0 l-pl-3">{{ 'commission.pool.common.addNew' | lang }}</span>
    </div>
  </div>

  <div *ngIf="isEdit" class="l-pt-4 l-pb-4">
    <div *ngIf="!isConfirm" class="l-d-f">
      <cds-button [config]="btnCfg" size="md" (click)="reset()">{{ 'commission.pool.common.reset' | lang }}</cds-button>
      <cds-button [disabled]="saveDisable" class="l-ml-3" size="md" (click)="save()">{{ 'commission.pool.common.save' | lang }}</cds-button>
    </div>
    <div *ngIf="isConfirm" class="l-d-f">
      <cds-button [config]="btnCfg" size="md" (click)="backToEdit()">{{ 'commission.pool.common.backToEdit' | lang }} </cds-button>
      <cds-button class="l-ml-3" size="md" (click)="confirm()">{{ 'commission.pool.common.confirm' | lang }} </cds-button>
    </div>
  </div>
</div>
