<app-cd-popup>
  <cds-popup-title>
    <span class="cds-body-hero-light">Upload document</span>
  </cds-popup-title>
  <cds-popup-content>
    <div class="cds-h6 cds-demibold l-mb-4">File requirements</div>
    <div class="cds-detail1">• A maximum of .xslx file with file size limit of 10MB can be uploaded</div>
    <div class="cds-detail1 l-mb-6">• Please input file with <span class="text-underline" (click)="requestedFormat()">requested format</span>.</div>
    <div class="upload-area l-mb-4" [class.file-error]="errorMessage">
      <cds-file-upload [formControl]="uploadFormControl" (change)="fileChange($event)" [errorMessage]="errorMessage" acceptedFiles="*" [uploading]="uploading">
      </cds-file-upload>
      <div class="l-mt-2" *ngIf="errorMessage">
        <cds-assistive-text>{{ errorMessage | lang }}</cds-assistive-text>
      </div>
    </div>
  </cds-popup-content>
  <cds-popup-actions>
    <cds-button size="sm" [style]="'secondary'" (click)="cancel()">{{ 'common.cancel' | lang }}</cds-button>
    <cds-button size="sm" (click)="confirm()" [disabled]="confirmDisabled">{{ 'common.confirm' | lang }}</cds-button>
  </cds-popup-actions>
</app-cd-popup>
