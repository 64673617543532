import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { BatchUploadAdjustmentComponent } from './batch-upload-adjustment.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { UploadAdjustmentDataComponent } from './upload-adjustment-data/upload-adjustment-data.component';
import { CdsFileUploadModule } from '@cds/ng-experimental/file-upload';
import { UploadFileFormatComponent } from './upload-file-format/upload-file-format.component';
@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule, CdsFileUploadModule],
  declarations: [BatchUploadAdjustmentComponent, UploadAdjustmentDataComponent, UploadFileFormatComponent],
})
export class BatchUploadAdjustmentModule {}
