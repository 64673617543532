<app-cd-popup>
  <cds-popup-content>
    <div class="cds-body-hero-light l-mb-4">User Guide on batch upload the Commission Adjustment:</div>
    <ul class="cds-body2">
      <li>Please refer the <span class="cds-green-text cursor-pointer" (click)="createTemplate()">standard template</span> to upload</li>
      <li>
        <span class="cds-demibold">Maximum 100 rows</span>
        (Except the header) in one file
      </li>
      <li>
        <span>[Col A# – Scenario ] Possible Value:</span>
        <div class="cds-demibold">N1 / N2 / N3 / N4 / I1</div>
      </li>
      <li>
        <span>[Col B# – Account Type ] Possible Value:</span>
        <div class="cds-demibold">MPF-ER/ MPF-SEP/ MPF-SEP(AGENT)/ MPF-FRC/ MPF-TVC/ ORSO-ER/ MACAU-ER(PPS)/ MACAU-ER(CPF)/ MPF-PAP/ MACAU-PAP/MACAU-IVC</div>
      </li>
      <li>
        <span>[Col C# – Employer/Member Acc Code ]</span>
        <div>Input Employer Account Code if account type is <span class="cds-demibold">MPF-ER/ ORSO-ER/ MACAU-ER(PPS)/ MACAU-ER(CPF)</span></div>
        <div>
          Input Member Account Code if account type is
          <span class="cds-demibold">MPF-SEP/ MPF-SEP(AGENT)/ MPF-FRC/ MPF-TVC/ MPF-PAP/ MACAU-PAP/ MACAU-IVC</span>
        </div>
        <div>in “Employer/Member Acc Code” [Column B] field</div>
      </li>
      <li>
        <span>[Col D% – Member Account Code [PAC Flag]] </span>
        <div>
          Only allow to input the Member Account Code if Col A-Section is “I1” & Col B-Account Type is
          <span class="cds-body2-demibold">MPF-ER/ MPF-SEP/ MPF-SEP(AGENT)</span>
        </div>
      </li>
      <li>
        <span>[Col E# – Agent Code] is only allowed to input <span class="cds-body2-demibold">up-to 6 digit</span></span>
      </li>
      <li>
        <span>[Col F# – Negative Flag] is only allowed to input <span class="cds-body2-demibold">blank or Y</span></span>
      </li>
      <li>
        <span class="l-mb-2">[Col G to L^ ] Column G,H,I,J,K,L,M is corresponding the updating by different Scenario</span>
        <table class="l-mb-4">
          <caption></caption>
          <thead>
            <tr>
              <th class="l-pa-2 cds-detail1 cds-demibold" style="width: 90px"><span></span></th>
              <th class="l-pa-2 cds-detail1 cds-demibold"><span>A</span></th>
              <th class="l-pa-2 cds-detail1 cds-demibold"><span>G</span></th>
              <th class="l-pa-2 cds-detail1 cds-demibold"><span>H</span></th>
              <th class="l-pa-2 cds-detail1 cds-demibold"><span>I</span></th>
              <th class="l-pa-2 cds-detail1 cds-demibold"><span>J</span></th>
              <th class="l-pa-2 cds-detail1 cds-demibold"><span>K</span></th>
              <th class="l-pa-2 cds-detail1 cds-demibold"><span>L</span></th>
            </tr>
            <tr>
              <th class="l-pa-2 cds-detail1"><span></span></th>
              <th class="l-pa-2 cds-detail1"><span>Scenario</span></th>
              <th class="l-pa-2 cds-detail1"><span>HKPC AMT</span></th>
              <th class="l-pa-2 cds-detail1"><span>COMM AMT</span></th>
              <th class="l-pa-2 cds-detail1"><span>HKPC / COMM PREM</span></th>
              <th class="l-pa-2 cds-detail1"><span>SF/OTHER AMT</span></th>
              <th class="l-pa-2 cds-detail1"><span>SF/PREM</span></th>
              <th class="l-pa-2 cds-detail1"><span>CASE COUNT</span></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="l-pa-2 cds-detail1">
                <div>MPF-ER</div>
                <div>ORSO-ER</div>
                <div>MACAU-ER(PPS)</div>
                <div>MACAU-ER(CPF)</div>
                <div>MPF-SEP</div>
                <div>MPF-SEP(AGENT)</div>
                <div>MPF-TVC</div>
                <div>MACAU-IVC</div>
              </td>
              <td class="l-pa-2 cds-detail1">N1</td>
              <td class="l-pa-2 cds-detail1">HKPC for FS HKPC Scale</td>
              <td class="l-pa-2 cds-detail1">COMM for 1st Year COMM Scale</td>
              <td class="l-pa-2 cds-detail1">Premium for Column G/H</td>
              <td class="l-pa-2 cds-detail1">Service Fee for FS Service Fee Scale[Not apply for MPF-TVC / MACAU-IVC]</td>
              <td class="l-pa-2 cds-detail1">Premium for Column J [Not apply for MPF-TVC / MACAU-IVC]</td>
              <td class="l-pa-2 cds-detail1">Case Count</td>
            </tr>
            <tr>
              <td class="l-pa-2 cds-detail1">
                <div>ORSO-ER</div>
                <div>MACAU-ER(PPS)</div>
                <div>MACAU-ER(CPF)</div>
              </td>
              <td class="l-pa-2 cds-detail1">N2</td>
              <td class="l-pa-2 cds-detail1">HKPC for PS HKPC Scale</td>
              <td class="l-pa-2 cds-detail1">COMM for PS COMM Scale</td>
              <td class="l-pa-2 cds-detail1">Premium for Column G/H</td>
              <td class="l-pa-2 cds-detail1">Service Fee for PS Service Fee Scale</td>
              <td class="l-pa-2 cds-detail1">Premium for Column J</td>
              <td class="l-pa-2 cds-detail1">Case Count</td>
            </tr>
            <tr>
              <td class="l-pa-2 cds-detail1">
                <div>MPF-ER</div>
                <div>ORSO-ER</div>
                <div>MACAU-ER(PPS)</div>
                <div>MACAU-ER(CPF)</div>
                <div>MPF-FRC</div>
              </td>
              <td class="l-pa-2 cds-detail1">N3</td>
              <td class="l-pa-2 cds-detail1">HKPC for TRF SP HKPC Scale</td>
              <td class="l-pa-2 cds-detail1">COMM for TRF SP COMM Scale</td>
              <td class="l-pa-2 cds-detail1">Premium for Column G/H</td>
              <td class="l-pa-2 cds-detail1"></td>
              <td class="l-pa-2 cds-detail1"></td>
              <td class="l-pa-2 cds-detail1">Case Count</td>
            </tr>
            <tr>
              <td class="l-pa-2 cds-detail1">
                <div>MPF-TVC</div>
                <div>MACAU-IVC</div>
              </td>
              <td class="l-pa-2 cds-detail1">N4</td>
              <td class="l-pa-2 cds-detail1">HKPC for RY HKPC Scale</td>
              <td class="l-pa-2 cds-detail1">Service Fee for FS Service Fee Scale</td>
              <td class="l-pa-2 cds-detail1">Premium for Column G/H</td>
              <td class="l-pa-2 cds-detail1"></td>
              <td class="l-pa-2 cds-detail1"></td>
              <td class="l-pa-2 cds-detail1">Case Count</td>
            </tr>
            <tr>
              <td class="l-pa-2 cds-detail1">
                <div>MPF-ER</div>
                <div>MPF-SEP</div>
                <div>MPF-SEP(AGENT)</div>
                <div>MPF-TVC</div>
                <div>MPF-PAP</div>
                <div>MACAU-PAP</div>
                <div>MACAU-IVC</div>
              </td>
              <td class="l-pa-2 cds-detail1">I1</td>
              <td class="l-pa-2 cds-detail1">HKPC for TRF SP HKPC Scale(Individual Transfer)</td>
              <td class="l-pa-2 cds-detail1">COMM for TRF SP COMM Scale(Individual Transfer)</td>
              <td class="l-pa-2 cds-detail1">Premium for Column G/H</td>
              <td class="l-pa-2 cds-detail1">Special PC for FS HKPC [Only apply for MPF-PAP]</td>
              <td class="l-pa-2 cds-detail1"></td>
              <td class="l-pa-2 cds-detail1">Case Count</td>
            </tr>
          </tbody>
        </table>
      </li>
      <li>
        <span><span class="cds-demibold">Col A#, Col B, Col C#, Col E#, Col F#</span> are compulsory fields.</span>
      </li>
      <li>
        <span>Col D% is compulsory field for <span class="cds-demibold">MPF-ER</span> only</span>
      </li>
      <li>
        <span><span class="cds-demibold">Col G to M</span> at least one field that need to input</span>
      </li>
    </ul>
  </cds-popup-content>
  <cds-popup-actions>
    <cds-button size="sm" (click)="cancel()">{{ 'common.gotIt' | lang }}</cds-button>
  </cds-popup-actions>
</app-cd-popup>
