<div class="l-d-f l-jc-cen" *ngIf="loading">
  <cds-progress mode="indeterminate" class="l-pl-6"></cds-progress>
</div>
<div *ngIf="!loading" class="row l-pa-0">
  <div class="col-xs-12 col-md-8 l-plr-0">
    <div class="l-d-f l-jc-sb">
      <div>
        <h3 class="l-mt-0 l-mb-4">
          {{ 'commission.ape.nbvRatio' | lang }}
        </h3>
        <p class="cds-body2 l-mt-0" *ngIf="updateTime && updatePeople">
          <span>{{ 'commission.ape.latestUpdate' | lang : { p: updateTime } }}</span>
          <span>({{ 'common.HKT' | lang }})</span>,
          <span>{{ 'commission.ape.by' | lang : { p: updatePeople } }}</span>
        </p>
      </div>
      <app-permission [role]="permissionAccess.W" [permission]="permissionItem.COMM_APE_MANUAL_ADJ_NBV_RATIO" class="l-d-f l-ai-cen">
        <div class="l-pr-4 l-d-f l-ai-cen" (click)="changeMode()">
          <cds-icon class="pc-show" size="sm" [icon]="'action:edit'"></cds-icon>
          <button *ngIf="!isEdit" class="button-border-none-font btn-text">
            {{ 'common.edit' | lang }}
          </button>
          <button *ngIf="isEdit" class="button-border-none-font btn-text">
            {{ 'common.cancel.editing' | lang }}
          </button>
        </div>
      </app-permission>
    </div>
  </div>

  <form [formGroup]="formGroup" class="row l-pa-0 l-mt-4">
    <div class="col-xs-12 col-md-8 l-plr-0" formArrayName="ratio">
      <div class="div-border cds-white l-pa-4">
        <div class="row l-plr-0 div-border-top div-border-bottom l-pt-2 l-pb-2">
          <div class="col-xs-5">
            <span class="cds-body1-demibold">{{ 'commission.ape.item' | lang }}</span>
          </div>
          <div class="col-xs-7">
            <span class="cds-body1-demibold">{{ 'commission.ape.nbvRatioUpper' | lang }}</span>
          </div>
        </div>
        <div class="row l-plr-0 div-border-bottom l-pt-4 l-pb-5" *ngFor="let item of nbvRatioItems; let index = index">
          <div class="col-xs-5">
            <span class="cds-body1-regular break">{{ item.label | lang }}</span>
          </div>
          <div class="col-xs-7" *ngIf="isEdit">
            <cds-textfield
              class="cds-body1-regular"
              (input)="ratioInput($event, index)"
              (change)="ratioChange($event, index)"
              [placeholder]="'commission.ape.nbvRatio.placeholder' | lang"
              ngDefaultControl
              [formControlName]="index.toString()">
            </cds-textfield>
            <div *ngIf="ratio.controls[index].errors" class="l-pt-2 l-mt-2">
              <cds-assistive-text>{{ ratio.controls[index].errors?.['error'] | lang }} </cds-assistive-text>
            </div>
          </div>
          <div class="col-xs-7 l-d-f l-ai-cen" *ngIf="!isEdit">{{ ratio.controls[index].value }}</div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-12 l-mt-5 l-pl-0" *ngIf="isEdit">
      <cds-button type="submit" class="l-mr-3" (click)="reset()" [disabled]="isResetDisable" [style]="'secondary'" size="md"
        >{{ 'common.reset' | lang }}
      </cds-button>
      <cds-button [disabled]="isSaveDisable" type="submit" size="md" (click)="save()">{{ 'common.save' | lang }} </cds-button>
    </div>
  </form>
</div>
