<app-loading class="p" [overlay]="true" [overlayInPopup]="true" *ngIf="isLoading"></app-loading>

<app-employer-profile-title
  [employerProfileDetail]="employerProfileDetail"
  [showQuickAccessBtn]="true"
  (erProfileInfoDetailShow)="erProfileInfoDetailShow()"
  (stickyStatusChange)="showPlaceholderHeight($event)"></app-employer-profile-title>

<div *ngIf="isHeaderSticky" class="placeholder-height"></div>

<div class="row l-pb-4">
  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>
</div>

<app-employer-profile-information
  #erProfileInfo
  [relationshipTotalCount]="relationshipTotalCount"
  [data]="employerProfileDetail"></app-employer-profile-information>

<app-agent-info *ngIf="isStaff" [id]="employerProfileDetail?.id"></app-agent-info>

<app-employer-profile-billing-class [id]="employerProfileDetail?.id"></app-employer-profile-billing-class>

<app-vesting-scale *ngIf="isStaff" [id]="employerProfileDetail?.id"></app-vesting-scale>

<app-charge-info *ngIf="isStaff && _typeShow" [employerId]="employerProfileDetail?.id"></app-charge-info>

<app-formula-class *ngIf="isStaff && _typeShow" [employerId]="employerProfileDetail?.id"></app-formula-class>

<app-employer-profile-company-list
  *ngIf="employerProfileDetail?.id && haveSubsidiaryPermission"
  [relationshipTotalCount]="relationshipTotalCount"
  (updateTotalScore)="getTotalScore(id)"
  [employerDetaildata]="employerProfileDetail"></app-employer-profile-company-list>

<app-more-info-details [id]="employerProfileDetail?.id" [employerProfileDetail]="employerProfileDetail" [isStaff]="isStaff"></app-more-info-details>

<app-back-top></app-back-top>
