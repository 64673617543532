import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsLangService } from '@cds/ng-core/lang';
import { ActionIcons } from '@cds/ng-themes';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { Subscription } from 'rxjs';
import { NotifyService } from 'src/app/core/services/notify.service';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { CdPopupService, CdPopupType, MatDialogRef } from 'src/app/shared/cd-popup';
import { CdRadioComponent, CdRadioConfig } from 'src/app/shared/cd-radio/cd-radio.component';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { PreferredLanguageType, SalesJourneyProdType } from '../../case-summary/case-summary.model';
import { empfCountryOption } from '../../case-summary/declaration-iff-er/empf-declaration-form.config';
import { ResetWarningComponent } from '../../iff-paper-form/reset-warning/reset-warning.component';
import { HasBeenSentCustomerPopupComponent } from '../has-been-sent-customer-popup/has-been-sent-customer-popup.component';
import { PendingSplitAgentConfirPopupComponent } from '../pending-split-agent-confir-popup/pending-split-agent-confir-popup.component';
import { WillBeLoseInputComponent } from 'src/app/shared/will-be-lose-input/will-be-lose-input.component';
import { ValidateService } from 'src/app/core/services/validate/validate.service';
import { pageToTop } from 'src/app/utils/utils';

enum Show {
  employer = 'employer',
  intermediary = 'intermediary',
}

interface AgentInfo {
  agentId: string;
  agentCode: string;
  agentName: string;
  agentNameTC: string;
  mobileNumber: string;
  emailAddress: string;
  branchCode: string;
  agentCategory: string;
  licenseNumber: string;
  licenseCode: string;
  licenseStatus: string;
  status: string;
  shortEmail?: string;
  shortPhone?: string;
}

interface AddEmployerParams {
  email?: string;
  phoneCountry?: string;
  phone?: string;
  isRegulatedActivities?: boolean;
  intraGroup?: boolean;
  commChannel?: 'SMS' | 'EMAIL';
  secondAgent?: {
    agentCode?: string;
    isRegulatedActivities?: boolean;
    commChannel?: 'SMS' | 'EMAIL';
    language?: 'CN' | 'EN';
  };
  thirdAgent?: {
    agentCode?: string;
    isRegulatedActivities?: boolean;
    commChannel?: 'SMS' | 'EMAIL';
    language?: 'CN' | 'EN';
  };
  language?: 'CN' | 'EN';
  productTypes?: string[];
  employerName?: string;
  businessRegisterNo?: string;
}

@Component({
  selector: 'app-employer-for-md',
  templateUrl: './employer-for-md.component.html',
  styleUrls: ['./employer-for-md.component.scss'],
})
export class EmployerForMdComponent implements OnInit, OnDestroy {
  @ViewChild('cdRadioPreferredLanguage') cdRadioPreferredLanguage?: CdRadioComponent;
  @ViewChild('radioRelevantRegulatedActivities') radioRelevantRegulatedActivities!: CdRadioComponent;
  @ViewChild('radioInTransferValue') radioInTransferValue!: CdRadioComponent;
  @ViewChild('radioSecondAgentRelevantRegulatedActivities') radioSecondAgentRelevantRegulatedActivities!: CdRadioComponent;
  @ViewChild('radioSecondAgentClientContact') radioSecondAgentClientContact?: CdRadioComponent;
  @ViewChild('radioSecondAgentLanguage') radioSecondAgentLanguage?: CdRadioComponent;
  @ViewChild('radioThirdAgentRelevantRegulatedActivities') radioThirdAgentRelevantRegulatedActivities!: CdRadioComponent;
  @ViewChild('radioThirdAgentClientContact') radioThirdAgentClientContact?: CdRadioComponent;
  @ViewChild('radioThirdAgentLanguage') radioThirdAgentLanguage?: CdRadioComponent;
  PreferredLanguageType = PreferredLanguageType;
  Show = Show;
  show = Show.employer;
  isConfirming = false;
  backIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };
  private readonly CN_PHONE = /^1[345678]\d{9}$/;
  backIcon = ActionIcons.button_left_filled;
  employerForm!: FormGroup;
  authpersonContact = '';
  preferredLanguageConfig = {
    options: [
      {
        label: 'common.chinese',
        value: PreferredLanguageType.Chinese,
        disable: true,
      },
      {
        label: 'common.english',
        value: PreferredLanguageType.English,
        disable: true,
      },
    ],
  };
  lanChangeSubscription?: Subscription;
  language = 'EN';
  authpersonContactConfig: CdRadioConfig = {
    options: [
      {
        label: 'Email Address',
        value: 'E',
        disable: false,
      },
      {
        label: 'Phone Number',
        value: 'M',
        disable: false,
      },
    ],
  };
  resetDatePicker = false;
  secondAgentInfo?: AgentInfo;
  thirdAgentInfo?: AgentInfo;
  isTryConfirm = false;
  countrycodeConfig: CdsDropdownConfig = {
    options: empfCountryOption,
    placeholder: '+852 (HK)',
    color: 'ml',
  };
  employerFullNameOrBrNumber = '';
  employerFullNameOrBrNumberConfig: CdRadioConfig = {
    options: [
      {
        label: '僱主全名',
        value: 'N',
        disable: false,
      },
      {
        label: '商業登記號碼',
        value: 'B',
        disable: false,
      },
    ],
  };

  relevantRegulatedActivitiesValue = '';
  relevantRegulatedActivitiesConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.yes'),
        value: 'y',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.no'),
        value: 'n',
        disable: false,
      },
    ],
  };

  inTransferValue = '';
  inTransferConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.yes'),
        value: 'y',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.no'),
        value: 'n',
        disable: false,
      },
    ],
  };

  secondAgentRelevantRegulatedActivities = '';
  secondAgentRelevantRegulatedActivitiesConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.yes'),
        value: 'Y',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.no'),
        value: 'N',
        disable: false,
      },
    ],
  };
  secondAgentClientContact = '';
  secondAgentClientContactConfig?: CdRadioConfig;
  secondAgentLanguage = '';
  secondAgentLanguageConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.chinese'),
        value: 'ZH',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.english'),
        value: 'EN',
        disable: false,
      },
    ],
  };

  thirdAgentRelevantRegulatedActivities = '';
  thirdAgentRelevantRegulatedActivitiesConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.yes'),
        value: 'Y',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.no'),
        value: 'N',
        disable: false,
      },
    ],
  };
  thirdAgentClientContact = '';
  thirdAgentClientContactConfig?: CdRadioConfig;
  thirdAgentLanguage = '';
  thirdAgentLanguageConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.chinese'),
        value: 'ZH',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.english'),
        value: 'EN',
        disable: false,
      },
    ],
  };

  defaultFormGroupValues = {
    idType: '0',
    authpersonEmailAddress: null,
    authpersonCountryCode: '852',
    authpersonPhoneNumber: '',
    preferredLanguage: null,
    employerFullName: '',
    BRNumber: '',
    secondAgentCode: '',
    thirdAgentCode: '',
  };

  tabIndex!: number;
  prodType?: SalesJourneyProdType;

  secondAgentCodeError = false;
  thirdAgentCodeError = false;

  get preferredLanguage() {
    return this.employerForm.get('preferredLanguage') as FormControl;
  }

  get authpersonEmailAddress() {
    return this.employerForm.get('authpersonEmailAddress') as FormControl;
  }

  get authpersonPhoneNumber() {
    return this.employerForm.get('authpersonPhoneNumber') as FormControl;
  }

  get authpersonCountryCode() {
    return this.employerForm.get('authpersonCountryCode') as FormControl;
  }

  get employerFullName() {
    return this.employerForm.get('employerFullName') as FormControl;
  }

  get BRNumber() {
    return this.employerForm.get('BRNumber') as FormControl;
  }

  get idType() {
    return this.employerForm.get('idType') as FormControl;
  }

  get secondAgentCode() {
    return this.employerForm.get('secondAgentCode') as FormControl;
  }

  get thirdAgentCode() {
    return this.employerForm.get('thirdAgentCode') as FormControl;
  }

  get isChangedAnyValues() {
    if (this.defaultFormGroupValues) {
      if (
        !(
          this.defaultFormGroupValues.idType === this.idType.value &&
          this.defaultFormGroupValues.authpersonEmailAddress === this.authpersonEmailAddress.value &&
          this.defaultFormGroupValues.authpersonCountryCode === this.authpersonCountryCode.value &&
          this.defaultFormGroupValues.authpersonPhoneNumber === this.authpersonPhoneNumber.value &&
          this.defaultFormGroupValues.preferredLanguage === this.preferredLanguage.value &&
          this.defaultFormGroupValues.employerFullName === this.employerFullName.value &&
          this.defaultFormGroupValues.BRNumber === this.BRNumber.value &&
          this.employerFullNameOrBrNumber === '' &&
          this.relevantRegulatedActivitiesValue === '' &&
          this.inTransferValue === '' &&
          this.defaultFormGroupValues.secondAgentCode === this.secondAgentCode.value &&
          this.defaultFormGroupValues.thirdAgentCode === this.thirdAgentCode.value
        )
      ) {
        return true;
      }
    }
    return false;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public cdsLangService: CdsLangService,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    private notifyService: NotifyService,
    private languageChangeService: LanguageChangeService,
    private cdsPopup: CdPopupService,
    private validateService: ValidateService
  ) {}

  private beforeunload = (event: BeforeUnloadEvent) => {
    if (this.isChangedAnyValues) {
      event.preventDefault();
      event.returnValue = '';
    }
  };

  private popstate = () => {
    const popupRef: MatDialogRef<WillBeLoseInputComponent> = this.cdsPopup.open(WillBeLoseInputComponent, {
      size: 'sm',
    });
    popupRef.afterClosed().subscribe(({ agree }) => {
      if (agree) {
        this.router.navigate([
          '/salesjourney/md-iff-summary',
          {
            tabIndex: this.tabIndex,
            prodType: this.prodType,
          },
        ]);
      } else {
        history.pushState(null, '', document.URL);
      }
    });
  };

  ngOnInit(): void {
    window.addEventListener('beforeunload', this.beforeunload);
    history.pushState(null, '', document.URL);
    window.addEventListener('popstate', this.popstate);

    this.tabIndex = Number(this.route.snapshot.paramMap.get('tabIndex'));
    this.prodType = (this.route.snapshot.paramMap.get('prodType') as SalesJourneyProdType) || undefined;

    this.lanChangeSubscription = this.languageChangeService.lanSubject.subscribe(this.lanSubjectChange);

    this.employerForm = new FormGroup({
      idType: new FormControl(this.defaultFormGroupValues.idType),
      authpersonEmailAddress: new FormControl(this.defaultFormGroupValues.authpersonEmailAddress, [this.authpersonEmailAddressVali]),
      authpersonCountryCode: new FormControl(this.defaultFormGroupValues.authpersonCountryCode),
      authpersonPhoneNumber: new FormControl(this.defaultFormGroupValues.authpersonPhoneNumber, [this.authpersonPhoneNumberVali]),
      preferredLanguage: new FormControl(this.defaultFormGroupValues.preferredLanguage, [this.preferredLanguageVali]),
      employerFullName: new FormControl(this.defaultFormGroupValues.employerFullName, [this.employerFullNameVali]),
      BRNumber: new FormControl(this.defaultFormGroupValues.BRNumber, [this.brNumberVali]),
      secondAgentCode: new FormControl(this.defaultFormGroupValues.secondAgentCode, {
        asyncValidators: [this.validateService.agentValidate],
      }),
      thirdAgentCode: new FormControl(this.defaultFormGroupValues.thirdAgentCode, {
        asyncValidators: [this.validateService.agentValidate],
      }),
    });
  }

  employerFullNameVali = (control: AbstractControl): ValidationErrors | null => {
    if (this.employerFullNameOrBrNumber === 'N') {
      let input = control.value || '';
      input = input.trim();
      if (input.length === 0) {
        return { required: true };
      }
    }
    return null;
  };

  brNumberVali = (control: AbstractControl): ValidationErrors | null => {
    if (this.employerFullNameOrBrNumber === 'B') {
      let input = control.value || '';
      input = input.trim();
      if (input.length === 0) {
        return { required: true };
      }
    }
    return null;
  };

  authpersonPhoneNumberVali = (option: AbstractControl): ValidationErrors | null => {
    if (this.authpersonContact === 'M') {
      if (!option.value) {
        return { required: true };
      }

      if (typeof option.value === 'string') {
        if (['852', '853'].includes(this.authpersonCountryCode.value)) {
          if (option.value.length === 8 && ['4', '5', '6', '7', '8', '9'].includes(option.value.substring(0, 1))) {
            return null;
          }
        }

        if (['86'].includes(this.authpersonCountryCode.value)) {
          if (option.value.length === 11) {
            const e = this.CN_PHONE.test(option.value);
            if (e) {
              return null;
            }
          }
        }

        if (!['852', '853', '86'].includes(this.authpersonCountryCode.value)) {
          return null;
        }
      }

      return { errorPhoneNumber: true };
    }

    return null;
  };

  authpersonEmailAddressVali = (option: AbstractControl): ValidationErrors | null => {
    if (this.authpersonContact === 'E') {
      if (!option.value) {
        return { required: true };
      }

      if (typeof option.value === 'string') {
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.exec(option.value) ? null : { email: true };
      }
    }
    return null;
  };

  authpersonContactChange() {
    this.preferredLanguage.setValue(undefined);
    this.preferredLanguageConfig = {
      options: [
        {
          label: 'common.chinese',
          value: PreferredLanguageType.Chinese,
          disable: false,
        },
        {
          label: 'common.english',
          value: PreferredLanguageType.English,
          disable: false,
        },
      ],
    };
    this.cdRadioPreferredLanguage?.refreshConfig();
    if (this.authpersonContact === 'E') {
      this.authpersonPhoneNumber.updateValueAndValidity();
    }
  }

  preferredLanguageVali = (control: AbstractControl): ValidationErrors | null => {
    if ((this.authpersonContact === 'M' && control.value) || this.authpersonContact === 'E' || !this.authpersonContact) {
      return null;
    }
    return { formatError: true };
  };

  authpersonCountryCodeChange() {
    this.authpersonPhoneNumber.updateValueAndValidity();
  }

  lanSubjectChange = (lan: string) => {
    this.language = lan === 'en' ? 'EN' : 'TC';

    this.authpersonContactConfig = {
      options: [
        {
          label: this.cdsLangService.translate('generate.md.emailAddress'),
          value: 'E',
          disable: false,
        },
        {
          label: this.cdsLangService.translate('generate.md.phoneNumber'),
          value: 'M',
          disable: false,
        },
      ],
    };

    this.employerFullNameOrBrNumberConfig = {
      options: [
        {
          label: this.cdsLangService.translate('generate.md.employer-full-name'),
          value: 'N',
          disable: false,
        },
        {
          label: this.cdsLangService.translate('generate.md.br-bumber'),
          value: 'B',
          disable: false,
        },
      ],
    };

    this.refreshRelevantRegulatedActivitiesConfig();
    this.refreshinTransferConfig();
    this.refreshSecondAgentClientContactConfig();
    this.refreshThirdAgentClientContactConfig();
    this.refreshSecondAgentRelevantRegulatedActivitiesConfig();
    this.refreshThirdAgentRelevantRegulatedActivitiesConfig();
  };

  private refreshSecondAgentClientContactConfig() {
    if (!this.secondAgentInfo) {
      return;
    }
    this.secondAgentInfo.shortEmail = '';
    if (this.secondAgentInfo.emailAddress) {
      const ea = this.secondAgentInfo.emailAddress || '';
      const endIndex = ea.indexOf('@');
      this.secondAgentInfo.shortEmail = ea.substring(0, 4) + '*****' + ea.substring(endIndex);
    }
    this.secondAgentInfo.shortPhone = '';
    if (this.secondAgentInfo.mobileNumber && this.secondAgentInfo.mobileNumber.length) {
      this.secondAgentInfo.shortPhone = '****' + this.secondAgentInfo.mobileNumber.slice(4);
    }

    this.secondAgentClientContactConfig = {
      options: [
        {
          label: `${this.cdsLangService.translate('generate.md.registered-email-address')}${
            this.secondAgentInfo.shortEmail ? ' (' + this.secondAgentInfo.shortEmail + ')' : ''
          }`,
          value: 'E',
          disable: !this.secondAgentInfo.shortEmail,
        },
        {
          label: `${this.cdsLangService.translate('generate.md.registered-phone-number')}${
            this.secondAgentInfo.shortPhone ? ' (+852) ' + this.secondAgentInfo.shortPhone : ''
          }`,
          value: 'M',
          disable: !this.secondAgentInfo.shortPhone,
        },
      ],
    };

    if (!this.secondAgentInfo.shortEmail && this.secondAgentInfo.shortPhone) {
      this.secondAgentClientContact = 'M';
    } else if (this.secondAgentInfo.shortEmail && !this.secondAgentInfo.shortPhone) {
      this.secondAgentClientContact = 'E';
    }
    this.radioSecondAgentClientContact?.refreshConfig();
  }

  private refreshThirdAgentClientContactConfig() {
    if (!this.thirdAgentInfo) {
      return;
    }
    this.thirdAgentInfo.shortEmail = '';
    if (this.thirdAgentInfo.emailAddress) {
      const ea = this.thirdAgentInfo.emailAddress || '';
      const endIndex = ea.indexOf('@');
      this.thirdAgentInfo.shortEmail = ea.substring(0, 4) + '*****' + ea.substring(endIndex);
    }
    this.thirdAgentInfo.shortPhone = '';
    if (this.thirdAgentInfo.mobileNumber && this.thirdAgentInfo.mobileNumber.length) {
      this.thirdAgentInfo.shortPhone = '****' + this.thirdAgentInfo.mobileNumber.slice(4);
    }

    this.thirdAgentClientContactConfig = {
      options: [
        {
          label: `${this.cdsLangService.translate('generate.md.registered-email-address')}${
            this.thirdAgentInfo.shortEmail ? ' (' + this.thirdAgentInfo.shortEmail + ')' : ''
          }`,
          value: 'E',
          disable: !this.thirdAgentInfo.shortEmail,
        },
        {
          label: `${this.cdsLangService.translate('generate.md.registered-phone-number')}${
            this.thirdAgentInfo.shortPhone ? ' (+852) ' + this.thirdAgentInfo.shortPhone : ''
          }`,
          value: 'M',
          disable: !this.thirdAgentInfo.shortPhone,
        },
      ],
    };

    if (!this.thirdAgentInfo.shortEmail && this.thirdAgentInfo.shortPhone) {
      this.thirdAgentClientContact = 'M';
    } else if (this.thirdAgentInfo.shortEmail && !this.thirdAgentInfo.shortPhone) {
      this.thirdAgentClientContact = 'E';
    }
    this.radioThirdAgentClientContact?.refreshConfig();
  }

  reset() {
    if (!this.isChangedAnyValues) {
      return;
    }
    const popupRef: MatDialogRef<ResetWarningComponent> = this.cdsPopup.open(ResetWarningComponent, {
      size: 'sm',
    });
    popupRef.afterClosed().subscribe(this.resetWarningComponentPopupCloseCall);
  }

  resetWarningComponentPopupCloseCall = (confirm: { agree: boolean }) => {
    if (confirm.agree) {
      this.secondAgentCodeError = false;
      this.thirdAgentCodeError = false;
      this.employerForm.reset(this.defaultFormGroupValues);
      this.isTryConfirm = false;
      this.relevantRegulatedActivitiesValue = '';
      this.inTransferValue = '';
      this.authpersonContact = '';
      this.employerFullNameOrBrNumber = '';
      this.resetDatePicker = true;
      this.secondAgentInfo = undefined;
      this.thirdAgentInfo = undefined;
      setTimeout(() => {
        this.resetDatePicker = false;
      }, 0);
      this.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: true,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: true,
          },
        ],
      };
      this.cdRadioPreferredLanguage?.refreshConfig();
    }
  };

  get isDisableNext() {
    return (
      this.employerForm.invalid ||
      this.employerFullNameOrBrNumber === '' ||
      this.authpersonContact === '' ||
      this.relevantRegulatedActivitiesValue === '' ||
      this.inTransferValue === ''
    );
  }

  get getSecondAgentName() {
    if (!this.secondAgentInfo) {
      return 'N/A';
    }
    if (this.cdsLangService.currentLang === 'en') {
      return this.secondAgentInfo.agentName || this.secondAgentInfo.agentNameTC;
    } else {
      return this.secondAgentInfo.agentNameTC || this.secondAgentInfo.agentName;
    }
  }

  get getThirdAgentName() {
    if (!this.thirdAgentInfo) {
      return 'N/A';
    }
    if (this.cdsLangService.currentLang === 'en') {
      return this.thirdAgentInfo.agentName || this.thirdAgentInfo.agentNameTC;
    } else {
      return this.thirdAgentInfo.agentNameTC || this.thirdAgentInfo.agentName;
    }
  }

  next() {
    if (this.isDisableNext) {
      return;
    }

    this.secondAgentInfo = undefined;
    this.thirdAgentInfo = undefined;
    this.secondAgentRelevantRegulatedActivities = '';
    this.secondAgentLanguage = '';
    this.secondAgentClientContact = '';
    this.secondAgentRelevantRegulatedActivitiesConfig.options[0].disable = false;
    this.secondAgentRelevantRegulatedActivitiesConfig.options[1].disable = false;
    this.refreshSecondAgentRelevantRegulatedActivitiesConfig();
    this.thirdAgentRelevantRegulatedActivities = '';
    this.thirdAgentLanguage = '';
    this.thirdAgentClientContact = '';
    this.thirdAgentRelevantRegulatedActivitiesConfig.options[0].disable = false;
    this.thirdAgentRelevantRegulatedActivitiesConfig.options[1].disable = false;
    this.refreshThirdAgentRelevantRegulatedActivitiesConfig();

    if (this.employerForm.valid) {
      const agentCodeList = [];
      if (this.secondAgentCode.value) {
        agentCodeList.push(this.secondAgentCode.value);
      }
      if (this.thirdAgentCode.value) {
        agentCodeList.push(this.thirdAgentCode.value);
      }
      if (agentCodeList.length > 0) {
        this.getAgentInfoByAgentCode(agentCodeList).then(agentInfoList => {
          const tempAgentCode2 = agentInfoList.find(item => item.agentCode === this.secondAgentCode.value);
          const tempAgentCode3 = agentInfoList.find(item => item.agentCode === this.thirdAgentCode.value);
          if (tempAgentCode2) {
            this.secondAgentInfo = tempAgentCode2;
          } else if (this.secondAgentCode.value) {
            this.secondAgentCodeError = true;
          }
          if (tempAgentCode3) {
            this.thirdAgentInfo = tempAgentCode3;
          } else if (this.thirdAgentCode.value) {
            this.thirdAgentCodeError = true;
          }

          if (!this.secondAgentCodeError && !this.thirdAgentCodeError) {
            this.isConfirming = true;
            this.setRelevantRegulatedActivitiesDisable();
            this.setInTransferConfigDisable();
            this.show = Show.intermediary;
            pageToTop();
            if (this.relevantRegulatedActivitiesValue === 'n') {
              this.secondAgentRelevantRegulatedActivities = 'N';
              this.secondAgentRelevantRegulatedActivitiesConfig.options[0].disable = true;
              this.secondAgentRelevantRegulatedActivitiesConfig.options[1].disable = true;
              this.refreshSecondAgentRelevantRegulatedActivitiesConfig();
              this.thirdAgentRelevantRegulatedActivities = 'N';
              this.thirdAgentRelevantRegulatedActivitiesConfig.options[0].disable = true;
              this.thirdAgentRelevantRegulatedActivitiesConfig.options[1].disable = true;
              this.refreshThirdAgentRelevantRegulatedActivitiesConfig();
            }
            this.refreshSecondAgentClientContactConfig();
            this.refreshThirdAgentClientContactConfig();
          }
        });
      } else {
        this.isConfirming = true;
        this.setRelevantRegulatedActivitiesDisable();
        this.setInTransferConfigDisable();
        pageToTop();
      }
    }
  }

  private getAgentInfoByAgentCode(agentCodeList: string[]) {
    this.notifyService.screenLoadingSubject.next(true);
    return new Promise<AgentInfo[]>((resolve, reject) => {
      this.cdHttpServeService.post<AgentInfo[]>(`/ext/eb-ssms/agent-service/agent/infos/agentCd`, agentCodeList).subscribe({
        next: res => {
          if (res.result === 0) {
            resolve(res.data);
          } else {
            this.alert.error('Error!', res.message);
          }
          reject();
          this.notifyService.screenLoadingSubject.next(false);
        },
        error: err => {
          this.alert.error('Error!', err);
          reject();
          this.notifyService.screenLoadingSubject.next(false);
        },
      });
    });
  }

  private setRelevantRegulatedActivitiesDisable() {
    this.relevantRegulatedActivitiesConfig = {
      options: [
        {
          label: this.cdsLangService.translate('common.yes'),
          value: 'y',
          disable: true,
        },
        {
          label: this.cdsLangService.translate('common.no'),
          value: 'n',
          disable: true,
        },
      ],
    };
    this.radioRelevantRegulatedActivities?.refreshConfig();
  }

  private setRelevantRegulatedActivitiesEnable() {
    this.relevantRegulatedActivitiesConfig = {
      options: [
        {
          label: this.cdsLangService.translate('common.yes'),
          value: 'y',
          disable: false,
        },
        {
          label: this.cdsLangService.translate('common.no'),
          value: 'n',
          disable: false,
        },
      ],
    };
    this.radioRelevantRegulatedActivities?.refreshConfig();
  }

  private setInTransferConfigDisable() {
    this.inTransferConfig = {
      options: [
        {
          label: this.cdsLangService.translate('common.yes'),
          value: 'y',
          disable: true,
        },
        {
          label: this.cdsLangService.translate('common.no'),
          value: 'n',
          disable: true,
        },
      ],
    };
    this.radioInTransferValue?.refreshConfig();
  }

  private setInTransferConfigEnable() {
    this.inTransferConfig = {
      options: [
        {
          label: this.cdsLangService.translate('common.yes'),
          value: 'y',
          disable: false,
        },
        {
          label: this.cdsLangService.translate('common.no'),
          value: 'n',
          disable: false,
        },
      ],
    };
    this.radioInTransferValue?.refreshConfig();
  }

  backToEdit() {
    const popupRef = this.cdsPopup.openCommon({
      type: CdPopupType.CONTINUE,
      data: {
        message: 'generate.md.leave-page',
        continue: 'common.confirm-continue',
      },
    });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm.continue) {
        this.isConfirming = false;
        this.setRelevantRegulatedActivitiesEnable();
        this.setInTransferConfigEnable();
        this.secondAgentRelevantRegulatedActivitiesConfig.options[0].disable = false;
        this.secondAgentRelevantRegulatedActivitiesConfig.options[1].disable = false;
        this.secondAgentRelevantRegulatedActivities = '';
        this.refreshSecondAgentRelevantRegulatedActivitiesConfig();
        this.thirdAgentRelevantRegulatedActivitiesConfig.options[0].disable = false;
        this.thirdAgentRelevantRegulatedActivitiesConfig.options[1].disable = false;
        this.thirdAgentRelevantRegulatedActivities = '';
        this.refreshThirdAgentRelevantRegulatedActivitiesConfig();
        this.secondAgentClientContact = '';
        this.secondAgentLanguage = '';
        this.thirdAgentClientContact = '';
        this.thirdAgentLanguage = '';
      }
    });
  }

  submit() {
    const params: AddEmployerParams = {};

    if (this.employerFullNameOrBrNumber === 'N') {
      params.employerName = this.employerFullName.value;
    } else {
      params.businessRegisterNo = this.BRNumber.value;
    }

    if (this.authpersonContact === 'E') {
      params.commChannel = 'EMAIL';
      params.email = this.authpersonEmailAddress.value;
    } else {
      params.commChannel = 'SMS';
      params.phoneCountry = this.authpersonCountryCode.value;
      params.phone = this.authpersonPhoneNumber.value;
      params.language = this.preferredLanguage.value === PreferredLanguageType.Chinese ? 'CN' : 'EN';
    }

    params.isRegulatedActivities = this.relevantRegulatedActivitiesValue === 'y';
    params.intraGroup = this.inTransferValue === 'y';
    params.productTypes = ['ER'];

    if (this.secondAgentInfo) {
      params.secondAgent = {};
      params.secondAgent.agentCode = this.secondAgentInfo.agentCode;
      params.secondAgent.isRegulatedActivities = this.secondAgentRelevantRegulatedActivities === 'Y';
      if (params.secondAgent.isRegulatedActivities) {
        params.secondAgent.commChannel = this.secondAgentClientContact === 'E' ? 'EMAIL' : 'SMS';
        if (this.secondAgentClientContact === 'M') {
          params.secondAgent.language = this.secondAgentLanguage === 'EN' ? 'EN' : 'CN';
        }
      }
    }

    if (this.thirdAgentInfo) {
      params.thirdAgent = {};
      params.thirdAgent.agentCode = this.thirdAgentInfo.agentCode;
      params.thirdAgent.isRegulatedActivities = this.thirdAgentRelevantRegulatedActivities === 'Y';
      if (params.thirdAgent.isRegulatedActivities) {
        params.thirdAgent.commChannel = this.thirdAgentClientContact === 'E' ? 'EMAIL' : 'SMS';
        if (this.thirdAgentClientContact === 'M') {
          params.thirdAgent.language = this.thirdAgentLanguage === 'EN' ? 'EN' : 'CN';
        }
      }
    }

    this.notifyService.screenLoadingSubject.next(true);
    this.cdHttpServeService
      .post<{
        mainId: string;
        ids: JSON;
      }>(`/ext/eb-ssms-sales-journey-service/md/add/employer`, params)
      .subscribe({
        next: res => {
          if (res.result === 0) {
            if (this.secondAgentRelevantRegulatedActivities === 'Y' || this.thirdAgentRelevantRegulatedActivities === 'Y') {
              this.cdsPopup
                .open(PendingSplitAgentConfirPopupComponent, {
                  size: 'md',
                  data: {
                    mdNo: res.data.mainId,
                    ids: res.data.ids ? Object.values(res.data.ids) : [],
                  },
                })
                .afterClosed()
                .subscribe(() => {
                  this.router.navigate([
                    '/salesjourney/md-iff-summary',
                    {
                      tabIndex: 0,
                      prodType: SalesJourneyProdType.Corperate,
                    },
                  ]);
                });
            } else {
              this.cdsPopup
                .open(HasBeenSentCustomerPopupComponent, {
                  size: 'md',
                  data: {
                    mdNo: res.data.mainId,
                    ids: res.data.ids ? Object.values(res.data.ids) : [],
                  },
                })
                .afterClosed()
                .subscribe(() => {
                  this.router.navigate([
                    '/salesjourney/md-iff-summary',
                    {
                      tabIndex: 0,
                      prodType: SalesJourneyProdType.Corperate,
                    },
                  ]);
                });
            }
          } else {
            this.alert.error('Error!', res.message);
          }
          this.notifyService.screenLoadingSubject.next(false);
        },
        error: err => {
          this.alert.error('Error!', err);
          this.notifyService.screenLoadingSubject.next(false);
        },
      });
  }

  ngOnDestroy(): void {
    this.lanChangeSubscription?.unsubscribe();
    window.removeEventListener('beforeunload', this.beforeunload);
    window.removeEventListener('popstate', this.popstate);
  }

  onBack() {
    if (!this.isChangedAnyValues) {
      this.router.navigate([
        '/salesjourney/md-iff-summary',
        {
          tabIndex: this.tabIndex,
          prodType: this.prodType,
        },
      ]);
    } else {
      const popupRef: MatDialogRef<WillBeLoseInputComponent> = this.cdsPopup.open(WillBeLoseInputComponent, {
        size: 'sm',
      });
      popupRef.afterClosed().subscribe(({ agree }) => {
        if (agree) {
          this.router.navigate([
            '/salesjourney/md-iff-summary',
            {
              tabIndex: this.tabIndex,
              prodType: this.prodType,
            },
          ]);
        }
      });
    }
  }

  employerFullNameOrBrNumberChange() {
    this.employerFullName.updateValueAndValidity();
    this.BRNumber.updateValueAndValidity();
  }

  private refreshRelevantRegulatedActivitiesConfig() {
    this.relevantRegulatedActivitiesConfig.options[0].label = this.cdsLangService.translate('common.yes');
    this.relevantRegulatedActivitiesConfig.options[1].label = this.cdsLangService.translate('common.no');
    this.radioRelevantRegulatedActivities?.refreshConfig();
  }

  private refreshinTransferConfig() {
    this.inTransferConfig.options[0].label = this.cdsLangService.translate('common.yes');
    this.inTransferConfig.options[1].label = this.cdsLangService.translate('common.no');
    this.radioInTransferValue?.refreshConfig();
  }

  secondAgentCodeChange(code: string) {
    if (code.length === 0) {
      this.thirdAgentCode.setValue('');
    }
    this.secondAgentCodeError = false;
    this.thirdAgentCodeError = false;
  }

  secondAgentClientContactChange() {
    this.secondAgentLanguage = '';
  }

  thirdAgentClientContactChange() {
    this.thirdAgentLanguage = '';
  }

  get isDisableIntermediaryNext() {
    if (this.secondAgentInfo) {
      if (this.secondAgentRelevantRegulatedActivities === '') {
        return true;
      }
      if (this.secondAgentRelevantRegulatedActivities === 'Y') {
        if (this.secondAgentClientContact === '') {
          return true;
        }
        if (this.secondAgentClientContact === 'M' && this.secondAgentLanguage === '') {
          return true;
        }
      }
    }

    if (this.thirdAgentInfo) {
      if (this.thirdAgentRelevantRegulatedActivities === '') {
        return true;
      }
      if (this.thirdAgentRelevantRegulatedActivities === 'Y') {
        if (this.thirdAgentClientContact === '') {
          return true;
        }
        if (this.thirdAgentClientContact === 'M' && this.thirdAgentLanguage === '') {
          return true;
        }
      }
    }
    return false;
  }

  get getSecondAgentLanguage() {
    if (this.secondAgentLanguage === 'ZH') {
      return this.secondAgentLanguageConfig.options[0].label;
    } else if (this.secondAgentLanguage === 'EN') {
      return this.secondAgentLanguageConfig.options[1].label;
    }
    return undefined;
  }

  get getThirdAgentLanguage() {
    if (this.thirdAgentLanguage === 'ZH') {
      return this.thirdAgentLanguageConfig.options[0].label;
    } else if (this.thirdAgentLanguage === 'EN') {
      return this.thirdAgentLanguageConfig.options[1].label;
    }
    return undefined;
  }

  intermediaryNext() {
    if (this.isDisableIntermediaryNext) {
      return;
    }
    this.isConfirming = true;
    this.show = Show.employer;
    this.setRelevantRegulatedActivitiesDisable();
    this.secondAgentRelevantRegulatedActivitiesConfig.options[0].disable = true;
    this.secondAgentRelevantRegulatedActivitiesConfig.options[1].disable = true;
    this.refreshSecondAgentRelevantRegulatedActivitiesConfig();
    this.thirdAgentRelevantRegulatedActivitiesConfig.options[0].disable = true;
    this.thirdAgentRelevantRegulatedActivitiesConfig.options[1].disable = true;
    this.refreshThirdAgentRelevantRegulatedActivitiesConfig();
    pageToTop();
  }

  private refreshSecondAgentRelevantRegulatedActivitiesConfig() {
    this.secondAgentRelevantRegulatedActivitiesConfig.options[0].label = this.cdsLangService.translate('common.yes');
    this.secondAgentRelevantRegulatedActivitiesConfig.options[1].label = this.cdsLangService.translate('common.no');
    this.radioSecondAgentRelevantRegulatedActivities?.refreshConfig();
  }

  private refreshThirdAgentRelevantRegulatedActivitiesConfig() {
    this.thirdAgentRelevantRegulatedActivitiesConfig.options[0].label = this.cdsLangService.translate('common.yes');
    this.thirdAgentRelevantRegulatedActivitiesConfig.options[1].label = this.cdsLangService.translate('common.no');
    this.radioThirdAgentRelevantRegulatedActivities?.refreshConfig();
  }

  backToEdit1() {
    this.show = Show.employer;
    this.isTryConfirm = false;
    this.isConfirming = false;
    this.setRelevantRegulatedActivitiesEnable();
    this.setInTransferConfigEnable();
    pageToTop();
  }

  backToEdit2() {
    if (this.secondAgentInfo) {
      this.show = Show.intermediary;
      if (this.relevantRegulatedActivitiesValue === 'n') {
        this.secondAgentRelevantRegulatedActivities = 'N';
        this.secondAgentRelevantRegulatedActivitiesConfig.options[0].disable = true;
        this.secondAgentRelevantRegulatedActivitiesConfig.options[1].disable = true;
        this.refreshSecondAgentRelevantRegulatedActivitiesConfig();

        this.thirdAgentRelevantRegulatedActivities = 'N';
        this.thirdAgentRelevantRegulatedActivitiesConfig.options[0].disable = true;
        this.thirdAgentRelevantRegulatedActivitiesConfig.options[1].disable = true;
        this.refreshThirdAgentRelevantRegulatedActivitiesConfig();
      } else {
        this.secondAgentRelevantRegulatedActivitiesConfig.options[0].disable = false;
        this.secondAgentRelevantRegulatedActivitiesConfig.options[1].disable = false;
        this.refreshSecondAgentRelevantRegulatedActivitiesConfig();

        this.thirdAgentRelevantRegulatedActivitiesConfig.options[0].disable = false;
        this.thirdAgentRelevantRegulatedActivitiesConfig.options[1].disable = false;
        this.refreshThirdAgentRelevantRegulatedActivitiesConfig();
      }
    } else {
      this.isTryConfirm = false;
      this.isConfirming = false;
      this.setRelevantRegulatedActivitiesEnable();
      this.setInTransferConfigEnable();
    }
    pageToTop();
  }
}
