import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadCustomerDataComponent } from './upload-customer-data.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdsFileUploadModule } from '@cds/ng-experimental/file-upload';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule, CdsFileUploadModule],
  declarations: [UploadCustomerDataComponent],
  exports: [UploadCustomerDataComponent],
})
export class UploadCustomerDataModule {}
