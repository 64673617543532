import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { AdminLayoutComponent } from 'src/app/views/layout/admin-layout/admin-layout.component';
import { CommissionDefaultScaleComponent } from './commission-default-scale/commission-default-scale.component';
import { RoleCreateComponent } from './role/role-create/role-create.component';
import { RoleInfoComponent } from './role/role-info/role-info.component';
import { RoleListComponent } from './role/role-list/role-list.component';

import { ReportingRoleComponent } from './reporting-role/reporting-role.component';
import { ReportingRoleCreateComponent } from './reporting-role/reporting-role-create/reporting-role-create.component';
import { ReportingRoleInfoComponent } from './reporting-role/reporting-role-info/reporting-role-info.component';
import { AuditHistoryComponent } from './audit-history/audit-history.component';
import { DayCardComponent } from './calendar/day-card.component';

const routes: Routes = [
  {
    path: 'system',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'role-list',
        component: RoleListComponent,
        data: {
          roles: {
            SYS_ROLE_MANAGE: PermissionAccess.R,
          },
        },
      },
      {
        path: 'role-create',
        component: RoleCreateComponent,
        data: {
          roles: {
            [PermissionItem.SYS_ROLE_MANAGE]: PermissionAccess.W,
          },
        },
      },
      {
        path: 'role-info',
        component: RoleInfoComponent,
        data: {
          roles: {
            [PermissionItem.SYS_ROLE_MANAGE]: PermissionAccess.W,
          },
        },
      },
      {
        path: 'reporting-role-list',
        component: ReportingRoleComponent,
        data: {
          roles: {
            SYS_ROLE_MANAGE: PermissionAccess.R,
          },
        },
      },
      {
        path: 'reporting-role-create',
        component: ReportingRoleCreateComponent,
        data: {
          roles: {
            [PermissionItem.SYS_ROLE_MANAGE]: PermissionAccess.W,
          },
        },
      },
      {
        path: 'reporting-role-info',
        component: ReportingRoleInfoComponent,
        data: {
          roles: {
            [PermissionItem.SYS_ROLE_MANAGE]: PermissionAccess.W,
          },
        },
      },
      {
        path: 'default-scale-list',
        component: CommissionDefaultScaleComponent,
        data: {
          roles: {
            [PermissionItem.SYS_COMMISSION_DEFAULT_SCALE]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'calendar',
        component: DayCardComponent,
        data: {
          roles: {
            [PermissionItem.SYS_CALENDAR]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'audit-history',
        component: AuditHistoryComponent,
        data: {
          roles: {
            [PermissionItem.SYS_AUDIT_LOG]: PermissionAccess.R,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SystemRoutingModule {}
