import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommissionRoutingModule } from './commission-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTreeModule } from '@angular/material/tree';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsSortModule } from '@cds/ng-data-table/sort';
import { CdsTableModule } from '@cds/ng-data-table/table';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsCheckboxModule } from '@cds/ng-web-components/checkbox';
import { CdsDatepickerModule } from '@cds/ng-web-components/datepicker';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';
import { CdsFormFieldModule } from '@cds/ng-web-components/form-field';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsNavigationModule } from '@cds/ng-web-components/navigation';
import { CdsPaginationModule } from '@cds/ng-web-components/pagination';
import { CdsPopupModule } from '@cds/ng-web-components/popup';
import { CdsProgressModule } from '@cds/ng-web-components/progress';
import { CdsSearchModule } from '@cds/ng-web-components/search';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';
import { CdsTooltipModule } from '@cds/ng-web-components/tooltip';
import { CdsPillModule } from '@cds/ng-web-components/pill';
import { CdsSwitchModule } from '@cds/ng-web-components/switch';

import { SharedModule } from '../../shared/shared.module';

import { CommissionScaleListComponent } from './scale/commission-scale-list.component';

import { CommissionPoolInfoComponent } from './summary/pool-info.component';
import { CommissionPoolListComponent } from './summary/pool-list.component';

import { CommissionRecordDetailsComponent } from './summary/record-details/record-details.component';
import { AddScaleRecordsComponent } from './summary/record-details/add-scale-records/add-scale-records.component';

import { RelatedPartiesAddComponent } from './summary/related-parties/related-parties-add.component';
import { RelatedPartiesListComponent } from './summary/related-parties/related-parties-list.component';

import { AgentRecordAddComponent } from 'src/app/views/commission/summary/agent/agent-record-add/agent-record-add.component';
import { AgentRecordEditComponent } from 'src/app/views/commission/summary/agent/agent-record-edit/agent-record-edit.component';
import { AgentAddAlertComponent } from 'src/app/views/commission/summary/agent/agent-record-add/agent-add-alert.component';
import { AgentComponent } from './summary/agent/agent.component';

import { IndividualTransferComponent } from './summary/individual-transfer/individual-transfer.component';

import { NormalCompensationComponent } from './adjustment/normal-compensation/normal-compensation.component';
import { adIndividualTransferComponent } from './adjustment/ad-individual-transfer/ad-individual-transfer.component';
import { appealCaseComponent } from './adjustment/appeal-case/appeal-case.component';
import { SearchRecordTransactionComponent } from './adjustment/appeal-case/search-record-transaction/search-record-transaction.component';
import { AdjustmentComponent } from './adjustment/adjustment.component';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CommissionBreakpointComponent } from './scale/commission-breakpoint/commission-breakpoint.component';
import { CommissionRecordDetailComponent } from './scale/commission-record-detail/commission-record-detail.component';
import { CommissionScaleInfoComponent } from './scale/commission-scale-info/commission-scale-info.component';
import { DropDownComponent } from './scale/shared/drop-down/drop-down.component';
import { IconButtonComponent } from './scale/shared/icon-button/icon-button.component';
import { UserAgreementPopupComponent } from './user-agreement-popup.component';

import { JournalModule } from './journal/journal.module';
import { HoldOnReportModule } from './hold-on-report/hold-on-report.module';
import { SchemeMasterModule } from './scheme-master/scheme-master.module';
import { ErrorLogModule } from './error-log/error-log.module';
import { BatchUploadAdjustmentModule } from './batch-upload-adjustment/batch-upload-adjustment.module';
import { ApeJournalModule } from './ape-journal/ape-journal.module';
import { ApeManualModule } from './ape-manual/ape-manual.module';

@NgModule({
  declarations: [
    CommissionScaleListComponent,
    CommissionPoolInfoComponent,
    CommissionPoolListComponent,
    CommissionRecordDetailsComponent,
    AddScaleRecordsComponent,
    UserAgreementPopupComponent,
    RelatedPartiesListComponent,
    RelatedPartiesAddComponent,
    AgentComponent,
    AgentRecordAddComponent,

    IndividualTransferComponent,

    AgentAddAlertComponent,
    AgentRecordEditComponent,
    AdjustmentComponent,
    NormalCompensationComponent,
    adIndividualTransferComponent,
    appealCaseComponent,
    SearchRecordTransactionComponent,
    IconButtonComponent,
    DropDownComponent,
    CommissionScaleInfoComponent,
    CommissionRecordDetailComponent,
    CommissionBreakpointComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CommissionRoutingModule,

    CdsButtonModule,
    CdsTextfieldModule,
    CdsTooltipModule,
    CdsLanguageModule,
    CdsIconModule,
    CdsNavigationModule,
    CdsPaginationModule,
    CdsTableModule,
    CdsCheckboxModule,
    CdsSearchModule,
    CdsPopupModule,
    CdsFormFieldModule,
    CdsAssistiveTextModule,
    CdsDropdownModule,
    CdsProgressModule,
    CdsSortModule,
    CdsDatepickerModule,
    CdsPillModule,
    CdsSwitchModule,

    MatButtonModule,
    MatButtonToggleModule,
    MatTreeModule,
    MatSelectModule,
    MatSelectInfiniteScrollModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    SharedModule,
    JournalModule,
    HoldOnReportModule,
    SchemeMasterModule,
    ErrorLogModule,
    BatchUploadAdjustmentModule,
    ApeJournalModule,
    ApeManualModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommissionModule {
  constructor() {}
}
