/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { finalize } from 'rxjs';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { PaymentDetailsService } from '../shared/payment-details.service';
import { dateValidator, demicalValidator, numberValidator } from 'src/app/core/validators';
import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';
import { aoaToSheet, FileType } from 'src/app/utils/xlsx';
import {
  I18N_KEY,
  PAGE_SIZE,
  SchemeCodeConfig,
  StatusCodeConfig,
  PayMthdConfig,
  PAYMENT_DETAILS_COLUMNS,
  EXCEL_COLUMNS,
  TrBnkAcctNoConfig,
} from './details.config';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit, AfterViewInit {
  isShowSearchDatePicker = true; // Fix DatePicker Bug

  previousSearchParams: any;

  schemeCdConfig = SchemeCodeConfig;
  statusCodeConfig = StatusCodeConfig;
  payMthdConfig = PayMthdConfig;
  trBnkAcctNoConfig = TrBnkAcctNoConfig;

  formGroup = new FormGroup(
    {
      schemeCode: new FormControl('', {
        initialValueIsDefault: true,
      }),
      erAccountCode: new FormControl('', {
        validators: [
          numberValidator({
            error: I18N_KEY.INPUT_NUMBER,
          }),
        ],
        initialValueIsDefault: true,
      }),
      payrollGroupId: new FormControl('', {
        initialValueIsDefault: true,
      }),
      memberAccountCode: new FormControl('', {
        validators: [
          numberValidator({
            error: I18N_KEY.INPUT_NUMBER,
          }),
        ],
        initialValueIsDefault: true,
      }),
      receiptReferenceNo: new FormControl('', {
        initialValueIsDefault: true,
      }),
      statusCode: new FormControl('', {
        initialValueIsDefault: true,
      }),
      paymentSubmissionReferenceNo: new FormControl('', {
        initialValueIsDefault: true,
      }),
      paymentMethod: new FormControl('', {
        initialValueIsDefault: true,
      }),
      receivedAmount: new FormControl('', {
        validators: [
          demicalValidator({
            error: I18N_KEY.RECEIVED_AMOUNT_INVALID,
          }),
        ],
        initialValueIsDefault: true,
      }),
      clearedDate: new FormControl('', {
        initialValueIsDefault: true,
        validators: [dateValidator({ error: I18N_KEY.INVALID_CLEARED_DATE })],
      }),
      trusteeBankAccountNo: new FormControl('', {
        initialValueIsDefault: true,
      }),
      reportNo: new FormControl('', {
        validators: [
          numberValidator({
            error: I18N_KEY.INPUT_NUMBER,
          }),
        ],
        initialValueIsDefault: true,
      }),
    },
    []
  );

  isDataLoading = false;
  isDataDownLoading = false;
  isIndeterminate = false;
  isAllItemSelected = false;
  pageConfig: PageConfig = {};
  dataDisplayed: any[] = [];
  dataSelected: any[] = [];
  columnsConfig: Array<ColumnConfig> = PAYMENT_DETAILS_COLUMNS;

  get schemeCd() {
    return this.formGroup.get('schemeCode') as FormControl;
  }

  get payrollGroupId() {
    return this.formGroup.get('payrollGroupId') as FormControl;
  }

  get employerAccountCode() {
    return this.formGroup.get('erAccountCode') as FormControl;
  }

  get memberAccountCode() {
    return this.formGroup.get('memberAccountCode') as FormControl;
  }

  get rcvRefNo() {
    return this.formGroup.get('receiptReferenceNo') as FormControl;
  }

  get statusCode() {
    return this.formGroup.get('statusCode') as FormControl;
  }

  get paySubmitRefNo() {
    return this.formGroup.get('paymentSubmissionReferenceNo') as FormControl;
  }

  get payMthd() {
    return this.formGroup.get('paymentMethod') as FormControl;
  }

  get rcvdAmt() {
    return this.formGroup.get('receivedAmount') as FormControl;
  }

  get clearDate() {
    return this.formGroup.get('clearedDate') as FormControl;
  }

  get trBnkAcctNo() {
    return this.formGroup.get('trusteeBankAccountNo') as FormControl;
  }

  get reportNo() {
    return this.formGroup.get('reportNo') as FormControl;
  }

  get searchDisabled() {
    let tag = true;
    const controls = this.formGroup.controls;
    const keys = Object.keys(controls);
    keys.forEach((key: string) => {
      const defaultValue = (controls[key] as FormControl).defaultValue;
      const controlValue = (controls[key] as FormControl).value;
      if (defaultValue !== controlValue) {
        tag = false;
      }
    });
    return this.formGroup.invalid || tag || this.isDataLoading;
  }

  get getTotalCount() {
    return this.pageConfig.totalCount || 0;
  }

  get exportSeltDisable() {
    return this.dataSelected.length === 0;
  }

  constructor(private paymentDetailsService: PaymentDetailsService, private elemRef: ElementRef, private ref: ChangeDetectorRef) {}

  ngOnInit() {
    this.search();
  }

  ngAfterViewInit(): void {
    this.setExportBar();
  }

  search() {
    this.previousSearchParams = null;
    this.dataSelected = [];
    this.isIndeterminate = false;
    this.isAllItemSelected = false;
    this.pageConfig = {
      pageSize: PAGE_SIZE,
      current: 1,
    };
    this.getData();
  }

  getData() {
    this.isDataLoading = true;
    const params = this.createParams();
    this.paymentDetailsService
      .getData(params)
      .pipe(
        finalize(() => {
          this.isDataLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === ResponseResult.SUCCESS) {
          this.previousSearchParams = params;
          this.pageConfig = {
            ...this.pageConfig,
            totalCount: res.data.totalElements,
          };
          const tempData = res.data.content || [];
          this.dataDisplayed = this.createDisplayData(tempData);
          this.updateAllItemSelected();
          this.updateIndeterminate();
        }
      });
  }

  createDisplayData(data: any[]) {
    return data.map((item: any) => {
      item.erAccountCode = item.erAccountCode ? item.erAccountCode.toString() : '';
      item.memberAccountCode = item.memberAccountCode ? item.memberAccountCode.toString() : '';
      item.checked = this.isItemSelected(item) > -1;
      item.chequeDate = item.chequeDate ? moment(new Date(item.chequeDate)).format('DD/MM/YYYY') : '';
      item.chequeBankInDate = item.chequeBankInDate ? moment(new Date(item.chequeBankInDate)).format('DD/MM/YYYY') : '';
      item.clearedDate = item.clearedDate ? moment(new Date(item.clearedDate)).format('DD/MM/YYYY') : '';
      item.receivedDate = item.receivedDate ? moment(new Date(item.receivedDate)).format('DD/MM/YYYY') : '';
      return item;
    });
  }

  createParams() {
    const params: any = {
      page: (this.pageConfig.current || 1) - 1,
      size: PAGE_SIZE,
    };
    if (this.previousSearchParams) {
      return { ...this.previousSearchParams, ...params };
    }
    const formValue = this.getFormValue();
    if (formValue.clearedDate) {
      formValue.clearedDate = moment(formValue.clearedDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    return { ...params, ...formValue };
  }

  getFormValue() {
    const valueTemp: any = {};
    const value = this.formGroup.value;
    const keys: string[] = Object.keys(value);
    keys.forEach(key => {
      if (value[key]) {
        valueTemp[key] = value[key];
      }
    });
    return valueTemp;
  }

  reset() {
    this.formGroup.reset();
    this.isShowSearchDatePicker = false;
    setTimeout(() => {
      this.isShowSearchDatePicker = true; // Fix DatePicker Bug
    });
    this.search();
  }

  pageChange(ev: any) {
    if (this.pageConfig.current !== ev.current) {
      this.pageConfig.current = ev.current;
      this.getData();
    }
  }

  isItemSelected(item: any) {
    return this.dataSelected.findIndex(list => item.id === list.id);
  }

  updateIndeterminate(): void {
    this.isIndeterminate = this.dataDisplayed.filter(choice => choice.checked).length > 0 && !this.isAllItemSelected;
  }

  updateAllItemSelected(): void {
    this.isAllItemSelected = this.dataDisplayed.every(choice => choice.checked);
  }

  updateSlectedArray(): void {
    this.dataDisplayed.forEach(choice => {
      const index = this.isItemSelected(choice);
      if (index === -1 && choice.checked) {
        this.dataSelected.push(choice);
      }
      if (index > -1 && !choice.checked) {
        this.dataSelected.splice(index, 1);
      }
    });
  }

  handleAllChange(event: boolean) {
    if (this.isAllItemSelected !== event) {
      this.dataDisplayed.forEach(choice => (choice.checked = event));
      this.isAllItemSelected = event;
      this.updateIndeterminate();
      this.updateSlectedArray();
    }
  }

  updateChecked(item: any, event: boolean) {
    item.checked = event;
    this.updateAllItemSelected();
    this.updateIndeterminate();
    this.updateSlectedArray();
    this.ref.detectChanges();
  }

  exportSelectedData() {
    if (this.exportSeltDisable) {
      return;
    }
    this.exportExcel(this.dataSelected);
  }

  exportAllData() {
    if (this.isDataDownLoading) {
      return;
    }
    this.isDataDownLoading = true;
    const params = this.createParams();
    this.paymentDetailsService
      .downLoad({
        ...params,
      })
      .pipe(
        finalize(() => {
          this.isDataDownLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === ResponseResult.SUCCESS) {
          const tempData = res.data || [];
          const dataDownload = this.createDisplayData(tempData);
          this.exportExcel(dataDownload);
        }
      });
  }

  exportExcel(data: any[]) {
    const excelData: any[] = [];
    excelData.push(EXCEL_COLUMNS);
    data.forEach(item => {
      const temp = [];
      temp.push(item.schemeCode);
      temp.push(item.erAccountCode);
      temp.push(item.payrollGroupId);
      temp.push(item.memberAccountCode);
      temp.push(item.receiptReferenceNo);
      temp.push(item.statusCode);
      temp.push(item.paymentSubmissionReferenceNo);
      temp.push(item.paymentMethod);
      temp.push(item.receivedAmount);
      temp.push(item.bankCode);
      temp.push(item.branchCode);
      temp.push(item.bankAccountNo);
      temp.push(item.payerName);
      temp.push(item.chequeDate);
      temp.push(item.chequeBankInDate);
      temp.push(item.otherTransactionReferenceNo);
      temp.push(item.clearedDate);
      temp.push(item.receivedDate);
      temp.push(item.chequeDirectMailToEmpfFlag);
      temp.push(item.oriRefNo);
      temp.push(item.invalidFlg);
      temp.push(item.splitFundingId);
      temp.push(item.splitFundingFlag);
      temp.push(item.trusteeBankCode);
      temp.push(item.trusteeBankBranchCode);
      temp.push(item.trusteeBankAccountNo);
      temp.push(item.workflowReferenceNo);
      temp.push(item.reportNo);
      temp.push(item.currency);
      temp.push(item.auditFee);
      excelData.push(temp);
    });
    aoaToSheet(
      {
        fileName: `PaymentDetails${moment().format('YYYYMMDDHHmmss')}`,
        fileType: FileType.XLSX,
      },
      excelData
    );
  }

  setExportBar() {
    const ele: HTMLElement = document.getElementsByClassName('cds-navigation-content')[0] as HTMLElement;
    let barWidth = 0;
    if (ele) {
      barWidth = ele.offsetWidth - ele.clientWidth;
    }
    this.elemRef.nativeElement.getElementsByClassName('export-bar')[0].style.right = `${barWidth}px`;
  }
}
