<app-cd-page-title title="{{ 'commission.ape.manualTitle' | lang }}"> </app-cd-page-title>

<div class="row">
  <div class="col-xs-12 clo-md-12 l-pt-56 l-mb-7">
    <div class="l-d-f">
      <div [class.tab-selected]="tabSelected === 0" class="tab l-fg-1" (click)="tabSelected = 0">
        <span class="cds-body1-demibold">{{ 'commission.ape.manualAdjustment' | lang }}</span>
      </div>
      <div [class.tab-selected]="tabSelected === 1" class="tab l-fg-1" (click)="tabSelected = 1">
        <span class="cds-body1-demibold">{{ 'commission.ape.nbvRatio' | lang }}</span>
      </div>
    </div>
  </div>
  <div class="col-xs-12 clo-md-12">
    <app-manual-adjustment *ngIf="tabSelected === 0"></app-manual-adjustment>
    <app-nbv-ratio *ngIf="tabSelected === 1"></app-nbv-ratio>
  </div>
</div>
