export enum IndividualType {
  ALL = 'all',
  PA = 'PA',
  TVC = 'TVC',
  SVC = 'SVC',
}

export interface MdiffAgentIndRecordItem {
  mdNo: string;
  declarationDate: string;
  mdIffStatus: number;
  isSplitAgent: boolean;
  clientName: string;
  hkIdOrPassport: string;
}

export interface MdiffAgentErRecordItem {
  mdNo: string;
  declarationDate: string;
  isSplitAgent: boolean;
  employerName: string;
  brNumber: string;
}

export interface EmpfAgentIndRecordItem {
  mdNo: string;
  empfNo: string;
  declarationDate: string;
  mdIffStatus: number;
  empfStatus: number;
  isSplitAgent: boolean;
  productType: string;
  clientName: string;
  hkIdOrPassport: string;
}

export interface EmpfAgentErRecordItem {
  mdNo: string;
  empfNo: string;
  declarationDate: string;
  mdIffStatus: number;
  empfStatus: number;
  isSplitAgent: boolean;
  employerName: string;
  brNumber: string;
}

export interface EmpfSupportIndRecordItem {
  mdNo: string;
  empfNo: string;
  declarationDate: string;
  mdIffStatus: number;
  empfStatus: number;
  isSplitAgent: boolean;
  productType: string;
  clientName: string;
  districtCode: string;
  agentCode: string;
  hkIdOrPassport: string;
  declineReason: string;
}

export interface EmpfSupportErRecordItem {
  mdNo: string;
  empfNo: string;
  declarationDate: string;
  mdIffStatus: number;
  empfStatus: number;
  isSplitAgent: boolean;
  employerName: string;
  brNumber: string;
  agentCode: string;
  districtCode: string;
  declinedReason: string;
  expiryDate: string;
}

export interface MdiffSupportIndRecordItem {
  mdNo: string;
  empfNo: string;
  declarationDate: string;
  mdIffStatus: number;
  isSplitAgent: boolean;
  clientName: string;
  hkIdOrPassport: string;
  districtCode: string;
  agentCode: string;
}

export interface MdiffSupportErRecordItem {
  mdNo: string;
  empfNo: string;
  declarationDate: string;
  mdIffStatus: number;
  isSplitAgent: boolean;
  employerName: string;
  brNumber: string;
  districtCode: string;
  agentCode: string;
}

export enum RefNoType {
  MD = 'MD',
  EMPF = 'EMPF',
}

export enum ProductType {
  PA = 'PA',
  TVC = 'TVC',
  SVC = 'SVC',
  SEP = 'SEP',
}
