import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CDS_POPUP_DATA } from '@cds/ng-web-components/popup';

@Component({
  selector: 'app-pending-split-agent-confir-popup',
  templateUrl: './pending-split-agent-confir-popup.component.html',
  styleUrls: ['./pending-split-agent-confir-popup.component.scss'],
})
export class PendingSplitAgentConfirPopupComponent implements OnInit {
  buttonConfig: CdsButtonConfig = {
    size: 'md',
    style: 'primary',
    disabled: false,
  };
  constructor(
    private dialogRef: MatDialogRef<PendingSplitAgentConfirPopupComponent>,
    @Inject(CDS_POPUP_DATA)
    public data: {
      ids: [];
    }
  ) {}

  ngOnInit(): void {}

  gotIt() {
    this.dialogRef.close({ agree: true });
  }
}
