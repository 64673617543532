/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs';
import moment from 'moment';

import { CdsToastService } from '@cds/ng-web-components/toast';

import { ResponseResult } from 'src/app/core/models/response/response-result';
import { CommissionApeManualService } from 'src/app/views/commission/ape-manual/commission-ape-manual.service';
import { requiredValidator } from 'src/app/core/validators';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { CdPopupService, CdPopupType } from 'src/app/shared/cd-popup';
import { I18N_KEY, NbvRatioItem, NbvRatioItems, ResNbvRatioItem } from './nbv-ratio.config';

@Component({
  selector: 'app-nbv-ratio',
  templateUrl: './nbv-ratio.component.html',
  styleUrls: ['./nbv-ratio.component.scss'],
})
export class NbvRatioComponent implements OnInit {
  loading = false;
  isEdit = false;
  permissionAccess = PermissionAccess;
  permissionItem = PermissionItem;

  updateTime = '';
  updatePeople = '';

  formGroup: FormGroup = new FormGroup({
    ratio: new FormArray([]),
  });

  nbvRatioItems: NbvRatioItem[] = NbvRatioItems;
  nbvRatios: ResNbvRatioItem[] = [];

  get ratio() {
    return this.formGroup.get('ratio') as FormArray;
  }

  get isSaveDisable() {
    let empty = false;
    for (let i = 0; i < this.ratio.controls.length; i++) {
      const control = this.ratio.controls[i] as FormControl;
      if (!control.value) {
        empty = true;
        break;
      }
    }
    return this.ratio.invalid || this.isResetDisable || empty;
  }

  get isResetDisable() {
    let scaleCodeChange = false;
    for (let i = 0; i < this.ratio.controls.length; i++) {
      const controls = this.ratio.controls[i] as FormControl;
      if (controls.defaultValue !== controls.value) {
        scaleCodeChange = true;
        break;
      }
    }
    return !scaleCodeChange;
  }

  constructor(private toast: CdsToastService, private cdPopup: CdPopupService, private commissionApeManualService: CommissionApeManualService) {}

  ngOnInit() {
    this.createRatioForm();
    this.getNbvRatios();
  }

  createRatioForm() {
    this.nbvRatioItems.forEach(() => {
      this.ratio.push(
        new FormControl('', {
          initialValueIsDefault: true,
          validators: [requiredValidator({ error: I18N_KEY.NBV_RATIO_NULL })],
        })
      );
    });
  }

  resetRatioForm() {
    this.nbvRatios.forEach(item => {
      const index = this.nbvRatioItems.findIndex((ratio: any) => ratio.apeType === item.apeType);
      if (index > -1) {
        const value = item.value ? item.value.toFixed(3).toString() : '';
        this.ratio.setControl(
          index,
          new FormControl(value, {
            initialValueIsDefault: true,
            validators: [requiredValidator({ error: I18N_KEY.NBV_RATIO_NULL })],
          })
        );
      }
    });
  }

  getNbvRatios() {
    this.loading = true;
    this.commissionApeManualService
      .getNbvRatios()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(res => {
        if (res.result === ResponseResult.SUCCESS) {
          this.initPage(res.data);
        }
      });
  }

  initPage(data: any) {
    this.nbvRatios = data.nbvRatios || [];
    this.updateTime = data.lastUpdateTime ? moment(data.lastUpdateTime).format('hh:mma DD/MM/YYYY') : '';
    this.updatePeople = data.lastUpdater || '';
    this.resetRatioForm();
  }

  changeMode() {
    if (this.isEdit) {
      const ref = this.cdPopup.openCommon({ type: CdPopupType.CONTINUE });
      ref.afterClosed().subscribe(res => {
        if (res && res.continue) {
          this.isEdit = !this.isEdit;
          this.ratio.reset();
        }
      });
    } else {
      this.isEdit = !this.isEdit;
    }
  }

  reset() {
    if (this.isResetDisable) {
      return;
    }
    this.ratio.reset();
  }

  save() {
    if (this.isSaveDisable) {
      return;
    }
    this.loading = true;
    const params = this.createParams();
    this.commissionApeManualService
      .saveNbvRatios(params)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(res => {
        if (res.result === ResponseResult.SUCCESS) {
          const ref = this.cdPopup.openCommon({
            type: CdPopupType.GOT,
          });
          ref.afterClosed().subscribe(() => {
            this.initPage(res.data);
          });
        } else {
          this.toast.error(res.message);
        }
      });
  }

  createParams() {
    const params: any[] = [];
    this.nbvRatioItems.forEach((item, index) => {
      params.push({
        apeType: item.apeType,
        value: parseFloat(this.ratio.controls[index].value),
      });
    });
    return params;
  }

  ratioInput(ev: any, index: number) {
    let value = ev.target.value;
    value = value.match(/^(([1-9]\d?)|100|0|(\d{1,2})\.(\d){0,3})$/g); // 只允許數字3位小數點3位
    const pos = ev.target.selectionEnd;
    this.ratio.controls[index].setValue(value ? value.toString() : value);
    ev.target.setSelectionRange(pos, pos);
  }

  ratioChange(ev: any, index: number) {
    if (!ev.target) {
      return;
    }
    let value = ev.target.value;
    value = value.match(/^(([1-9]\d?)|100|0|(\d{1,2})\.(\d){0,3})$/g); // 只允許數字3位小數點3位
    if (!value) {
      return;
    }
    value = parseFloat(value).toFixed(3).toString();
    this.ratio.controls[index].setValue(value);
  }
}
