<div class="loading-box" [class.overlay]="overlay" *ngIf="isLoading">
  <app-loading [overlay]="overlay"></app-loading>
</div>
<app-no-result-found *ngIf="totalNum === 0 && !isLoading"></app-no-result-found>
<div class="table-box" #tablebox [class.hidden]="!fixed" [class.fixed-header]="fixedHeader">
  <cds-table [class.fixed]="fixed" [dataSource]="disPlayData" [class.full]="true" *ngIf="totalNum > 0 && (!isLoading || overlay)">
    <ng-container
      *ngFor="let item of columnsConfig; let i = index"
      [cdsColumnDef]="item.key"
      [sticky]="!!item.sticky && !fixed"
      [stickyEnd]="!!item.sticky && !fixed">
      <cds-header-cell *cdsHeaderCellDef>
        <div class="l-d-f l-jc-sb l-ai-cen">
          <ng-container
            *ngIf="tempHeaderCellRefs[item.key]"
            [ngTemplateOutlet]="tempHeaderCellRefs[item.key].templateRef"
            [ngTemplateOutletContext]="{
              $implicit: item,
              index: i
            }"></ng-container>
          <div *ngIf="!tempHeaderCellRefs[item.key]">
            {{ item.title | lang }}
          </div>
          <div *ngIf="item.sort">
            <cds-icon *ngIf="item.sort === sortMode.DEFAULT" icon="arrow:sort" (click)="sortChange(item, sortMode.UP)"></cds-icon>
            <cds-icon *ngIf="item.sort === sortMode.UP" icon="arrow:up" (click)="sortChange(item, sortMode.DOWN)"></cds-icon>
            <cds-icon *ngIf="item.sort === sortMode.DOWN" icon="arrow:down" (click)="sortChange(item, sortMode.DEFAULT)"></cds-icon>
          </div>
        </div>
      </cds-header-cell>
      <cds-cell *cdsCellDef="let element; let num = index">
        <ng-container
          *ngIf="tempBodyCellRefs[item.key]"
          [ngTemplateOutlet]="tempBodyCellRefs[item.key].templateRef"
          [ngTemplateOutletContext]="{
            $implicit: element,
            index: num
          }"></ng-container>
        <div *ngIf="!tempBodyCellRefs[item.key]">
          {{ element[item.key] | lang }}
        </div>
      </cds-cell>
    </ng-container>
    <cds-header-row *cdsHeaderRowDef="displayedColumns; sticky: fixedHeader"></cds-header-row>
    <cds-row *cdsRowDef="let row; columns: displayedColumns"></cds-row>
  </cds-table>
</div>
<div class="l-pt-4 l-pb-4 l-d-f l-jc-sb" *ngIf="totalNum > 0 && (!isLoading || overlay) && isShowPagination">
  <app-pagination [useOf]="true" [pageCount]="pageCount" (pageChange)="pageChange($event)"> </app-pagination>
</div>
