<app-cd-page-title title="{{ 'paymentJournal.financial' | lang }}"> </app-cd-page-title>
<div class="row">
  <form class="col-xs-12 l-mb-8" [formGroup]="formGroup" (ngSubmit)="search()">
    <div class="cds-h2-light l-pt-7 l-mb-7">
      {{ 'common.searchRecord' | lang }}
    </div>
    <p class="cds-body2-light cds-coral-text dark-1 l-mb-5 l-mt-5">
      {{ 'common.fillIn' | lang }}
    </p>
    <div class="row l-plr-0 l-pb-4">
      <div class="cds-h5 col-xs-12 col-md-12 l-plr-0">
        {{ 'common.dateRange' | lang }}
        <span class="cds-coral-text dark-1">*</span>
      </div>
      <div class="col-xs-12 col-md-12 col-lg-6 l-d-f l-plr-0">
        <cds-form-field class="l-pb-4 l-fg-1 l-pr-4" *ngIf="isShowSearchDatePicker">
          <label class="cds-body2 font-color-light3 l-d-ib l-mb-2">
            {{ 'common.business.runDateFrom' | lang }} <span class="cds-coral-text dark-1">*</span>
          </label>
          <cds-textfield [placeholder]="'common.business.dateFormat.placeholder' | lang" [cdsDatepicker]="fromDateFromPicker" [formControl]="fromRunDate">
          </cds-textfield>
          <cds-datepicker #fromDateFromPicker="cdsDatepicker"></cds-datepicker>
          <cds-assistive-text *ngIf="fromRunDate.errors && fromRunDate.dirty">{{ fromRunDate.errors['error'] | lang }}</cds-assistive-text>
          <cds-assistive-text *ngIf="formGroup.errors && formGroup.dirty && !fromRunDate.errors">{{ formGroup.errors['error'] | lang }}</cds-assistive-text>
        </cds-form-field>

        <div class="l-pr-4 l-pt-6 desktop-md-hide">-</div>

        <cds-form-field class="l-pr-4 l-pb-4 l-fg-1" *ngIf="isShowSearchDatePicker">
          <label class="cds-body2 font-color-light3 l-d-ib l-mb-2">
            {{ 'common.business.runDateTo' | lang }} <span class="cds-coral-text dark-1">*</span>
          </label>
          <cds-textfield [placeholder]="'common.business.dateFormat.placeholder' | lang" [cdsDatepicker]="fromDateToPicker" [formControl]="toRunDate">
          </cds-textfield>
          <cds-datepicker #fromDateToPicker="cdsDatepicker"></cds-datepicker>
          <cds-assistive-text *ngIf="toRunDate.errors && toRunDate.dirty">{{ toRunDate.errors['error'] | lang }}</cds-assistive-text>
        </cds-form-field>
      </div>
    </div>
    <div class="row l-plr-0 l-pb-4">
      <div class="cds-h5 col-xs-12 col-md-12 l-plr-0">
        {{ 'commission.common.specificNo' | lang }}
      </div>

      <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-4 l-pb-4">
        <cds-textfield
          [label]="'common.business.employerAcconutCode' | lang"
          [placeholder]="'common.business.employerAcconutCode.placeholder' | lang"
          [formControl]="employerCode">
        </cds-textfield>
        <cds-assistive-text *ngIf="employerCode.errors && employerCode.dirty">{{ employerCode.errors['error'] | lang }}</cds-assistive-text>
      </cds-form-field>

      <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-4 l-pb-4">
        <cds-textfield
          [label]="'common.business.memberAcconutCode' | lang"
          [placeholder]="'common.business.memberAcconutCode.placeholder' | lang"
          [formControl]="memberCode">
        </cds-textfield>
        <cds-assistive-text *ngIf="memberCode.errors && memberCode.dirty">{{ memberCode.errors['error'] | lang }}</cds-assistive-text>
      </cds-form-field>

      <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-4 l-pb-4">
        <cds-textfield
          [label]="'common.business.payrollGroupID' | lang"
          [placeholder]="'common.business.payrollGroupID.placeholder' | lang"
          [formControl]="payrollGroupId">
        </cds-textfield>
      </cds-form-field>

      <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-4 l-pb-4">
        <cds-textfield
          [label]="'common.business.transaction' | lang"
          [placeholder]="'common.business.transaction.placeholder' | lang"
          [formControl]="transaction">
        </cds-textfield>
      </cds-form-field>
    </div>

    <div class="row l-plr-0 l-pb-3">
      <div class="col-xs-12 col-md-6 col-lg-3 l-plr-0 search-area-btn">
        <cds-button type="submit" [disabled]="searchDisabled" [style]="'primary'" size="md">{{ 'common.search' | lang }} </cds-button>
        <cds-button (click)="reset()" [style]="'secondary'" size="md">{{ 'common.reset' | lang }} </cds-button>
      </div>
    </div>
  </form>
  <div class="col-xs-12" *ngIf="previousSearchParams || isDataLoading">
    <app-cd-table-title class="l-mb-5" [title]="'common.recordList' | lang" [total]="getTotalCount">
      <ng-template #tableAction>
        <cds-button (click)="download()" [disabled]="downloadDisabled" [style]="'secondary'" size="sm">
          <span>{{ 'common.download' | lang }}</span>
        </cds-button>
      </ng-template>
    </app-cd-table-title>
    <div>
      <app-data-table
        [frontPagination]="false"
        [fixed]="false"
        [isLoading]="isDataLoading"
        (reloadTable)="pageChange($event)"
        [pageConfig]="pageConfig"
        [dataSource]="dataDisplayed"
        [columnsConfig]="columnsConfig">
      </app-data-table>
    </div>
  </div>
</div>
<app-cd-download-progress [show]="isDownLoading"></app-cd-download-progress>
