/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { finalize } from 'rxjs';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { CdPopupService, CdPopupSize, CdPopupType, MatDialogRef } from 'src/app/shared/cd-popup';
import { BYTE_OF_10M, FILE_TYPE, I18N_KEY } from '../batch-upload-adjustment.model';
import { BatchUploadAdjustmentService } from '../batch-upload-adjustment.service';
import { UploadFileFormatComponent } from '../upload-file-format/upload-file-format.component';

@Component({
  selector: 'app-upload-adjustment-data',
  templateUrl: './upload-adjustment-data.component.html',
  styleUrls: ['./upload-adjustment-data.component.scss'],
})
export class UploadAdjustmentDataComponent implements OnInit {
  uploadFormControl: FormControl = new FormControl();
  file!: File | null;
  errorMessage = '';

  uploading = false;

  get confirmDisabled() {
    return !!this.errorMessage || !this.file;
  }

  constructor(
    private batchUploadAdjustmentService: BatchUploadAdjustmentService,
    private dialogRef: MatDialogRef<UploadAdjustmentDataComponent>,
    private cdsPopup: CdPopupService
  ) {}

  ngOnInit(): void {
    this.uploadFormControl.valueChanges.subscribe(value => {
      if (value === null) {
        this.file = null;
        this.errorMessage = '';
      }
    });
  }

  requestedFormat() {
    const popupRef: MatDialogRef<UploadFileFormatComponent> = this.cdsPopup.open(UploadFileFormatComponent, {
      size: CdPopupSize.LARGE,
      isShowClose: false,
    });
    popupRef.afterClosed().subscribe(() => {});
  }

  fileChange(event: any) {
    this.file = event?.target?.files[0];
    if (!this.file || !this.file.size || !this.file.type || !this.file.name) {
      this.errorMessage = I18N_KEY.FILE_ERROR;
      return;
    }
    if (this.file.type !== FILE_TYPE) {
      this.errorMessage = I18N_KEY.FILE_ERROR;
      return;
    }
    if (this.file.size > BYTE_OF_10M) {
      this.errorMessage = I18N_KEY.FILE_ERROR;
    }
  }

  cancel() {
    if (!this.file) {
      this.dialogRef.close();
      return;
    }
    const ref = this.cdsPopup.openCommon({ type: CdPopupType.CONTINUE });
    ref.afterClosed().subscribe(res => {
      if (res && res.continue) {
        this.dialogRef.close();
      }
    });
  }

  confirm() {
    if (this.errorMessage) {
      return;
    }
    this.upload();
  }

  upload() {
    if (this.file) {
      this.uploading = true;
      this.batchUploadAdjustmentService
        .uploadFile(this.file)
        .pipe(
          finalize(() => {
            this.uploading = false;
          })
        )
        .subscribe(res => {
          if (res.result == ResponseResult.SUCCESS) {
            const ref = this.cdsPopup.openCommon({
              type: CdPopupType.GOT,
            });
            ref.afterClosed().subscribe(() => {
              this.dialogRef.close(true);
            });
          } else {
            this.errorMessage = I18N_KEY.FILE_ERROR;
          }
        });
    }
  }
}
