<app-cd-popup>
  <cds-popup-content>
    <div class="cds-body-hero-light l-mb-4">User Guide on batch upload the APE/VSF , AUM/Trailer Fee:</div>
    <div class="cds-body2">
      <ul class="l-pl-6">
        <li>Please refer the <span class="cds-green-text cursor-pointer" (click)="createTemplate()">standard template</span> to upload</li>
        <li><span class="cds-demibold">Maximum 100 rows</span> (Except the header) in one file</li>
        <li>
          <div class="l-mb-2">Possible Combination:</div>
          <table class="l-mb-4">
            <caption></caption>
            <thead>
              <tr>
                <th class="l-pa-2 cds-detail1 cds-demibold"><span>Adjust Account/Adjust Type</span></th>
                <th class="l-pa-2 cds-detail1 cds-demibold"><span>MPF-ER,MACAU-ER(CPF)</span></th>
                <th class="l-pa-2 cds-detail1 cds-demibold"><span>ORSO-ER,MACAU-ER(PPS)</span></th>
                <th class="l-pa-2 cds-detail1 cds-demibold"><span>MPF-SEP,MPF-SEP(AGENT)</span></th>
                <th class="l-pa-2 cds-detail1 cds-demibold"><span>MPF-FRC</span></th>
                <th class="l-pa-2 cds-detail1 cds-demibold"><span>MPF-TVC,Macau-IVC</span></th>
                <th class="l-pa-2 cds-detail1 cds-demibold"><span>MPF-PAP,MACAU-PAP</span></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="l-pa-2 cds-detail1"><span>112</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>Member Acc Code</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
              </tr>
              <tr>
                <td class="l-pa-2 cds-detail1"><span>112P</span></td>
                <td class="l-pa-2 cds-detail1"><span>ER Acc Code</span></td>
                <td class="l-pa-2 cds-detail1"><span>ER Acc Code</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
              </tr>
              <tr>
                <td class="l-pa-2 cds-detail1"><span>112I</span></td>
                <td class="l-pa-2 cds-detail1"><span>ER Acc Code + Member Acc Code[PAC Flag]</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>Member Acc Code+ Optional: Member Acc Code [PAC Flag]</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>Member Acc Code</span></td>
                <td class="l-pa-2 cds-detail1"><span>Member Acc Code</span></td>
              </tr>
              <tr>
                <td class="l-pa-2 cds-detail1"><span>115</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>Member Acc Code</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
              </tr>
              <tr>
                <td class="l-pa-2 cds-detail1"><span>133</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>Member Acc Code</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
              </tr>
              <tr>
                <td class="l-pa-2 cds-detail1"><span>141ANN</span></td>
                <td class="l-pa-2 cds-detail1"><span>Member Acc Code</span></td>
                <td class="l-pa-2 cds-detail1"><span>Member Acc Code</span></td>
                <td class="l-pa-2 cds-detail1"><span>Member Acc Code</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
              </tr>
              <tr>
                <td class="l-pa-2 cds-detail1"><span>935ANN</span></td>
                <td class="l-pa-2 cds-detail1"><span>Member Acc Code</span></td>
                <td class="l-pa-2 cds-detail1"><span>Member Acc Code</span></td>
                <td class="l-pa-2 cds-detail1"><span>Member Acc Code</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
              </tr>
              <tr>
                <td class="l-pa-2 cds-detail1"><span>136FEE</span></td>
                <td class="l-pa-2 cds-detail1"><span>Member Acc Code</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
                <td class="l-pa-2 cds-detail1"><span>N</span></td>
              </tr>
            </tbody>
          </table>
        </li>
        <li>
          <span class="cds-demibold">APE/AUM</span>
          <ul>
            <li class="l-mb-1">Applied to Adjust Type = 112,112P,112I,115,133,141ANN,935ANN</li>
            <li class="l-mb-1">Numerical within 2 decimal place</li>
          </ul>
        </li>
        <li>
          <span class="cds-demibold">VSF/Trailer Fee</span>
          <ul>
            <li class="l-mb-1">Applied to Adjust Type =136FEE</li>
            <li class="l-mb-1">Numerical within 2 decimal place</li>
            <li class="l-mb-1">Only allow to input agent channel = SCB / DBS / BROKER/ IA BROKER</li>
          </ul>
        </li>
      </ul>
    </div>
  </cds-popup-content>
  <cds-popup-actions>
    <cds-button size="sm" (click)="cancel()">{{ 'common.gotIt' | lang }}</cds-button>
  </cds-popup-actions>
</app-cd-popup>
