/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnConfig } from 'src/app/shared/data-table';

export const ADJUSTMENT_UPLOAD_COLUMNS: Array<ColumnConfig> = [
  { key: 'createTime', sticky: true, title: 'Upload Date' },
  { key: 'fileName', sticky: true, title: 'File Name' },
  { key: 'processor', sticky: true, title: 'Processor' },
  { key: 'batchStatusLabel', title: 'Status' },
  { key: 'validatedCountStr', title: 'Pass Validate' },
  { key: 'updatedCountStr', title: 'Success Updated' },
  { key: 'lastUpdateTime', title: 'Last Updated Date' },
  { key: 'action', title: 'Action' },
];

export const EXCEL_COLUMNS = [
  'Scenario',
  'Account Type',
  'Employer / Member Acc Code',
  'Member Account Code [PAC Flag]',
  'Agent Code',
  'Negative Flag',
  'HKPC AMT',
  'COMM AMT',
  'HKPC / COMM PREM',
  'SF/OTHER AMT',
  'SF/PREM',
  'CASE COUNT',
  'RESULT',
];

export const EXCEL_DEMO = [
  {
    adjustType: 'N1',
    accountType: 'MPF-ER',
    accountCode: '20000001',
    memberCode: '',
    agentCode: '300001',
    negativeFlag: '',
    hkpcAmount: '300',
    commAmount: '300.01',
    pcCommPremium: '10000.33',
    sfOtherAmount: '100.01',
    sfOtherPremium: '10001.11',
    caseCount: '1',
  },
  {
    adjustType: 'N1',
    accountType: 'MPF-ER',
    accountCode: '20000002',
    memberCode: '',
    agentCode: '300001',
    negativeFlag: '',
    hkpcAmount: '300',
    commAmount: '300.01',
    pcCommPremium: '10000.33',
    sfOtherAmount: '',
    sfOtherPremium: '',
    caseCount: '',
  },
  {
    adjustType: 'N1',
    accountType: 'MPF-SEP',
    accountCode: '91000002',
    memberCode: '',
    agentCode: '999999',
    negativeFlag: 'Y',
    hkpcAmount: '',
    commAmount: '300.01',
    pcCommPremium: '10000.33',
    sfOtherAmount: '',
    sfOtherPremium: '',
    caseCount: '',
  },
  {
    adjustType: 'N3',
    accountType: 'MPF-FRC',
    accountCode: '91000003',
    memberCode: '',
    agentCode: '300001',
    negativeFlag: '',
    hkpcAmount: '',
    commAmount: '',
    pcCommPremium: '',
    sfOtherAmount: '',
    sfOtherPremium: '',
    caseCount: '0.33',
  },
  {
    adjustType: 'N4',
    accountType: 'MPF-TVC',
    accountCode: '91000004',
    memberCode: '',
    agentCode: '300001',
    negativeFlag: '',
    hkpcAmount: '300',
    commAmount: '300.01',
    pcCommPremium: '10000.33',
    sfOtherAmount: '',
    sfOtherPremium: '',
    caseCount: '1',
  },
  {
    adjustType: 'I1',
    accountType: 'MPF-ER',
    accountCode: '20000001',
    memberCode: '91000005',
    agentCode: '300001',
    negativeFlag: '',
    hkpcAmount: '300',
    commAmount: '300.01',
    pcCommPremium: '10000.33',
    sfOtherAmount: '',
    sfOtherPremium: '',
    caseCount: '1',
  },
  {
    adjustType: 'I1',
    accountType: 'MPF-PAP',
    accountCode: '91000006',
    memberCode: '',
    agentCode: '300001',
    negativeFlag: 'Y',
    hkpcAmount: '300',
    commAmount: '300.01',
    pcCommPremium: '10000.33',
    sfOtherAmount: '180',
    sfOtherPremium: '',
    caseCount: '1',
  },
];
export const FILE_TEMPLATE_NAME = 'Comm_Adj_template';
export const BYTE_OF_10M = 10485760;
export const FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const PAGE_SIZE = 10;
export const I18N_KEY = {
  COMIS_VALIDATE_CONFIRM: 'commission.ape.validateWarning',
  FILE_ERROR: 'Please use standard template to upload / Over 100 records in the file.',
};
