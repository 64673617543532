import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filterParams } from 'src/app/core/models/request';
import { BasicResponse, PageResponse } from 'src/app/core/models/response/response';
import { environment } from 'src/environments/environment';
import { EmployerStatusE, employerStatusMapObj, ErRecord } from '../employer';
import { CompanyGroupListRequest } from './employer-profile-company-list';

@Injectable({
  providedIn: 'root',
})
export class EmployerProfileCompanyListService {
  constructor(private http: HttpClient) {}

  private getApiPath(...strings: string[]) {
    return environment.apiPrefix.concat('/ext/eb-ssms/customer-service').concat(...strings);
  }
  private getCompanyAipPath(iStr: string) {
    return this.getApiPath('/employer', iStr);
  }

  getCompanyGroupList(params: CompanyGroupListRequest): Observable<PageResponse<ErRecord>> {
    return this.http.get<PageResponse<ErRecord>>(this.getCompanyAipPath('/companyGroupList'), {
      params: filterParams(params),
    });
  }

  findAll(id: string): Observable<BasicResponse<ErRecord[]>> {
    return this.http.get<BasicResponse<ErRecord[]>>(this.getCompanyAipPath('/companyGroupDropdown'), {
      params: { id },
    });
  }

  searchCompany(searchKey: string, employerId: string): Observable<BasicResponse<ErRecord[]>> {
    return this.http.get<BasicResponse<ErRecord[]>>(this.getCompanyAipPath('/searchCompanyGroup'), {
      params: {
        searchKey,
        employerId,
        id: employerId,
      },
    });
  }

  canAdd(employerId: string, checkId: string): Observable<BasicResponse<boolean>> {
    return this.http.get<BasicResponse<boolean>>(this.getCompanyAipPath('/canAddCompanyGroup'), {
      params: {
        employerId: employerId,
        id: employerId,
        checkIds: [checkId],
      },
    });
  }

  commit(data: ErRecord[], id: string): Observable<PageResponse<ErRecord>> {
    return this.http.post<PageResponse<ErRecord>>(this.getCompanyAipPath('/commitCompanyGroup'), {
      list: this.getParamList(data),
      employerId: id,
      id: id,
    });
  }

  private getParamList(data: ErRecord[]) {
    return data.map(element => {
      return {
        employerId: element.id,
        parent: element.parentCompany,
      };
    });
  }

  getStatusColorStyle(status?: EmployerStatusE) {
    if (!status) {
      return '';
    }
    return `background-color: ${employerStatusMapObj[status]?.color};`;
  }

  getStatus(status?: EmployerStatusE) {
    if (!status) {
      return '';
    }
    return employerStatusMapObj[status]?.text;
  }
}
