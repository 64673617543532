/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import Big from 'big.js';
import { RelatedPartiesAddComponent } from './related-parties-add.component';

import { CdsLangService } from '@cds/ng-core/lang';
import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { UserAgreementPopupComponent } from '../../user-agreement-popup.component';

import { CdsButtonConfig } from '@cds/ng-core/button';
import { BTN_CONFIG_SECONDARY } from 'src/app/config/btn.config';
import { CommissionRelatedPartiesService } from 'src/app/core/services/commission/commission-related-parties.service';
import { CommissionCommonService } from 'src/app/core/services/commission/commission-common.service';

import { deepCopy } from 'src/app/utils/copy';
import { RELEATE_PAYTY_RELEASE_TAG, texts } from '../pool-info.model';

import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { AccountCodeExplain, RejoinIntraGroupExplain, ApeDependAccountExplain } from 'src/app/views/commission/summary/related-parties/related-parties.model';
import { AccountType } from 'src/app/core/models/enum/account-type.enum';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';

interface Parties {
  mainParty: string;
  id: string;
  accountCode?: string;
  accountName: string;
  accountType: string;
  accountTypeStr: string;
  commencementDate: string;
  firstContributionDate?: string;
  servicingAgents: string;
  rejoin: boolean;
  isRejoinDisable: boolean;
  contAdjPend: string;
  contAdjPendEdit: boolean;
  offset: number;
  firstPcReleaseDate?: string;
  sysAdjustmentDate?: string;
  sysAdjustmentFlag?: string;
  apeDependAccount?: string;
  isApeDepAccEditable?: boolean;
  apeDependAccountError?: boolean;
  apeDependAccountWarning?: boolean;
  earlyBenefitStartDate: string;
  earlyBirdBonusSignal: string;
  lastDate: string;
}

@Component({
  selector: 'app-related-parties-list',
  templateUrl: './related-parties-list.component.html',
  styleUrls: ['./related-parties-list.component.scss'],
})
export class RelatedPartiesListComponent implements OnInit, OnDestroy {
  @Input() commissionPoolId = '';
  subscription: Subscription;
  private formSubscription: Subscription;

  role = PermissionAccess.W;
  permission = PermissionItem.COMM_INFO_MAIN_PARTIES_CONT_ADJ;
  permission2 = PermissionItem.COMM_INFO_MAIN_PARTIES_OTHERS;
  summaryInfo: any = {};
  btnCfg: CdsButtonConfig = BTN_CONFIG_SECONDARY;
  lastUpdateTime = '';
  lastUpdateBy = '';
  isEdit = false;
  isConfirm = false;
  loading = false;
  dataSource: Parties[] = [];
  dataSourceOriginal: Parties[] = [];
  mainParty: any;
  pageCount: IPaginationCount = { current: 1, count: 1 };

  formGroup = new FormGroup({
    account: new FormControl(''),
  });

  get account() {
    return this.formGroup.get('account') as FormControl;
  }

  get quickSearchDisabled() {
    return !this.account.value;
  }

  constructor(
    private cdsPopup: CdsPopupService,
    private relatedPartiesService: CommissionRelatedPartiesService,
    private commonService: CommissionCommonService,
    private router: Router,
    private langService: CdsLangService
  ) {
    this.subscription = commonService.summary$.subscribe(summaryInfo => {
      this.summaryInfo = summaryInfo;
    });
    this.formSubscription = this.account.valueChanges.subscribe(value => {
      if (!value) {
        this.getList();
      }
    });
  }

  ngOnInit(): void {
    this.commonService.applySummaryInfo(null);
    this.getList();
  }

  quickSearch() {
    if (this.quickSearchDisabled) {
      return;
    }
    this.getList();
  }

  sss(id: string | undefined) {
    this.router.navigate(['/employer/profile-detail'], {
      queryParams: { id: id },
    });
  }

  addNew() {
    const popupRef: MatDialogRef<RelatedPartiesAddComponent> = this.cdsPopup.open(RelatedPartiesAddComponent, {
      size: 'lg',
      data: {
        type: null,
        commissionPoolId: this.commissionPoolId,
        summaryInfo: this.summaryInfo,
      },
    });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.getList();
        this.commonService.announceRelatedParties(null);
      }
    });
  }

  edit() {
    this.isEdit = true;
    this.isConfirm = false;
  }

  pendChange(event: any, i: number) {
    const value = event.target.value;
    event.target.value = new Big(value ? value : 0).toFixed(2);
    this.dataSource[i].contAdjPend = event.target.value;
  }

  apeDependAccountChange(event: any, i: number) {
    this.dataSource[i].apeDependAccountWarning = false;
    this.dataSource[i].apeDependAccountError = false;
    const relatedParty = this.dataSource[i];
    const value = event.target.value.trim();
    if (value && value !== this.mainParty.accountCode) {
      this.dataSource[i].apeDependAccountError = true;
    }
    if (relatedParty.sysAdjustmentDate !== this.mainParty.sysAdjustmentDate) {
      this.dataSource[i].apeDependAccountWarning = true;
    }
    this.dataSource[i].apeDependAccount = event.target.value;
  }

  formChange(input: string, item: any, index: number) {
    switch (input) {
      case 'rejoin':
        this.rejoinConfirm(item.rejoin, index);
        break;
    }
  }

  rejoinConfirm(b: boolean, index: number) {
    if (!this.isEdit || this.isConfirm) {
      return;
    }
    if (!b) {
      const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
        data: {
          message: texts.rejoinConfirm,
          cancel: texts.no,
          continue: texts.yes,
          type: 'confirm',
        },
      });
      popupRef.afterClosed().subscribe(confirm => {
        if (confirm && confirm.agree) {
          this.dataSource[index].rejoin = b;
        } else {
          this.dataSource[index].rejoin = !b;
        }
      });
    }
  }

  reset() {
    this.dataSource = deepCopy(this.dataSourceOriginal);
  }

  cancelEdit() {
    const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
      data: {
        message: texts.cancelMsg,
        cancel: texts.cancel,
        continue: texts.continue,
        type: 'confirm',
      },
    });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm.agree) {
        this.dataSource = deepCopy(this.dataSourceOriginal);
        this.isEdit = false;
      }
    });
  }
  backToEdit() {
    this.isConfirm = false;
  }

  get saveDisable() {
    return this.dataSource.some(item => item.apeDependAccountError);
  }

  save() {
    if (this.saveDisable) {
      return;
    }
    this.isConfirm = true;
    this.dataSource.forEach(item => {
      item.contAdjPend = item.contAdjPend ? new Big(item.contAdjPend).toFixed(2) : parseFloat('0').toFixed(2);
    });
  }

  confirm() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    const dataSource: Parties[] = this.dataSource;
    const list: any[] = [];
    dataSource.forEach(item => {
      const i = {
        id: item.id,
        commissionPoolId: this.commissionPoolId,
        rejoinIntraGroup: item.rejoin,
        contAdjPend: item.contAdjPend,
        apeDependAccount: item.apeDependAccount,
      };
      list.push(i);
    });
    this.relatedPartiesService.editRelatedParties(list).subscribe(resp => {
      this.loading = false;
      let popData, result: number;
      if (resp && resp.data && resp.result == 0) {
        popData = {
          message: texts.alertSuccessMsg,
          continue: texts.alertSuccessContinue,
          type: 'alert',
        };
        result = resp.result;
      } else {
        popData = {
          message: texts.alertFailedMsg,
          continue: texts.alertFailedContinue,
          type: 'alert',
          params: { respMsg: resp.message },
        };
        result = resp.result;
      }
      const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, { data: popData });
      popupRef.afterClosed().subscribe(confirm => {
        if (confirm.agree) {
          if (result == 0) {
            this.getList();
            this.commonService.announceRelatedParties(null);
            this.isEdit = false;
          }
        }
      });
    });
  }

  getList() {
    const params: any = {
      commissionPoolId: this.commissionPoolId,
      page: this.pageCount.current - 1,
      size: 5,
    };
    if (this.account.value) {
      params.accountCode = this.account.value;
    }
    this.loading = true;
    this.relatedPartiesService.getRelatedList(params).then(res => {
      this.loading = false;
      if (!this.mainParty) {
        this.mainParty = res.list.find((item: Parties) => item.mainParty);
      }
      this.dataSource = res.list.map((item: Parties) => {
        item.isApeDepAccEditable = true;
        const relatedPartyDate = moment(item.commencementDate);
        const mainPartyDate = moment(this.mainParty.commencementDate);
        if (relatedPartyDate.year() !== mainPartyDate.year() || relatedPartyDate.month() !== mainPartyDate.month()) {
          item.isApeDepAccEditable = false;
        }
        if (item.sysAdjustmentDate === RELEATE_PAYTY_RELEASE_TAG) {
          item.isApeDepAccEditable = false;
        }
        if (item.mainParty) {
          item.isApeDepAccEditable = false;
        }
        if (item.apeDependAccount) {
          item.isApeDepAccEditable = false;
        }
        return item;
      });
      this.pageCount = {
        current: res.number + 1,
        count: res.totalPages,
      };
      this.lastUpdateTime = res.lastUpdateTime;
      this.lastUpdateBy = res.lastUpdateBy;
      this.dataSourceOriginal = deepCopy(this.dataSource);

      this.dataSource.forEach(item => {
        if (item.accountType == AccountType.MPF_SEP_AGENT) {
          document.getElementsByTagName('body')[0].style.setProperty('--tableFixed', '500px');
        }
      });
    });
  }

  pageChange(currentPage: number) {
    this.pageCount.current = currentPage;
    this.getList();
  }

  accountExplain() {
    this.langService.addLangEntries({
      alertMsg: {
        en: AccountCodeExplain,
        zh: AccountCodeExplain,
      },
    });
    this.cdsPopup.open(UserAgreementPopupComponent, {
      size: 'md',
      data: {
        message: 'alertMsg',
        continue: texts.alertSuccessContinue,
        type: 'alert',
        actionRight: true,
      },
    });
  }

  rejoinIntraGroupExplain() {
    this.langService.addLangEntries({
      alertMsg: {
        en: RejoinIntraGroupExplain,
        zh: RejoinIntraGroupExplain,
      },
    });
    this.cdsPopup.open(UserAgreementPopupComponent, {
      size: 'md',
      data: {
        message: 'alertMsg',
        continue: texts.alertSuccessContinue,
        type: 'alert',
        actionRight: true,
      },
    });
  }

  apeDependAccountExplain() {
    this.langService.addLangEntries({
      alertMsg: {
        en: ApeDependAccountExplain,
        zh: ApeDependAccountExplain,
      },
    });
    this.cdsPopup.open(UserAgreementPopupComponent, {
      size: 'md',
      data: {
        message: 'alertMsg',
        continue: texts.alertSuccessContinue,
        type: 'alert',
        actionRight: true,
      },
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
  }
}
