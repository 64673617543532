<div class="welcome" [ngClass]="{ 'col-1': landingBoxNumOfCol === 1 }">
  <div class="head-content" [ngClass]="{ 'col-1': landingBoxNumOfCol === 1 }">
    <div>
      <span class="cds-h4 greeting">{{ greeting | lang }}</span>
      <span class="cds-h4 cds-h4-bold display-name"> {{ displayName }}, </span>
      <span class="cds-h4 greeting">{{ 'welcome.title' | lang }}</span>
    </div>
    <div class="current-last-update-box">
      <span class="cds-body1 current-last-update">{{ 'welcome.last.logged' | lang }} {{ currentLastUpdate | ssmsDateTime }}</span>
    </div>
  </div>

  <!-- <div class="l-r-tab" *ngIf="landingBoxNumOfCol === 1">
    <div class="l-ml-4">
      <cds-icon [ngClass]="[mobileWidgetsPage > 0 ? 'enable' : 'disable']" icon="action:button_left" (click)="mobileWidgetsPageLast()"></cds-icon>
    </div>
    <div class="dot-list">
      <div class="dot" [ngClass]="{ selected: mobileWidgetsPage === 0 }"></div>
      <div *ngIf="widgetInfoList.length >= 2" class="dot l-ml-3" [ngClass]="{ selected: mobileWidgetsPage === 1 }"></div>
      <div *ngIf="widgetInfoList.length >= 3" class="dot l-ml-3" [ngClass]="{ selected: mobileWidgetsPage === 2 }"></div>
    </div>
    <div class="l-mr-4">
      <cds-icon
        [ngClass]="[(widgetInfoList.length >= 3 && mobileWidgetsPage < 2) || (widgetInfoList.length === 2 && mobileWidgetsPage < 1) ? 'enable' : 'disable']"
        icon="action:button_right"
        (click)="mobileWidgetsPageNext()"></cds-icon>
    </div>
  </div> -->

  <div style="width: 100%; overflow-x: hidden">
    <div #mobileWidgetsBox class="mobile-widgets-box" *ngIf="landingBoxNumOfCol === 1">
      <div class="widgets-list col-1">
        <div class="announcement col-1" *ngIf="isShowAnnouncement">
          <div class="l-d-f l-ai-cen l-jc-sb" style="border-bottom: 1px solid var(--cds-color-light-3-blue); padding-bottom: 6px">
            <div class="l-d-f l-ai-cen">
              <cds-icon style="--cds-icon-color: var(--cds-icon-inverse)" icon="action:megaphone"></cds-icon>
              <div class="l-ml-2">
                <span class="cds-body1-demibold" style="color: var(--cds-text-inverse)">Announcement</span>
              </div>
            </div>
            <div>
              <cds-icon (click)="closeAnnouncement()" class="action-cross" style="--cds-icon-color: var(--cds-icon-inverse)" icon="action:cross"></cds-icon>
            </div>
          </div>
          <app-cd-carousel [list]="announcementList">
            <div *ngFor="let announcement of announcementList" class="carousel-page">
              <div class="l-d-f l-ai-cen l-jc-sb">
                <div>
                  <span *ngIf="cdsLangService.currentLang === 'en'" class="topic cds-h6-demibold">{{ announcement.announcementSubjectEng }}</span>
                  <span *ngIf="cdsLangService.currentLang === 'zh'" class="topic cds-h6-demibold">{{ announcement.announcementSubjectChi }}</span>
                </div>
                <!-- <div>
                  <span class="date cds-detail1">{{ announcement.announcementDate | date : 'dd MMM' }}</span>
                </div> -->
              </div>
              <div style="margin-top: 6px">
                <span class="text cds-detail1 line-break" *ngIf="cdsLangService.currentLang === 'en'">{{ announcement.announcementBodyEng }}</span>
                <span class="text cds-detail1 line-break" *ngIf="cdsLangService.currentLang === 'zh'">{{ announcement.announcementBodyChi }}</span>
              </div>
            </div>
          </app-cd-carousel>
        </div>

        <div class="landing-box col-1" #landingBox>
          <div
            *ngFor="let widgetInfo of widgetInfoList"
            cdkDragBoundary=".landing-box"
            class="widget-vessel col-1 landing-box-col-12"
            [ngStyle]="{ 'min-height': widgetInfo.height + 'px' }"
            [ngClass]="{
              'cdk-drag-animating': isAllowAnimal
            }"
            cdkDrag
            #drag="cdkDrag"
            [cdkDragDisabled]="!widgetInfo.isDraggable"
            [cdkDragData]="{ widgetInfo: widgetInfo }"
            (cdkDragStarted)="cdkDragStarted($event)"
            (cdkDragEnded)="cdkDragEnded($event)">
            <div class="head">
              <div class="left">
                <div class="example-handle l-mr-3" cdkDragHandle *ngIf="widgetInfo.isDraggable">
                  <div class="spot-row">
                    <div class="spot"></div>
                    <div class="spot"></div>
                  </div>
                  <div class="spot-row">
                    <div class="spot"></div>
                    <div class="spot"></div>
                  </div>
                  <div class="spot-row">
                    <div class="spot"></div>
                    <div class="spot"></div>
                  </div>
                </div>
                <div>
                  <span class="cds-body2-demibold">{{ widgetInfo.name }}</span>
                </div>
              </div>
              <div class="right" *ngIf="widgetInfo.isViewAll" (click)="goGoal(widgetInfo.component)">
                <cds-icon style="--cds-icon-color: var(--cds-icon-cta)" icon="action:button_right_outlined"></cds-icon>
                <div style="margin-left: 10px">
                  <span class="cds-body2-demibold">View all</span>
                </div>
              </div>
            </div>

            <app-dynamic-content class="dynamic-content" [componentName]="widgetInfo.component"></app-dynamic-content>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="landingBoxNumOfCol !== 1" class="widgets-list">
    <div class="announcement" *ngIf="isShowAnnouncement">
      <div class="l-d-f l-ai-cen l-jc-sb" style="border-bottom: 1px solid var(--cds-color-light-3-blue); padding-bottom: 6px">
        <div class="l-d-f l-ai-cen">
          <cds-icon style="--cds-icon-color: var(--cds-icon-inverse)" icon="action:megaphone"></cds-icon>
          <div class="l-ml-2">
            <span class="cds-body1-demibold" style="color: var(--cds-text-inverse)">Announcement</span>
          </div>
        </div>
        <div>
          <cds-icon (click)="closeAnnouncement()" class="action-cross" style="--cds-icon-color: var(--cds-icon-inverse)" icon="action:cross"></cds-icon>
        </div>
      </div>
      <app-cd-carousel [list]="announcementList">
        <div *ngFor="let announcement of announcementList" class="carousel-page">
          <div class="l-d-f l-ai-cen l-jc-sb">
            <div>
              <span *ngIf="cdsLangService.currentLang === 'en'" class="topic cds-h6-demibold">{{ announcement.announcementSubjectEng }}</span>
              <span *ngIf="cdsLangService.currentLang === 'zh'" class="topic cds-h6-demibold">{{ announcement.announcementSubjectChi }}</span>
            </div>
            <!-- <div>
              <span class="date cds-detail1">{{ announcement.announcementDate | date : 'dd MMM' }}</span>
            </div> -->
          </div>
          <div style="margin-top: 6px">
            <span class="text cds-detail1 line-break" *ngIf="cdsLangService.currentLang === 'en'">{{ announcement.announcementBodyEng }}</span>
            <span class="text cds-detail1 line-break" *ngIf="cdsLangService.currentLang === 'zh'">{{ announcement.announcementBodyChi }}</span>
          </div>
        </div>
      </app-cd-carousel>
    </div>

    <div class="landing-box" #landingBox>
      <div
        *ngFor="let widgetInfo of widgetInfoList"
        cdkDragBoundary=".landing-box"
        class="widget-vessel"
        [ngStyle]="{ 'min-height': widgetInfo.height + 'px' }"
        [ngClass]="{
          'cdk-drag-animating': isAllowAnimal,
          'landing-box-col-12':
            (widgetInfo.width === 3 && landingBoxNumOfCol === 3) ||
            (widgetInfo.width === 3 && landingBoxNumOfCol === 2) ||
            (widgetInfo.width === 2 && landingBoxNumOfCol === 2) ||
            (widgetInfo.width === 3 && landingBoxNumOfCol === 1) ||
            (widgetInfo.width === 2 && landingBoxNumOfCol === 1) ||
            (widgetInfo.width === 1 && landingBoxNumOfCol === 1),
          'landing-box-col-8': widgetInfo.width === 2 && landingBoxNumOfCol === 3,
          'landing-box-col-4': widgetInfo.width === 1 && landingBoxNumOfCol === 3,
          'landing-box-col-6': widgetInfo.width === 1 && landingBoxNumOfCol === 2
        }"
        cdkDrag
        #drag="cdkDrag"
        [cdkDragDisabled]="!widgetInfo.isDraggable"
        [cdkDragData]="{ widgetInfo: widgetInfo }"
        (cdkDragStarted)="cdkDragStarted($event)"
        (cdkDragEnded)="cdkDragEnded($event)">
        <div class="head">
          <div class="left">
            <div class="example-handle l-mr-3" cdkDragHandle *ngIf="widgetInfo.isDraggable">
              <div class="spot-row">
                <div class="spot"></div>
                <div class="spot"></div>
              </div>
              <div class="spot-row">
                <div class="spot"></div>
                <div class="spot"></div>
              </div>
              <div class="spot-row">
                <div class="spot"></div>
                <div class="spot"></div>
              </div>
            </div>
            <div>
              <span class="cds-body2-demibold">{{ widgetInfo.name }}</span>
            </div>
          </div>
          <div class="right" *ngIf="widgetInfo.isViewAll" (click)="goGoal(widgetInfo.component)">
            <cds-icon style="--cds-icon-color: var(--cds-icon-cta)" icon="action:button_right_outlined"></cds-icon>
            <div style="margin-left: 10px">
              <span class="cds-body2-demibold">View all</span>
            </div>
          </div>
        </div>

        <app-dynamic-content class="dynamic-content" [componentName]="widgetInfo.component"></app-dynamic-content>
      </div>
    </div>
  </div>
</div>
<app-user *ngIf="isShowChatBotIcon"></app-user>
