<div class="row head-container" [formGroup]="form">
  <div>
    <cds-icon color="#00a758" width="100px" height="100px" icon="form:form_search"></cds-icon>
  </div>
  <div class="row col-xs">
    <div class="col-xs-12">
      <span class="cds-h2-light">Search Records</span>
    </div>
    <div class="col-xs-12">
      <span>Related cheque printing and bank transfer result will be displayed</span>
    </div>
    <div class="col-xs-10 row l-plr-0">
      <div class="row col-xs-12 col-sm-12 col-md-8 col-lg-8 l-pt-5 l-pb-5 l-plr-0 date-picker-area relative">
        <div class="col-xs-6 date-picker">
          <cds-textfield
            formControlName="instructionFileDateFrom"
            placeholder="DD/MM/YYYY"
            label="Payment Instruction File Date  From"
            [max]="maxDate(searchDTO.instructionFileDateTo)"
            [(ngModel)]="searchDTO.instructionFileDateFrom"
            (ngModelChange)="validating()"
            [cdsDatepicker]="startPicker">
          </cds-textfield>
          <cds-datepicker #startPicker="cdsDatepicker"></cds-datepicker>

          <cds-assistive-text class="l-pt-3" *ngIf="form.get('instructionFileDateFrom')?.invalid">{{ 'create.dateError' | lang }} </cds-assistive-text>
        </div>
        <div class="connector">-</div>
        <div class="col-xs-6 date-picker">
          <cds-textfield
            formControlName="instructionFileDateTo"
            placeholder="DD/MM/YYYY"
            label="To"
            [min]="minDate(searchDTO.instructionFileDateFrom)"
            [(ngModel)]="searchDTO.instructionFileDateTo"
            (ngModelChange)="validating()"
            [cdsDatepicker]="endPicker">
          </cds-textfield>
          <cds-datepicker #endPicker="cdsDatepicker"></cds-datepicker>
          <cds-assistive-text class="l-pt-3" *ngIf="form.get('instructionFileDateTo')?.invalid">{{ 'create.dateError' | lang }} </cds-assistive-text>
        </div>
      </div>

      <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4 l-pt-5 l-pb-5">
        <cds-textfield
          formControlName="payRefNo"
          [(ngModel)]="searchDTO.payRefNo"
          (input)="inputChange(payRefNo.value, 'payRefNo')"
          (compositionend)="inputChange(payRefNo.value, 'payRefNo')"
          #payRefNo
          label="{{ 'list.pay-ref-no' | lang }}"
          placeholder="{{ 'list.input-pay-ref-no' | lang }}"></cds-textfield>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4 l-pt-5 l-pb-5">
        <cds-textfield
          formControlName="appRefNo"
          [(ngModel)]="searchDTO.appRefNo"
          (input)="inputChange(appRefNo.value, 'appRefNo')"
          (compositionend)="inputChange(appRefNo.value, 'appRefNo')"
          #appRefNo
          label="{{ 'list.application-reference-no' | lang }}"
          placeholder="{{ 'list.input-application-reference-no' | lang }}"></cds-textfield>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4 l-pt-5 l-pb-5">
        <cds-textfield
          formControlName="payeeName"
          [(ngModel)]="searchDTO.payeeName"
          (input)="inputChange(payeeName.value, 'payeeName')"
          (compositionend)="inputChange(payeeName.value, 'payeeName')"
          #payeeName
          label="{{ 'list.payee-name' | lang }}"
          placeholder="{{ 'list.input-payee-name' | lang }}"></cds-textfield>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4 l-pt-5 l-pb-5">
        <cds-textfield
          formControlName="transactionIdentifier"
          [(ngModel)]="searchDTO.transactionIdentifier"
          (input)="inputChange(transactionIdentifier.value, 'transactionIdentifier')"
          (compositionend)="inputChange(payeeName.value, 'transactionIdentifier')"
          #transactionIdentifier
          label="{{ 'list.lawson-transaction-identifier' | lang }}"
          placeholder="{{ 'list.lawson-transaction-identifier-input' | lang }}"></cds-textfield>
      </div>

      <div class="row col-xs-6 col-sm-6 col-md-12 col-lg-12 l-pt-5 l-pb-5 middle-xs">
        <cds-checkbox
          formControlName="isReissue"
          [checked]="searchDTO.isReissue === 'Y'"
          (change)="reissueChnage($event)"
          #isReissue
          label="{{ 'list.reissurance' | lang }}">
        </cds-checkbox>
      </div>
      <div class="row col-xs-6 col-sm-6 col-md-4 col-lg-4 l-pt-5 l-pb-5 middle-xs">
        <cds-checkbox formControlName="orso" [checked]="_schemeList.orso" (change)="schemeChange($event, 'orso')" label="{{ 'list.orso' | lang }}">
        </cds-checkbox>
      </div>
      <div class="row col-xs-6 col-sm-6 col-md-4 col-lg-4 l-pt-5 l-pb-5 middle-xs">
        <cds-checkbox
          formControlName="macaupps"
          [checked]="_schemeList.macaupps"
          (change)="schemeChange($event, 'macaupps')"
          label="{{ 'list.macaupps' | lang }}">
        </cds-checkbox>
      </div>
      <div class="row col-xs-6 col-sm-6 col-md-4 col-lg-4 l-pt-5 l-pb-5 middle-xs">
        <cds-checkbox
          formControlName="macaucpf"
          [checked]="_schemeList.macaucpf"
          (change)="schemeChange($event, 'macaucpf')"
          label="{{ 'list.macaucpf' | lang }}">
        </cds-checkbox>
      </div>

      <cds-button
        class="l-pr-2 l-pl-2 l-pt-2"
        [disabled]="!form.valid || !_valid"
        [config]="searchButtonConfig"
        label="{{ 'list.search' | lang }}"
        (click)="search()"></cds-button>
      <cds-button
        class="l-pr-2 l-pl-2 l-pt-2"
        [disabled]="!_valid"
        [config]="resetButtonConfig"
        label="{{ 'list.reset' | lang }}"
        (click)="reset()"></cds-button>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-xs-12">
    <cds-tab-group type="box" (selectedTabIndexChange)="selectedTabIndexChange($event)">
      <cds-tab label="Cheque Issuance"></cds-tab>
      <cds-tab label="Bank Transfer"></cds-tab>
    </cds-tab-group>
  </div>
</div>

<app-cheque-issuance-ptw [baseParam]="searchDTO" [search]="_search" *ngIf="_tabIndex === 0"></app-cheque-issuance-ptw>

<app-bank-transfer-ptw [baseParam]="searchDTO" [search]="_search" *ngIf="_tabIndex === 1"></app-bank-transfer-ptw>
