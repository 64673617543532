<div *ngIf="show === Show.individuals" class="individuals">
  <div class="head-box">
    <div class="back-box">
      <cds-icon (click)="onBack()" [config]="backIconConfig" [icon]="backIcon"> </cds-icon>
      <div class="back-title">
        <span (click)="onBack()" class="cds-body2-demibold">Exit and back to MD_IFF Summary Page</span>
      </div>
    </div>
    <span class="head">Generate eIFF Reference Number</span>
  </div>

  <form [formGroup]="individualsForm">
    <div class="content-box">
      <div *ngIf="domain === Domain.individual" class="l-d-f l-ai-cen">
        <cds-icon style="width: 40px; height: 40px; --cds-icon-color: var(--cds-bg-ml)" icon="people:login_1"></cds-icon>
        <div class="l-ml-4">
          <span class="cds-h2-demibold" style="color: var(--cds-bg-ml)">{{ 'generate.md.individuals' | lang }}</span>
        </div>
      </div>
      <div *ngIf="domain === Domain.sep" class="l-d-f l-ai-cen">
        <cds-icon style="width: 40px; height: 40px; --cds-icon-color: var(--cds-bg-ml)" icon="contact:qualify"></cds-icon>
        <div class="l-ml-4">
          <span class="cds-h2-demibold" style="color: var(--cds-bg-ml)">{{ 'generate.md.sep' | lang }}</span>
        </div>
      </div>
      <div class="l-mt-7">
        <span class="cds-h2-demibold">{{ 'generate.md.mpf-intermediary-fulfillment' | lang }}</span>
      </div>
      <div class="l-mt-4">
        <span class="cds-detail2">請填寫以下資料以完成聲明</span>
      </div>

      <div *ngIf="isConfirming" class="l-mt-6">
        <app-matters-needing-attention [color]="'red'" [title]="'generate.md.important-notes' | lang">
          <ul class="l-mt-0 l-mb-0">
            <li>
              <span class="cds-detail1">{{ 'generate.md.I-declare-that' | lang }}</span>
            </li>
            <li>
              <span class="cds-detail1 red-text">{{ 'generate.md.agents-should-no1' | lang }}</span>
              <span class="cds-detail1">{{ 'generate.md.agents-should-no2' | lang }}</span>
            </li>
          </ul>
        </app-matters-needing-attention>
      </div>

      <div class="information-list-box">
        <div class="information-box">
          <div>
            <span class="cds-h4-light">{{ 'generate.md.clientDetails' | lang }}</span>
          </div>
          <div class="item-list-box">
            <div class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body2-demibold">{{ 'generate.md.clientName' | lang }}</span
                ><span class="cds-body2-demibold" style="color: var(--cds-color-dark-1-coral)">*</span><span class="cds-body2-demibold">:</span>
              </div>
              <div *ngIf="isConfirming" style="flex: 1">
                <span class="cds-body1">{{ customerSurname.value }} {{ customerGivenName.value }}</span>
              </div>
              <div *ngIf="!isConfirming" class="offsets" style="flex: 1">
                <div class="l-d-f">
                  <div style="max-width: 180px; min-width: 180px">
                    <app-cd-input
                      [group]="individualsForm"
                      [controlName]="'customerSurname'"
                      [regExpLimit]="'^[a-zA-Z]*$'"
                      placeholder="{{ 'generate.md.surname' | lang }}"></app-cd-input>
                  </div>
                  <div class="l-ml-6" style="max-width: 400px; min-width: 400px">
                    <app-cd-input
                      [group]="individualsForm"
                      [controlName]="'customerGivenName'"
                      [regExpLimit]="'^[a-zA-Z]*$'"
                      placeholder="{{ 'generate.md.givenName' | lang }}"></app-cd-input>
                  </div>
                </div>
                <div
                  *ngIf="
                    (customerSurname?.touched && customerSurname?.hasError('required')) ||
                    (customerGivenName?.touched && customerGivenName?.hasError('required'))
                  "
                  class="l-mt-1">
                  <cds-assistive-text>{{ 'generate.md.fullNameError' | lang }}</cds-assistive-text>
                </div>
              </div>
            </div>
            <div class="item-box">
              <div style="width: 270px">
                <span class="cds-body2-demibold">{{ 'generate.md.clientHKIDorPass' | lang }}</span
                ><span class="cds-body2-demibold" style="color: var(--cds-color-dark-1-coral)">*</span><span class="cds-body2-demibold">:</span>
              </div>
              <div *ngIf="isConfirming" class="l-d-f" style="flex: 1">
                <span class="cds-body1 l-mb-0"
                  >({{ individualsForm.get('idType')?.value === '0' ? 'HKID' : 'Passport No.' }}) {{ individualsForm.get('hkidOrPassportNo')?.value }}</span
                >
              </div>
              <div *ngIf="!isConfirming" class="l-d-f" style="flex: 1">
                <div class="offsets" style="max-width: 180px; min-width: 180px">
                  <app-cd-dropdown
                    [placeholder]="'iff.pleaseSelectIDType'"
                    [group]="individualsForm"
                    [controlName]="'idType'"
                    (selectedChange)="hKIDPassportNoChange()"
                    [toppingList]="idTypeConfigOptions"></app-cd-dropdown>
                </div>
                <div class="l-ml-6 offsets" style="max-width: 400px; min-width: 400px">
                  <app-cd-input
                    *ngIf="individualsForm.get('idType')?.value === '0'"
                    [group]="individualsForm"
                    controlName="hkidOrPassportNo"
                    [placeholder]="'iff.pleaseInputHKIDPassportNo' | lang"
                    (valueChange)="hkidOrPassportNoChange($event)"></app-cd-input>
                  <app-cd-input
                    *ngIf="individualsForm.get('idType')?.value === '1'"
                    [group]="individualsForm"
                    controlName="hkidOrPassportNo"
                    [placeholder]="'iff.pleaseInputHKIDPassportNo' | lang"
                    (valueChange)="hkidOrPassportNoChange($event)"></app-cd-input>
                  <div class="l-mt-1" *ngIf="hkidOrPassportNo?.touched && hkidOrPassportNo?.hasError('required')">
                    <cds-assistive-text type="error">
                      <span>
                        {{ 'generate.md.pleaseInputHkid' | lang }}
                      </span>
                    </cds-assistive-text>
                  </div>
                  <div class="l-mt-1" *ngIf="hkidOrPassportNo?.touched && hkidOrPassportNo?.hasError('hkidFormatError')">
                    <cds-assistive-text type="error">
                      <span>
                        {{ 'generate.md.hkidInvalid' | lang }}
                      </span>
                    </cds-assistive-text>
                  </div>
                  <div class="l-mt-1" *ngIf="hkidOrPassportNo?.touched && hkidOrPassportNo?.hasError('passportFormatError')">
                    <cds-assistive-text type="error">
                      <span>
                        {{ 'generate.md.passportNoInvalid' | lang }}
                      </span>
                    </cds-assistive-text>
                  </div>
                </div>
              </div>
            </div>

            <div class="item-box">
              <div style="width: 270px">
                <span class="cds-body2-demibold">{{ 'generate.md.clientEmailorPhone' | lang }}</span
                ><span class="cds-body2-demibold" style="color: var(--cds-color-dark-1-coral)">*</span><span class="cds-body2-demibold">:</span>
              </div>
              <div *ngIf="isConfirming">
                <span *ngIf="clientContact === 'E'" class="cds-body1">{{ individualsForm.get('clientEmailAddress')?.value }}</span>
                <span *ngIf="clientContact === 'M'" class="cds-body1"
                  >(+{{ individualsForm.get('clientCountryCode')?.value }}) {{ individualsForm.get('clientPhoneNumber')?.value }}</span
                >
              </div>
              <div *ngIf="!isConfirming">
                <div>
                  <app-cd-radio
                    class="cd-radio-margin-bottom-clear"
                    [(value)]="clientContact"
                    [config]="clientContactConfig"
                    (valueChange)="clientContactChange()"></app-cd-radio>
                  <div>
                    <cds-assistive-text class="l-mt-4" *ngIf="isTryConfirm && !clientContact">{{
                      'generate.md.pleaseSelCustomerContactType' | lang
                    }}</cds-assistive-text>
                  </div>
                </div>
                <div>
                  <div *ngIf="clientContact === 'E'" class="l-mt-5 l-mb-3">
                    <app-cd-input
                      ngDefaultControl
                      [placeholder]="'generate.md.client-email' | lang"
                      [group]="individualsForm"
                      controlName="clientEmailAddress"></app-cd-input>
                    <cds-assistive-text class="l-mt-1" *ngIf="clientEmailAddress?.touched && clientEmailAddress?.hasError('email')">{{
                      'generate.md.pleaseInputValidCustomerEmail' | lang
                    }}</cds-assistive-text>
                    <cds-assistive-text class="l-mt-1" *ngIf="clientEmailAddress?.touched && clientEmailAddress?.hasError('required')">{{
                      'generate.md.pleaseInputCustomerEmail' | lang
                    }}</cds-assistive-text>
                  </div>
                  <div *ngIf="clientContact === 'M'" class="l-d-f l-mt-5">
                    <cds-dropdown
                      formControlName="clientCountryCode"
                      [config]="countrycodeConfig"
                      (ngModelChange)="clientCountryCodeChange()"
                      ngDefaultControl></cds-dropdown>
                    <div>
                      <div class="l-ml-4" style="position: relative; top: -1px; width: 208px">
                        <app-cd-input
                          [type]="'number'"
                          ngDefaultControl
                          placeholder="{{ 'generate.md.pleaseInputPhoneNumber' | lang }}"
                          [group]="individualsForm"
                          controlName="clientPhoneNumber"></app-cd-input>
                      </div>
                      <div class="l-ml-4 l-mt-1">
                        <cds-assistive-text *ngIf="clientPhoneNumber?.touched && clientPhoneNumber.hasError('errorPhoneNumber')">
                          <span>
                            {{ 'generate.md.pleaseInputValidMobileNumber' | lang }}
                          </span>
                        </cds-assistive-text>
                      </div>
                      <div class="l-ml-4 l-mt-1">
                        <cds-assistive-text *ngIf="clientPhoneNumber?.touched && clientPhoneNumber?.hasError('required')">
                          <span>
                            {{ 'generate.md.pleaseInputPhoneNumber' | lang }}
                          </span>
                        </cds-assistive-text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item-box" *ngIf="!(isConfirming && !individualsForm.get('preferredLanguage')?.value)">
              <div *ngIf="clientContact === 'M'" style="width: 270px">
                <span class="cds-body2-demibold">{{ 'generate.md.selectLanguage' | lang }}</span
                ><span class="cds-body2-demibold" style="color: var(--cds-color-dark-1-coral)">*</span><span class="cds-body2-demibold">:</span>
              </div>
              <div *ngIf="isConfirming" style="flex: 1">
                <span class="cds-body1">{{ (preferredLanguage.value === PreferredLanguageType.English ? 'common.english' : 'common.chinese') | lang }}</span>
              </div>
              <div *ngIf="!isConfirming && clientContact === 'M'" style="flex: 1">
                <app-cd-radio
                  class="cd-radio-margin-bottom-clear"
                  #cdRadioPreferredLanguage
                  (valueChange)="individualsForm.get('preferredLanguage')?.setValue($event)"
                  [value]="individualsForm.get('preferredLanguage')?.value"
                  [config]="preferredLanguageConfig"></app-cd-radio>
                <div
                  *ngIf="individualsForm.get('preferredLanguage')?.invalid && individualsForm.get('preferredLanguage')?.touched"
                  style="margin-top: var(--cds-spacing-01)">
                  <cds-assistive-text type="error">{{ 'generate.md.please-select-language' | lang }} </cds-assistive-text>
                </div>
              </div>
            </div>
            <div *ngIf="!isConfirming" class="item-box">
              <div>
                <span class="cds-detail2" style="color: var(--cds-color-dark-1-coral)">{{ 'generate.md.pleaseEnsure' | lang }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="information-list-box">
        <div class="information-box">
          <div>
            <span class="cds-h4-light">{{ 'generate.md.relevantRegulatedActivities' | lang }}</span>
          </div>
          <div class="item-list-box">
            <div class="item-box">
              <span class="cds-body2-demibold" style="margin-bottom: 0">{{ 'generate.md.MPFIntermediary' | lang }}</span>
            </div>
            <div class="item-box">
              <app-cd-radio
                #radioRelevantRegulatedActivities
                class="cd-radio-margin-bottom-clear"
                [(value)]="relevantRegulatedActivitiesValue"
                [config]="relevantRegulatedActivitiesConfig"></app-cd-radio>
            </div>
            <div class="l-mt-1">
              <cds-assistive-text *ngIf="isTryConfirm && !relevantRegulatedActivitiesValue">{{
                'generate.md.pleaseSelectRelevantRegulatedActivities' | lang
              }}</cds-assistive-text>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!isConfirming" class="information-list-box">
        <div class="information-box">
          <div>
            <span class="cds-h4-light">{{ 'generate.md.2ndMPFIntermediary' | lang }}</span>
          </div>
          <div class="item-list-box">
            <div class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body2-demibold" style="margin-bottom: 0">{{ 'generate.md.addMPFIntermediary' | lang }}：</span>
              </div>
              <div *ngIf="isConfirming" style="flex: 1">
                <span class="cds-body1">{{ secondAgentCode.value }}</span>
              </div>
              <div *ngIf="!isConfirming" class="offsets" style="flex: 1">
                <div class="l-d-f">
                  <div style="max-width: 560px; min-width: 560px">
                    <app-cd-input
                      [group]="individualsForm"
                      controlName="secondAgentCode"
                      [type]="'number'"
                      maxlength="6"
                      [isHasErrors]="secondAgentCodeError"
                      placeholder="輸入強積金中介人編號（選填）"></app-cd-input>
                  </div>
                </div>
                <div *ngIf="(secondAgentCode?.touched && secondAgentCode?.hasError('invalid')) || secondAgentCodeError" class="l-mt-1">
                  <cds-assistive-text>
                    <span>
                      {{ 'generate.md.2nd-invalid-agent-code' | lang }}
                    </span>
                  </cds-assistive-text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isConfirming && secondAgentInfo" class="information-list-box">
        <div class="information-box">
          <div>
            <span class="cds-h4-light">{{ 'generate.md.2nd-MPF-intermediary-details' | lang }}</span>
          </div>
          <div class="item-list-box">
            <div class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body2-demibold">{{ 'generate.md.agent-code' | lang }}</span
                ><span class="cds-body2-demibold">:</span>
              </div>
              <div style="flex: 1">
                <span class="cds-body1">{{ secondAgentInfo.agentCode }}</span>
              </div>
            </div>
            <div class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body2-demibold">{{ 'generate.md.agent-name' | lang }}</span
                ><span class="cds-body2-demibold">:</span>
              </div>
              <div style="flex: 1">
                <span class="cds-body1">{{ getSecondAgentName }}</span>
              </div>
            </div>
            <div *ngIf="secondAgentRelevantRegulatedActivities === 'Y'" class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body2-demibold">{{ 'generate.md.clientEmailorPhone' | lang }}</span
                ><span class="cds-body2-demibold">:</span>
              </div>
              <div style="flex: 1">
                <span class="cds-body1">{{ secondAgentClientContact === 'E' ? secondAgentInfo.shortEmail : '(+852) ' + secondAgentInfo.shortPhone }}</span>
              </div>
            </div>
            <div *ngIf="secondAgentRelevantRegulatedActivities === 'Y' && getSecondAgentLanguage" class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body2-demibold">{{ 'generate.md.selectLanguage' | lang }}</span
                ><span class="cds-body2-demibold">:</span>
              </div>
              <div style="flex: 1">
                <span class="cds-body1">{{ getSecondAgentLanguage }}</span>
              </div>
            </div>
            <div class="second-agent-relevant-regulated-activities-box l-mt-5">
              <div>
                <span class="cds-detail2">{{ 'generate.md.relevantRegulatedActivities' | lang }}</span>
              </div>
              <div class="l-mt-4">{{ 'generate.md.mpf-intermediary' | lang }}</div>
              <div class="l-mt-5">
                <app-cd-radio
                  class="cd-radio-margin-bottom-clear"
                  #radioSecondAgentRelevantRegulatedActivities
                  [(value)]="secondAgentRelevantRegulatedActivities"
                  [config]="secondAgentRelevantRegulatedActivitiesConfig"></app-cd-radio>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="information-list-box" *ngIf="domain === Domain.individual">
        <div class="information-box">
          <div>
            <span class="cds-h4-light">{{ 'generate.md.product' | lang }}</span
            ><span class="cds-h4-light" style="color: var(--cds-color-dark-1-coral)">*</span>
          </div>
          <div class="item-list-box">
            <div class="item-box l-ml-2">
              <cds-checkbox-list ngDefaultControl [config]="productTypeListConfig" [formControl]="productTypes"></cds-checkbox-list>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!isConfirming" class="l-mt-6 l-d-f">
        <div>
          <cds-button [disabled]="!isChangedAnyValues" (click)="reset()" [style]="'secondary'">{{ 'common.reset1' | lang }}</cds-button>
        </div>
        <div class="l-ml-4">
          <cds-button [disabled]="isDisableNext" (click)="next()">{{ 'common.next' | lang }}</cds-button>
        </div>
      </div>

      <div *ngIf="isConfirming" class="l-mt-6 l-d-f">
        <div>
          <cds-button (click)="backToEdit2()" [style]="'secondary'">{{ 'common.back' | lang }}</cds-button>
        </div>
        <div class="l-ml-4">
          <cds-button (click)="submit()">{{ 'common.confirmAndSubmit' | lang }}</cds-button>
          <div class="l-mt-3">
            <cds-assistive-text>{{ 'generate.md.no-changes-submitted' | lang }}</cds-assistive-text>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div *ngIf="show === Show.intermediary" class="individuals">
  <div class="head-box">
    <div class="back-box">
      <cds-icon (click)="onBack()" [config]="backIconConfig" [icon]="backIcon"> </cds-icon>
      <div class="back-title">
        <span (click)="onBack()" class="cds-body2-demibold">Exit and back to MD_IFF Summary Page</span>
      </div>
    </div>
    <span class="head">Generate eIFF Reference Number</span>
  </div>

  <div class="content-box">
    <div *ngIf="domain === Domain.individual" class="l-d-f l-ai-cen">
      <cds-icon style="width: 40px; height: 40px; --cds-icon-color: var(--cds-bg-ml)" icon="people:login_1"></cds-icon>
      <div class="l-ml-4">
        <span class="cds-h2-demibold" style="color: var(--cds-bg-ml)">{{ 'generate.md.individuals' | lang }}</span>
      </div>
    </div>
    <div *ngIf="domain === Domain.sep" class="l-d-f l-ai-cen">
      <cds-icon style="width: 40px; height: 40px; --cds-icon-color: var(--cds-bg-ml)" icon="contact:qualify"></cds-icon>
      <div class="l-ml-4">
        <span class="cds-h2-demibold" style="color: var(--cds-bg-ml)">{{ 'generate.md.sep' | lang }}</span>
      </div>
    </div>
    <div class="l-mt-7">
      <span class="cds-h2-demibold">{{ 'generate.md.split-agent-declaration' | lang }}</span>
    </div>
    <div class="l-mt-4">
      <span class="cds-detail2">{{ 'generate.md.addMPFIntermediary' | lang }}</span>
    </div>
    <div class="information-list-box">
      <div class="information-box">
        <div>
          <span class="cds-h4-light">{{ 'generate.md.2nd-MPF-intermediary-details' | lang }}</span>
        </div>
        <div class="item-list-box">
          <div class="item-box">
            <div style="width: 270px; line-height: 33px">
              <span class="cds-body2-demibold">{{ 'generate.md.agent-code' | lang }}</span
              ><span class="cds-body2-demibold">:</span>
            </div>
            <div style="flex: 1">
              <span class="cds-body1">{{ secondAgentInfo?.agentCode }}</span>
            </div>
          </div>
          <div class="item-box">
            <div style="width: 270px; line-height: 33px">
              <span class="cds-body2-demibold">{{ 'generate.md.agent-name' | lang }}</span
              ><span class="cds-body2-demibold">:</span>
            </div>
            <div style="flex: 1">
              <span class="cds-body1">{{ getSecondAgentName }}</span>
            </div>
          </div>
          <div class="second-agent-relevant-regulated-activities-box l-mt-5">
            <div>
              <span class="cds-detail2">{{ 'generate.md.relevantRegulatedActivities' | lang }}</span>
            </div>
            <div class="l-mt-4">{{ 'generate.md.mpf-intermediary' | lang }}</div>
            <div class="l-mt-5">
              <app-cd-radio
                class="cd-radio-margin-bottom-clear"
                #radioSecondAgentRelevantRegulatedActivities
                [(value)]="secondAgentRelevantRegulatedActivities"
                [config]="secondAgentRelevantRegulatedActivitiesConfig"></app-cd-radio>
            </div>
            <ng-container *ngIf="secondAgentRelevantRegulatedActivities === 'Y'">
              <div class="l-mt-7">
                <app-matters-needing-attention [title]="'generate.md.important-notes' | lang">
                  <div class="cds-detail2 l-d-f l-fd-col">
                    <span class="cds-detail2 l-mt-1">
                      {{ 'generate.md.mpf-declaration-will' | lang }}
                    </span>
                  </div>
                </app-matters-needing-attention>
              </div>
              <div class="l-mt-7">
                <span class="cds-body2-demibold" style="color: #000000">{{ 'generate.md.choose-the-following-registered' | lang }}</span>
              </div>
              <div *ngIf="secondAgentClientContactConfig" class="l-mt-4">
                <app-cd-radio
                  class="cd-radio-margin-bottom-clear cd-radio-vertical-distance"
                  #radioSecondAgentClientContact
                  [(value)]="secondAgentClientContact"
                  (valueChange)="secondAgentClientContactChange()"
                  [config]="secondAgentClientContactConfig"></app-cd-radio>
              </div>
              <div *ngIf="secondAgentClientContact === 'M'" class="l-mt-2 l-d-f">
                <div>
                  <span>{{ 'generate.md.selectLanguage' | lang }}</span>
                </div>
                <div style="margin-left: 100px">
                  <app-cd-radio
                    class="cd-radio-margin-bottom-clear"
                    #radioSecondAgentLanguage
                    [(value)]="secondAgentLanguage"
                    [config]="secondAgentLanguageConfig"></app-cd-radio>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="l-mt-6 l-d-f">
      <div>
        <cds-button (click)="backToEdit1()" [style]="'secondary'">{{ 'common.back' | lang }}</cds-button>
      </div>
      <div class="l-ml-4">
        <cds-button [disabled]="isDisableIntermediaryNext" (click)="intermediaryNext()">{{ 'common.next' | lang }}</cds-button>
      </div>
    </div>
  </div>
</div>
